import React, { useEffect, useMemo, useRef, useState } from "react";
import "@fullcalendar/react/dist/vdom";
import FullCalendar, { EventInput } from "@fullcalendar/react"; //plugin 부르기 전에 불러와야함
import dayGridPlugin from "@fullcalendar/daygrid";
import momentPlugin from "@fullcalendar/moment";
import timeGridPlugin from "@fullcalendar/timegrid";
import koLocale from "@fullcalendar/core/locales/ko";
import moment from "moment";

import { useAppSelector } from "../../../../app/hooks";
import { createPollSelectors } from "../createPollSlice";
import "./CreatePollCalendar.css";
import { useHandleEventMouseEnter } from "./hooks/useHandleEventMouseEnter";
import { useHandleEventMouseLeave } from "./hooks/useHandleEventMouseLeave";
import { useHandleEventMouseClick } from "./hooks/useHandleEventMouseClick";
import { parseHourToMoment } from "../CreatePollSetting";
import { useHandleEventContent } from "./hooks/useHandleEventContent";
import { useHandleDayHeaderContentChange } from "./hooks/useHandleDayHeaderContentChange";

const headerToolbar = {
  //left: "prev,next today",
  // right: "timeGridFourDay,timeGridWeek,timeGridDay,timeGridDayCountFourDay",
  right: "",
};
const plugins = [momentPlugin, dayGridPlugin, timeGridPlugin];
const calendarViewType = "timeGridWeek";
const locales = [koLocale];

export interface CreatePollCalendarProps {
  isMobile?: boolean;
}

export function CreatePollCalendar({ isMobile }: CreatePollCalendarProps) {
  const {
    startAt,
    endAt,
    slotUnitMinutes,
    dateRange,
    calendarEventInputs: eventInputs,
  } = useAppSelector(createPollSelectors.selectState);
  const selectedEvents = useAppSelector((store) =>
    createPollSelectors.selectedEventsSelectors.selectAll(store)
  );
  const [tempEventInput, setTempEventInput] = useState<EventInput | undefined>(
    undefined
  );

  const calendarRef = useRef<FullCalendar | null>(null);

  const viewDuration = useMemo(() => {
    return isMobile ? { days: 3 } : { weeks: 1 };
  }, [isMobile]);
  const mergedEventInputs = useMemo(
    () => [
      ...eventInputs.availableAndOccupied,
      ...(tempEventInput !== undefined ? [tempEventInput] : []),
      ...eventInputs.selected,
      ...eventInputs.selectedLabel,
      ...eventInputs.background,
    ],
    [eventInputs, tempEventInput]
  );
  const views = useMemo(() => {
    return {
      [calendarViewType]: {
        type: calendarViewType,
        duration: viewDuration,
        allDaySlot: false,
        slotMinTime: startAt,
        slotMaxTime: endAt,
        slotEventOverlap: false,
        slotLabelFormat: " ", //https://fullcalendar.io/docs/date-formatting 일단 공백으로 나둠
      },
    };
  }, [viewDuration, startAt, endAt]);
  const contentHeight = useMemo(() => {
    const startMoment = parseHourToMoment(startAt);
    const endMoment = parseHourToMoment(endAt);
    const slotHeight = Math.ceil(
      moment.duration(endMoment.diff(startMoment)).asMinutes() / slotUnitMinutes
    );
    return slotHeight * (80 + 10) + 84;
  }, [slotUnitMinutes, startAt, endAt]); // FIXME: isMobile에 따라 변환

  const handleEventMouseEnter = useHandleEventMouseEnter({
    tempEventInput,
    eventInputs,
    onTempEventInputChange: setTempEventInput,
  });
  const handleEventMouseLeave = useHandleEventMouseLeave({
    onTempEventInputChange: setTempEventInput,
  });
  const handleEventMouseClick = useHandleEventMouseClick({
    tempEventInput,
    onTempEventInputChange: setTempEventInput,
    isAvailableAppend: selectedEvents.length < 10, // 10개 이상 추가 불가 // TODO: 토스트 추가
  });
  const handleEventContent = useHandleEventContent();
  const handleDayHeaderContentChange = useHandleDayHeaderContentChange({
    calendarRef,
    isMobile,
  });

  useEffect(() => {
    if (dateRange && dateRange.length > 1)
      calendarRef.current?.getApi().gotoDate(dateRange[0]?.toDate());
  }, [dateRange]);

  return (
    <FullCalendar
      selectable={false}
      ref={calendarRef}
      // @ts-ignore
      plugins={plugins}
      headerToolbar={headerToolbar}
      initialView={calendarViewType}
      stickyHeaderDates={true}
      contentHeight={contentHeight}
      expandRows={true}
      views={views}
      events={mergedEventInputs}
      eventContent={handleEventContent}
      eventMouseEnter={handleEventMouseEnter}
      eventMouseLeave={handleEventMouseLeave}
      eventClick={handleEventMouseClick}
      selectMirror={false}
      locales={locales}
      locale={"ko"}
      dayHeaderContent={handleDayHeaderContentChange}
      titleFormat={" "}
    />
  );
}
