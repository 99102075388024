import React from "react";
import { Item, Span } from "react-html-email";

const emailInfoTitleStyle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#333",
};

export interface EmailInfoTitleProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}
export function EmailInfoTitle({ children, style }: EmailInfoTitleProps) {
  return (
    <Item style={style}>
      <Span {...emailInfoTitleStyle}>{children}</Span>
    </Item>
  );
}
