import { useEffect, useState } from "react";
import { EventWithGuests, GuestWithStatus } from "../app/services/events/types";

export function useSortedGuestsFromEvent(event?: EventWithGuests) {
  const [guests, setGuests] = useState<GuestWithStatus[]>([]);

  useEffect(() => {
    if (event) {
      const hostIndex = event.guests.findIndex((d) => d.is_host);
      if (hostIndex >= 0) {
        setGuests([
          event.guests[hostIndex],
          ...event.guests.slice(0, hostIndex),
          ...event.guests.slice(hostIndex + 1),
        ]);
      } else {
        setGuests(event.guests);
      }
    } else {
      setGuests([]);
    }
  }, [event]);

  return guests;
}
