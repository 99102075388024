import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box } from "@mui/material";
import validator from "validator";

import { isAlpha } from "../../utils/isAlpha";
import {
  StyledFormCard,
  StyledFormCardContent,
} from "../styled/StyledSignUpForm";
import { isPassword } from "../../utils/isPassword";
import {
  ValidationOptions,
  FormValidationValue,
  useFormValidation,
} from "../../hooks/useFormValidation";
import { TextFieldWithValidation } from "../inputs/TextFieldWithValidation";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { GoogleSignUpLink } from "../buttons/Link";
import { useHistory } from "react-router-dom";

export interface SignUpFormValue extends FormValidationValue {
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
}

export const valueKeys = ["email", "name", "password", "confirmPassword"];

const inputValidators: ValidationOptions<SignUpFormValue> = {
  email: {
    validator: (value) => validator.isEmail(value?.email || ""),
    required: true,
  },
  name: {
    validator: (value) => isAlpha(value?.name || ""),
    required: true,
  },
  password: {
    validator: (value) => isPassword(value?.password || ""),
    required: true,
  },
  confirmPassword: {
    validator: (value) => value?.password === value?.confirmPassword,
    required: true,
  },
};

export interface SignUpFormProps {
  value: SignUpFormValue;
  errorStatusCode?: number;
  disabledKeys?: (keyof SignUpFormValue)[];
  onChange?: (value: SignUpFormValue) => void;
  onSubmit?: (value: SignUpFormValue) => void;
}

export function SignUpForm({
  value,
  disabledKeys,
  errorStatusCode,
  onChange,
  onSubmit,
}: SignUpFormProps) {
  const history = useHistory();
  const { t } = useTranslation("signUp");
  const [isAfterSubmit, setIsAfterSubmit] = useState<boolean>(false);
  const { isFormatValid, isFulfillValid, isTotalValid } = useFormValidation(
    valueKeys,
    value,
    inputValidators
  );
  const handleSubmit = useCallback(() => {
    setIsAfterSubmit(true);
    if (onSubmit && isTotalValid) {
      onSubmit(value);
    }
  }, [onSubmit, value, isTotalValid]);

  useEffect(() => {
    setIsAfterSubmit(false); // 초기화
  }, [value]);

  return (
    <ContainerWithTitle
      title={t("Welcome")}
      description={t("google.Description")}
      hasQuotation={true}
    >
      <GoogleSignUpLink
        href={history.createHref({ pathname: "/login/google" })}
      >
        {t("google.SignUp")}
      </GoogleSignUpLink>
      <StyledFormCard variant="outlined">
        <StyledFormCardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              "& > :not(style)": { mb: 1 },
            }}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            {valueKeys.map((name) => {
              let errorMessage: string | undefined = undefined;
              if (
                isAfterSubmit &&
                errorStatusCode === 409 &&
                name === "email"
              ) {
                // duplicated
                errorMessage = t("DuplicatedEmail");
              } else if (isAfterSubmit && !isFulfillValid[name]) {
                errorMessage = t("NeedInput");
              } else if (!isFormatValid[name]) {
                errorMessage = t(`invalidFormats.${name}`);
              }
              return (
                <TextFieldWithValidation
                  type={
                    name === "email"
                      ? name
                      : name === "password" || name === "confirmPassword"
                      ? "password"
                      : "text"
                  }
                  disabled={disabledKeys && disabledKeys.includes(name)}
                  value={value[name]}
                  placeholder={t(`labels.${name}`)}
                  errorMessage={errorMessage}
                  autoComplete={undefined}
                  onValueChange={(text) => {
                    if (onChange) {
                      onChange({ ...value, [name]: text });
                    }
                  }}
                />
              );
            })}
          </Box>
        </StyledFormCardContent>
      </StyledFormCard>
      <Box component="div" className="footer">
        <Button
          className="footer-button"
          fullWidth
          onClick={handleSubmit}
          type={"submit"}
          variant="cta"
          color="primary"
        >
          {t("SignUp")}
        </Button>
      </Box>
    </ContainerWithTitle>
  );
}
