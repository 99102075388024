import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import styled, { css } from "@mui/styled-engine";
import { Box, Button, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { HighlightOff } from "@mui/icons-material";
import { useDesktopMatches } from "../../../hooks/useDesktopMatches";
import { PollEventResponse } from "../../../app/services/poll/types";
import { SegmentButton } from "../../../components/buttons/SegmentButton";
import { SelectEventGuestAddWidget } from "./SelectEventGuestAddWidget";
import { selectEventSelectors, selectEventSlice } from "./selectEventSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { BigTitle, SubDescription } from "../../../styled/typography";
import { GuestChip } from "../common/GuestChip";
import { MainBorder } from "../../../styled/layout/setting";
import {
  SelectedScheduleWithGuests,
  SelectScheduleCheckbox,
} from "../common/SelectScheduleCheckbox";

const CreateEventMainBox = styled(Box)`
  padding: 20px 40px;
  max-width: 1040px;
  margin: 0 auto;
`;

const AddGuestContainer = styled(Box)`
  padding-bottom: 9px;
  width: calc(100% + 15px);
`;

const SubTitle = styled(Box)`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 31px 0 27px;
`;

const EmptyStateContainer = styled(Box)`
  position: relative;
  display: flex;
  height: 660px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > .emoji {
    font-size: 70px;
    margin-bottom: 15px;
  }
  > .description {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
    white-space: pre-line;
  }
`;

const SelectEventConfirmButtonWrapper = styled(Box)<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          position: fixed;
          bottom: 0;
          width: 100%;
          left: 0;
        `
      : css`
          max-width: 960px;
          position: absolute;
          bottom: 91px;
          width: calc(100% - 80px);
        `}
`;

const SelectEventConfirmButton = styled(Button)<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100%;
          bottom: 0;
        `
      : css`
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        `}
  position: absolute;
  min-width: 360px;
  justify-content: space-between;
  border-radius: 0;
`;

const SelectEventMainEmptyState = () => {
  return (
    <EmptyStateContainer>
      <Box className="emoji">😀</Box>
      <Box component="p" className="description">
        {"위 게스트 중 본인을 선택해 주세요~\n" +
          "\n" +
          "혹시, 이름이 없으신 경우 게스트를 등록하시면\n" +
          "일정을 선택하실 수 있어요!"}
      </Box>
    </EmptyStateContainer>
  );
};

type AttendButtonValue = "attend" | "not-attend";
const attendButtonItems = [
  {
    value: "attend" as AttendButtonValue,
    label: "참석",
  },
  {
    value: "not-attend" as AttendButtonValue,
    label: "불참",
  },
];

export const SelectEventMain = ({
  pollInfo,
}: {
  pollInfo: PollEventResponse | undefined;
}) => {
  const isDesktop = useDesktopMatches();
  const dispatch = useAppDispatch();
  const schedules = useMemo(() => {
    if (pollInfo?.cards) {
      return pollInfo.cards.map(
        (card) =>
          ({
            id: card.key,
            start: moment(card.start_at).toDate(),
            end: moment(card.end_at).toDate(),
            guests: card.guests,
            select_count: card.select_count,
          } as SelectedScheduleWithGuests)
      );
    }
    return [];
  }, [pollInfo?.cards]);
  const guestTotal = useMemo(() => {
    return pollInfo?.guests?.length || 0;
  }, [pollInfo?.guests]);
  const initCheckedMap = useMemo(() => {
    return schedules.reduce((result, schedule) => {
      result[schedule.id] = false;
      return result;
    }, {} as Record<string, boolean>);
  }, [schedules]);
  const { selectedGuestKey, newGuest } = useAppSelector(
    selectEventSelectors.selectState
  );
  const [attendValue, setAttendValue] = useState<AttendButtonValue>("attend");
  const [checkedMap, setCheckedMap] =
    useState<Record<string, boolean>>(initCheckedMap);
  useEffect(() => {
    dispatch(selectEventSlice.actions.setSelectedGuestKey(undefined)); //초기화
    dispatch(selectEventSlice.actions.setNewGuest(undefined)); //초기화
  }, [dispatch]);
  useEffect(() => {
    setCheckedMap(initCheckedMap);
  }, [initCheckedMap]);

  useEffect(() => {
    if (selectedGuestKey) {
      setAttendValue("attend"); // 초기화
      setCheckedMap(initCheckedMap);
    }
    // ignore initCheckedMap
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuestKey]);

  const handleCheckChange = useCallback(
    (checked: boolean, index: number) => {
      setCheckedMap((prev) => {
        const result = { ...prev };
        if (schedules[index]?.id) {
          result[schedules[index].id] = checked;
        }
        return result;
      });
    },
    [schedules]
  );

  const handleAttendValueChange = useCallback(
    (_, value) => {
      setAttendValue(value);
      if (value === "not-attend") {
        setCheckedMap(initCheckedMap);
      }
    },
    [initCheckedMap]
  );

  const handleGuestChipClick = useCallback(
    (guest, selected) =>
      dispatch(
        selectEventSlice.actions.setSelectedGuestKey(
          !selected && guest.key ? guest.key : undefined
        )
      ),
    [dispatch]
  );

  const handleNewGuestDelete = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(selectEventSlice.actions.setNewGuest(undefined));
      if (newGuest?.key === selectedGuestKey) {
        dispatch(selectEventSlice.actions.setSelectedGuestKey(undefined));
      }
    },
    [dispatch, newGuest, selectedGuestKey]
  );

  const handleConfirmButton = () => {
    // TODO
  };

  return (
    <CreateEventMainBox>
      <BigTitle sx={{ marginBottom: "18px", marginTop: "10px" }}>
        본인 선택 후, 가능한 시간을 선택해 주세요.
        <br />
        <strong>2시간</strong> 진행되는 이벤트 입니다.
      </BigTitle>
      <SubDescription sx={{ marginBottom: "20px" }}>
        아래 참석자 중 본인을 선택해 주세요.
        <br />
        목록에 없으신 경우 [+게스트 추가하기]를 통해 등록 가능합니다.
      </SubDescription>
      <AddGuestContainer>
        <>
          {newGuest ? (
            <GuestChip
              guest={newGuest}
              onChipClick={handleGuestChipClick}
              selected={!!newGuest && selectedGuestKey === newGuest.key}
            >
              <>
                <span>{newGuest.name}</span>
                <IconButton
                  aria-label={"Remove This Guest"}
                  onClick={handleNewGuestDelete}
                >
                  <HighlightOff />
                </IconButton>
              </>
            </GuestChip>
          ) : null}
          {pollInfo?.guests?.map((guest) => {
            const isSelected = selectedGuestKey === guest.key;
            return (
              <GuestChip
                key={guest.key}
                guest={guest}
                onChipClick={handleGuestChipClick}
                selected={isSelected}
              >
                {guest.name}
              </GuestChip>
            );
          })}
          <SelectEventGuestAddWidget eventKey={pollInfo?.key} />
        </>
      </AddGuestContainer>
      <MainBorder />
      {selectedGuestKey ? (
        <>
          <Box>
            <SubTitle>참석여부</SubTitle>
            <SegmentButton
              color={"primary"}
              items={attendButtonItems}
              value={attendValue}
              onChange={handleAttendValueChange}
            />
          </Box>
          <Box>
            {schedules.map((schedule, index) => (
              <SelectScheduleCheckbox
                key={index}
                schedule={schedule}
                checked={checkedMap[schedule.id] || false}
                index={index}
                guestTotal={guestTotal}
                disabled={attendValue === "not-attend"}
                onChange={handleCheckChange}
              />
            ))}
          </Box>
        </>
      ) : (
        <SelectEventMainEmptyState />
      )}
      {!!Object.keys(checkedMap)?.length && (
        <SelectEventConfirmButtonWrapper isMobile={!isDesktop}>
          <SelectEventConfirmButton
            variant="cta"
            color="primary"
            isMobile={!isDesktop}
            onClick={handleConfirmButton}
          >
            {Object.keys(checkedMap)?.length}개 일정 선택하기
            <ArrowForwardIosIcon />
          </SelectEventConfirmButton>
        </SelectEventConfirmButtonWrapper>
      )}
    </CreateEventMainBox>
  );
};
