import { createApi } from "@reduxjs/toolkit/query/react";

import {
  UserRequest,
  UserResponse,
  UserSignUpRequest,
  UserSignUpRequestWithToken,
} from "./types";
import { fetchCsrfBaseQuery } from "../fetchCsrfBaseQuery";
import { baseUrl } from "./config";

export const authApi = createApi({
  baseQuery: fetchCsrfBaseQuery(`${baseUrl}/`),
  reducerPath: "accounts/authApi",
  tagTypes: ["User"],
  endpoints: (build) => ({
    getUserMe: build.query<UserResponse, void>({
      query() {
        return { url: `auth/user/me/`, method: "GET" };
      },
      providesTags: ["User"],
    }),
    postLogin: build.mutation<UserResponse, UserRequest>({
      query(body) {
        // {email, password}
        return {
          url: "auth/login/",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    postRegister: build.mutation<UserResponse, UserSignUpRequest>({
      // { email, password, first_name, last_name }
      // { content: { user }, status }
      query: (body) => ({
        url: "auth/register/",
        method: "POST",
        body,
      }),
    }),
    postOauthRegister: build.mutation<UserResponse, UserSignUpRequestWithToken>(
      {
        query: ({ token, ...body }) => ({
          url: "auth/oauth_register/",
          method: "POST",
          body,
          headers: {
            Authorization: `Token ${token}`,
          },
        }),
      }
    ),
    deleteLogout: build.mutation<void, void>({
      query() {
        return {
          url: "auth/logout/",
          method: "DELETE",
        };
      },
      invalidatesTags: ["User"],
    }),
    deleteUnregister: build.mutation<void, void>({
      query() {
        return {
          url: "auth/unregister/",
          method: "DELETE",
        };
      },
      invalidatesTags: ["User"],
    }),
    // 패스워드 재설정
    putResetPasswordWithUser: build.mutation<
      void,
      { password: string; password_check: string }
    >({
      query: (body) => ({
        url: "password/reset/",
        method: "PUT",
        body,
      }),
    }),
    // 약관 가져오기
    getServiceTerms: build.query<
      { service: string; privacy: string; marketing: string },
      void
    >({
      query: () => ({
        url: "service/terms/",
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostRegisterMutation,
  usePostOauthRegisterMutation,
  useDeleteLogoutMutation,
  useGetUserMeQuery,
  useLazyGetUserMeQuery,
  useDeleteUnregisterMutation,
  usePutResetPasswordWithUserMutation,
  useGetServiceTermsQuery,
} = authApi;
