import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import validator from "validator";
import {
  StyledFormCard,
  StyledFormCardContent,
} from "../styled/StyledSignUpForm";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

export interface FindPasswordFormContentProps {
  value?: string;
  errorStatusCode?: number;
  isDesktop?: boolean;
  onChange?: (value?: string) => void;
  onSubmit?: (value?: string) => void;
}

export function FindPasswordFormContent({
  value,
  errorStatusCode,
  onChange,
  onSubmit,
  isDesktop,
}: FindPasswordFormContentProps) {
  const { t } = useTranslation("findPassword");
  const [isError, setIsError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFulfilled, setIsFulfilled] = useState(false);

  const handleChange = useCallback(
    (e) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );
  const handleSubmit = useCallback(() => {
    if (isFulfilled && onSubmit) onSubmit(value);
    if (!isFulfilled) {
      setIsError(true);
    }
  }, [onSubmit, value, isFulfilled]);

  useEffect(() => {
    if (value !== undefined && value !== "") {
      const isValid = validator.isEmail(value);
      setIsFulfilled(isValid);
      setIsEmailValid(validator.isEmail(value));
    } else {
      setIsFulfilled(false);
      setIsEmailValid(true);
    }
  }, [value]);

  useEffect(() => {
    setIsError(errorStatusCode !== undefined || !isEmailValid);
  }, [isEmailValid, errorStatusCode]);

  return (
    <StyledFormCardContent
      sx={{ padding: isDesktop ? "30px 20px" : "0 !important" }}
    >
      <Typography component="h5" className="card-title">
        {t("Title")}
      </Typography>
      <Typography component="p" className="card-description">
        {t("Description")}
      </Typography>
      <Box
        className="card-form"
        component="form"
        noValidate
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      >
        <TextField
          className="textfield"
          error={isError}
          helperText={
            errorStatusCode !== undefined
              ? t("DuplicatedEmail")
              : !isEmailValid
              ? t("InvalidEmail")
              : isError
              ? t("InvalidEmail")
              : undefined
          }
          placeholder={t("Email")}
          id="invy-find-password-email-input"
          variant="outlined"
          type={"email"}
          value={value}
          onChange={handleChange}
          fullWidth
        />
      </Box>
      <Button
        fullWidth
        onClick={handleSubmit}
        type={"submit"}
        color="primary"
        variant="cta"
      >
        {t("Submit")}
      </Button>
    </StyledFormCardContent>
  );
}

export function FindPasswordForm(props: FindPasswordFormContentProps) {
  const isDesktop = useDesktopMatches();

  return (
    <ContainerWithTitle>
      {isDesktop ? (
        <StyledFormCard>
          <FindPasswordFormContent isDesktop={isDesktop} {...props} />
        </StyledFormCard>
      ) : (
        <FindPasswordFormContent {...props} />
      )}
    </ContainerWithTitle>
  );
}
