import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MoreButton, MoreButtonOption } from "./MoreButton";

export interface EditEventButtonProps {
  onEditEventModalOpen: () => void;
  onInviteGuestsModalOpen: () => void;
  onDeleteEventModalOpen: () => void;
}

export function EditEventButton({
  onEditEventModalOpen,
  onInviteGuestsModalOpen,
  onDeleteEventModalOpen,
}: EditEventButtonProps) {
  const { t } = useTranslation("editEvent");
  const [options, setOptions] = useState<MoreButtonOption[]>([]);
  useEffect(() => {
    if (
      t &&
      onEditEventModalOpen &&
      onInviteGuestsModalOpen &&
      onDeleteEventModalOpen
    ) {
      setOptions([
        { title: t("options.EditEvent"), action: onEditEventModalOpen },
        { title: t("options.InviteGuests"), action: onInviteGuestsModalOpen },
        { title: t("options.DeleteEvent"), action: onDeleteEventModalOpen },
      ]);
    }
  }, [
    t,
    onEditEventModalOpen,
    onInviteGuestsModalOpen,
    onDeleteEventModalOpen,
  ]);
  return (
    <MoreButton
      options={options}
      button={
        <Button size="small" variant="inner" color="normal">
          {t("Title")}
        </Button>
      }
    />
  );
}
