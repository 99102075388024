import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  StyledFormCard,
  StyledFormCardContent,
} from "../styled/StyledSignUpForm";
import { isPassword } from "../../utils/isPassword";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";
import {
  InputFormValueBase,
  InputValidationBase,
  useInputFormatValidation,
} from "../../hooks/useInputFormatValidation";

export interface InputValidations extends InputValidationBase {
  password: boolean;
  confirmPassword: boolean;
}

export type ValueKeys = keyof InputValidations;
export const valueKeys: ValueKeys[] = ["password", "confirmPassword"];

export interface ResetPasswordFormValue
  extends Partial<Record<ValueKeys, string>>,
    InputFormValueBase {
  password?: string;
  confirmPassword?: string;
}

export const initInputFormatValidations: InputValidations = {
  password: true,
  confirmPassword: true,
};

export const initInputFulfillValidations: InputValidations = {
  password: false,
  confirmPassword: false,
};

const inputValidators: Record<ValueKeys, (value: string) => boolean> = {
  password: isPassword,
  confirmPassword: () => true,
};

export interface ResetPasswordTextFieldProps {
  name: keyof InputValidations;
  type: React.ComponentProps<typeof TextField>["type"];
  value?: ResetPasswordFormValue;
  inputFormatValidations: InputValidations;
  inputFulfillValidations: InputValidations;
  errorMessage?: string;
  isAfterSubmit: boolean;
  onChange?: (value?: ResetPasswordFormValue) => void;
  onSubmit?: () => void;
  className?: string;
}

export function ResetPasswordTextField({
  inputFormatValidations,
  inputFulfillValidations,
  isAfterSubmit,
  name,
  value,
  type,
  errorMessage,
  onChange,
  className,
  onSubmit = () => {},
}: ResetPasswordTextFieldProps) {
  const { t } = useTranslation("resetPassword");
  const { isError, handleChange } = useInputFormatValidation<
    InputValidations,
    ResetPasswordFormValue
  >({
    name,
    onChange,
    value,
    errorMessage,
    isAfterSubmit,
    inputFulfillValidations,
    inputFormatValidations,
  });

  return (
    <TextField
      error={isError}
      helperText={
        !inputFormatValidations[name]
          ? t(`invalidFormats.${name}`)
          : errorMessage
          ? errorMessage
          : isError
          ? t(`needInput.${name}`)
          : undefined
      }
      id={`invy-reset-password-form-${name}`}
      placeholder={t(`labels.${name}`)}
      variant="outlined"
      type={type}
      value={value?.[name]}
      autoComplete={type === "password" ? "new-password" : undefined}
      className={className}
      onChange={handleChange}
      onKeyPress={(ev) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          onSubmit?.();
        }
      }}
      fullWidth
    />
  );
}

export interface ResetPasswordFormProps {
  value?: ResetPasswordFormValue;
  errorStatusCode?: number;
  onChange?: (value?: ResetPasswordFormValue) => void;
  onSubmit?: (value?: ResetPasswordFormValue) => void;
  isDesktop?: boolean;
}

export function ResetPasswordFormContent({
  value,
  errorStatusCode,
  onChange,
  onSubmit,
  isDesktop,
}: ResetPasswordFormProps) {
  const { t } = useTranslation("resetPassword");
  const [isAfterSubmit, setIsAfterSubmit] = useState<boolean>(false);
  const [isFulfilled, setIsFulfilled] = useState<boolean>(false);
  const [inputFormatValidations, setInputFormatValidations] =
    useState<InputValidations>(initInputFormatValidations);
  const [inputFulfillValidations, setInputFulfillValidations] =
    useState<InputValidations>(initInputFulfillValidations);

  const handleSubmit = useCallback(() => {
    setIsAfterSubmit(true);
    if (onSubmit && isFulfilled) {
      onSubmit(value);
    }
  }, [onSubmit, value, isFulfilled]);

  useEffect(() => {
    setIsAfterSubmit(false); // 초기화
  }, [value]);

  useEffect(() => {
    // validate fulfill
    if (!value) return setInputFulfillValidations(initInputFulfillValidations);

    const validations = { ...initInputFulfillValidations };
    for (const key of valueKeys) {
      const targetValue = value[key];
      validations[key] = targetValue !== undefined && targetValue.trim() !== "";
    }
    setInputFulfillValidations(validations);
  }, [value]);

  useEffect(() => {
    // validate formats
    if (value) {
      const newErrors = Object.entries(inputValidators).reduce(
        (result, [key, validator]) => {
          const typedKey = key as ValueKeys;
          const targetValue = value[typedKey];

          if (targetValue && targetValue !== "") {
            if (typedKey === "confirmPassword") {
              result[typedKey] = value.confirmPassword === value.password;
            } else {
              result[typedKey] = validator(targetValue);
            }
          } else {
            result[typedKey] = true;
          }
          return result;
        },
        { ...initInputFormatValidations }
      );
      setInputFormatValidations(newErrors);
    }
  }, [value]);

  useEffect(() => {
    // 모두 validate 한지 확인
    for (const prop in inputFormatValidations) {
      const isValid = inputFormatValidations[prop as ValueKeys];
      if (!isValid) {
        return setIsFulfilled(false);
      }
    }

    for (const prop in inputFulfillValidations) {
      const isValid = inputFormatValidations[prop as ValueKeys];
      if (!isValid) {
        return setIsFulfilled(false);
      }
    }

    setIsFulfilled(true);
  }, [inputFormatValidations, inputFulfillValidations]);

  return (
    <StyledFormCardContent
      sx={{ padding: isDesktop ? "30px 20px" : "0 !important" }}
    >
      <Typography component="h5" className="card-title">
        {t("Title")}
      </Typography>
      <Typography component="p" className="card-description">
        {t("Description")}
      </Typography>
      <Box
        className="card-form"
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          "& > :not(style)": { mb: 1 },
        }}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      >
        {valueKeys.map((name) => {
          let errorMessage: string | undefined = undefined;
          if (isAfterSubmit && errorStatusCode === 404 && name === "password") {
            // duplicated
            errorMessage = t("Invalid");
          }
          return (
            <ResetPasswordTextField
              className="textfield"
              key={name}
              name={name}
              type={"password"}
              value={value}
              errorMessage={errorMessage}
              isAfterSubmit={isAfterSubmit}
              inputFormatValidations={inputFormatValidations}
              inputFulfillValidations={inputFulfillValidations}
              onChange={onChange}
            />
          );
        })}
      </Box>
      <Button
        fullWidth
        onClick={handleSubmit}
        type={"submit"}
        variant="cta"
        color="primary"
      >
        {t("Reset")}
      </Button>
    </StyledFormCardContent>
  );
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const isDesktop = useDesktopMatches();

  return (
    <ContainerWithTitle>
      {isDesktop ? (
        <StyledFormCard>
          <ResetPasswordFormContent isDesktop={isDesktop} {...props} />
        </StyledFormCard>
      ) : (
        <ResetPasswordFormContent {...props} />
      )}
    </ContainerWithTitle>
  );
}
