import React from "react";
import styled, { css } from "@mui/styled-engine";
import { Box, BoxProps, Typography } from "@mui/material";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

export const StyledContentWithTitle = styled(Box)<{
  isDesktop: boolean;
  noTopPadding: boolean;
  isRightButtonWithTitle: boolean;
  isWrapper: boolean;
}>`
  margin: 0 auto;
  max-width: 515px;
  position: relative;
  ${(props) =>
    props.isWrapper
      ? ""
      : !props.isDesktop
      ? css`
          padding: 0 20px;
        `
      : ""}
  padding-top: ${(props) => (props.noTopPadding ? 0 : 36)}px;

  .wrapper {
    white-space: pre-line;
    > .sub-title {
      font-size: 14px;
      color: #767676;
    }
    > .description {
      font-size: 14px;
      color: #767676;
    }
    ${(props) =>
      props.isRightButtonWithTitle
        ? css`
            > .right-button {
              position: absolute;
              right: 0;
              top: 52px;
              transform: translateY(-50%);
            }
          `
        : css`
            > .right-button {
              margin-top: 16px;
              display: flex;
              justify-content: flex-end;
            }
          `}
  }
  .textfield {
    input {
      background-color: #ffffff;
    }
  }
`;

const StyledTitleArea = styled(Box)<{
  hasQuotation: boolean;
  isDesktop: boolean;
}>`
  position: relative;
  white-space: pre-wrap;
  word-break: break-all;
  strong {
    color: #4555ff;
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    color: #232323;
    white-space: pre-line;
    &-decorator {
      font-size: 22px;
      font-weight: 700;
      color: #4555ff;
      & > span {
        display: inline-block;
        max-width: 100%;
      }
    }
  }

  ${(props) =>
    props.hasQuotation &&
    css`
      padding: 0 30px;
      &:before,
      &:after {
        content: " ";
        width: 20px;
        height: 25px;
        position: absolute;
        top: 3px;
        background: url("/images/ic_quatation_blue.svg") no-repeat;
        background-size: 20px 25px;
      }
      &:before {
        left: 0;
        transform-origin: center center;
        transform: rotate(180deg);
      }
      &:after {
        right: 0;
      }
    `}
`;

export interface ContainerWithTitleProps extends BoxProps {
  titleDecorator?: string;
  titleComponent?: React.ElementType;
  title?: string;
  subTitle?: string;
  description?: string;
  hasQuotation?: boolean;
  noTopPadding?: boolean;
  cta?: React.ReactNode;
  children: React.ReactNode;
  rightButton?: React.ReactNode;
  isRightButtonWithTitle?: boolean;
  isWrapper?: boolean;
  descriptionMarginTop?: number;
}
export function ContainerWithTitle({
  title,
  subTitle,
  description,
  children,
  titleDecorator,
  titleComponent = "div",
  rightButton,
  hasQuotation = false,
  noTopPadding = false,
  isWrapper = false,
  isRightButtonWithTitle = false,
  descriptionMarginTop = 32,
  ...props
}: ContainerWithTitleProps) {
  const isDesktop = useDesktopMatches();
  return (
    <StyledContentWithTitle
      isDesktop={isDesktop}
      noTopPadding={noTopPadding}
      isRightButtonWithTitle={isRightButtonWithTitle}
      isWrapper={isWrapper}
      className="container-with-title"
      {...props}
    >
      <Box className="wrapper">
        {title && (
          <StyledTitleArea hasQuotation={hasQuotation} isDesktop={isDesktop}>
            {titleDecorator && (
              <>
                <strong className="title-decorator">[{titleDecorator}]</strong>{" "}
              </>
            )}
            {title ? (
              <>
                <Typography
                  variant="h5"
                  component={titleComponent}
                  className="title"
                >
                  {title.split("\n").map((line, index) => {
                    return (
                      <React.Fragment key={index}>
                        <span
                          key={`title-${index}`}
                          dangerouslySetInnerHTML={{
                            __html: line,
                          }}
                        />
                        <br />
                      </React.Fragment>
                    );
                  })}
                </Typography>
                {subTitle && (
                  <Typography component="div" className="sub-title">
                    {subTitle}
                  </Typography>
                )}
              </>
            ) : null}
          </StyledTitleArea>
        )}
        {description ? (
          <Typography
            sx={{
              fontSize: 14,
              marginTop: `${
                descriptionMarginTop ? descriptionMarginTop : 32
              }px`,
            }}
            color="text.secondary"
            className="description"
            gutterBottom
          >
            {description.split("\n").map((line, index) => {
              return (
                <span key={`description-${index}`}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Typography>
        ) : undefined}
        {rightButton && <Box className="right-button">{rightButton}</Box>}
      </Box>
      {children}
    </StyledContentWithTitle>
  );
}
