import React, { useCallback } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { usePostResendEmailMutation } from "../../app/services/accounts/verifyApi";
import { RequestVerificationContainer } from "../../components/forms/RequestVerificationContainer";

export function ResendVerificationPage() {
  const { state } = useLocation<{ email?: string }>();
  const [postResend, { isSuccess, isLoading }] = usePostResendEmailMutation();
  const email = state?.email;
  const handleResendClick = useCallback(() => {
    if (email && postResend) {
      postResend({ email });
    }
  }, [email, postResend]);

  if (!email) return <Redirect to={"/login"} />;
  else
    return (
      <RequestVerificationContainer
        email={email}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onSend={handleResendClick}
      />
    );
}
