import React from "react";
import styled from "@mui/styled-engine";

const StyledWelcomePage = styled("article")`
  padding: 0 18px;

  h1,
  h2,
  h3 {
    letter-spacing: -0.01em;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 0;
  }

  .page-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  h1 {
    font-size: 1.875rem;
    margin-top: 1.875rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
    margin-top: 1.25rem;
  }

  .source {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 1.5em;
    word-break: break-all;
  }

  .callout {
    border-radius: 3px;
    padding: 1rem;
  }

  figure {
    margin: 1.25em 0;
    page-break-inside: avoid;
  }

  figcaption {
    opacity: 0.5;
    font-size: 85%;
    margin-top: 0.5em;
  }

  mark {
    background-color: transparent;
  }

  .indented {
    padding-left: 1.5em;
  }

  hr {
    background: transparent;
    display: block;
    width: 100%;
    height: 1px;
    visibility: visible;
    border: none;
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
  }

  img {
    max-width: 100%;
  }

  @media only print {
    img {
      max-height: 100vh;
      object-fit: contain;
    }
  }

  @page {
    margin: 1in;
  }

  .collection-content {
    font-size: 0.875rem;
  }

  .column-list {
    display: flex;
    justify-content: space-between;
  }

  .column {
    padding: 0 1em;
  }

  .column:first-child {
    padding-left: 0;
  }

  .column:last-child {
    padding-right: 0;
  }

  .table_of_contents-item {
    display: block;
    font-size: 0.875rem;
    line-height: 1.3;
    padding: 0.125rem;
  }

  .table_of_contents-indent-1 {
    margin-left: 1.5rem;
  }

  .table_of_contents-indent-2 {
    margin-left: 3rem;
  }

  .table_of_contents-indent-3 {
    margin-left: 4.5rem;
  }

  .table_of_contents-link {
    text-decoration: none;
    opacity: 0.7;
    border-bottom: 1px solid rgba(55, 53, 47, 0.18);
  }

  table,
  th,
  td {
    border: 1px solid rgba(55, 53, 47, 0.09);
    border-collapse: collapse;
  }

  table {
    border-left: none;
    border-right: none;
  }

  th,
  td {
    font-weight: normal;
    padding: 0.25em 0.5em;
    line-height: 1.5;
    min-height: 1.5em;
    text-align: left;
  }

  th {
    color: rgba(55, 53, 47, 0.6);
  }

  ol,
  ul {
    margin: 0;
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
  }

  li > ol:first-child,
  li > ul:first-child {
    margin-block-start: 0.6em;
  }

  ul > li {
    list-style: disc;
  }

  ul.to-do-list {
    text-indent: -1.7em;
  }

  ul.to-do-list > li {
    list-style: none;
  }

  .to-do-children-checked {
    text-decoration: line-through;
    opacity: 0.375;
  }

  ul.toggle > li {
    list-style: none;
  }

  ul {
    padding-inline-start: 1.7em;
  }

  ul > li {
    padding-left: 0.1em;
  }

  ol {
    padding-inline-start: 1.6em;
  }

  ol > li {
    padding-left: 0.2em;
  }

  .mono ol {
    padding-inline-start: 2em;
  }

  .mono ol > li {
    text-indent: -0.4em;
  }

  .toggle {
    padding-inline-start: 0em;
    list-style-type: none;
  }

  /* Indent toggle children */

  .toggle > li > details {
    padding-left: 1.7em;
  }

  .toggle > li > details > summary {
    margin-left: -1.1em;
  }

  .selected-value {
    display: inline-block;
    padding: 0 0.5em;
    background: rgba(206, 205, 202, 0.5);
    border-radius: 3px;
    margin-right: 0.5em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    white-space: nowrap;
  }

  .collection-title {
    display: inline-block;
    margin-right: 1em;
  }

  .simple-table {
    margin-top: 1em;
    font-size: 0.875rem;
    empty-cells: show;
  }

  .simple-table td {
    height: 29px;
    min-width: 120px;
  }

  .simple-table th {
    height: 29px;
    min-width: 120px;
  }

  .simple-table-header-color {
    background: rgb(247, 246, 243);
    color: black;
  }

  .simple-table-header {
    font-weight: 500;
  }

  time {
    opacity: 0.5;
  }

  .icon {
    display: inline-block;
    max-width: 1.2em;
    max-height: 1.2em;
    text-decoration: none;
    vertical-align: text-bottom;
    margin-right: 0.5em;
  }

  img.icon {
    border-radius: 3px;
  }

  .user-icon {
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  .user-icon-inner {
    font-size: 0.8em;
  }

  .text-icon {
    border: 1px solid #000;
    text-align: center;
  }

  .page-cover-image {
    display: block;
    object-fit: cover;
    width: 100%;
    max-height: 30vh;
  }

  .page-header-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .page-header-icon-with-cover {
    margin-top: -0.72em;
    margin-left: 0.07em;
  }

  .page-header-icon img {
    border-radius: 3px;
  }

  .link-to-page {
    margin: 1em 0;
    padding: 0;
    border: none;
    font-weight: 500;
  }

  p > .user {
    opacity: 0.5;
  }

  td > .user,
  td > time {
    white-space: nowrap;
  }

  input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 0.6em;
    vertical-align: middle;
  }

  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .image {
    border: none;
    margin: 1.5em 0;
    padding: 0;
    border-radius: 0;
    text-align: center;
  }

  .code,
  code {
    background: rgba(135, 131, 120, 0.15);
    border-radius: 3px;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-size: 85%;
    tab-size: 2;
  }

  code {
    color: #eb5757;
  }

  .code {
    padding: 1.5em 1em;
  }

  .code-wrap {
    white-space: pre-wrap;
    word-break: break-all;
  }

  .code > code {
    background: none;
    padding: 0;
    font-size: 100%;
    color: inherit;
  }

  blockquote {
    font-size: 1.25em;
    margin: 1em 0;
    padding-left: 1em;
    border-left: 3px solid rgb(55, 53, 47);
  }

  .bookmark {
    text-decoration: none;
    max-height: 8em;
    padding: 0;
    display: flex;
    width: 100%;
    align-items: stretch;
  }

  .bookmark-title {
    font-size: 0.85em;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.75em;
    white-space: nowrap;
  }

  .bookmark-text {
    display: flex;
    flex-direction: column;
  }

  .bookmark-info {
    flex: 4 1 180px;
    padding: 12px 14px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bookmark-image {
    width: 33%;
    flex: 1 1 180px;
    display: block;
    position: relative;
    object-fit: cover;
    border-radius: 1px;
  }

  .bookmark-description {
    color: rgba(55, 53, 47, 0.6);
    font-size: 0.75em;
    overflow: hidden;
    max-height: 4.5em;
    word-break: break-word;
  }

  .bookmark-href {
    font-size: 0.75em;
    margin-top: 0.25em;
  }

  .sans {
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
      "Segoe UI Symbol";
  }

  .code {
    font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono",
      Courier, monospace;
  }

  .serif {
    font-family: Lyon-Text, Georgia, ui-serif, serif;
  }

  .mono {
    font-family: iawriter-mono, Nitti, Menlo, Courier, monospace;
  }

  .pdf .sans {
    font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
      "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
      "Noto Sans CJK JP";
  }

  .pdf:lang(zh-CN) .sans {
    font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
      "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
      "Noto Sans CJK SC";
  }

  .pdf:lang(zh-TW) .sans {
    font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
      "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
      "Noto Sans CJK TC";
  }

  .pdf:lang(ko-KR) .sans {
    font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif,
      "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji", "Noto Color Emoji",
      "Noto Sans CJK KR";
  }

  .pdf .code {
    font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
      "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
      "Noto Sans Mono CJK JP";
  }

  .pdf:lang(zh-CN) .code {
    font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
      "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
      "Noto Sans Mono CJK SC";
  }

  .pdf:lang(zh-TW) .code {
    font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
      "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
      "Noto Sans Mono CJK TC";
  }

  .pdf:lang(ko-KR) .code {
    font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono",
      "Liberation Mono", Courier, monospace, "Twemoji", "Noto Color Emoji",
      "Noto Sans Mono CJK KR";
  }

  .pdf .serif {
    font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
      "Noto Color Emoji", "Noto Serif CJK JP";
  }

  .pdf:lang(zh-CN) .serif {
    font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
      "Noto Color Emoji", "Noto Serif CJK SC";
  }

  .pdf:lang(zh-TW) .serif {
    font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
      "Noto Color Emoji", "Noto Serif CJK TC";
  }

  .pdf:lang(ko-KR) .serif {
    font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, "Twemoji",
      "Noto Color Emoji", "Noto Serif CJK KR";
  }

  .pdf .mono {
    font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
      "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK JP";
  }

  .pdf:lang(zh-CN) .mono {
    font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
      "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK SC";
  }

  .pdf:lang(zh-TW) .mono {
    font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
      "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK TC";
  }

  .pdf:lang(ko-KR) .mono {
    font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace,
      "Twemoji", "Noto Color Emoji", "Noto Sans Mono CJK KR";
  }

  .highlight-default {
    color: rgba(55, 53, 47, 1);
  }

  .highlight-gray {
    color: rgba(120, 119, 116, 1);
    fill: rgba(120, 119, 116, 1);
  }

  .highlight-brown {
    color: rgba(159, 107, 83, 1);
    fill: rgba(159, 107, 83, 1);
  }

  .highlight-orange {
    color: rgba(217, 115, 13, 1);
    fill: rgba(217, 115, 13, 1);
  }

  .highlight-yellow {
    color: rgba(203, 145, 47, 1);
    fill: rgba(203, 145, 47, 1);
  }

  .highlight-teal {
    color: rgba(68, 131, 97, 1);
    fill: rgba(68, 131, 97, 1);
  }

  .highlight-blue {
    color: rgba(51, 126, 169, 1);
    fill: rgba(51, 126, 169, 1);
  }

  .highlight-purple {
    color: rgba(144, 101, 176, 1);
    fill: rgba(144, 101, 176, 1);
  }

  .highlight-pink {
    color: rgba(193, 76, 138, 1);
    fill: rgba(193, 76, 138, 1);
  }

  .highlight-red {
    color: rgba(212, 76, 71, 1);
    fill: rgba(212, 76, 71, 1);
  }

  .highlight-gray_background {
    background: rgba(241, 241, 239, 1);
  }

  .highlight-brown_background {
    background: rgba(244, 238, 238, 1);
  }

  .highlight-orange_background {
    background: rgba(251, 236, 221, 1);
  }

  .highlight-yellow_background {
    background: rgba(251, 243, 219, 1);
  }

  .highlight-teal_background {
    background: rgba(237, 243, 236, 1);
  }

  .highlight-blue_background {
    background: rgba(231, 243, 248, 1);
  }

  .highlight-purple_background {
    background: rgba(244, 240, 247, 0.8);
  }

  .highlight-pink_background {
    background: rgba(249, 238, 243, 0.8);
  }

  .highlight-red_background {
    background: rgba(253, 235, 236, 1);
  }

  .block-color-default {
    color: inherit;
  }

  .block-color-gray {
    color: rgba(120, 119, 116, 1);
    fill: rgba(120, 119, 116, 1);
  }

  .block-color-brown {
    color: rgba(159, 107, 83, 1);
    fill: rgba(159, 107, 83, 1);
  }

  .block-color-orange {
    color: rgba(217, 115, 13, 1);
    fill: rgba(217, 115, 13, 1);
  }

  .block-color-yellow {
    color: rgba(203, 145, 47, 1);
    fill: rgba(203, 145, 47, 1);
  }

  .block-color-teal {
    color: rgba(68, 131, 97, 1);
    fill: rgba(68, 131, 97, 1);
  }

  .block-color-blue {
    color: rgba(51, 126, 169, 1);
    fill: rgba(51, 126, 169, 1);
  }

  .block-color-purple {
    color: rgba(144, 101, 176, 1);
    fill: rgba(144, 101, 176, 1);
  }

  .block-color-pink {
    color: rgba(193, 76, 138, 1);
    fill: rgba(193, 76, 138, 1);
  }

  .block-color-red {
    color: rgba(212, 76, 71, 1);
    fill: rgba(212, 76, 71, 1);
  }

  .block-color-gray_background {
    background: rgba(241, 241, 239, 1);
  }

  .block-color-brown_background {
    background: rgba(244, 238, 238, 1);
  }

  .block-color-orange_background {
    background: rgba(251, 236, 221, 1);
  }

  .block-color-yellow_background {
    background: rgba(251, 243, 219, 1);
  }

  .block-color-teal_background {
    background: rgba(237, 243, 236, 1);
  }

  .block-color-blue_background {
    background: rgba(231, 243, 248, 1);
  }

  .block-color-purple_background {
    background: rgba(244, 240, 247, 0.8);
  }

  .block-color-pink_background {
    background: rgba(249, 238, 243, 0.8);
  }

  .block-color-red_background {
    background: rgba(253, 235, 236, 1);
  }

  .select-value-color-pink {
    background-color: rgba(245, 224, 233, 1);
  }

  .select-value-color-purple {
    background-color: rgba(232, 222, 238, 1);
  }

  .select-value-color-green {
    background-color: rgba(219, 237, 219, 1);
  }

  .select-value-color-gray {
    background-color: rgba(227, 226, 224, 1);
  }

  .select-value-color-opaquegray {
    background-color: rgba(255, 255, 255, 0.0375);
  }

  .select-value-color-orange {
    background-color: rgba(250, 222, 201, 1);
  }

  .select-value-color-brown {
    background-color: rgba(238, 224, 218, 1);
  }

  .select-value-color-red {
    background-color: rgba(255, 226, 221, 1);
  }

  .select-value-color-yellow {
    background-color: rgba(253, 236, 200, 1);
  }

  .select-value-color-blue {
    background-color: rgba(211, 229, 239, 1);
  }

  .checkbox {
    display: inline-flex;
    vertical-align: text-bottom;
    width: 16px;
    height: 16px;
    background-size: 16px;
    margin-left: 2px;
    margin-right: 5px;
  }

  .checkbox-on {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
  }

  .checkbox-off {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
  }
`;

export function WelcomePage() {
  return (
    <StyledWelcomePage
      id="17934215-bff6-416c-9dac-6aae250048f6"
      className="page sans"
    >
      <header>
        <img
          alt={"Warm Welcome to Invy"}
          className="page-cover-image"
          src="/images/welcome/header.png"
          style={{ objectPosition: "center 50%" }}
        />
        <div className="page-header-icon page-header-icon-with-cover">
          <span className="icon">💌</span>
        </div>
        <h1 className="page-title">
          <strong>Warm Welcome to</strong> [invy]
        </h1>
      </header>
      <div className="page-body">
        <p id="4d8f4399-644f-422a-8563-f07cbf56d594" className=""></p>
        <h3 id="0071759f-c7a0-460c-8353-dd9e4611b477" className="">
          👋 Hi there
        </h3>
        <hr id="b1c174bc-e601-4a54-8bcc-3081d7113294" />
        <p id="089a0136-cef1-4e51-9d69-20df5805d033" className="">
          안녕하세요, Invy[인:비]팀입니다. 바쁘신 가운데도 저희 멤버들에 대한
          애정으로 Invy 오픈베타 테스트에 참여해주셔서 진심어린 감사의 말씀을
          드립니다 🙇‍♂️🙇‍♀️{" "}
        </p>
        <p id="5b5c4903-2f1a-4f1b-9e41-db0e07f0dde5" className=""></p>
        <h3 id="3b2d9497-50b4-45b1-8572-abd9c57825c3" className="">
          🤔 invy는 어떤 서비스인가요?
        </h3>
        <hr id="b85fdfbc-1fb9-44ce-889d-f1bf4b4c0f21" />
        <p id="b7aa0a57-19df-4212-a8b5-83965819e133" className="">
          우리는 비즈니스 미팅, 친구들과의 약속, 가족들과의 식사 등 일상의 모든
          순간 일정을 잡으면서 살아가고 있습니다. Invy는 일정을 잡는데 발생하는
          모든 비효율을 해결하기 위해 만들어졌습니다. 여러 사람들의 가능한
          일정을 자동으로 취합하여 최적의 일정을 찾아드리죠. 1:1 만남부터 그룹
          일정까지, 소중한 만남들의 모든 순간 이제 ‘인비’ 해보시죠!
        </p>
        <p id="ee662769-6241-405c-962f-fe94c3c77007" className=""></p>
        <h3 id="dc421b6d-901b-490a-ba91-b6944d44b15f" className="">
          💁‍♀️ 서비스 이용 전, 이것만은 참고해주세요!
        </h3>
        <hr id="afc1ac46-21e5-4360-be3d-e4d63fefd7a8" />
        <p id="323d7846-c613-4cc3-8afc-72715e92b099" className="">
          이용해보시게 될 서비스는 현재 Beta 버전입니다. 아직 부족한 부분이 많죠
          🥲 따라서 서비스 이용에 혼란이 없도록 이용 전에 아래 사항을 꼭
          참고해주시기 바랍니다.
        </p>
        <ul id="8db36881-b3cf-444e-a3d6-ba062a4beddd" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <mark className="highlight-red">
              <strong>
                아직 구글 계정 연동을 지원하지 않습니다. 회원가입을 통해 이메일
                계정을 등록한 후 사용해주세요.
              </strong>
            </mark>
          </li>
        </ul>
        <ul id="0f28bccb-2dcb-4a0d-b25c-5f8650ce25cf" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <mark className="highlight-red">
              <strong>
                일정을 만들때 초대할 게스트를 등록한 후 초대링크를 공유해주세요.
                게스트가 미리 등록되어 있지 않으면 일정 취합이 불가합니다.
              </strong>
            </mark>
          </li>
        </ul>
        <ul id="d54ef231-2e67-43b4-a6c5-3c173d0788ad" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <mark className="highlight-red">
              <strong>
                인증 메일이 스팸 메일함으로 들어오는 경우가 있으니 유의해주세요.
              </strong>
            </mark>
          </li>
        </ul>
        <ul id="9fdb0c93-9b0a-432f-8b1e-96905744ca3d" className="bulleted-list">
          <li style={{ listStyleType: "disc" }}>
            <mark className="highlight-red">
              <strong>
                모바일 환경에서 일정을 등록하실때에는 원하는 일정 슬롯을 꾸욱
                누르고 아래로 드래그 해주세요.
              </strong>
            </mark>
          </li>
        </ul>
        <p id="9f21e61d-2518-4f02-8448-87135b4c04cf" className=""></p>
        <h3 id="e4571d42-9dbd-45fa-87dc-ed9b519a24f6" className="">
          🌸 <mark className="highlight-pink">베타 테스트의 꽃은 [피드백]</mark>
        </h3>
        <hr id="d026b78f-3186-4cd6-8e95-001c729ca0b9" />
        <p id="947e0f79-f73b-4366-8eae-1d63933c6516" className="">
          서비스 이용 후 아래 설문 링크를 통하여 피드백을 꼭 남겨주세요.
          테스터분들의 목소리야말로 Invy의 발전을 위한 가장 중요한 요소이기
          때문에 꼭 부탁드리겠습니다.
        </p>
        <figure
          className="block-color-pink_background callout"
          style={{ whiteSpace: "pre-wrap", display: "flex" }}
          id="b3e75d78-97d9-436d-ab17-90c29d27f969"
        >
          <div style={{ fontSize: "1.5em" }}>
            <span className="icon">✏️</span>
          </div>
          <div style={{ width: "100%" }}>
            <a href="https://docs.google.com/forms/d/1p6trCJvSCnpXd0J_8VaojDeMrxrDraCrxKMhla8KN_Q/edit">
              {" "}
              Invy 서비스 이용 설문
            </a>
          </div>
        </figure>
        <p id="96245c87-8949-444d-9a03-cf0c5360eb82" className=""></p>
        <h3 id="bf3ff566-fe1e-429c-9f2d-71b4840cb081" className="">
          📖 간단한 사용 설명서
        </h3>
        <hr id="acce90dd-6283-4bc6-a644-58aaf3155e76" />
        <p id="49f338c2-9a20-459f-adfa-a06a39fa2c09" className="">
          1️⃣ 초대할 게스트를 등록하여 일정을 만든다.
        </p>
        <p id="bcbc0ea9-005d-48c6-99dc-d7ac53d62963" className="">
          2️⃣ 링크를 복사하여 게스트에 공유한다 (일정 생성과 동시에 자동으로
          게스트에게 이메일이 갑니다.)
        </p>
        <p id="2b107512-8aa7-4c5c-ba51-dce6b43eae3e" className="">
          3️⃣ ‘내&#x27;가 가능한 일정들을 최대한 많이 등록한다 (등록한 가능일정이
          많을수록 확정율이 올라가요!)
        </p>
        <p id="224919ba-e215-4508-9e84-17bac033ff56" className="">
          4️⃣ 게스트가 가능한 일정을 등록할때까지 기다린다 (모든 가능일정이
          취합되면 알림메일을 받습니다.)
        </p>
        <p id="aee838b0-9cfb-4b4a-a537-8c7ff2b30db4" className="">
          5️⃣ Invy의 추천 일정 혹은 다른 일정을 선택하여 일정을 확정한다.
        </p>
        <p id="7e083760-46ab-43e9-a5a5-b0198d71cd23" className=""></p>
        <h3 id="fedfa1a3-4681-40c6-904f-266c98813673" className="">
          🥳 <mark className="highlight-pink">자, 출발해볼까요?</mark> 🎉
        </h3>
        <hr id="328fc3fd-46c9-40a8-bf22-99f4e6bab307" />
        <p id="5e54d312-17c9-4ec3-9a0f-45ddbbe62b27" className="">
          모든 준비가 끝났습니다. 아래 링크를 통해 실제 서비스로 이동해보시죠!
        </p>
        <figure
          className="block-color-yellow_background callout"
          style={{ whiteSpace: "pre-wrap", display: "flex" }}
          id="017466fd-c240-49ad-a64e-a12af7f6d3c1"
        >
          <div style={{ fontSize: "1.5em" }}>
            <span className="icon">🚂</span>
          </div>
          <div style={{ width: "100%" }}>
            <a href="/login">[Invy] 사용하기</a>
          </div>
        </figure>
        <p id="979ff1f0-1adf-4f6c-b424-80886d2af6b8" className=""></p>
        <h3 id="f1a61499-650d-45e9-9f27-c9d22d2c202c" className="">
          🙌 저…궁금한게 있어요
        </h3>
        <hr id="b51947cd-0428-4a8f-ab16-438389398ee3" />
        <p id="b2fc600e-774a-4664-9982-1d70b32ca1fc" className="">
          서비스 이용 중 궁금한 사항이 있으시면 언제든지 아래 채널을 통해
          연락주세요! 신속히 해결해드리겠습니다 😊
        </p>
        <div id="7f1b9df3-2203-4acf-9179-02aa86837ada" className="column-list">
          <div
            id="f6ea733f-04cc-47e5-b0a8-33cadddacce5"
            style={{ width: "50%" }}
            className="column"
          >
            <figure
              className="block-color-gray_background callout"
              style={{ whiteSpace: "pre-wrap", display: "flex" }}
              id="3dbfec47-85b5-4c54-bee7-5a9a5f993bae"
            >
              <div style={{ fontSize: "1.5em" }}>
                <img
                  alt={"Kakaotalk 오픈채팅"}
                  className="icon"
                  src="/images/welcome/kakaotalk.webp"
                />
              </div>
              <div style={{ width: "100%" }}>
                <a href="https://open.kakao.com/o/gNj1KUke">
                  Kakaotalk 오픈채팅
                </a>
              </div>
            </figure>
          </div>
          <div
            id="6f85d4af-7337-4624-b2df-36f5d8f3a5ce"
            style={{ width: "50%" }}
            className="column"
          >
            <figure
              className="block-color-gray_background callout"
              style={{ whiteSpace: "pre-wrap", display: "flex" }}
              id="fcb1349e-2f18-424e-b376-9865f5877653"
            >
              <div style={{ fontSize: "1.5em" }}>
                <img
                  alt={"Twitter"}
                  className="icon"
                  src="/images/welcome/twitter.webp"
                />
              </div>
              <div style={{ width: "100%" }}>
                <strong>
                  <a href="https://twitter.com/invyclub">Twitter</a>
                </strong>
              </div>
            </figure>
          </div>
        </div>
        <p id="6f838258-0361-4bbf-9c88-6a93105eecd4" className=""></p>
        <h3 id="2440394a-9f8c-4833-a3b5-b7dcd70bc520" className="">
          👨‍👩‍👦‍👦 invy는 누가 만들었나요
        </h3>
        <hr id="c5d34c59-555c-406f-91f8-c4419407a9f5" />
        <p id="86634185-377b-45b3-9b46-e79fb3bf0c72" className="">
          invy팀은 우리 일상을 조금이나마 윤택하게 만들어줄 수 있는 &#x27;작지만
          중요한&#x27; 숙제들을 풀기위해 모였습니다. 다양한 개성과 탤런트를 가진
          IT 현직자 일곱명이 함께 문제의 본질을 고민해요. 소소하지만 번뜩이는
          아이디어들이 모여 생각지 못한 미래를 만든다는 믿음을 가지고 열심히
          뚝딱뚝딱거리는 중입니다 😄
        </p>
        <p id="866f12d7-49a2-4011-919c-b8b677f50592" className=""></p>
        <p id="64179014-2844-4892-aeb6-16ec1aa559f2" className=""></p>
        <p
          id="6b07530b-df69-4454-a501-736ef117ec8f"
          className="block-color-gray"
        >
          ⓒ AlwaysAwake Inc. All rights reserved
        </p>
        <p id="2cd297c4-2f94-4330-babc-1dbdff131942" className=""></p>
      </div>
    </StyledWelcomePage>
  );
}
