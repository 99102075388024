import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { Box, ModalProps } from "@mui/material";
import { GuestEmailAndKey } from "../../app/services/events/types";
import { TextFieldWithValidation } from "../inputs/TextFieldWithValidation";
import {
  FormValidationValue,
  useFormValidation,
  ValidationOptions,
} from "../../hooks/useFormValidation";
import { CommonModal } from "./CommonModal";

export interface VerifyGuestModalProps
  extends Omit<ModalProps, "children" | "onSubmit"> {
  guest?: GuestEmailAndKey;
  errorStatusCode?: number;
  onSubmit: (guest: GuestEmailAndKey) => void;
  onClose: () => void;
}

export interface VerifyGuestModalValue extends FormValidationValue {
  email?: string;
}
const initValue: VerifyGuestModalValue = {
  email: undefined,
};
export const valueKeys = ["email"];
const inputValidators: ValidationOptions<VerifyGuestModalValue> = {
  email: {
    validator: (value) => validator.isEmail(value?.email || ""),
    required: true,
  },
};

export function VerifyGuestModal({
  open,
  errorStatusCode,
  guest,
  onSubmit,
  onClose,
}: VerifyGuestModalProps) {
  const { t } = useTranslation("verifyGuest");
  const [value, setValue] = useState<VerifyGuestModalValue>(initValue);
  const [isAfterSubmit, setIsAfterSubmit] = useState<boolean>(false);
  const [internalErrorStatusCode, setInternalErrorStatusCode] = useState<
    number | undefined
  >(undefined); // FIXME: errorStatusCode가 바뀌었을 때만 isAfterSubmit과 조합해서 사용해야함
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const { isFormatValid, isFulfillValid, isTotalValid } = useFormValidation(
    valueKeys,
    value,
    inputValidators
  );

  const handleClose = useCallback(() => {
    setValue(initValue);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if (guest && value.email && isTotalValid) {
      onSubmit({ email: value.email, key: guest.key });
    }
    setIsAfterSubmit(true);
  }, [value, guest, onSubmit, isTotalValid]);

  const handleValueChange = useCallback((email) => {
    setValue((value) => ({ ...value, email }));
  }, []);

  useEffect(() => {
    setInternalErrorStatusCode(errorStatusCode);
  }, [errorStatusCode]);

  useEffect(() => {
    setInternalErrorStatusCode(undefined);
    setIsAfterSubmit(false); // 초기화
  }, [value]);

  useEffect(() => {
    if (isFormatValid) {
      setErrorMessage(undefined);
    }
  }, [isFormatValid]);

  useEffect(() => {
    if (
      isAfterSubmit &&
      internalErrorStatusCode &&
      internalErrorStatusCode >= 400
    ) {
      setErrorMessage(t("input.Invalid"));
    } else if (isAfterSubmit && !isFulfillValid.email) {
      setErrorMessage(t("input.needInput.email"));
    } else if (!isFormatValid.email) {
      setErrorMessage(t("input.invalidFormats.email"));
    }
  }, [
    t,
    isAfterSubmit,
    isFulfillValid,
    isFormatValid,
    internalErrorStatusCode,
  ]);

  return (
    <CommonModal
      width={360}
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      title={t("input.Title")}
      description={`${guest?.email}${t("input.Description")}`}
      onConfirm={handleSubmit}
      confirmButtonText={t("input.Confirm")}
      confirmButtonColor="secondary"
      confirmButtonDisabled={!isTotalValid}
      cancelButtonText={t("input.Cancel")}
      cancelButtonColor="normal"
    >
      <Box sx={{ position: "relative", height: "100px" }}>
        <TextFieldWithValidation
          type={"email"}
          value={value?.email}
          placeholder={t("input.labels.email")}
          errorMessage={errorMessage}
          onValueChange={handleValueChange}
          sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
        />
      </Box>
    </CommonModal>
  );
}
