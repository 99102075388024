import React from "react";
import { Box, Item } from "react-html-email";

export interface EmailInfoBoxProps {
  children: React.ReactNode;
}

export function EmailInfoBox({ children }: EmailInfoBoxProps) {
  return (
    <Item style={{ padding: "0 0 23px" }}>
      <Box
        bgcolor="#fff"
        style={{ borderRadius: "10px", padding: "15px 20px" }}
        width="100%"
      >
        {children}
      </Box>
    </Item>
  );
}
