import React from "react";
import { Button } from "@mui/material";
import { useLayoutContext } from "./context/LayoutContext";
import styled from "@mui/styled-engine";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SettingContainer, SettingWrapper } from "../styled/layout/setting";
// @ts-ignore
import { SxProps, Theme } from "@mui/material/styles";

const CloseButtonOnMobile = styled(Button)`
  position: absolute;
  top: 4px;
  right: 4px;
  color: #000000;
  min-width: 36px;
`;

interface SettingProps {
  children: React.ReactNode;
  sx: SxProps<Theme>;
}

export const Setting = ({ sx, children }: SettingProps) => {
  const { isDesktop, platform, isSettingOpenOnMobile, setSettingOpenOnMobile } =
    useLayoutContext();

  return (
    <SettingContainer
      id="setting-container"
      className={platform}
      isSettingOpenOnMobile={isSettingOpenOnMobile}
      sx={sx}
    >
      {!isDesktop && (
        <CloseButtonOnMobile
          variant="text"
          onClick={() => setSettingOpenOnMobile?.(false)}
        >
          <CloseRoundedIcon />
        </CloseButtonOnMobile>
      )}
      <SettingWrapper>{children}</SettingWrapper>
    </SettingContainer>
  );
};
