import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import styled from "@mui/styled-engine";
import { EventFormItem } from "../event/EventFormItem";
import { EventInfo } from "../event/EventInfo";
import {
  CreateEventResponse,
  EventBase,
  GuestWithBgColor,
  Schedule,
} from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
import { GuestItem } from "../event/GuestItem";
import { User } from "../../app/services/accounts/types";
import {
  EventConfirmList,
  EventConfirmListItem,
} from "../list/EventConfirmList";
import { UrlView } from "../event/UrlView";
import { StyledAvatar, StyledAvatarGroup } from "../styled/StyledAvatar";
import ListIcon from "@mui/icons-material/List";
import EventIcon from "@mui/icons-material/Event";
import { StyledEventDetailContainer } from "../styled/StyledEventDetailContainer";

const StyledUrlViewWrapper = styled(Box)`
  border-top: solid 1px #dadada;
  padding: 20px 0;
`;

interface EventDetailFormProps {
  event: EventBase | CreateEventResponse | undefined;
  user?: User;
  guests?: GuestWithBgColor[];
  schedules?: Schedule[];
  onSelectSchedulesButtonClick: () => void;
}

const selectedGuests = [
  {
    name: "Douglas",
    email: "douglas+11@invy.club",
    bg_color: "#00ff00",
  },
  {
    name: "Douglas",
    email: "douglas+11@invy.club",
    bg_color: "#ff0000",
  },
  {
    name: "Douglas",
    email: "douglas+11@invy.club",
    bg_color: "#0000ff",
  },
  {
    name: "Douglas",
    email: "douglas+11@invy.club",
    bg_color: "#ff0000",
  },
  {
    name: "Douglas",
    email: "douglas+11@invy.club",
    bg_color: "#0000ff",
  },
];

interface ScheduleWithChecked extends Schedule {
  checked?: boolean;
}

export function EventDetailForm({
  event,
  guests = [],
  schedules = [],
  onSelectSchedulesButtonClick,
  user,
}: EventDetailFormProps) {
  const { t: tEventDetail } = useTranslation("eventDetail");
  const { t: tEventInfo } = useTranslation("eventInfo");
  const { t: tGuestInfo } = useTranslation("guestInfo");
  const { t: tConfirmSchedule } = useTranslation("confirmSchedule");

  const [scheduleWithChecked, setScheduleWithChecked] = useState<
    ScheduleWithChecked[]
  >(
    schedules.map((schedule) => ({
      ...schedule,
      checked: false,
    }))
  );

  const [page, setPage] = useState<number>(0);

  const scheduleWithCheckedDisplayed = scheduleWithChecked.slice(
    0,
    Math.min((page + 1) * 4, schedules.length)
  );

  const onScheduleRadioChange = (radioIndex: number) => {
    setScheduleWithChecked(
      scheduleWithChecked.map((schedule, index) => ({
        ...schedule,
        checked: index === radioIndex,
      }))
    );
  };

  const onMoreButtonClick = () => {
    setPage(page + 1);
  };

  return (
    <ContainerWithTitle
      title={tEventDetail("Title")}
      titleDecorator={event?.title}
      titleComponent="span"
    >
      <StyledEventDetailContainer component="div">
        <EventFormItem
          title={tEventInfo("Label")}
          collapsible={true}
          defaultCollapsed={true}
        >
          <StyledUrlViewWrapper component="div">
            <UrlView
              url={event?.url}
              title={event?.title}
              showWarning={false}
            />
          </StyledUrlViewWrapper>
          <EventInfo event={event} />
        </EventFormItem>
        <EventFormItem
          title={tGuestInfo("Label", { count: guests.length })}
          collapsible={true}
          defaultCollapsed={true}
        >
          <Box component="ul">
            <GuestItem isHost={true} guest={user} isMe={false} />
            {guests.map((guest) => (
              <GuestItem
                isHost={false}
                guest={guest}
                isMe={guest?.email === user?.email}
              />
            ))}
          </Box>
        </EventFormItem>
        <EventFormItem
          title={tConfirmSchedule("Label")}
          rightButton={
            <Button size="small" variant="inner" color="normal">
              일정 직접 입력
            </Button>
          }
          segmentItems={[
            {
              value: "list",
              label: <ListIcon />,
              children: (
                <EventConfirmList
                  onMoreButtonClick={onMoreButtonClick}
                  moreButtonHidden={
                    scheduleWithCheckedDisplayed.length === schedules.length
                  }
                >
                  {scheduleWithCheckedDisplayed?.map(
                    (schedule: ScheduleWithChecked, index: number) => (
                      <EventConfirmListItem
                        schedule={schedule}
                        checked={schedule?.checked}
                        onRadioChange={() => onScheduleRadioChange(index)}
                      >
                        <StyledAvatarGroup max={4} size="small">
                          {selectedGuests?.map((guest) => (
                            <StyledAvatar
                              size="small"
                              bgColor={guest?.bg_color || "#000000"}
                            >
                              {(guest?.name || guest?.email)
                                ?.substr(0, 1)
                                .toUpperCase()}
                            </StyledAvatar>
                          ))}
                        </StyledAvatarGroup>
                      </EventConfirmListItem>
                    )
                  )}
                </EventConfirmList>
              ),
            },
            {
              value: "calendar",
              label: <EventIcon />,
              children: <Box>{"calendar"}</Box>,
            },
          ]}
        />
        <Box>
          <Button
            fullWidth
            onClick={onSelectSchedulesButtonClick}
            variant="cta"
            color="primary"
          >
            {"일정 확정"}
          </Button>
        </Box>
      </StyledEventDetailContainer>
    </ContainerWithTitle>
  );
}
