export const color = {
  primary: "#4555ff",
  primaryButtonFont: "#ffffff",
  primaryButtonBackgroundHover: "#0d1cbf",
  normalButtonFont: "#1b1b1b",
  normalButtonBackground: "#e0e0e0",
  normalButtonBackgroundHover: "#e0e0e0",
  secondaryButtonFont: "#ffffff",
  secondaryButtonBackground: "#000000",
  secondaryButtonBackgroundHover: "#111111",
  secondaryButtonBackgroundDisabled: "#cccccc",
  secondaryButtonColorDisabled: "#999999",
};
