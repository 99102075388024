import React, { useMemo } from "react";
import { Box, Radio, Chip, Button } from "@mui/material";
import styled, { css } from "@mui/styled-engine";
import { Schedule } from "../../app/services/events/types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

const StyledEventConfirmList = styled(Box)<{ isDesktop?: boolean }>`
  border-radius: 10px;
  background-color: #fff;
  ${(props) =>
    !props.isDesktop &&
    css`
      margin: 0 -20px;
    `}
  & > {
    .event-confirm-list {
      &-button-area {
        padding: 0 20px 15px;
      }
    }
  }
`;

const StyledEventConfirmListItemWrapper = styled(Box)<{
  showDaySeparator?: boolean;
}>`
  ${(props) =>
    props.showDaySeparator
      ? css`
          &:before {
            content: attr(data-date-header);
            display: flex;
            align-items: center;
            background-color: #ededed;
            height: 21px;
            font-size: 14px;
            padding: 0 21px;
            color: #707070;
          }
        `
      : css`
          border-top: solid 1px #dadada;
          &:first-of-type {
            border-top: 0;
          }
        `}
`;

const StyledEventConfirmListItem = styled(Box)`
  height: 82px;
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;

  & > .event-confirm-list-item {
    &-date {
      font-size: 14px;
      color: #333;
    }
    &-slot {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
    }
  }
`;

interface EventConfirmListItemProp {
  schedule: Schedule;
  recommended?: boolean;
  showDaySeparator?: boolean;
  children?: React.ReactNode;
  radioName?: string;
  checked?: boolean;
  onRadioChange?: () => void;
}

export function EventConfirmListItem({
  schedule,
  showDaySeparator,
  recommended = false,
  children,
  radioName = "radio-buttons",
  checked,
  onRadioChange,
}: EventConfirmListItemProp) {
  const { t } = useTranslation("confirmList");
  const daysDiff = useMemo(() => {
    return moment().diff(moment(schedule.from_time), "days");
  }, [schedule.from_time]);
  return (
    <StyledEventConfirmListItemWrapper
      showDaySeparator={showDaySeparator}
      data-date-header={moment(schedule.from_time).format("YYYY.MM.DD(dd)")}
    >
      <StyledEventConfirmListItem component="li">
        <Radio
          name={radioName}
          sx={{ marginRight: "10px" }}
          checked={checked}
          onChange={onRadioChange}
        />
        {recommended ? (
          <Chip
            label={t("Recommended")}
            color="primary"
            variant="outlined"
            sx={{ marginRight: "20px", height: "26px" }}
          />
        ) : (
          <Chip
            label={`D${daysDiff >= 0 ? "+" : ""}${daysDiff}`}
            variant="outlined"
            sx={{ marginRight: "20px", height: "26px" }}
          />
        )}
        <Box
          component="div"
          className="event-confirm-list-item-date"
          sx={{ marginRight: "20px" }}
        >
          <Box component="span" sx={{ whiteSpace: "nowrap" }}>
            {moment(schedule.from_time).format("MM.DD(dd) a hh:mm")} -
          </Box>
          <br />
          <Box component="span" sx={{ whiteSpace: "nowrap" }}>
            {moment(schedule.to_time).format("MM.DD(dd) a hh:mm")}
          </Box>
        </Box>
        <Box component="div" className="event-confirm-list-item-slot">
          {children}
        </Box>
      </StyledEventConfirmListItem>
    </StyledEventConfirmListItemWrapper>
  );
}

export interface EventConfirmListProps {
  children?: React.ReactNode;
  onMoreButtonClick?: () => void;
  moreButtonHidden?: Boolean;
}

export function EventConfirmList({
  children,
  onMoreButtonClick,
  moreButtonHidden = true,
}: EventConfirmListProps) {
  const { t } = useTranslation("confirmList");
  const isDesktop = useDesktopMatches();

  return (
    <StyledEventConfirmList component="ul" isDesktop={isDesktop}>
      <Box component="ul">{children}</Box>
      {!moreButtonHidden && (
        <Box component="div" className="event-confirm-list-button-area">
          <Button
            variant="inner"
            color="normal"
            sx={{ width: "100%" }}
            onClick={onMoreButtonClick}
          >
            {t("MoreButton")}
          </Button>
        </Box>
      )}
    </StyledEventConfirmList>
  );
}
