import { createApi } from "@reduxjs/toolkit/query/react";

import { fetchCsrfBaseQuery } from "../fetchCsrfBaseQuery";
import { baseUrl } from "./config";
import {
  BaseGuest,
  CreateEventRequest,
  CreateEventResponse,
  EventBase,
  EventInfoRequest,
  EventsRequest,
  EventsResponse,
  EventStatus,
  EventWithGuests,
  Guest,
  GuestEmailAndKey,
  GuestSchedule,
  RecommendSchedule,
  Schedule,
  UpdateEventRequest,
} from "./types";
import { AttendStatus } from "../types";

export const eventApi = createApi({
  baseQuery: fetchCsrfBaseQuery(`${baseUrl}/`),
  reducerPath: "events/eventApi",
  tagTypes: ["EventInfo", "Schedule", "RecommendSchedule", "Attendee"],
  endpoints: (build) => ({
    postEventsHosted: build.query<EventsResponse, EventsRequest>({
      query(body: {
        show_count: number;
        page: number;
        status_filter?: EventStatus;
      }) {
        return {
          url: "hosted/",
          method: "POST",
          body,
        };
      },
    }),
    postEventsAttended: build.query<EventsResponse, EventsRequest>({
      query(body: {
        show_count: number;
        page: number;
        status_filter?: EventStatus;
      }) {
        return {
          url: "attended/",
          method: "POST",
          body,
        };
      },
    }),
    postCreateEvent: build.mutation<CreateEventResponse, CreateEventRequest>({
      query(body) {
        return {
          url: "new/",
          method: "POST",
          body,
        };
      },
    }),
    getRecentEmails: build.mutation<Guest[], void>({
      query() {
        return {
          url: "recent/emails/",
          method: "GET",
        };
      },
    }),
    postInviteGuest: build.mutation<
      BaseGuest[],
      { id: number; guests: BaseGuest[] }
    >({
      query({ id, guests }) {
        return {
          url: `${id}/invite/`,
          method: "POST",
          body: { guests },
        };
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [{ type: "EventInfo", id: args.id }, "RecommendSchedule"]
          : ["EventInfo", "RecommendSchedule"];
      },
    }),
    getHostedRecent: build.mutation<EventBase[], { host: number }>({
      query({ host }) {
        return {
          url: `hosted/recent/`,
          method: "GET",
          params: {
            host,
          },
        };
      },
    }),
    getEventInfo: build.query<EventBase, { id: number | string }>({
      query({ id }) {
        return {
          url: `${id}/info/`,
        };
      },
      providesTags: (result) => {
        return result
          ? [{ type: "EventInfo" as const, id: result.id }]
          : ["EventInfo" as const];
      },
    }),
    getGuests: build.query<Guest[], { id: number }>({
      query({ id }) {
        return {
          url: `${id}/guests/`,
        };
      },
    }),
    getGuestSchedules: build.query<GuestSchedule[], { id: number }>({
      query({ id }) {
        return {
          url: `${id}/schedule/`,
        };
      },
    }),
    postEventsInfo: build.query<EventWithGuests, EventInfoRequest>({
      query({ id, emailAndKey }) {
        return {
          url: `${id}/info/`,
          method: "POST",
          body: emailAndKey,
        };
      },
      providesTags: (result) => {
        return result
          ? [{ type: "EventInfo" as const, id: result.id }]
          : ["EventInfo" as const];
      },
    }),
    postSchedules: build.query<GuestSchedule[], EventInfoRequest>({
      query({ id, emailAndKey }) {
        return {
          url: `${id}/schedules/`,
          method: "POST",
          body: emailAndKey,
        };
      },
      providesTags: (result, error, arg) => {
        return result
          ? [{ type: "Schedule", id: arg.id }]
          : ["Schedule" as const];
      },
    }),
    getPriorRecommendSchedules: build.query<
      RecommendSchedule[],
      { id: number; email?: string }
    >({
      query({ id, ...params }) {
        return {
          url: `${id}/recommend/`,
          method: "GET",
          params,
        };
      },
      providesTags: ["RecommendSchedule"],
    }),
    getAllRecommendSchedules: build.query<
      RecommendSchedule[],
      {
        id: number;
        email?: string;
        page?: number;
        limit?: number;
        from_at?: string;
        to_at?: string;
      }
    >({
      query({ id, ...params }) {
        return {
          url: `${id}/recommend/all/`,
          method: "GET",
          params,
        };
      },
      providesTags: ["RecommendSchedule"],
    }),
    // 추천 일정을 선택하여 확정한다.
    putSelectSchedule: build.mutation<
      EventWithGuests,
      { id: number; scheduleId: number }
    >({
      query({ id, scheduleId }) {
        return {
          url: `${id}/select/`,
          method: "PUT",
          body: {
            id: scheduleId,
          },
        };
      },
      invalidatesTags: (result) => {
        return result
          ? [{ type: "EventInfo" as const, id: result.id }]
          : ["EventInfo" as const];
      },
    }),
    // 직접 입력 일정 확정한다
    postInsertSchedule: build.mutation<
      RecommendSchedule,
      { id: number; from_time: string; to_time: string }
    >({
      query({ id, ...body }) {
        return {
          url: `${id}/insert/`,
          method: "POST",
          body,
        };
      },
    }),
    putUpdateEvent: build.mutation<EventWithGuests, UpdateEventRequest>({
      query({ id, ...body }) {
        return {
          url: `${id}/update/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result) => {
        return result
          ? [{ type: "EventInfo" as const, id: result.id }]
          : ["EventInfo" as const];
      },
    }),
    deleteCancelEvent: build.mutation<EventWithGuests, number>({
      query(id) {
        return {
          url: `${id}/cancel/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => {
        return result
          ? [{ type: "EventInfo" as const, id: result.id }]
          : ["EventInfo" as const];
      },
    }),
    postInviteResend: build.mutation<void, { id: number; email: string }>({
      query({ id, ...body }) {
        return {
          url: `${id}/invite/resend/`,
          method: "POST",
          body,
        };
      },
    }),
    getInviteResend: build.mutation<void, { id?: number }>({
      query({ id }) {
        return {
          url: `${id}/invite/resend/`,
          method: "GET",
        };
      },
    }),
    postSelectSchedules: build.mutation<
      void,
      {
        id: number | string;
        schedules: Schedule[];
        emailAndKey?: GuestEmailAndKey;
      }
    >({
      query({ id, schedules, emailAndKey }) {
        return {
          url: `${id}/guests/schedules/select/`,
          method: "POST",
          body: {
            schedules,
            ...emailAndKey,
          },
        };
      },
      // FIXME: 현재는 귀찮아서 id 상관없이 invalidate 하고 있음
      invalidatesTags: ["RecommendSchedule", "Schedule", "EventInfo"],
    }),
    deleteSchedules: build.mutation<void, number>({
      query(id) {
        return {
          url: `/guests/schedules/${id}/delete/`,
          method: "DELETE",
        };
      },
    }),
    postAttendee: build.query<
      { status: AttendStatus; email?: string; key?: string },
      { id: number | string; emailAndKey?: GuestEmailAndKey }
    >({
      query({ id, emailAndKey }) {
        return {
          url: `${id}/guests/attendee/`,
          method: "POST",
          body: emailAndKey,
        };
      },
      providesTags: (result, error, arg) => [{ type: "Attendee", id: arg.id }],
    }),
    putUpdateStatus: build.mutation<
      void,
      { id: number | string; status: 0 | 1 | 2; emailAndKey?: GuestEmailAndKey }
    >({
      query({ id, status, emailAndKey }) {
        return {
          url: `${id}/guests/update/`,
          method: "PUT",
          body: {
            ...emailAndKey,
            status,
          },
        };
      },
      //Optimistic Update
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const tempResult = dispatch(
          eventApi.util.updateQueryData(
            "postAttendee",
            { id: arg.id, emailAndKey: arg.emailAndKey },
            (draft) => {
              Object.assign(draft, { status: arg.status });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          tempResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Attendee", id: arg.id },
        { type: "EventInfo", id: arg.id },
      ],
    }),
    getGuestsEmails: build.query<GuestEmailAndKey[], { id: string }>({
      query({ id }) {
        return {
          url: `guest/${id}/guests/emails/`,
        };
      },
    }),
    postGuestsVerify: build.mutation<
      void,
      { id: string; email: string; key: string }
    >({
      query({ id, ...body }) {
        return {
          url: `guest/${id}/guests/verify/`,
          method: "POST",
          body,
        };
      },
    }),
  }),
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
});

export const {
  usePostCreateEventMutation,
  useGetRecentEmailsMutation,
  usePostInviteGuestMutation,
  useGetHostedRecentMutation,
  usePostEventsHostedQuery,
  usePostEventsAttendedQuery,
  useGetGuestsQuery,
  useGetEventInfoQuery,
  useGetGuestSchedulesQuery,
  usePostEventsInfoQuery,
  usePostSchedulesQuery,
  useGetPriorRecommendSchedulesQuery,
  useGetAllRecommendSchedulesQuery,
  usePutSelectScheduleMutation,
  usePostInsertScheduleMutation,
  usePutUpdateEventMutation,
  useDeleteCancelEventMutation,
  usePostInviteResendMutation,
  useGetInviteResendMutation,
  usePostSelectSchedulesMutation,
  useDeleteSchedulesMutation,
  usePutUpdateStatusMutation,
  usePostAttendeeQuery,
  useGetGuestsEmailsQuery,
  usePostGuestsVerifyMutation,
} = eventApi;
