import React from "react";
import { Box, Button } from "@mui/material";
import styled from "@mui/styled-engine";
import { Events } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

const StyledWorkspaceList = styled(Box)`
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  background-color: #fff;
  & > {
    .workspace-list {
      &-button-area {
        padding: 0 20px 15px;
      }
    }
  }
`;

const StyledWorkspaceListItemWrapper = styled(Box)`
  border-top: solid 1px #dadada;
  &:first-of-type {
    border-top: 0;
  }
`;

const StyledWorkspaceListItem = styled(Box)<{ isDesktop?: boolean }>`
  height: 82px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  &: ${(props) => (props.isDesktop ? "hover" : "active")} {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .workspace-list-item {
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin: 0 0 4px 0;
      width: 200px;
      display: flex;
      align-items: center;
      & > span {
        display: inline-block;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & > img {
        margin-left: 4px;
      }
    }
    &-summary {
      font-size: 12px;
      font-weight: 500;
      color: #888;
      margin: 0;
    }
    &-slot {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
    }
  }
`;

export const StyledEmptyState = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  color: #aaaaaa;
  height: 433px;
  margin-top: 60px;
  white-space: pre-line;
  & > p {
    margin: 0 0 11px 0;
    &:last-of-type {
      margin: 0;
    }
  }
  & > .emoji {
    font-size: 46px;
    line-height: 46px;
  }
`;

interface WorkspaceListEmptyStateProps {
  emoji: any;
  message: string;
}

export function WorkspaceListEmptyState({
  emoji,
  message,
}: WorkspaceListEmptyStateProps) {
  return (
    <StyledEmptyState component="div">
      <Box component="p" className="emoji">
        {emoji}
      </Box>
      <Box component="p">{message}</Box>
    </StyledEmptyState>
  );
}

interface WorkspaceListItemProp {
  event: Events;
  children?: React.ReactNode;
}

export function WorkspaceListItem({ event, children }: WorkspaceListItemProp) {
  const { t } = useTranslation("workspace");
  const isDesktop = useDesktopMatches();
  return (
    <StyledWorkspaceListItemWrapper>
      <StyledWorkspaceListItem
        component="li"
        onClick={() => window.open(event.url, "_parent")}
        isDesktop={isDesktop}
      >
        <Box component="div">
          <Box component="p" className="workspace-list-item-title">
            <span>{event.title}</span>
            {event.is_host && (
              <img src="/images/ic_host.svg" alt="hosted event" />
            )}
          </Box>
          <Box component="p" className="workspace-list-item-summary">
            {event.confirmed_from && event.confirmed_to
              ? `${moment(event.confirmed_from).format(
                  "MM.DD a hh:mm"
                )} ~ ${moment(event.confirmed_to).format("MM.DD a hh:mm")}`
              : t("eventList.item.EmptyText")}
          </Box>
        </Box>
        <Box component="div" className="workspace-list-item-slot">
          {children}
        </Box>
      </StyledWorkspaceListItem>
    </StyledWorkspaceListItemWrapper>
  );
}

export interface WorkspaceListProps {
  children?: React.ReactNode;
  onMoreButtonClick?: () => void;
  moreButtonHidden?: boolean;
}

export function WorkspaceList({
  children,
  onMoreButtonClick,
  moreButtonHidden,
}: WorkspaceListProps) {
  const { t } = useTranslation("confirmList");

  return (
    <StyledWorkspaceList component="ul">
      <Box component="ul">{children}</Box>
      {!moreButtonHidden && (
        <Box component="div" className="workspace-list-button-area">
          <Button
            variant="inner"
            color="normal"
            sx={{ width: "100%" }}
            onClick={onMoreButtonClick}
          >
            {t("MoreButton")}
          </Button>
        </Box>
      )}
    </StyledWorkspaceList>
  );
}
