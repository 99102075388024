import React from "react";
import { Email } from "react-html-email";
import { EmailFooterText } from "./EmailFooterText";
import { EmailCommonProps } from "./types";
import { useEmailTranslation } from "../useEmailTranslation";

const emailHeadCSS = `
  body {
    font-family: sans-serif;
    background-color: #F5F8FA;
  }
`.trim();

export interface EmailContainerProps extends EmailCommonProps {
  title: string;
  expirationTime: string;
}

export function EmailContainer({
  title,
  expirationTime,
  children,
}: EmailContainerProps) {
  const { t } = useEmailTranslation("mailTemplate");
  return (
    <Email
      title={title}
      headCSS={emailHeadCSS}
      width="100%"
      style={{
        padding: "20px 25px",
        backgroundColor: "#f5f6fa",
        maxWidth: "600px",
      }}
    >
      {children}
      <EmailFooterText style={{ padding: "0 0 10px" }}>
        {t("Footer", { expirationTime: expirationTime })}
      </EmailFooterText>
      <EmailFooterText style={{ padding: "10px 0" }}>
        {t("Signature")}
      </EmailFooterText>
    </Email>
  );
}
