import { AttendStatus } from "../types";

export type EventTypeKey = "v" | "o" | "p";
export const eventTypeKeys: EventTypeKey[] = ["v", "o", "p"];

export interface GuestEmailAndKey {
  email: string;
  key: string;
}

export interface EventInfoRequest {
  id: number | string;
  emailAndKey?: GuestEmailAndKey;
}

export interface EventsRequest {
  show_count: number;
  page: number;
  status_filter?: string;
}

export const EventStatusMap = {
  ALL: "a",
  WAIT: "w",
  CONFIRMED: "c",
  DELETED: "d",
  FINISHED: "f",
} as const;

export type EventStatus = typeof EventStatusMap[keyof typeof EventStatusMap]; // w, c, d, f

export interface Events {
  id: number;
  title: string;
  summary: string;
  confirmed_from: string;
  confirmed_to: string;
  guests: Guest[];
  status: EventStatus;
  url: string;
  is_host: boolean;
}

export interface EventsResponse {
  events: Events[];
  scheduled: number;
  wait: number;
  confirmed: number;
  deleted: number;
}

export interface EventBase {
  id: number;
  host: number;
  url: string;
  title: string;
  description: string | null;
  event_type: EventTypeKey | null;
  place_detail: string | null;
  duration_minutes: number;
}

export interface CreateEventRequest {
  title: string;
  description?: string;
  event_type?: EventTypeKey; //v: 비디오, o: 오프라인 미팅, p: 전화
  place_detail?: string;
  duration_minutes: number;
  guest?: BaseGuest[];
}

export interface UpdateEventRequest extends CreateEventRequest {
  id: number;
}

export interface CreateEventResponse extends EventBase {}

export interface Schedule {
  from_time: string; //ISO-8601;
  to_time: string;
  //whole_date: string; //yyyy-mm-dd
}

export interface BaseGuest {
  email: string;
  name?: string;
}

export interface GuestWithBgColor extends BaseGuest {
  bg_color?: string;
}

export interface GuestWithStatus extends GuestWithBgColor {
  status: AttendStatus; // '0' | '1' | '2'
  is_host?: boolean;
}

export interface Guest extends GuestWithStatus {
  schedules?: Schedule[];
}

export interface Schedule {
  from_time: string; // 참여가능시간의 시작 시각. ISO-8601 에 따라 UTC 시간을 넘겨줍니다.
  // 종일 선택시에는 종일 선택한 날의 0시 0분으로 선택합니다(timezone 이슈 해결을 위함)
  to_time: string; //참여가능시간의 끝 시각. ISO-8601 에 따라 UTC 시간을 넘겨줍니다.
  //종일 선택시에는 종일 선택한 날의 다음날 0시 0분으로 선택합니다(timezone 이슈 해결을 위함)
}

export interface GuestSchedule {
  email: string;
  name: string;
  is_me: boolean;
  schedules: Schedule[];
}

export interface EventWithGuests extends EventBase {
  status?: EventStatus; //w: 일정 미정, c:일정 확정, d: 일정 삭제, f: 일정 종료
  guests: GuestWithStatus[];
  is_host: boolean;
  selected?: RecommendSchedule;
}

export interface RecommendSchedule extends Schedule {
  id: number;
  guests: GuestWithStatus[];
}
