import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  usePostResetPasswordVerifyEmailMutation,
  usePutResetPasswordWithTokenMutation,
} from "../../app/services/accounts/resetApi";
import {
  ResetPasswordForm,
  ResetPasswordFormValue,
} from "../../components/forms/ResetPasswordForm";
import { useErrorStatusCode } from "../../hooks/useErrorStatusCode";

export function ResetPasswordWithTokenPage() {
  const { token: verifyEmailToken } = useParams<{ token: string }>();
  const [resetPasswordToken, setResetPasswordToken] = useState<
    undefined | string
  >();
  const [value, setValue] = useState<ResetPasswordFormValue | undefined>(
    undefined
  );
  const [
    postVerifyEmail,
    {
      data: verifyEmailData,
      isSuccess: isSuccessVerifyEmail,
      isError: isErrorVerifyEmail,
    },
  ] = usePostResetPasswordVerifyEmailMutation();
  const [
    postResetPassword,
    { isSuccess: isSuccessResetPassword, error: errorResetPassword },
  ] = usePutResetPasswordWithTokenMutation();
  const errorStatusCode = useErrorStatusCode(errorResetPassword);
  const handleSubmit = useCallback(
    (value) => {
      if (value?.password && resetPasswordToken) {
        postResetPassword({
          password: value.password,
          token: resetPasswordToken,
        });
      }
    },
    [postResetPassword, resetPasswordToken]
  );

  useEffect(() => {
    if (verifyEmailToken) postVerifyEmail({ token: verifyEmailToken });
  }, [verifyEmailToken, postVerifyEmail]);

  useEffect(() => {
    if (isSuccessVerifyEmail && verifyEmailData && verifyEmailData.token) {
      setResetPasswordToken(verifyEmailData.token);
    }
  }, [isSuccessVerifyEmail, verifyEmailData]);

  return isErrorVerifyEmail ? (
    <Redirect to={"/empty"} />
  ) : isSuccessResetPassword ? (
    <Redirect to={{ pathname: "/login", state: { fromResetPassword: true } }} />
  ) : resetPasswordToken ? (
    <Box>
      <ResetPasswordForm
        value={value}
        errorStatusCode={errorStatusCode}
        onChange={(value) => setValue(value)}
        onSubmit={handleSubmit}
      />
    </Box>
  ) : null;
}
