import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled, { css } from "@mui/styled-engine";
import { SegmentButton, SegmentButtonItem } from "../buttons/SegmentButton";

const StyledEventFormItem = styled(Box)<{
  collapsible: boolean;
  collapsed: boolean;
}>`
  margin-bottom: 30px;
  ${(props) =>
    props.collapsed
      ? css`
          border-bottom: 1px solid #dadada;
          padding-bottom: 12px;
        `
      : ""}
  .event-form-item {
    &-title {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      display: flex;
      align-items: center;
      position: relative;
      ${(props) =>
        props.collapsible
          ? css`
              cursor: pointer;
            `
          : ""}
      .event-form-item-collapse-indicator {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
          rotate(${(props) => (props.collapsed ? 0 : 180)}deg);
      }
    }

    &-segment-button {
      margin-left: 20px;
    }

    &-right-button-wrap {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &-description {
      margin-top: 5px;
      font-size: 14px;
      color: #767676;
    }

    &-content {
      margin-top: 12px;
    }
  }
`;

interface SegmentItem extends SegmentButtonItem {
  children?: React.ReactNode;
}

export interface EventFormItemProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  collapsible?: boolean;
  collapsed?: boolean;
  defaultCollapsed?: boolean;
  segmentItems?: SegmentItem[];
  selectedSegment?: string;
  rightButton?: React.ReactNode;
}

export function EventFormItem({
  title,
  description,
  children,
  collapsed,
  collapsible = false,
  defaultCollapsed = false,
  selectedSegment,
  segmentItems,
  rightButton,
}: EventFormItemProps) {
  const [displayCollapsed, setDisplayCollapsed] = useState<boolean>(
    collapsible && defaultCollapsed
  );
  const [displaySelectedSegment, setDisplaySelectedSegment] = useState<
    string | undefined
  >(selectedSegment ?? segmentItems?.[0]?.value);

  const onClick = useCallback(() => {
    if (collapsible) {
      setDisplayCollapsed(collapsible && !displayCollapsed);
    }
  }, [collapsible, displayCollapsed]);

  const handleToggle = useCallback(
    (event: React.MouseEvent<HTMLElement>, currentValue: string | null) => {
      if (currentValue) {
        setDisplaySelectedSegment(currentValue);
      }
    },
    []
  );

  useEffect(() => {
    setDisplayCollapsed(collapsible && (collapsed ?? defaultCollapsed));
  }, [collapsed, collapsible, defaultCollapsed]);

  useEffect(() => {
    if (selectedSegment) {
      setDisplaySelectedSegment(selectedSegment);
    }
  }, [selectedSegment]);

  return (
    <StyledEventFormItem collapsible={collapsible} collapsed={displayCollapsed}>
      <Typography
        component="h6"
        className={`event-form-item-title${collapsible ? " collapsible" : ""}`}
        onClick={onClick}
      >
        {title}
        {segmentItems && segmentItems?.length && (
          <Box component="div" className="event-form-item-segment-button">
            <SegmentButton
              size="small"
              color="primary"
              defaultValue={displaySelectedSegment}
              value={displaySelectedSegment}
              items={segmentItems.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              onChange={handleToggle}
            />
          </Box>
        )}
        {collapsible && (
          <ExpandMoreIcon className="event-form-item-collapse-indicator" />
        )}
        {rightButton && (
          <Box component="div" className="event-form-item-right-button-wrap">
            {rightButton}
          </Box>
        )}
      </Typography>
      {description && (
        <Typography component="p" className="event-form-item-description">
          {description}
        </Typography>
      )}
      {!displayCollapsed && !segmentItems?.length && (
        <Box component="div" className="event-form-item-content">
          {children}
        </Box>
      )}
      {!displayCollapsed &&
        segmentItems?.length &&
        segmentItems.map((item) => (
          <React.Fragment key={item.value}>
            {item.value === displaySelectedSegment && (
              <Box component="div" className="event-form-item-content">
                {item.children}
              </Box>
            )}
          </React.Fragment>
        ))}
    </StyledEventFormItem>
  );
}
