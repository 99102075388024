import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { FindPasswordForm } from "../../components/forms/FindPasswordForm";
import { usePostResetPasswordSendEmailMutation } from "../../app/services/accounts/resetApi";
import { isStatusError } from "../../app/services/accounts/types";

export function FindPasswordPage() {
  const { t } = useTranslation("findPassword");
  const [postResetPassword, { isSuccess, error }] =
    usePostResetPasswordSendEmailMutation();
  const [email, setEmail] = useState<string>("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const handleChange = useCallback((value) => {
    setEmail(value);
  }, []);
  const handleSubmit = useCallback(
    (value) => {
      if (value) postResetPassword({ email: value });
    },
    [postResetPassword]
  );
  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);
  useEffect(() => {
    if (isSuccess) {
      setIsSnackbarOpen(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Box>
        <FindPasswordForm
          value={email}
          errorStatusCode={
            error && isStatusError(error) ? error.status : undefined
          }
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      </Box>
      <Snackbar
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={t("Success")}
      />
    </>
  );
}
