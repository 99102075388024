import { Image, Item } from "react-html-email";
import React from "react";
import { theme } from "../theme";

const headerStyle = {
  borderBottom: "solid 1px #979797",
  paddingBottom: 20,
};

export function EmailHeader() {
  return (
    <Item style={headerStyle}>
      <Image
        alt={"logo icon"}
        height={40}
        src={theme.header.image.LOGO}
        width={80}
      />
    </Item>
  );
}
