import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGetUserMeQuery } from "../app/services/accounts/authApi";
import { RedirectTo, useLogIn } from "./useLogIn";

/*
/auth/user/me 통해 현재 저장된 토큰 이용한 자동 로그인 수행하고 실패시 /login 으로 redirect
 */
export function useUserMe(redirectTo?: RedirectTo) {
  const { data, isError, isLoading } = useGetUserMeQuery();
  const loginResult = useLogIn(data, redirectTo);
  const history = useHistory();

  useEffect(() => {
    if (isError && redirectTo?.failure) {
      history.push(redirectTo.failure);
    }
  }, [history, isError, redirectTo?.failure]);

  return {
    isLoginSuccess: loginResult.isSuccess,
    isLoginError: loginResult.isError,
    isError,
    isLoading,
    data,
  };
}
