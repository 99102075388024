import React, { useCallback, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { LogInForm, LoginFormValue } from "../../components/forms/LogInForm";
import { usePostLoginMutation } from "../../app/services/accounts/authApi";
import { useLogOut } from "../../hooks/useLogOut";
import { isStatusError } from "../../app/services/accounts/types";
import { useLogIn } from "../../hooks/useLogIn";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useErrorStatusCode } from "../../hooks/useErrorStatusCode";

export function LogInPage() {
  const { token } = useLogOut();
  const { t } = useTranslation("login");
  const [value, setValue] = useState<LoginFormValue | undefined>(undefined);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [postLogin, { data: postData, error, isSuccess }] =
    usePostLoginMutation();
  const { state } = useLocation<{ fromResetPassword?: boolean }>();
  const fromResetPassword = state?.fromResetPassword || false;
  const loginErrorStatusCode = useErrorStatusCode(error);
  useLogIn(postData, { success: "/" });
  useEffect(() => {
    if (fromResetPassword) setIsOpenSnackbar(fromResetPassword);
  }, [fromResetPassword]);

  const handleLogin = useCallback<(value?: LoginFormValue | undefined) => void>(
    (value) => {
      if (value) {
        const { email, password } = value;
        if (email && password) {
          postLogin({
            email,
            password,
          });
        }
      }
    },
    [postLogin]
  );

  const handleSnackbarClose = useCallback(() => {
    setIsOpenSnackbar(false);
  }, []);

  return (
    <>
      {error && loginErrorStatusCode === 403 ? (
        <Redirect
          to={{ pathname: "/verify/resend", state: { email: value?.email } }}
        />
      ) : token === undefined ? (
        <LogInForm
          value={value}
          errorStatusCode={
            error && isStatusError(error) ? error.status : undefined
          }
          onChange={setValue}
          onSubmit={handleLogin}
        ></LogInForm>
      ) : isSuccess ? (
        <Redirect to={"/"}></Redirect>
      ) : null}
      <Snackbar
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        open={isOpenSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={t("snackbar.FromResetPassword")}
      />
    </>
  );
}
