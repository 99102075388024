import React, { useCallback } from "react";
import { Box } from "@mui/material";
import styled from "@mui/styled-engine";
import { useTranslation } from "react-i18next";
import Linkify from "linkify-react";
import {
  CreateEventResponse,
  EventBase,
} from "../../app/services/events/types";
import { convertMinutesToText } from "../../utils/format";

const StyledEventInfo = styled(Box)`
  padding: 0;
  margin: 0;
`;

const StyledEventInfoItem = styled(Box)`
  display: flex;
  padding: 12px 0;
  border-bottom: solid 1px #dadada;
  &:first-of-type {
    border-top: solid 1px #dadada;
  }

  > .event-info-item {
    &-label {
      font-size: 14px;
      color: #767676;
      width: 110px;
    }
    &-value {
      font-size: 14px;
      font-weight: bold;
      text-align: right;
      white-space: pre-line;
      flex: 1;
      width: calc(100% - 110px);
      > a {
        color: inherit;
      }
    }
  }
`;

export interface EventInfoProps {
  event: EventBase | CreateEventResponse | undefined;
}

export function EventInfo({ event }: EventInfoProps) {
  const { t } = useTranslation("eventInputList");
  const eventInfoKey = [
    "event_type",
    "title",
    "description",
    "place_detail",
    "duration_minutes",
  ];

  const valueByKey = useCallback(
    (key, value) => {
      if (key === "duration_minutes") {
        return convertMinutesToText(value, t("Hour"), t("Minute"));
      }
      if (key === "event_type") {
        return value ? t(`eventTypeLabels.${value}`) : "";
      }
      return value;
    },
    [t]
  );

  if (!event) {
    return null;
  }

  return (
    <StyledEventInfo component="ul">
      {Object.entries(event)
        .sort(
          ([keyA], [keyB]) =>
            eventInfoKey.indexOf(keyA) - eventInfoKey.indexOf(keyB)
        )
        .filter(([key]) => eventInfoKey.includes(key))
        .map(([key, value]) => (
          <StyledEventInfoItem key={key}>
            <Box component="div" className="event-info-item-label">
              {t(`labels.${key}`)}
            </Box>
            <Box component="div" className="event-info-item-value">
              <Linkify options={{ target: "_blank" }}>
                {valueByKey(key, value)}
              </Linkify>
            </Box>
          </StyledEventInfoItem>
        ))}
    </StyledEventInfo>
  );
}
