import React, { useState, useEffect } from "react";
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButton,
} from "@mui/material";
import { primaryButtonStyle } from "../../mui/theme";

export interface SegmentButtonItem {
  label: string | React.ReactNode;
  value: string;
}

export interface SegmentButtonProps extends ToggleButtonGroupProps {
  defaultValue?: string;
  value?: string;
  items: SegmentButtonItem[];
}

export function SegmentButton({
  defaultValue,
  value,
  color,
  items,
  onChange,
  ...props
}: SegmentButtonProps) {
  const [displayValue, setDisplayValue] = useState<string | undefined>(
    value ?? defaultValue
  );

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    currentValue: string | null
  ) => {
    if (currentValue !== null) {
      setDisplayValue(currentValue);
      if (onChange) {
        onChange(event, currentValue);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setDisplayValue(value);
    }
  }, [value]);

  return (
    <ToggleButtonGroup
      value={displayValue}
      onChange={handleToggle}
      color={color}
      exclusive
      aria-label={displayValue}
      {...props}
    >
      {items?.map((item) => (
        <ToggleButton
          key={item.value}
          value={item.value}
          aria-label={item.value}
          sx={{
            ...(color === "primary"
              ? { "&.Mui-selected": primaryButtonStyle }
              : {}),
          }}
        >
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
