import React, { useCallback } from "react";
import styled from "@mui/styled-engine";
import { ContentDescription, ContentTitle } from "../../../styled/typography";
import { Box, Button } from "@mui/material";
import { SelectedEvent, SelectedEventList } from "../common/SelectedEventList";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createPollSelectors, createPollSlice } from "./createPollSlice";
import { useHistory } from "react-router-dom";

const CreatePollFloatingBox = styled(Box)`
  padding: 0 0 60px;
`;

export interface CreatePollFloatingProps {}

export const CreatePollFloating = (_: CreatePollFloatingProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedEvents = useAppSelector((state) =>
    createPollSelectors.selectedEventsSelectors.selectAll(state)
  );

  const handleSelectedEventDelete = useCallback(
    (event: SelectedEvent) => {
      dispatch(createPollSlice.actions.removeSelectedEvents([event.id]));
    },
    [dispatch]
  );

  return (
    <CreatePollFloatingBox>
      <ContentTitle sx={{ marginBottom: "13px" }}>
        {"투표일정"} <strong>{selectedEvents.length}</strong>
      </ContentTitle>
      <ContentDescription sx={{ marginBottom: "46px" }}>
        {"게스트 들이 선택 할 수 있는 일정 목록입니다."}
      </ContentDescription>
      <SelectedEventList
        events={selectedEvents}
        onDelete={handleSelectedEventDelete}
      />
      <Button
        variant="cta"
        onClick={() => {
          history.push("/poll/event");
        }}
        disabled={selectedEvents.length === 0}
        sx={{
          borderRadius: 0,
          width: "100%",
          height: 60,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        선택 일정 {selectedEvents.length}건 최종 선택하기
      </Button>
    </CreatePollFloatingBox>
  );
};
