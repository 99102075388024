import moment from "moment";
import { DateLocalizer } from "react-big-calendar";
import { Schedule } from "../app/services/events/types";
import {
  ExtendedEventWithSchedule,
  getTitleFromTimeRange,
} from "../components/calendars/BaseCalendar";
import { getUUID } from "./getUUID";

export function convertScheduleToEvent<T extends Schedule>(
  s: Schedule,
  options: {
    email?: string;
    name?: string;
    title?: string;
    strokeColor?: string;
    bgColor?: string;
    isActive: boolean;
    originSchedule?: T;
  },
  localizer: DateLocalizer
): ExtendedEventWithSchedule<T> {
  const { email, title, ...restOptions } = options;
  const { title: titleFromTimeRange, allDay } = getTitleFromTimeRange(
    s.from_time,
    s.to_time,
    localizer
  );
  return {
    id: email ? `${email}-${getUUID()}` : `event-${getUUID()}`,
    title: title || titleFromTimeRange,
    start: moment(s.from_time).toDate(),
    end: moment(s.to_time).toDate(),
    allDay,
    email,
    ...restOptions,
  };
}
