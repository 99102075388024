import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styled from "@mui/styled-engine";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Typography,
} from "@mui/material";
import { MainTitle, SubDescription } from "../../../styled/typography";
import { TextFieldWithValidation } from "../../../components/inputs/TextFieldWithValidation";
import { useDesktopMatches } from "../../../hooks/useDesktopMatches";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createEventSelectors, createEventSlice } from "./createEventSlice";
import { usePostCreatePollMutation } from "../../../app/services/poll/pollApi";
import {
  createPollSelectors,
  createPollSlice,
} from "../create-poll/createPollSlice";
import { pollEventTypeKeys } from "../../../app/services/poll/types";

const CreateEventMainBox = styled(Box)`
  padding: 20px 40px;
`;

const CreateEventMainWrapper = styled(Box)`
  max-width: 910px;
  margin: 0 auto;
`;

const CreateEventFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  width: 100%;
  margin: 0 0 12px;
`;

const CreateEventLabel = styled(Typography)<{
  isDesktop: boolean;
}>`
  width: ${({ isDesktop }) => (isDesktop ? 220 : 120)}px;
  font-size: 16px;
  color: #333;
  line-height: 40px;
`;

export const CreateEventMain = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation("eventInputList"); // eventInputList 옮기기
  const [postCreatePoll, { isLoading, isSuccess, data: eventResponse }] =
    usePostCreatePollMutation();
  const { title, description, eventType, placeDetail } = useAppSelector(
    createEventSelectors.selectState
  );
  const selectedEvents = useAppSelector(
    createPollSelectors.selectedEventsSelectors.selectAll
  );
  const { duration } = useAppSelector(createPollSelectors.selectState);
  const handleTitleChange = useCallback(
    (value) => {
      dispatch(createEventSlice.actions.setTitle(value || ""));
    },
    [dispatch]
  );
  const handleDescriptionChange = useCallback(
    (value) => {
      dispatch(createEventSlice.actions.setDescription(value || ""));
    },
    [dispatch]
  );
  const handleEventTypeChange = useCallback(
    (value) => {
      dispatch(createEventSlice.actions.setEventType(value));
    },
    [dispatch]
  );
  const handlePlaceDetailChange = useCallback(
    (value) => {
      dispatch(createEventSlice.actions.setPlaceDetail(value));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(() => {
    postCreatePoll({
      title,
      description,
      event_type: eventType,
      place_detail: placeDetail,
      cards: selectedEvents.map((d) => ({
        start_at: d.start.toISOString(),
        end_at: d.end.toISOString(),
      })),
      duration_minutes: duration,
    });
  }, [
    postCreatePoll,
    title,
    description,
    eventType,
    placeDetail,
    selectedEvents,
    duration,
  ]);

  const isDesktop = useDesktopMatches();
  const labelPlacement: "end" | "start" | "top" | "bottom" = isDesktop
    ? "start"
    : "top";

  useEffect(() => {
    if (isSuccess && eventResponse) {
      history.push(`/poll/${eventResponse.id}/complete`);
      dispatch(createEventSlice.actions.reset());
      dispatch(createPollSlice.actions.removeSelectedEventsAll());
    }
    // ignore dispatch, history
    // eslint-disable-next-line
  }, [isSuccess, eventResponse]);

  return (
    <CreateEventMainBox>
      <CreateEventMainWrapper>
        <MainTitle sx={{ marginBottom: "3px" }}>
          이벤트 정보를 입력해주세요.
        </MainTitle>
        <SubDescription sx={{ marginBottom: "38px" }}>
          * 표기 항목은 필수 입력입니다.
        </SubDescription>
        <FormGroup sx={{ maxWidth: "910px" }}>
          <CreateEventFormControlLabel
            control={
              <TextFieldWithValidation
                size="small"
                type="text"
                placeholder={t(`labels.title`)}
                fullWidth
                value={title}
                onValueChange={handleTitleChange}
              />
            }
            label={
              <CreateEventLabel isDesktop={isDesktop}>
                {t(`labels.title`)}
              </CreateEventLabel>
            }
            labelPlacement={labelPlacement}
          />
          <CreateEventFormControlLabel
            control={
              <TextFieldWithValidation
                sx={{
                  "& textarea": { height: "142px !important" },
                }}
                size="small"
                type="text"
                placeholder={t(`labels.description`)}
                fullWidth
                multiline
                rows={6}
                value={description}
                onValueChange={handleDescriptionChange}
              />
            }
            label={
              <CreateEventLabel isDesktop={isDesktop}>
                {t(`labels.description`)}
              </CreateEventLabel>
            }
            labelPlacement={labelPlacement}
          />
          <CreateEventFormControlLabel
            control={
              <>
                <TextFieldWithValidation
                  size="small"
                  select={true}
                  fullWidth
                  multiline
                  value={eventType}
                  placeholder={t(`labels.event_type`)}
                  onValueChange={handleEventTypeChange}
                >
                  {pollEventTypeKeys.map((eventTypeKey) => {
                    return (
                      <MenuItem key={eventTypeKey} value={eventTypeKey}>
                        {t(`eventTypeLabels.${eventTypeKey}`)}
                      </MenuItem>
                    );
                  })}
                </TextFieldWithValidation>
              </>
            }
            label={
              <CreateEventLabel isDesktop={isDesktop}>
                {t(`labels.event_type`)}
              </CreateEventLabel>
            }
            labelPlacement={labelPlacement}
          />
          {!!eventType && (
            <CreateEventFormControlLabel
              control={
                <TextFieldWithValidation
                  size="small"
                  type="text"
                  placeholder={t(`labels.place_detail`)}
                  fullWidth
                  value={placeDetail}
                  onValueChange={handlePlaceDetailChange}
                  helperText={`${t("placeDetailHelperText.Prefix")}${t(
                    `labels.place_detail`
                  )}${t("placeDetailHelperText.Suffix")}`}
                />
              }
              label={<CreateEventLabel isDesktop={isDesktop} />}
              labelPlacement={labelPlacement}
            />
          )}
        </FormGroup>
        <Box sx={{ textAlign: "right" }}>
          <LoadingButton
            sx={{ boxShadow: "none", marginTop: "20px" }}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            loading={isLoading}
            onClick={handleSubmit}
          >
            {"일정 생성하기"}
          </LoadingButton>
        </Box>
      </CreateEventMainWrapper>
    </CreateEventMainBox>
  );
};
