import { useTranslation } from "react-i18next";
type TranslationParams = Parameters<typeof useTranslation>;

export function useEmailTranslation(...params: TranslationParams) {
  const { t, ...others } = useTranslation(...params);
  return {
    t: ((keys: any, options: any) =>
      t(keys, {
        ...options,
        interpolation: { suffix: "//", prefix: "//" },
      })) as typeof t,
    ...others,
  };
}
