import React, { useCallback, useEffect } from "react";
import { Box, Drawer, DrawerProps, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";

import { ChangeScheduleCalendar } from "../../components/calendars/ChangeScheduleCalendar";
import { EventBase } from "../../app/services/events/types";
import {
  useGetGuestSchedulesQuery,
  useGetGuestsQuery,
  usePostAttendeeQuery,
  usePostSelectSchedulesMutation,
  usePutUpdateStatusMutation,
} from "../../app/services/events/eventApi";
import { ExtendedEvent } from "../../components/calendars/BaseCalendar";
import styled from "@mui/styled-engine";
import { useEventAttendStatus } from "../../hooks/useEventAttendStatus";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

const StyledCreateEventPageCalendarDrawer = styled(Drawer)<{
  isDesktop: boolean;
}>`
  .MuiPaper-root {
    width: ${(props) => (props.isDesktop ? "589px" : "100vw")};
    border-radius: 10px;
  }
  .calendar-drawer {
    background-color: #f5f6fa;
    padding: 20px 20px 50px;
    &-header {
      padding: 20px;
      height: 75px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
    }
    &-title {
      font-size: 24px;
      color: #232323;
      font-weight: bold;
      flex: 1;
    }
    &-close {
      margin-right: 2px;
    }
  }
`;

export interface CreateEventPageCalendarDrawerProps extends DrawerProps {
  me: string;
  event: EventBase;
  onClose: () => void;
}

export function CreateEventPageCalendarDrawer({
  me,
  event,
  open,
  onClose,
}: CreateEventPageCalendarDrawerProps) {
  const { t } = useTranslation("changeScheduleCalendar");
  const isDesktop = useDesktopMatches();
  const { data: attendData } = usePostAttendeeQuery({
    id: event.id,
  }); // post query는 작동하지 않음?
  const { data: others, refetch: refetchGuests } = useGetGuestsQuery({
    id: event.id,
  });
  const { data: schedules, refetch: refetchSchedules } =
    useGetGuestSchedulesQuery({ id: event.id });
  const [
    postSelectSchedules,
    { isSuccess: isPostSuccess, isError: isPostError },
  ] = usePostSelectSchedulesMutation();
  const [putUpdateStatus, { isError: isPutUpdateStatusError }] =
    usePutUpdateStatusMutation();
  const attend = useEventAttendStatus(attendData);

  const handleAttendOrNotChange = useCallback(
    (attend) => {
      //setAttend(attend);
      putUpdateStatus({ id: event.id, status: attend ? 1 : 2 });
    },
    [event, putUpdateStatus]
  );

  const handleSubmit = useCallback(
    (schedules: ExtendedEvent[]) => {
      postSelectSchedules({
        id: event.id,
        schedules: schedules.map((d) => ({
          from_time: d.start?.toISOString() as string,
          to_time: d.end?.toISOString() as string,
        })),
      });
    },
    [event, postSelectSchedules]
  );
  useEffect(() => {
    // TODO: 제출 실패 보고
  }, [isPostError]);

  useEffect(() => {
    // TODO: attend 변경 실패 시 보고
  }, [isPutUpdateStatusError]);

  useEffect(() => {
    if (isPostSuccess && open && onClose) {
      onClose();
    }
    // eslint-disable-next-line
  }, [isPostSuccess]);

  useEffect(() => {
    if (open) {
      refetchGuests();
      refetchSchedules();
    }
  }, [open, refetchGuests, refetchSchedules]);

  return (
    <StyledCreateEventPageCalendarDrawer
      open={open}
      anchor={"right"}
      onClose={onClose}
      isDesktop={isDesktop}
    >
      <Box className="calendar-drawer-header">
        <Box className="calendar-drawer-title">{t("TitleDrawer")}</Box>
        <IconButton className="calendar-drawer-close" onClick={onClose}>
          <ClearIcon sx={{ fontSize: 32 }} />
        </IconButton>
      </Box>
      <Box className="calendar-drawer">
        <ChangeScheduleCalendar
          calendarAnchorTop={0}
          me={me}
          attend={attend}
          others={others || []}
          schedules={schedules || []}
          onAttendOrNotChange={handleAttendOrNotChange}
          onSubmit={handleSubmit}
        />
      </Box>
    </StyledCreateEventPageCalendarDrawer>
  );
}
