import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePostEventsAttendedQuery } from "../../app/services/events/eventApi";
import {
  WorkspaceList,
  WorkspaceListEmptyState,
  WorkspaceListItem,
} from "../../components/list/WorkspaceList";
import styled from "@mui/styled-engine";
import {
  EventsResponse,
  EventStatus,
  EventStatusMap,
} from "../../app/services/events/types";
import { EventStatusChip } from "../../components/chip/EventStatusChip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  StyledAvatar,
  StyledAvatarGroup,
} from "../../components/styled/StyledAvatar";
import { css } from "@mui/styled-engine";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";
import { useUserMe } from "../../hooks/useUserMe";
import { skipToken } from "@reduxjs/toolkit/query";

const StyledWorkspaceItem = styled(Box)<{ isDesktop: boolean }>`
  padding: 30px 0;
  margin: 0 auto;
  max-width: 515px;
  position: relative;
  &:last-of-type {
    border-bottom: none;
  }
  .workspace-item {
    &-title {
      ${(props) =>
        !props.isDesktop &&
        css`
          padding: 0 20px;
        `}
      font-size: 24px;
      font-weight: 700;
      color: #232323;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      white-space: pre-line;
      > span {
        flex: 1;
        white-space: nowrap;
        > strong {
          color: #4555ff;
        }
      }
    }
    &-button-area {
      ${(props) =>
        !props.isDesktop
          ? css`
              padding: 0 20px;
              display: flex;
              align-items: center;
            `
          : css`
              position: absolute;
              top: 44px;
              right: 0;
            `}
    }
  }
`;

const StyledListFilterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 10px 10px 0 0;
  height: 60px;
  background-color: #ffffff;
  overflow: hidden;
`;

const StyledListFilterItem = styled(Button)<{
  selected: boolean;
}>`
  width: 25%;
  height: 100%;
  font-size: 14px;
  color: #333;
  position: relative;
  white-space: nowrap;
  min-width: unset;
  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.5;
  }
  strong {
    font-weight: normal;
  }
  ${(props) =>
    props.selected &&
    css`
      font-size: 14px;
      font-weight: bold;
      color: #4555ff;
      strong {
        font-weight: bold;
      }
    `}

  &:not(:first-child) {
    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 1px;
      height: 40px;
      background-color: #e2e2e2;
    }
  }
`;

const StyledListGuideMessage = styled(Box)`
  height: 30px;
  padding: 0 20px;
  background-color: #e9ebf2;
  display: flex;
  align-items: center;

  & > span {
    font-size: 12px;
    font-weight: 500;
    color: #6d6d6d;
    display: flex;
    align-items: center;
    white-space: nowrap;
    & > img {
      margin: 0 3px;
    }
  }
`;

interface ListFilterItem {
  title: string;
  value: EventStatus;
}

interface ListFilterProps {
  listFilterItems: ListFilterItem[];
  selected: EventStatus;
  onItemClick: (value: any) => void;
}

export function ListFilter({
  listFilterItems,
  onItemClick,
  selected,
}: ListFilterProps) {
  const onItemClickInternal = (value: any) => () => onItemClick(value);
  return (
    <StyledListFilterWrapper>
      {listFilterItems.map((listFilterItem: ListFilterItem, index) => (
        <StyledListFilterItem
          onClick={onItemClickInternal(listFilterItem.value)}
          key={`list-filter-item-${index}`}
          selected={listFilterItem.value === selected}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: listFilterItem.title,
            }}
          />
        </StyledListFilterItem>
      ))}
    </StyledListFilterWrapper>
  );
}

type WorkspaceFilterType = "all" | "wait" | "confirmed" | "deleted";

const useCurrentStatusFilteredTotal = (
  eventsTotal: number,
  statusFilter: EventStatus,
  eventHosted?: EventsResponse
) => {
  const getStatusFilterAsPropertyName = (statusFilter: EventStatus) => {
    switch (statusFilter) {
      case EventStatusMap.WAIT:
        return "wait";
      case EventStatusMap.CONFIRMED:
        return "confirmed";
      case EventStatusMap.DELETED:
        return "deleted";
      default:
        return "all";
    }
  };

  const [currentStatusFilteredTotal, setCurrentStatusFilteredTotal] =
    useState<number>(0);

  useEffect(() => {
    if (statusFilter === EventStatusMap.ALL) {
      setCurrentStatusFilteredTotal(eventsTotal);
    } else {
      setCurrentStatusFilteredTotal(
        (eventHosted?.[
          getStatusFilterAsPropertyName(statusFilter) as keyof EventsResponse
        ] ?? 0) as number
      );
    }
  }, [eventHosted, eventsTotal, statusFilter]);

  return currentStatusFilteredTotal;
};

export function WorkspacePage() {
  const { t } = useTranslation("workspace");
  const { isLoginSuccess } = useUserMe({
    failure: "/welcome",
  });
  const isDesktop = useDesktopMatches();
  const showCount = 5;
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState<EventStatus>(
    EventStatusMap.ALL
  );
  const pageAndStatusFilterRef = useRef<{
    page: number;
    statusFilter: EventStatus;
  }>({
    page,
    statusFilter,
  });
  const { data: eventHostedByPage, isFetching: isEventHostedByPageFetching } =
    usePostEventsAttendedQuery(
      isLoginSuccess
        ? {
            show_count: showCount,
            page,
            status_filter: statusFilter,
          }
        : skipToken // login 성공 했을 경우에만 조회
    );

  const [eventHosted, setEventHosted] = useState<EventsResponse | undefined>(
    eventHostedByPage
  );

  const eventsTotal = eventHosted
    ? eventHosted.wait + eventHosted.confirmed + eventHosted.deleted
    : 0;

  useEffect(() => {
    pageAndStatusFilterRef.current = {
      page,
      statusFilter,
    };
  }, [page, statusFilter]);

  useEffect(() => {
    if (eventHostedByPage) {
      const { page, statusFilter } = pageAndStatusFilterRef.current;
      setEventHosted(
        (previousEventHosted) =>
          ({
            ...(statusFilter === EventStatusMap.ALL
              ? eventHostedByPage
              : (previousEventHosted as EventsResponse)),
            events:
              page === 1
                ? [...(eventHostedByPage?.events ?? [])]
                : [
                    ...(previousEventHosted?.events ?? []),
                    ...(eventHostedByPage?.events ?? []),
                  ],
          } as EventsResponse)
      );
    }
  }, [eventHostedByPage]);

  const getFilterItemTitle = (statusString: WorkspaceFilterType) =>
    `<span>${t(`eventList.filter.${statusString}`)}</span><strong">${
      statusString !== "all"
        ? eventHosted?.[statusString as keyof EventsResponse] ?? 0
        : eventsTotal
    }</strong>`;

  const currentStatusFilteredTotal = useCurrentStatusFilteredTotal(
    eventsTotal,
    statusFilter,
    eventHosted
  );

  if (!isLoginSuccess) {
    return null;
  }

  return (
    <>
      <StyledWorkspaceItem isDesktop={isDesktop}>
        <Typography component="div" className="workspace-item-title">
          {t("create.Title")}
        </Typography>
        <Box className="workspace-item-button-area">
          <Button
            type={"submit"}
            variant="cta"
            color="primary"
            href={"/event/new"}
            sx={{
              height: "48px",
            }}
          >
            {t("CreateEvent")}{" "}
            <ArrowForwardIosIcon
              sx={{ fontSize: "16px", verticalAlign: "middle" }}
            />
          </Button>
        </Box>
      </StyledWorkspaceItem>
      <StyledWorkspaceItem isDesktop={isDesktop}>
        <Typography component="div" className="workspace-item-title">
          <span
            dangerouslySetInnerHTML={{
              __html: t("eventList.Title", {
                total: eventHosted?.scheduled,
              }),
            }}
          />
        </Typography>
        <ListFilter
          listFilterItems={[
            {
              title: getFilterItemTitle("all"),
              value: EventStatusMap.ALL,
            },
            {
              title: getFilterItemTitle("wait"),
              value: EventStatusMap.WAIT,
            },
            {
              title: getFilterItemTitle("confirmed"),
              value: EventStatusMap.CONFIRMED,
            },
            {
              title: getFilterItemTitle("deleted"),
              value: EventStatusMap.DELETED,
            },
          ]}
          selected={statusFilter}
          onItemClick={(value) => {
            if (statusFilter !== value) {
              setPage(1);
              setStatusFilter(value);
            }
          }}
        />
        <StyledListGuideMessage>
          <span
            dangerouslySetInnerHTML={{
              __html: t("eventList.Guide", {
                icon: '<img src="/images/ic_host.svg" width="18" height="18">',
              }),
            }}
          />
        </StyledListGuideMessage>
        <WorkspaceList
          onMoreButtonClick={() => setPage((page) => page + 1)}
          moreButtonHidden={
            eventsTotal === 0 ||
            isEventHostedByPageFetching ||
            (eventHosted?.events?.length ?? 0) >= currentStatusFilteredTotal
          }
        >
          {eventHosted?.events?.map((event, index) => (
            <WorkspaceListItem event={event} key={`${event.id}-${index}`}>
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "16px",
                }}
              >
                {isDesktop && (
                  <StyledAvatarGroup max={4} size="small">
                    {event.guests?.map((guest, index) => (
                      <StyledAvatar
                        size="small"
                        bgColor={guest?.bg_color || "#000000"}
                        key={`${guest.email}-${index}`}
                      >
                        {(guest?.name || guest?.email)
                          ?.substr(0, 1)
                          .toUpperCase()}
                      </StyledAvatar>
                    ))}
                  </StyledAvatarGroup>
                )}
              </Box>
              <EventStatusChip eventStatus={event.status} />
            </WorkspaceListItem>
          ))}
          {!eventsTotal && (
            <WorkspaceListEmptyState
              emoji={t("eventList.emptyState.noEvents.Emoji")}
              message={t("eventList.emptyState.noEvents.Text")}
            />
          )}
          {!!eventsTotal && !eventHosted?.events?.length && (
            <WorkspaceListEmptyState
              emoji={t(
                `eventList.emptyState.noFilteredEvents.${statusFilter}.Emoji`
              )}
              message={t(
                `eventList.emptyState.noFilteredEvents.${statusFilter}.Text`
              )}
            />
          )}
        </WorkspaceList>
      </StyledWorkspaceItem>
    </>
  );
}
