import React from "react";
import { Box, Button } from "@mui/material";
import { GuestItem } from "./GuestItem";
import { EventFormItem, EventFormItemProps } from "./EventFormItem";
import { GuestWithStatus } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
import { GuestStatusChip } from "../chip/GuestStatusChip";
import { MoreButton } from "../buttons/MoreButton";

export interface EventGuestItemListProps
  extends Omit<EventFormItemProps, "children"> {
  me: string;
  isFromHostEvent?: boolean;
  isAfterConfirm?: boolean;
  guests: GuestWithStatus[];
  onChangeScheduleCalendarOpen?: () => void;
  onGuestDelete?: (guest: GuestWithStatus) => void;
  onMailSend?: (guest: GuestWithStatus) => void;
  onGuestAddClick?: () => void;
}

export function EventGuestItemList({
  me,
  guests,
  title,
  isFromHostEvent,
  isAfterConfirm,
  onGuestDelete,
  onMailSend,
  onChangeScheduleCalendarOpen,
  onGuestAddClick,
  ...props
}: EventGuestItemListProps) {
  const { t } = useTranslation("guestInfo");
  const { t: tChangeSchedule } = useTranslation("changeScheduleCalendar");
  return (
    <EventFormItem
      title={title || t("Label", { count: guests.length || 0 })}
      {...props}
    >
      <Box component="ul">
        {guests.map((guest) => (
          <GuestItem
            key={guest.email}
            isHost={guest.is_host}
            guest={guest}
            isMe={me === guest.email}
          >
            <>
              {isFromHostEvent && guest.is_host ? (
                <Button
                  size="small"
                  variant="inner"
                  color="normal"
                  onClick={onChangeScheduleCalendarOpen}
                >
                  {tChangeSchedule("TitleDrawer")}
                </Button>
              ) : (
                <GuestStatusChip
                  isAfterConfirm={isAfterConfirm}
                  attendStatus={guest.status}
                  sx={{ marginRight: isFromHostEvent ? "16px" : 0 }}
                />
              )}

              {isFromHostEvent && !guest.is_host && (
                <MoreButton
                  options={[
                    {
                      title: t("moreButton.DeleteGuest"),
                      action: () => {
                        if (onGuestDelete) onGuestDelete(guest);
                      },
                    },
                    {
                      title: t("moreButton.SendMail"),
                      action: () => {
                        if (onMailSend) onMailSend(guest);
                      },
                    },
                  ]}
                />
              )}
            </>
          </GuestItem>
        ))}
        {onGuestAddClick && <div>hi</div>}
      </Box>
    </EventFormItem>
  );
}
