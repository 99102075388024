import React, { useCallback, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import styled from "@mui/styled-engine";
import { useDesktopMatches } from "../../../hooks/useDesktopMatches";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";
import { StyledCommonModalForEdit } from "../../../components/styled/StyledCommonModalForEdit";
import { useAppDispatch } from "../../../app/hooks";
import { selectEventSlice } from "./selectEventSlice";

const AddGuestButton = styled(Button)`
  display: inline-flex;
  min-width: 108px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  padding: 0 30px;
  margin-bottom: 12px;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  vertical-align: middle;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const tempNewGuestKey = "@@tempNewGuestKey@@";

export function SelectEventGuestAddWidget({ eventKey }: { eventKey?: string }) {
  const dispatch = useAppDispatch();
  const isDesktop = useDesktopMatches();
  const [guest, setGuest] = useState<PollEventGuestCandidate | null>(null);
  const [isGuestModalOpen, setGuestModalOpen] = useState<boolean>(false);

  const handleGuestModalOpen = useCallback(() => {
    setGuestModalOpen(true);
  }, []);
  const handleGuestModalClose = useCallback(() => {
    setGuest(null);
    setGuestModalOpen(false);
  }, []);

  const handleGuestConfirm = useCallback(() => {
    if (eventKey && guest && guest.name) {
      dispatch(
        selectEventSlice.actions.setNewGuest({ ...guest, key: tempNewGuestKey })
      );
      dispatch(selectEventSlice.actions.setSelectedGuestKey(tempNewGuestKey));
      handleGuestModalClose();
    }
  }, [dispatch, eventKey, guest, handleGuestModalClose]);

  return eventKey ? (
    <>
      <AddGuestButton
        onClick={handleGuestModalOpen}
        disabled={isGuestModalOpen}
      >
        {"+ 게스트 추가하기"}
      </AddGuestButton>

      <StyledCommonModalForEdit
        width={786}
        title={"게스트 등록하기"}
        open={isGuestModalOpen}
        confirmButtonText={"게스트 등록하기"}
        confirmButtonColor={"primary"}
        cancelButtonText={"취소"}
        onConfirm={handleGuestConfirm}
        onCancel={handleGuestModalClose}
        onClose={handleGuestModalClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            marginBottom: "30px",
          }}
        >
          <TextField
            id="new-guest-name"
            label="게스트 이름"
            value={guest?.name || ""}
            required={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setGuest((guest) => ({ ...guest, name: event.target.value }));
            }}
          />
          <TextField
            id="new-guest-email"
            label="게스트 이메일"
            helperText={"이메일을 등록하시면 이벤트 소식을 받아보실 수 있어요!"}
            value={guest?.email || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setGuest((guest) => ({ ...guest, email: event.target.value }));
            }}
          />
        </Box>
      </StyledCommonModalForEdit>
    </>
  ) : null;
}
