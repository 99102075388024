import { RootState } from "../store";
import { authSlice } from "../../features/auth/authSlice";
import Cookies from "universal-cookie";
import { fetchApiBaseQuery } from "./fetchApiBaseQuery";

export const fetchCsrfBaseQuery = (baseUrl: string) =>
  fetchApiBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState)[authSlice.name].token;
      // CSRF token
      const csrfToken = new Cookies().get("csrftoken");

      headers.set("X-CSRFToken", csrfToken);

      if (token) {
        headers.set("Authorization", " Token " + token);
      }

      return headers;
    },
  });
