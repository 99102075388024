import React from "react";
import styled from "@mui/styled-engine";
import { Box, BoxProps } from "@mui/material";
import { css } from "@mui/styled-engine";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

export const EmptyBoxContainer = styled(Box)<{
  isDesktop?: boolean;
  height: number;
}>`
  border-radius: 10px;
  background-color: #fff;
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${(props) =>
    !props.isDesktop &&
    css`
      margin: 0 -20px;
    `}
  & > .empty {
    &-emoji {
      font-size: 43px;
      margin-bottom: 13px;
    }
    &-description {
      font-size: 14px;
      text-align: center;
      color: #aaa;
      white-space: pre-line;
    }
    &-link {
      margin-top: 61px;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: #333;
      text-decoration: underline !important;
      margin-bottom: 25px;
      cursor: pointer;
    }
  }
`;

interface EmptyBoxProps extends BoxProps {
  children?: React.ReactNode;
  height: number;
}

export const EmptyBox = ({ children, height, ...props }: EmptyBoxProps) => {
  const isDesktop = useDesktopMatches();
  return (
    <EmptyBoxContainer isDesktop={isDesktop} height={height} {...props}>
      {children}
    </EmptyBoxContainer>
  );
};
