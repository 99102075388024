import React, { useEffect, useState } from "react";
import styled, { css } from "@mui/styled-engine";
import { Box } from "@mui/material";
import { Main } from "./Main";
import { Setting } from "./Setting";
import { Floating } from "./Floating";
import { LayoutContext } from "./context/LayoutContext";
import { useDesktopMatches } from "../hooks/useDesktopMatches";
import { layout } from "../styled/token";
// @ts-ignore
import { SxProps, Theme } from "@mui/material/styles";

const PageContainer = styled(Box)<{
  headerHeight: number;
  hasSetting: boolean;
  isDesktop: boolean;
}>`
  width: 100%;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      background: #ffffff;
    `}

  &.desktop {
    height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
    display: grid;
    ${({ hasSetting }) =>
      hasSetting
        ? css`
            grid-template-columns: ${layout.setting.width}px minmax(0, 1fr);
          `
        : css`
            background-color: #f8fafb;
          `}
    border-top: 1px solid #d9d7d2;
    overflow: hidden;
    border-top: 1px solid #d9d7d2;
  }
  &.mobile {
    width: 100%;
  }
`;

interface PageProps {
  main: React.ReactNode;
  setting?: React.ReactNode;
  floating?: React.ReactNode;
  mainStyle?: SxProps<Theme>;
  settingStyle?: SxProps<Theme>;
}

export const Page = ({
  main,
  setting,
  floating,
  mainStyle,
  settingStyle,
}: PageProps) => {
  const isDesktop = useDesktopMatches();
  const platform = isDesktop ? "desktop" : "mobile";
  const headerHeight = isDesktop ? 84 : 56;
  const [isSettingOpenOnMobile, setSettingOpenOnMobile] = useState(false);

  useEffect(() => {
    if (isDesktop || isSettingOpenOnMobile) {
      document.body.style.overflow = "hidden";
      if (isDesktop) {
        setSettingOpenOnMobile(false);
      }
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isDesktop, isSettingOpenOnMobile]);

  return (
    <LayoutContext.Provider
      value={{
        isDesktop,
        headerHeight,
        platform,
        isSettingOpenOnMobile,
        setSettingOpenOnMobile,
      }}
    >
      <PageContainer
        id="page-container"
        className={platform}
        headerHeight={headerHeight}
        hasSetting={!!setting}
        isDesktop={isDesktop}
      >
        {setting && <Setting sx={settingStyle}>{setting}</Setting>}
        <Main sx={mainStyle} hasSetting={!!setting}>
          {main}
        </Main>
      </PageContainer>
      {floating && (
        <Floating buttonText={"일정 확인하러 가기"}>{floating}</Floating>
      )}
    </LayoutContext.Provider>
  );
};
