import React from "react";
import { useAppSelector } from "../../app/hooks";
import { authSelectors } from "../auth/authSlice";
import { EventDetailForm } from "../../components/forms/EventDetailForm";
import {
  useGetEventInfoQuery,
  useGetGuestsQuery,
} from "../../app/services/events/eventApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import { GuestSchedule, Schedule } from "../../app/services/events/types";

const sampleEvents = [
  {
    email: "guest0@invy.clu",
    name: "게스트0",
    is_me: false,
    schedules: [
      {
        from_time: "2022-01-04T02:30:00+09:00",
        to_time: "2022-01-04T18:30:00+09:00",
      },
    ],
  },
  {
    email: "guest1@example.com",
    name: "게스트1",
    is_me: false,
    schedules: [
      {
        from_time: "2022-01-04T02:30:00+09:00",
        to_time: "2022-01-04T18:30:00+09:00",
      },
    ],
  },
  {
    email: "guest2@invy.club",
    name: "게스트2",
    is_me: false,
    schedules: [
      {
        from_time: "2022-01-05T12:30:00+09:00",
        to_time: "2022-01-05T14:30:00+09:00",
      },
      {
        from_time: "2022-01-05T16:30:00+09:00",
        to_time: "2022-01-05T18:30:00+09:00",
      },
    ],
  },
  {
    email: "me@example.com",
    name: "me",
    is_me: true,
    schedules: [
      {
        from_time: "2022-01-05T15:30:00+09:00",
        to_time: "2022-01-05T17:30:00+09:00",
      },
      {
        from_time: "2022-01-06T18:30:00+09:00",
        to_time: "2022-01-06T19:00:00+09:00",
      },
    ],
  },
];

export interface EventDetailPageParams {
  eventId: string;
}

export function EventDetailPage() {
  const { eventId } = useParams<EventDetailPageParams>();
  const { user } = useAppSelector(authSelectors.selectState);

  const { data: eventData } = useGetEventInfoQuery({
    id: eventId,
  });
  const eventIdQueryParams = eventData?.id
    ? {
        id: eventData?.id,
      }
    : skipToken;
  const { data: guests } = useGetGuestsQuery(eventIdQueryParams);

  return (
    <EventDetailForm
      event={eventData}
      onSelectSchedulesButtonClick={() => {}}
      user={user}
      guests={guests || []}
      schedules={sampleEvents?.reduce((acc: Schedule[], cur: GuestSchedule) => {
        acc = acc.concat(cur.schedules);
        return acc;
      }, [])}
    />
  );
}
