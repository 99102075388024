import React, { useCallback, useEffect, useState } from "react";
import styled from "@mui/styled-engine";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const StyledLeaveTermsCheck = styled(Box)`
  display: flex;
  cursor: pointer;
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .leave-terms-check {
    &-label {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      white-space: pre-line;
      &-container {
      }
      &--sub {
        margin-top: 10px;
        font-size: 14px;
        color: #333;
        white-space: pre-line;
        & > strong {
          color: #ff0000;
          font-weight: normal;
        }
      }
    }
  }
`;

interface LeaveTermsCheckProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  label: string;
  subLabel: string;
}

export function LeaveTermsCheck({
  checked,
  onChange,
  label,
  subLabel,
}: LeaveTermsCheckProps) {
  const [displayChecked, setDisplayChecked] = useState(checked);

  const onClick = useCallback(() => {
    setDisplayChecked(!displayChecked);
    onChange?.(!displayChecked);
  }, [displayChecked, onChange]);

  useEffect(() => {
    setDisplayChecked(checked);
  }, [checked]);
  return (
    <StyledLeaveTermsCheck onClick={onClick}>
      <CheckIcon
        sx={{
          color: displayChecked ? "#4555ff" : "#aaaaaa",
          fontSize: 24,
          marginRight: "16px",
        }}
      />
      <Box className="leave-terms-check-label-container">
        <Typography className="leave-terms-check-label">{label}</Typography>
        {subLabel && (
          <Typography
            className="leave-terms-check-label--sub"
            dangerouslySetInnerHTML={{
              __html: subLabel,
            }}
          />
        )}
      </Box>
    </StyledLeaveTermsCheck>
  );
}
