import React, { useCallback, useEffect, useState } from "react";
import { EditEventButton } from "../../components/buttons/EditEventButton";
import { EditEventModal } from "../../components/modal/EditEventModal";
import { EventWithGuests } from "../../app/services/events/types";
import {
  useDeleteCancelEventMutation,
  useGetRecentEmailsMutation,
  usePostInviteGuestMutation,
  usePutUpdateEventMutation,
} from "../../app/services/events/eventApi";
import { InviteGuestsToEventModal } from "../../components/modal/InviteGuestsToEventModal";
import { DeleteEventModal } from "../../components/modal/DeleteEventModal";

export interface EditEventButtonWithModalsProps {
  eventInfo?: EventWithGuests;
}

export function EditEventButtonWithModals({
  eventInfo,
}: EditEventButtonWithModalsProps) {
  const [putUpdateEvent] = usePutUpdateEventMutation();
  const [postInviteGuest] = usePostInviteGuestMutation();
  const [deleteInviteGuest] = useDeleteCancelEventMutation();
  const [getRecentEmails, { data: recentEmails }] =
    useGetRecentEmailsMutation();
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [isInviteGustsModalOpen, setIsInviteGustsModalOpen] = useState(false);
  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);
  const [guests, setGuests] = useState<EventWithGuests["guests"] | undefined>(
    undefined
  );
  const handleEditEventModalOpen = useCallback(() => {
    setIsEditEventModalOpen(true);
  }, []);
  const handleEditEventModalClose = useCallback(() => {
    setIsEditEventModalOpen(false);
  }, []);
  const handleEditEventModalSave = useCallback(
    (event) => {
      setIsEditEventModalOpen(false);
      putUpdateEvent(event);
    },
    [putUpdateEvent]
  );

  const handleInviteGuestsModalOpen = useCallback(() => {
    setIsInviteGustsModalOpen(true);
  }, []);
  const handleInviteGuestsModalClose = useCallback(() => {
    setIsInviteGustsModalOpen(false);
  }, []);
  const handleInviteGuestsModalSave = useCallback<
    React.ComponentProps<typeof InviteGuestsToEventModal>["onSave"]
  >(
    (guests) => {
      if (eventInfo) {
        guests = guests || [];
        postInviteGuest({
          id: eventInfo.id,
          guests: guests,
        });
      }
      setIsInviteGustsModalOpen(false);
    },
    [eventInfo, postInviteGuest]
  );

  const handleDeleteEventModalOpen = useCallback(() => {
    setIsDeleteEventModalOpen(true);
  }, []);
  const handleDeleteEventModalClose = useCallback(() => {
    setIsDeleteEventModalOpen(false);
  }, []);
  const handleDeleteEventModalSave = useCallback(() => {
    if (eventInfo) deleteInviteGuest(eventInfo.id);
    setIsDeleteEventModalOpen(false);
  }, [eventInfo, deleteInviteGuest]);

  useEffect(() => {
    if (isInviteGustsModalOpen) getRecentEmails();
  }, [getRecentEmails, isInviteGustsModalOpen]);

  useEffect(() => {
    if (eventInfo) {
      setGuests(eventInfo?.guests.filter((d) => !d.is_host));
    } else {
      setGuests(undefined);
    }
  }, [eventInfo]);

  return (
    <>
      <EditEventButton
        onEditEventModalOpen={handleEditEventModalOpen}
        onInviteGuestsModalOpen={handleInviteGuestsModalOpen}
        onDeleteEventModalOpen={handleDeleteEventModalOpen}
      />
      <EditEventModal
        open={isEditEventModalOpen}
        event={eventInfo}
        onClose={handleEditEventModalClose}
        onSave={handleEditEventModalSave}
      />
      <InviteGuestsToEventModal
        open={isInviteGustsModalOpen}
        recentEmails={recentEmails}
        guests={guests}
        onSave={handleInviteGuestsModalSave}
        onClose={handleInviteGuestsModalClose}
      />
      <DeleteEventModal
        open={isDeleteEventModalOpen}
        onSave={handleDeleteEventModalSave}
        onClose={handleDeleteEventModalClose}
      />
    </>
  );
}
