import React, { useCallback } from "react";
import { DateRangePicker } from "@mui/lab";
import { createPollSlice } from "../../create-poll/createPollSlice";
import { useAppDispatch } from "../../../../app/hooks";

export function useHandleDateRangeChange() {
  const dispatch = useAppDispatch();
  return useCallback<React.ComponentProps<typeof DateRangePicker>["onChange"]>(
    (dateRange) => {
      // @ts-ignore
      dispatch(createPollSlice.actions.setDateRange(dateRange));
      dispatch(createPollSlice.actions.removeSelectedEventsAll());
    },
    [dispatch]
  );
}
