import moment, { MomentInput, unitOfTime } from "moment";

export function getMomentRange(
  startDate: MomentInput,
  endDate: MomentInput,
  type: unitOfTime.Base
) {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, type));
  }
  return range;
}
