import React from "react";
import { A, AProps, Item, Span } from "react-html-email";
import { EmailCommonProps, EmailThemeType } from "./types";
import { theme } from "../theme";

const buttonTextStyle = {
  color: "#fff",
  textDecoration: "none",
};

const linkButtonStyle = {
  padding: "19px 20px",
  width: "100%",
  display: "block",
  borderRadius: "6px",
  boxSizing: "border-box",
  textAlign: "center",
} as AProps["style"];

export interface EmailButtonProps extends EmailCommonProps, EmailThemeType {
  to?: string;
}

export function EmailButton({
  to,
  style,
  type = "common",
  children,
}: EmailButtonProps) {
  return (
    <Item style={style}>
      {to ? (
        <A
          {...buttonTextStyle}
          href={to}
          style={{
            ...linkButtonStyle,
            backgroundColor: theme[type].color.button,
          }}
        >
          {children}
        </A>
      ) : (
        <Span
          {...buttonTextStyle}
          style={{
            ...linkButtonStyle,
            backgroundColor: theme[type].color.button,
          }}
        >
          {children}
        </Span>
      )}
    </Item>
  );
}
