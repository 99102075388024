import React, { useCallback, useEffect, useMemo, useState } from "react";
import { momentLocalizer, View, Views } from "react-big-calendar";
import moment, { MomentInput } from "moment";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@mui/styled-engine";

import "moment/locale/ko";

import {
  GuestSchedule,
  GuestWithBgColor,
  Schedule,
} from "../../app/services/events/types";
import {
  BaseCalendar,
  ExtendedEvent,
  ExtendedEventWithSchedule,
  getTitleFromTimeRange,
} from "./BaseCalendar";
import { getUUID } from "../../utils/getUUID";
import { ChangeScheduleCalendarGuestModal } from "./ChangeScheduleCalendarGuestModal";
import { ScheduleCalendarInputModal } from "./ScheduleCalendarInputModal";
import { TextFieldWithValidation } from "../inputs/TextFieldWithValidation";
import { convertScheduleToEvent } from "../../utils/convertScheduleToEvent";
import { splitScheduleRanges } from "../../utils/splitScheduleRanges";
import { SelectedSchedule } from "./SelectedSchedule";

const localizer = momentLocalizer(moment);
const selectAllValue = "__ALL__";
function generateMyId(me: string) {
  return `${me}-${getUUID()}`;
}
function getEventTempKey(event: ExtendedEvent) {
  return `${event.start}-${event.end}`;
}
/*
 API에서 받아온 Guest, Schedule을 Event 타입으로 변환 한다.
 */
function convertSchedulesToEventWithGuest(
  schedules: Schedule[],
  options: {
    email: string;
    name?: string;
    title?: string;
    bgColor?: string;
    isActive: boolean;
    originSchedule?: Schedule;
  },
  needSplit?: boolean
): ExtendedEvent[] {
  return schedules
    .map((originSchedule) => {
      if (needSplit) {
        return splitScheduleRanges(originSchedule).map((schedule) => {
          return convertScheduleToEvent(
            schedule,
            { ...options, originSchedule },
            localizer
          );
        });
      } else {
        return [
          convertScheduleToEvent(
            originSchedule,
            { ...options, originSchedule },
            localizer
          ),
        ];
      }
    })
    .reduce((result, cur) => {
      return result.concat(cur);
    }, []);
  /*
  return schedules.map((schedule) =>
    convertScheduleToEvent(schedule, { ...options }, localizer)
  );
   */
}

export function timeRangeFormat(fromTime: MomentInput, toTime: MomentInput) {
  const formatStr = "MM.DD(dd) LT";
  return `${moment(fromTime).format(formatStr)} - ${moment(toTime).format(
    formatStr
  )}`;
}

export interface ChangeScheduleCalendarProps {
  me: string; // email
  attend?: boolean;
  others: GuestWithBgColor[];
  schedules: GuestSchedule[];
  submitDisabled?: boolean;
  onAttendOrNotChange?: (attend: boolean) => void;
  onSchedulesChange?: (events: ExtendedEvent[]) => void;
  onSubmit?: (events: ExtendedEvent[]) => void;
  calendarAnchorTop?: number;
}

const StyledChangeScheduleCalendar = styled(Box)`
  background-color: #f5f6fa;
  .schedule-calendar {
    &-header {
      margin-bottom: 33px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      & > h5 {
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
    }
    &-actions {
      margin-bottom: 35px;
      .MuiInput-underline {
        width: auto;
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledFormLabel = styled(FormLabel)`
  min-width: 122px;
  font-size: 16px;
  font-weight: bold;
  color: #717171;
`;

export function ChangeScheduleCalendar({
  me,
  attend,
  others,
  schedules,
  submitDisabled,
  onAttendOrNotChange,
  onSchedulesChange,
  onSubmit,
  calendarAnchorTop = 56,
}: ChangeScheduleCalendarProps) {
  const { t } = useTranslation("changeScheduleCalendar");
  const { t: tInput } = useTranslation("scheduleCalendarInput");
  const [selectedMyEvent, setSelectedMyEvent] = useState<
    ExtendedEventWithSchedule<Schedule> | undefined
  >(undefined);
  const [selectedOthersEvent, setSelectedOthersEvent] =
    useState<ExtendedEventWithSchedule<Schedule> | null>(null);
  const [selectedOther, setSelectedOther] = useState<string>(selectAllValue);
  const [submittedActiveEventMap, setSubmittedActiveEventMap] = useState<
    Map<string, ExtendedEvent>
  >(new Map()); // Remote 통해서 schedule 추가 했을 때 변경한다.
  const [activeEvents, setActiveEvents] = useState<ExtendedEvent[]>([]);
  const [passiveEvents, setPassiveEvents] = useState<
    ExtendedEventWithSchedule<Schedule>[]
  >([]);
  const [isChanged, setIsChanged] = useState(false); // FIXME: 현재는 업데이트 될 때마다 set 함수를 끼워넣어야 하는데, 단순화 할 필요 있음.
  const filteredPassiveEvents = useMemo(() => {
    if (selectedOther === selectAllValue) return passiveEvents;
    return passiveEvents.filter((d) => d.email === selectedOther);
  }, [passiveEvents, selectedOther]);

  const handleEventCreate = useCallback(
    (event, view?: View) => {
      if (!attend) return;
      if (view === Views.MONTH) {
        setSelectedMyEvent({
          id: generateMyId(me),
          start: event.start,
          end: event.end,
          isActive: true,
          isTemp: true,
          email: me,
        });
      } else {
        setActiveEvents((events) => {
          return events.concat([
            {
              ...event,
              id: generateMyId(me),
              isActive: true,
            },
          ]);
        });
        //setIsChanged(true);
      }
    },
    [me, attend]
  );

  const handleEventChange = useCallback(
    (event: ExtendedEvent) => {
      if (!attend) return;
      setActiveEvents((events) => {
        const idx = events.findIndex((e) => e.id === event.id);
        if (idx >= 0) {
          return [...events.slice(0, idx), event, ...events.slice(idx + 1)];
        } else {
          return events;
        }
      });
      //setIsChanged(true);
    },
    [attend]
  );

  const handleEventRemove = useCallback(
    (event: ExtendedEvent) => {
      if (!attend) return;
      setActiveEvents((events) => {
        const idx = events.findIndex((e) => e.id === event.id);
        if (idx >= 0) {
          return [...events.slice(0, idx), ...events.slice(idx + 1)];
        } else {
          return events;
        }
      });
      //setIsChanged(true);
    },
    [attend]
  );

  const handleEventSelect = useCallback(
    (event: ExtendedEvent) => {
      if (!attend) return;
      if (event.isActive) {
        setSelectedMyEvent(event);
      } else {
        setSelectedOthersEvent(event);
      }
    },
    [attend]
  );

  const handleInputScheduleModalClose = useCallback(() => {
    setSelectedMyEvent(undefined);
  }, []);

  const handleInputScheduleModalSave = useCallback(
    (newEvent?: ExtendedEvent) => {
      if (newEvent) {
        setActiveEvents((events) => {
          const existIndex = events.findIndex((d) => d.id === newEvent.id);
          const { title, allDay } = getTitleFromTimeRange(
            newEvent.start || new Date(),
            newEvent.end || new Date(),
            localizer
          );
          const resultEvent = { ...newEvent, title, allDay, isTemp: false };
          if (existIndex < 0) {
            return [...events, resultEvent];
          } else {
            return [
              ...events.slice(0, existIndex),
              resultEvent,
              ...events.slice(existIndex + 1),
            ];
          }
        });
        //setIsChanged(true);
      }
      handleInputScheduleModalClose();
    },
    [handleInputScheduleModalClose]
  );

  const handleInputScheduleModalDelete = useCallback(
    (newEvent?: ExtendedEvent) => {
      if (newEvent) {
        handleEventRemove(newEvent);
      }
      handleInputScheduleModalClose();
    },
    [handleInputScheduleModalClose, handleEventRemove]
  );

  const handleGuestEventModalClick = useCallback(() => {
    if (!attend) return;
    if (
      selectedOthersEvent &&
      selectedOthersEvent.start &&
      selectedOthersEvent.end
    ) {
      const { title } = getTitleFromTimeRange(
        selectedOthersEvent.start,
        selectedOthersEvent.end,
        localizer
      );
      const newEvent: ExtendedEvent = {
        ...selectedOthersEvent,
        id: generateMyId(me),
        title,
        email: me,
        name: undefined,
        isActive: true,
        bgColor: undefined,
      };
      setActiveEvents((events) => {
        return [...events, newEvent];
      });
      setIsChanged(true);
    }
    setSelectedOthersEvent(null);
  }, [selectedOthersEvent, attend, me]);

  const handleGuestEventModalClose = useCallback(() => {
    setSelectedOthersEvent(null);
  }, []);

  const handleAttendOrNotChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onAttendOrNotChange)
        onAttendOrNotChange(
          (event.target as HTMLInputElement).value === "attend"
        );
    },
    [onAttendOrNotChange]
  );
  const handleActiveEventRemoveFactory = useCallback((index: number) => {
    return () => {
      setActiveEvents((events) => {
        return [...events.slice(0, index), ...events.slice(index + 1)];
      });
      //setIsChanged(true);
    };
  }, []);

  const handleSelectOtherChange = useCallback((value) => {
    if (value !== undefined) {
      setSelectedOther(value);
    }
  }, []);

  const handleInputModalOpen = useCallback(() => {
    setSelectedMyEvent({
      id: generateMyId(me),
      start: new Date(),
      end: new Date(), // FIXME: 기본 단위 offset 두도록 수정
      isActive: true,
      isTemp: true,
    });
  }, [me]);
  const handleSubmit = useCallback(() => {
    if (onSubmit && attend) onSubmit(activeEvents);
  }, [activeEvents, attend, onSubmit]);

  useEffect(() => {
    if (me && schedules) {
      const mySchedule = schedules.find((s) => s.is_me);
      if (mySchedule !== undefined) {
        const activeEvents = convertSchedulesToEventWithGuest(
          mySchedule.schedules,
          { email: me, isActive: true }
        );
        setActiveEvents(activeEvents);
        setSubmittedActiveEventMap(
          activeEvents.reduce((result, event) => {
            result.set(getEventTempKey(event), event);
            return result;
          }, new Map<string, ExtendedEvent>())
        );
      }
    }
  }, [me, schedules]);

  useEffect(() => {
    const passiveEvents = others
      .map(({ email, name, bg_color }) => {
        const schedule = schedules.find((s) => s.email === email);
        if (schedule !== undefined) {
          return convertSchedulesToEventWithGuest(
            schedule.schedules,
            {
              email,
              name,
              bgColor: bg_color,
              title: name,
              isActive: false,
            },
            true
          );
        } else {
          return [];
        }
      })
      .reduce((result, cur) => result.concat(cur), []);
    setPassiveEvents(passiveEvents);
  }, [others, schedules]);

  useEffect(() => {
    if (onSchedulesChange) onSchedulesChange(activeEvents);
    // FIXME: activeEvents 말고 displayedActiveEvents 별도로 관리하고 이를 전달하자.

    // ignore onSchedulesChange
    // eslint-disable-next-line
  }, [activeEvents]);

  useEffect(() => {
    // submittedActiveEvents 와 activeEvents가 다를 경우만 isChanged = true 설정
    if (activeEvents.length !== submittedActiveEventMap.size) {
      //일단 갯수가 다르면 무조건 바뀐 것으로 처리
      return setIsChanged(true);
    } else {
      // activeEvent 에서 없는게 있는지 체크하고 있으면 바로 종료
      for (let i = activeEvents.length - 1; i >= 0; i--) {
        if (!submittedActiveEventMap.has(getEventTempKey(activeEvents[i]))) {
          return setIsChanged(true);
        }
      }
      return setIsChanged(false);
    }
  }, [activeEvents, submittedActiveEventMap]);

  return (
    <StyledChangeScheduleCalendar>
      <Box className="schedule-calendar-header">
        <Typography variant={"h5"}>{t("Title")}</Typography>
        <Button
          onClick={handleInputModalOpen}
          variant="inner"
          color="normal"
          disabled={!attend}
        >
          {tInput("InputSchedulesDirectly")}
        </Button>
      </Box>
      <Box className="schedule-calendar-actions">
        <StyledFormControl>
          <StyledFormLabel>{t("AttendOrNot")}</StyledFormLabel>
          <RadioGroup
            aria-label="attendOrNot"
            value={attend ? "attend" : "nonAttend"}
            onChange={handleAttendOrNotChange}
            name="radio-buttons-group"
            row={true}
          >
            <FormControlLabel
              value={"attend"}
              control={<Radio />}
              label={t("Attend")}
            />
            <FormControlLabel
              value={"nonAttend"}
              control={<Radio />}
              label={t("NonAttend")}
            />
          </RadioGroup>
        </StyledFormControl>
        <StyledFormControl>
          <StyledFormLabel>{t("SelectOther")}</StyledFormLabel>
          <TextFieldWithValidation
            select={true}
            value={selectedOther}
            onValueChange={handleSelectOtherChange}
            variant={"standard"}
            fullWidth={false}
          >
            <MenuItem value={selectAllValue}>{t("SelectAllOthers")}</MenuItem>
            {others.map(({ email, name }, index) => {
              return (
                <MenuItem
                  key={`${email}-${index}`}
                  value={email}
                >{`${email} / ${name}`}</MenuItem>
              );
            })}
          </TextFieldWithValidation>
        </StyledFormControl>
      </Box>
      <BaseCalendar
        anchorTop={calendarAnchorTop}
        activeEvents={activeEvents}
        passiveEvents={filteredPassiveEvents}
        localizer={localizer}
        onEventCreate={handleEventCreate}
        onEventChange={handleEventChange}
        onEventDoubleClick={handleEventRemove}
        onEventSelect={handleEventSelect}
        disabled={!attend}
      />
      <ScheduleCalendarInputModal
        open={selectedMyEvent !== undefined}
        event={selectedMyEvent}
        onClose={handleInputScheduleModalClose}
        onSave={handleInputScheduleModalSave}
        onDelete={handleInputScheduleModalDelete}
      />
      <ChangeScheduleCalendarGuestModal
        selectedEvent={selectedOthersEvent}
        onClose={handleGuestEventModalClose}
        onButtonClick={handleGuestEventModalClick}
      />

      <SelectedSchedule
        activeEvents={activeEvents}
        handleActiveEventRemoveFactory={handleActiveEventRemoveFactory}
      />

      <Button
        fullWidth
        onClick={handleSubmit}
        type={"submit"}
        variant="cta"
        color="primary"
        disabled={!attend || submitDisabled || !isChanged}
        sx={{ marginTop: "30px" }}
      >
        {`${t("SelectedSchedules")}(${activeEvents?.length || 0}) ${t(
          "SaveSchedules"
        )}`}
      </Button>
    </StyledChangeScheduleCalendar>
  );
}
