import React, { useCallback, useState } from "react";
import { Button } from "@mui/material";
import { DeleteEventModal } from "../../components/modal/DeleteEventModal";
import { useDeleteCancelEventMutation } from "../../app/services/events/eventApi";
import { EventWithGuests } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
export interface DeleteEventButtonWithModalsProps {
  eventInfo?: EventWithGuests;
}
export function DeleteEventButtonWithModals({
  eventInfo,
}: DeleteEventButtonWithModalsProps) {
  const { t } = useTranslation("editEvent");
  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);
  const [deleteInviteGuest] = useDeleteCancelEventMutation();

  const handleDeleteEventModalOpen = useCallback(() => {
    setIsDeleteEventModalOpen(true);
  }, []);
  const handleDeleteEventModalClose = useCallback(() => {
    setIsDeleteEventModalOpen(false);
  }, []);
  const handleDeleteEventModalSave = useCallback(() => {
    if (eventInfo) deleteInviteGuest(eventInfo.id);
    setIsDeleteEventModalOpen(false);
  }, [eventInfo, deleteInviteGuest]);
  return (
    <>
      <Button
        size="small"
        variant="inner"
        color="normal"
        onClick={handleDeleteEventModalOpen}
      >
        {t("options.DeleteEvent")}
      </Button>
      <DeleteEventModal
        open={isDeleteEventModalOpen}
        onSave={handleDeleteEventModalSave}
        onClose={handleDeleteEventModalClose}
      />
    </>
  );
}
