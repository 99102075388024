export const convertMinutesToText = (
  totalMinutes: number,
  hourUnit: string,
  minuteUnit: string
): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const hourText = `${hours > 0 ? `${hours}${hourUnit}` : ""}`;
  const minuteText = `${minutes > 0 ? `${minutes}${minuteUnit}` : ""}`;
  return [hourText, minuteText].filter(Boolean).join(" ");
};
