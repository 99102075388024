import React, { useCallback, useEffect, useState } from "react";
import { ContainerWithTitle } from "../../components/commons/ContainerWithTitle";
import { AvatarCard } from "../../components/cards/AvatarCard";
import { useTranslation } from "react-i18next";
import { SnackbarWithCloseButton } from "../../components/commons/SnackbarWithCloseButton";
import { LoadingButton } from "@mui/lab";

export interface RequestVerificationContainerProps {
  email: string;
  name?: string;
  bgColor?: string;
  isSuccess?: boolean;
  isLoading?: boolean;
  onSend: () => void;
}

export function RequestVerificationContainer({
  email,
  name,
  bgColor,
  isSuccess,
  isLoading,
  onSend,
}: RequestVerificationContainerProps) {
  const { t } = useTranslation("requestVerification");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);
  useEffect(() => {
    if (isSuccess) setIsSnackbarOpen(true);
  }, [isSuccess]);

  return (
    <>
      <ContainerWithTitle
        title={t("Title")}
        description={t("Description")}
        isRightButtonWithTitle={true}
        hasQuotation={true}
        rightButton={
          <LoadingButton
            size="small"
            variant="inner"
            color="normal"
            onClick={onSend}
            loading={isLoading}
            disabled={isSuccess}
          >
            {t("Resend")}
          </LoadingButton>
        }
      >
        <AvatarCard email={email} name={name} bgColor={bgColor} />
      </ContainerWithTitle>
      <SnackbarWithCloseButton
        open={isSnackbarOpen}
        message={t("Success")}
        onClose={handleSnackbarClose}
        onCloseButtonClick={handleSnackbarClose}
      ></SnackbarWithCloseButton>
    </>
  );
}
