import { errorMessages } from "./errorMessages";

export let ko = {
  translations: {},
  sampleTranslations: {
    Sample: "안녕",
  },
  global: {
    Workspace: "워크스페이스",
    Calendar: "캘린더",
    Login: "로그인",
    PersonalInfo: "내정보",
    Logout: "로그아웃",
  },
  errorMessages: errorMessages.ko,
  login: {
    Welcome: "환영합니다.👏\n오늘도 함께 <strong>인비</strong>하세요!",
    Description: "안전한 이벤트 생성 및 관리를 위해 로그인이 필요합니다.",
    labels: {
      email: "이메일",
      password: "비밀번호",
    },
    invalidFormats: {
      email: "이메일 형식을 확인해주세요.",
      password: "비밀번호를 다시 확인해주세요.",
    },
    needInput: {
      email: "이메일을 입력해주세요.",
      password: "비밀번호를 입력해주세요",
    },
    Login: "로그인",
    GoogleLogin: "google 로그인",
    SignUp: "Sign Up",
    NoEmail: "이메일을 입력해주세요.",
    NoPassword: "비밀번호를 입력해주세요.",
    Invalid: "이메일 또는 비밀번호를 다시 확인해주세요.",
    Or: "또는",
    FindPasswordLink: "비밀번호 찾기",
    SignUpLink: "아직 회원이 아니신가요?",
    snackbar: {
      FromResetPassword: "비밀번호 변경이 완료되었습니다. 다시 로그인해주세요.",
    },
  },
  signUp: {
    Welcome: "반갑습니다.\n빠르게 가입을 도와드릴게요!",
    labels: {
      email: "이메일",
      password: "비밀번호",
      confirmPassword: "비밀번호 재확인",
      name: "이름",
    },
    invalidFormats: {
      email: "이메일 형식을 확인해주세요.",
      name: "한글과 영문 대 소문자를 입력해주세요. (특수기호, 공백 입력 불가)",
      password: "8~16자 영문, 숫자, 특수문자를 입력해주세요.",
      confirmPassword: "비밀번호를 다시 확인해주세요.",
    },
    google: {
      Description:
        "혹시 구글 계정이 있으신가요? 구글 계정을 통한 가입도 가능합니다.",
      SignUp: "구글 계정으로 회원가입",
    },
    SignUp: "가입하기",
    NeedInput: "필수입력입니다.",
    Invalid: "이메일 또는 비밀번호를 다시 확인해주세요.",
    DuplicatedEmail: "이미 가입된 Email입니다.",
    TermsWelcome: "invy 서비스 이용을 위해\n약관에 동의해주세요.",
    termsSelectAll: {
      Title: "모두 동의합니다.",
      Description:
        '"모두 동의합니다."는 필수 및 선택항목에 대한 동의가 포함되어 있습니다.\n' +
        "선택항목에 대해 동의를 원하지 않으시면 개별적으로 동의를 선택하실 수 있습니다.",
    },
    TermsRequired: "필수",
    TermsOptional: "선택",
    TermsAgree: "동의하기",
  },
  verify: {
    Welcome: "님, 환영합니다.🥳\n쉽고 빠르게 인비해보세요!",
    Description:
      "이메일 인증 및 가입이 완료되었습니다.\n혹시 미팅예정이신가요? 함께하시는 분들께 인비해보세요! ",
    Start: "시작하기",
  },
  signupTerms: {
    service: {
      Title: "서비스 이용약관",
      Description:
        "서비스 이용약관 서비스 이용약관 서비스 이용약관 서비스 이용약관 서비스 이용약관",
    },
    privacy: {
      Title: "개인정보 수집 및 이용 동의",
      Description:
        "개인정보 수집 및 이용 동의 개인정보 수집 및 이용 동의 개인정보 수집 및 이용 동의 개인정보 수집 및 이용 동의",
    },
    marketing: {
      Title: "광고 메세지 및 마케팅 수신 동의",
      Description:
        "광고 메세지 및 마케팅 수신 동의 광고 메세지 및 마케팅 수신 동의 광고 메세지 및 마케팅 수신 동의 광고 메세지 및 마케팅 수신 동의",
    },
    ErrorMessage:
      "서비스 이용약관과 개인정보 수집 및 이용에 대한 안내 모두 동의해주세요.",
  },
  leave: {
    Title: "회원 탈퇴 안내",
    Description: "회원탈퇴를 진행하시기 전에 안내 사항을 꼭 확인해주세요.",
    TermsAgree: "동의 후 탈퇴하기",
    TitleAfterLeave: "{{ name }}님,\n회원 탈퇴가 완료 되었습니다.",
    DescriptionAfterLeave:
      "이용해주시고 사랑해주셔서 감사합니다. 더욱더 노력하고 발전하는 서비스가 되겠습니다.",
  },
  leaveTerms: {
    restore: {
      Title: "사용하고 계신 아이디는 탈퇴할 경우 복구가 불가능합니다.",
      Description: "",
    },
    delete: {
      Title: "탈퇴 후 회원정보 및 개인형 서비스 이용기록은 모두 삭제됩니다.",
      Description:
        "이벤트 관련 개인형 서비스 이용 기록은 모두 삭제되며, 삭제된 데이터는 복구되지 않습니다.",
    },
    remain: {
      Title:
        "탈퇴 후에도 서비스에 등록한 메모 및 참여자 정보는 그대로 남아 있습니다.",
      Description:
        "이벤트 참여 및 일정 선택 내용, 이벤트에 작성한 메모 내용은 탈퇴 시 자동 삭제되지 않고 그대로 남아있습니다. <strong>삭제를 원하시는 내용이 있다면 반드시 탈퇴 전 삭제하시기 바랍니다.</strong>\n탈퇴 후에는 회원정보가 삭제되어 본인 여부를 확인할 수 있는 방법이 없어 운영 측면에서 임의로 삭제가 불가능 합니다.",
    },
    sync: {
      Title: "탈퇴 후 연동서비스는 자동으로 연동 해제됩니다.",
      Description: "",
    },
  },
  findPassword: {
    Title: "비밀번호 찾기",
    Description: "입력한 이메일로 인증이 필요합니다.",
    Email: "이메일",
    Submit: "이메일 인증하기",
    InvalidEmail: "이메일을 입력해주세요.",
    DuplicatedEmail: "이메일을 다시 확인해주세요.",
    Success: "비밀번호 변경을 위한 인증메일이 발송되었습니다.",
  },
  resetPassword: {
    Title: "비밀번호 변경",
    Description:
      "혹시 구글 계정이 있으신가요? 구글 계정을 통한 가입도 가능합니다.",
    labels: {
      password: "비밀번호",
      confirmPassword: "비밀번호 재확인",
    },
    invalidFormats: {
      password: "8~16자 영문, 숫자, 특수문자를 입력해주세요.",
      confirmPassword: "비밀번호를 다시 확인해주세요.",
    },
    needInput: {
      password: "비밀번호를 입력해주세요",
      confirmPassword: "비밀번호를 재 입력해주세요.",
    },
    Invalid: "비밀번호를 다시 확인해주세요.",
    Reset: "변경하기",
  },
  createEvent: {
    complete: {
      Title: "이벤트가 생성되었습니다.",
      eventInfo: {
        Label: "이벤트 정보",
      },
      guestInfo: {
        Label: "참여자 정보 ({{count}})",
        Description: "등록하신 게스트에게 이벤트 초대 메일이 발송되었습니다.",
      },
    },
    Title: "이벤트 정보를 입력해주세요.",
    Description: "* 표기 항목은 필수 입력입니다.",
    Create: "3자 제공 동의하고 이벤트 생성",
    BeforeUnload: "이벤트 생성을 중지 하시겠습니까?",
    Hour: "시간",
    Minute: "분",
    AllDay: "종일",
    SelectSchedules: "가능일정 선택하기",
    labels: {
      title: "이벤트 이름",
      description: "이벤트 설명",
      event_type: "이벤트 유형",
      place_detail: "이벤트 장소",
      duration_minutes: "이벤트 소요 시간",
    },
    eventTypeLabels: {
      o: "장소 직접 입력",
      v: "화상회의",
      p: "전화",
    },
    placeDetailLabels: {
      o: "장소",
      v: "URL",
      p: "전화번호",
    },
    placeDetailHelperText: {
      Prefix: "이벤트 생성 후 ",
      Suffix: " 수정 가능합니다.",
    },
    needInput: {
      title: "이벤트 이름을 입력해주세요.",
      duration_minutes: "이벤트 소요 시간을 입력해주세요",
    },
    invalidFormats: {
      isNotAllowedSpecialCharacter:
        "해당 특수 문자(<>'\"/)는 허용되지 않습니다.",
    },
    Preview: "초대장 미리보기",
    PreviewText: "미리보기",
  },
  inviteGuestsToEvent: {
    Title: "참여자를 초대해주세요.",
    Description: "참여자에게 이메일로 초대장이 발송됩니다.",
    Add: "추가",
    invalidFormats: {
      Input: "이메일 형식을 확인해주세요.",
    },
    labels: {
      Input: "참여자 이메일 입력",
      Name: "이름입력",
      NameInput: "게스트 이름 입력",
    },
  },
  workspace: {
    create: {
      Title: "빠르고 손쉽게\n이벤트를 만들어보세요.",
    },
    eventList: {
      Title: `예정된 이벤트가 <strong>{{total}}</strong>건 있습니다.`,
      Guide: "호스트로 참여하는 이벤트인 경우 {{icon}} 아이콘이 표시됩니다.",
      filter: {
        all: "전체",
        wait: "미정",
        confirmed: "확정",
        deleted: "삭제/종료",
        Unit: "건",
      },
      item: {
        EmptyText: "일정 확정이 필요합니다.",
      },
      emptyState: {
        noEvents: {
          Text: "앗 ! 아직 생성된 이벤트가 없어요.\n이벤트를 만들어 볼까요?",
          Emoji: "😮",
        },
        noFilteredEvents: {
          a: {
            Text: "앗 ! 아직 생성된 이벤트가 없어요.\n이벤트를 만들어 볼까요?",
            Emoji: "😮",
          },
          w: {
            Text: "미정인 이벤트가 없어요.\n이벤트를 만들어 볼까요?",
            Emoji: "🙂",
          },
          c: {
            Text: "확정된 이벤트가 없어요.\n혹시 확정이 필요한 이벤트는 없나요?",
            Emoji: "🤔",
          },
          d: {
            Text: "삭제/종료된 이벤트가 없어요.\n예정된 이벤트들 잘 마무리하시길 바랄게요!",
            Emoji: "😉",
          },
        },
      },
    },
    CreateEvent: "새로운 이벤트 만들기",
    status: {
      w: "일정 미정",
      c: "일정 확정",
      d: "일정 삭제",
      f: "일정 미정",
    },
  },
  changeScheduleCalendar: {
    Title: "일정 선택",
    TitleDrawer: "일정 선택하기",
    CandidateInfo: "참가자 정보",
    ChooseThisSchedule: "이 일정 선택하기",
    SelectedSchedules: "선택 일정",
    AttendOrNot: "참여여부",
    Attend: "참여",
    NonAttend: "미참여",
    SelectOther: "참여자 일정보기",
    SelectAllOthers: "전체",
    SaveSchedules: "저장하기",
    SelectedSchedulesOverlay:
      "총 {{activeEventsLength}}건의 일정을 선택하셨습니다.",
    ShowSelectedSchedule: "선택일정 전체보기 >",
    empty: {
      Emoji: "🥕",
      Description: "가능한 일정을 선택해주세요.",
    },
  },
  scheduleCalendarInput: {
    InputSchedulesDirectly: "일정 직접입력",
    UpdateSchedulesDirectly: "일정 직접수정",
    StartDate: "시작 일시",
    EndDate: "종료 일시",
    Cancel: "취소",
    Save: "저장",
    Delete: "삭제",
  },
  urlView: {
    Title: "초대 URL",
    warning: {
      Title: "! 꼭 확인해주세요 !",
      Description:
        "- 초대 URL로 접근 시 참여자들이 본인인증 후 가능 일정을 선택할 수 있습니다.\n" +
        "- 초대받지 못한 참여자도 본인 정보를 등록하여 참여할 수 있으나, 호스트 인증 전까지는 이벤트 정보 확인은 가능하나 다른 참여자들의 정보를 확인할 수 없습니다.",
    },
    CopiedMessage: "초대 URL이 복사되었습니다.",
    Copy: "복사",
    Share: "공유",
    ShareText: "[{{title}}] 이벤트에 초대되셨습니다. 인비를 확인해주세요.",
  },
  guestItem: {
    Host: "호스트",
    IsMe: "(나)",
  },
  verifyGuest: {
    Title: " 이벤트에 초대받으셨나요?\n참여자 목록에서 본인을 선택해주세요.",
    input: {
      Title: "본인 확인을 위해 정보를 완성해주세요.",
      Description:
        "이신가요?\n본인 확인을 위해 이메일 주소를 전체 입력해주세요.",
      Confirm: "확인",
      Cancel: "취소",
      Invalid: "입력하신 이메일 정보를 다시 확인해주세요.",
      labels: {
        email: "게스트 이메일 주소",
      },
      invalidFormats: {
        email: "이메일 정보를 입력해주세요.",
      },
      needInput: {
        email: "이메일 정보를 입력해주세요.",
      },
    },
  },
  confirmList: {
    Recommended: "추천",
    MoreButton: "더보기",
  },
  guestEvent: {
    Title: "이벤트를 위해 가능한 일정을 선택해주세요.",
    guestInfo: {
      Label: "참여자 정보 ({{count}})",
    },
    confirmModal: {
      Title: "일정 보내기",
      Description: "{{count}}개의 일정을 호스트에게 보내시겠습니까?",
      Cancel: "취소",
      Confirm: "확인",
    },
  },
  eventDetail: {
    Title: "이벤트를 위해 가능한 일정을 확정해주세요.",
  },
  eventInfo: {
    Label: "이벤트 정보",
  },
  guestInfo: {
    Label: "참여자 정보 ({{count}})",
    Description: "등록하신 게스트에게 이벤트 초대 메일이 발송되었습니다.",
    status: {
      "0": "미입력",
      "1": "입력완료",
      "2": "참여불가",
    },
    statusAfterConfirm: {
      "0": "미입력",
      "1": "참여",
      "2": "참여불가",
    },
    moreButton: {
      DeleteGuest: "게스트 삭제",
      SendMail: "이메일 재발송",
    },
  },
  confirmSchedule: {
    Label: "일정 확정",
    AfterConfirmHelpText:
      "일정 확정 시 게스트 이메일로 최종 이벤트 일정이 공유됩니다.",
    AllAvailable: "모두 참여",
    CheckedSchedule: "최종선택일정",
    RequiredParticipants: "필수참여자",
    Empty: "없음",
    ParticipantsDetail: "참여가능 인원수",
    ParticipantsWithCount:
      "참여 <strong>{{matched}}</strong>명 (일정 일치: <strong>{{matched}}</strong>명, 미일치: <strong>{{unmatched}}</strong>명, 불참: <strong>{{unsettled}}</strong>명)",
    RequireSchedule: "최종 일정을 선택해주세요",
    RequireGuest: "- 명",
    confirmModal: {
      Title: "이벤트를 확정하시겠습니까?",
      Description:
        "이벤트를 확정 시 최종 확정 내용이 참여자들에게 메일로 발송되며, 더 이상 이벤트 정보 변경이 불가능 합니다. ",
      Time: "이벤트 일시",
      GuestStatus: "게스트 참여 여부",
      Matched: "일정 일치",
      Unmatched: "일정 미일치",
      Unsettled: "참여 불가",
      Yes: "네",
      No: "아니오",
    },
    listEmpty: {
      Emoji: "😮",
      Description:
        "앗 ! 아직 게스트분들이 가능한 일정을 보내지 않았어요.\n게스트에게 다시 한번 안내 메일을 보내시겠어요?",
      Resend: "게스트에게 초대장 다시 보내기",
      ResentMessage: "모든 게스트에게 초대장을 다시 보냈습니다.",
    },
  },
  confirmScheduleCalendar: {
    ChooseThisSchedule: "이 일정 선택하기",
    HasAllParticipants: "모두가능",
    ParticipantsWithCount: "참여자 ({{count}})",
    UnsettledWithCount: "미참여 ({{count}})",
  },
  commonEvent: {
    Title: " 이벤트가\n{{dateTime}}에 진행 예정입니다.",
    TitleWhenFinishedOrDeleted: "이벤트가 종료(삭제)되었습니다.",
  },
  emptyPage: {
    Title: "앗! 더이상 진행할 수 없어요.",
    Description:
      "유효 기간이 만료되었거나,\n" +
      "더 이상 이용할 수 없는 경로입니다.\n" +
      "\n" +
      "자세한 내용은 고객센터 (help@invy.club)로 문의해주세요.\n" +
      "빠르게 도와드릴게요!",
  },
  editEvent: {
    Title: "이벤트 편집",
    Cancel: "취소",
    Save: "저장",
    Yes: "네",
    No: "아니오",
    options: {
      EditEvent: "이벤트 정보 수정",
      InviteGuests: "게스트 추가",
      DeleteEvent: "이벤트 삭제",
    },
  },
  eventInputList: {
    labels: {
      title: "이벤트 이름",
      description: "이벤트 설명",
      event_type: "이벤트 유형",
      place_detail: "이벤트 장소",
      duration_minutes: "이벤트 소요 시간",
    },
    eventTypeLabels: {
      o: "장소 직접 입력",
      v: "화상회의",
      p: "전화",
      video: "화상회의",
      offline: "장소 직접 입력",
      phone: "전화",
    },
    placeDetailLabels: {
      o: "장소",
      v: "URL",
      p: "전화번호",
      video: "URL",
      offline: "장소",
      phone: "전화번호",
    },
    placeDetailHelperText: {
      Prefix: "이벤트 생성 후 ",
      Suffix: " 수정 가능합니다.",
    },
    needInput: {
      title: "이벤트 이름을 입력해주세요.",
      duration_minutes: "이벤트 소요 시간을 입력해주세요",
    },
    invalidFormats: {
      isNotAllowedSpecialCharacter:
        "해당 특수 문자({{notAllowedSpecialCharacters}})는 허용되지 않습니다.",
    },
    Hour: "시간",
    Minute: "분",
    AllDay: "종일",
  },
  deleteEvent: {
    Title: "이벤트를 삭제하시겠습니까?",
    Description:
      "이벤트를 삭제하는 경우, 게스트들에게 이벤트 취소 메일이 발송됩니다.",
  },
  managePersonalInfo: {
    Title: "내정보 관리",
    ChangePassword: "비밀번호 변경",
    Leave: "탈퇴하기",
  },
  emoji: {
    Unavailable: "😶",
  },
  invitationToEventMail: {
    Title: "$t(Host) $t(EventDecorated) $t(InviteToEvent)",
    EventDecorated: "[//event//]",
    Host: "//hostName// 님이", //이메일의 경우  //로 interpolate 한다.
    InviteToEvent: "이벤트에 인비하셨습니다.",
    Description:
      "안녕하세요? //hostName//님이 //guestMail//님께 인비하셨습니다.\n아래 내용을 확인 후 가능 일정을 알려주세요.",
    Button: "일정 입력하러 가기",
    Duration: "소요시간: //duration//",
    Place: "장소: //eventType// //placeDetail//",
  },
  verificationMail: {
    Title: "환영합니다! 가입을 위해 이메일을 인증해주세요.",
    Description:
      "회원가입을 진행 중이신가요? \n하단의 이메일 인증 버튼을 눌러주세요.",
    Button: "이메일 인증",
  },
  findPasswordMail: {
    Title: "비밀번호 찾기/변경을 위해 이메일을 인증해주세요.",
    Description:
      "비밀번호 찾기 / 변경을 진행하고 계신가요?\n하단의 이메일 인증 버튼을 눌러주세요.",
    Button: "이메일 인증",
  },
  noticeGuestScheduleMail: {
    Title: "$t(Guest) $t(RespondToEvent)",
    EventDecorated: "[//event//]",
    Guest: "//guestName// 님이",
    RespondToEvent: "이벤트에 응답하셨습니다.",
    Description:
      "안녕하세요? //guestName//님이 가능한 일정을 입력하셨습니다. 게스트 분들의 가능 일정을 확인 후 최종 일정을 확정해주세요.",
    Button: "일정 확인하러 가기",
    Schedule: "//guestName//님의 가능 일정",
    ExtraSchedulesCount: "외 //count//건",
    Response: "응답현황",
    Respondents: "응답 총 //count//명: //respondents//",
    NoRespondents: "미응답 총 //count//명: //noRespondents//",
  },
  remindConfirmScheduleMail: {
    Title: "$t(EventDecorated) $t(RespondToEvent)",
    EventDecorated: "[//event//]",
    Button: "일정 확인하러 가기",
    RespondToEvent: "이벤트 일정확정을 해주세요.",
    Description:
      "모든 게스트 분들이 이벤트 인비에 응답하셨습니다. 게스트 분들의 가능 일정을 확인 후 최종 일정을 확정해주세요.",
    Response: "응답현황",
    Respondents: "응답 총 //count//명: //respondents//",
    NoRespondents: "미응답 총 //count//명: //noRespondents//",
  },
  noticeConfirmedScheduleMail: {
    Title: "$t(EventDecorated) $t(ConfirmSchedule)",
    EventDecorated: "[//event//]",
    ConfirmSchedule: "일정이 최종 확정되었습니다.",
    Description:
      "안녕하세요? //hostName//님이 $t(EventDecorated) 일정을 최종 확정하였습니다. 아래 상세 내용을 확인해주세요.",
    Button: "이벤트 확인하러 가기",
    Time: "일시: //time//",
    Duration: "소요시간: //duration//",
    Place: "장소: //eventType// //placeDetail//",
  },
  noticeCanceledEventMail: {
    Title: "$t(EventDecorated) $t(CancelEvent)",
    EventDecorated: "[//event//]",
    CancelEvent: "이벤트가 취소되었습니다.",
    Description:
      "안녕하세요? //hostName//님이 $t(EventDecorated) 이벤트의 일정을 취소하였습니다. 아래 상세 내용을 확인해주세요.",
    Time: "일시: //time//",
    Duration: "소요시간: //duration//",
    Place: "장소: //eventType// //placeDetail//",
  },
  mailTemplate: {
    Footer:
      "해당 메일은 //expirationTime// 까지 유효합니다. 만일 본인이 시도하지 않았거나 도움이 필요한 경우, help@invy.club 으로 문의바랍니다.",
    Signature: "인비 드림",
  },
  requestVerification: {
    Title: "가입 완료를 위해\n이메일 인증을 해주세요",
    Description:
      "아래 이메일 주소로 인증메일이 발송되었습니다.\n이메일을 통해 인증버튼을 누를 경우, 가입이 완료됩니다.",
    Resend: "인증메일 재전송",
    Success: "인증메일이 발송되었습니다.",
  },
  pollComplete: {
    guestList: {
      EmptyEmoji: "😮",
      EmptyDescription:
        "앗 ! 아직 초대받은 게스트가 없어요.\n게스트를 등록해 볼까요?",
    },
  },
};
