import { createSlice } from "@reduxjs/toolkit";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";
import { RootState } from "../../../app/store";

const name = "selectEventSlice";
export interface SelectEventState {
  selectedGuestKey?: string;
  newGuest?: PollEventGuestCandidate;
}
const initialState: SelectEventState = {
  selectedGuestKey: undefined,
  newGuest: undefined,
};
export const selectEventSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectedGuestKey: (state, action) => {
      state.selectedGuestKey = action.payload;
    },
    setNewGuest: (state, action) => {
      state.newGuest = action.payload;
    },
  },
});

export const selectEventSelectors = {
  selectState: (state: RootState) => state[name] as SelectEventState,
};
