import React, { useCallback } from "react";
import styled from "@mui/styled-engine";
import { Box, Button } from "@mui/material";
import { StaticDateRangePicker } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { ContentTitle, PageTitle } from "../../../styled/typography";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createPollSelectors,
  createPollSlice,
} from "../create-poll/createPollSlice";
import { SelectedEvent, SelectedEventList } from "../common/SelectedEventList";
import { SettingBorder } from "../../../styled/layout/setting";
import { useHandleDateRangeChange } from "../common/hooks/useHandleDateRangeChange";

const CreateEventSettingBox = styled(Box)``;

export const CreateEventSetting = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { dateRange } = useAppSelector(createPollSelectors.selectState);
  const selectedEvents = useAppSelector((state) =>
    createPollSelectors.selectedEventsSelectors.selectAll(state)
  );

  const handleSelectedEventDelete = useCallback(
    (event: SelectedEvent) => {
      dispatch(createPollSlice.actions.removeSelectedEvents([event.id]));
    },
    [dispatch]
  );

  const handleDateRangeChange = useHandleDateRangeChange();

  return (
    <CreateEventSettingBox>
      <PageTitle>이벤트 상세정보 입력</PageTitle>
      {/*@ts-ignore*/}
      <StaticDateRangePicker
        calendars={1}
        displayStaticWrapperAs="desktop"
        value={dateRange}
        onChange={handleDateRangeChange}
        disabled={true}
      />
      <SettingBorder />
      <ContentTitle sx={{ marginBottom: "42px" }}>
        <span>
          선택일정 <strong>{selectedEvents.length}</strong>건
        </span>
        <Button
          variant="contained"
          color="inherit"
          type="submit"
          onClick={() => {
            history.push("/poll/create");
          }}
        >
          {"일정 수정하기"}
        </Button>
      </ContentTitle>

      <SelectedEventList
        events={selectedEvents}
        onDelete={handleSelectedEventDelete}
      />
    </CreateEventSettingBox>
  );
};
