import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";

export type SupportedLocales = keyof typeof locales;
export const i18nToMuiLocaleMap: { [key: string]: SupportedLocales } = {
  en: "enUS",
  ko: "koKR",
};
export function useMuiLocale(initLocale?: SupportedLocales) {
  const [locale, setLocale] = React.useState<SupportedLocales>(
    initLocale || "koKR"
  );
  const { i18n } = useTranslation();
  useEffect(() => {
    let locale = i18nToMuiLocaleMap[i18n.language];
    if (!locale) locale = "enUS";
    setLocale(locale);
  }, [i18n.language]);
  return locale;
}
