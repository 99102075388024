import { createApi } from "@reduxjs/toolkit/query/react";
import { TokenRequest, UserResponse } from "./types";
import { baseUrl } from "./config";
import { fetchApiBaseQuery } from "../fetchApiBaseQuery";

export const verifyApi = createApi({
  baseQuery: fetchApiBaseQuery({ baseUrl: `${baseUrl}/verify/` }),
  reducerPath: "accounts/verifyApi",
  endpoints: (build) => ({
    postSendEmail: build.mutation<void, TokenRequest>({
      query: (body) => ({
        url: "send/",
        method: "POST",
        body,
      }),
    }),
    postResendEmail: build.mutation<void, { email: string }>({
      query: (body) => ({
        url: "send/",
        method: "POST",
        body,
      }),
    }),
    postVerifyEmail: build.mutation<UserResponse, TokenRequest>({
      query: (body) => ({
        url: "email/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePostSendEmailMutation,
  usePostResendEmailMutation,
  usePostVerifyEmailMutation,
} = verifyApi;
