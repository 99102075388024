import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { DateRange } from "@mui/lab";
import moment from "moment";

import { RootState } from "../../../app/store";
import { SelectedEvent } from "../common/SelectedEventList";
import { PollHourOption } from "../../../app/services/poll/types";
import { EventInput } from "@fullcalendar/react";
import {
  availableSlotClassName,
  backgroundSlotClassName,
  invySlotClassName,
  occupiedSlotClassName,
  selectedLabelSlotClassName,
  selectedSlotClassName,
} from "./create-poll-calendar/createPollCalendarClassNames";
import { CreatePollCalendarEventType } from "./create-poll-calendar/types/CreatePollCalendarEventType";
import { CreatePollCalendarExtendedProps } from "./create-poll-calendar/types/CreatePollCalendarExtendedProps";
import { getUUID } from "../../../utils/getUUID";

const name = "createPollSlice";

export const selectedEventsAdapter = createEntityAdapter<SelectedEvent>({
  selectId: (event) => event.id,
});

export interface PollCalendarEventInputs {
  background: EventInput[];
  selected: EventInput[];
  selectedLabel: EventInput[];
  availableAndOccupied: EventInput[];
}

const initPollCalendarEvents: PollCalendarEventInputs = {
  background: [],
  selected: [],
  selectedLabel: [],
  availableAndOccupied: [],
};

export interface CreatePollState {
  duration: number;
  startAt: string;
  endAt: string;
  dateRange: DateRange<moment.Moment>;
  hourOption: PollHourOption;
  slotUnitMinutes: number;
  selectedEvents: ReturnType<typeof selectedEventsAdapter.getInitialState>;
  calendarEventInputs: PollCalendarEventInputs;
}

const initialState: CreatePollState = {
  duration: 120, // 이벤트 소요 시간(분)
  startAt: "08:00", // 이벤트 시작 시각
  endAt: "19:00", // 이벤트 종료 시각
  dateRange: [moment(), moment()], // 이벤트 선택 가능 기간
  hourOption: "workhour",
  slotUnitMinutes: 30,
  selectedEvents: selectedEventsAdapter.getInitialState(),
  calendarEventInputs: initPollCalendarEvents,
};
initialState.calendarEventInputs = getInitCalendarEventInputs(initialState);

/**
 * 초기 조건이 바뀔때마다 캘린더 배경 이벤트를 변경
 **/
function getInitCalendarEventInputs({
  dateRange,
  startAt,
  endAt,
  slotUnitMinutes,
}: CreatePollState) {
  const [startDate, endDate] = dateRange;
  if (startDate && endDate) {
    // background eventInputs 를 계산한다.
    const diff = endDate.diff(startDate, "days");
    let backgroundEventInputs: EventInput[] = [];
    let index = 0;
    for (let i = 0; i <= diff; i++) {
      const today = moment(startDate).add(i, "days");
      const [minMoment, maxMoment] = [
        moment(`${today.format("YYYY-MM-DD")}T${startAt}`),
        moment(`${today.format("YYYY-MM-DD")}T${endAt}`),
      ];
      const diffMinutes = maxMoment.diff(minMoment, "minutes");
      for (let j = 0; j < diffMinutes; j += slotUnitMinutes) {
        const start = moment(minMoment).add(j, "minutes").toDate();
        const end = moment(start).add(slotUnitMinutes, "minutes").toDate();
        const backgroundEventInput: EventInput = {
          id: getUUID(),
          start,
          end,
          classNames: [invySlotClassName, backgroundSlotClassName],
          extendedProps: {
            type: CreatePollCalendarEventType.background,
            childType: CreatePollCalendarEventType.available, // FIXME: 바탕에 깔린 이벤트 유형 바꿔야함
            index, // FIXME: 어차피 sort 되어있으니깐 없애거나, id로 찾게할까?
            isSelected: false,
          } as CreatePollCalendarExtendedProps, // FIXME: extendedProps 도 type 정의할 수 있는 방법 보기
        };
        backgroundEventInputs.push(backgroundEventInput);
        index++;
      }
    }
    let occupiedEvents: EventInput[] = []; // FIXME: 아직 외부 이벤트 받아오는 부분 없으므로 임시로 빈 배열 사용 중
    occupiedEvents = [...occupiedEvents].sort((a, b) => {
      return moment(a.start).diff(b.start);
    });
    backgroundEventInputs = backgroundEventInputs.map((backgroundEvent) => {
      let isAvailable = true;
      const [backgroundEventStartMoment, backgroundEventEndMoment] = [
        moment(backgroundEvent.start),
        moment(backgroundEvent.end),
      ];
      for (let i = 0; i < occupiedEvents.length; i++) {
        const occupiedEvent = occupiedEvents[i];
        const [occupiedEventStartMoment, occupiedEventEndMoment] = [
          moment(occupiedEvent.start),
          moment(occupiedEvent.end),
        ];
        if (occupiedEventStartMoment.isAfter(backgroundEventEndMoment)) {
          // 현재 occupied 보다 작다면 넘어감
          break;
        }
        if (
          (occupiedEventStartMoment.isSameOrAfter(backgroundEventStartMoment) &&
            occupiedEventStartMoment.isBefore(backgroundEventEndMoment)) ||
          (occupiedEventEndMoment.isAfter(backgroundEventStartMoment) &&
            occupiedEventEndMoment.isSameOrBefore(backgroundEventEndMoment))
        ) {
          isAvailable = false;
          break;
        }
      }
      return {
        ...backgroundEvent,
        extendedProps: {
          ...backgroundEvent.extendedProps,
          childType: isAvailable
            ? CreatePollCalendarEventType.available
            : CreatePollCalendarEventType.occupied,
        },
      } as EventInput;
    });
    return {
      background: backgroundEventInputs,
      availableAndOccupied: backgroundEventInputs.map((d, index) => {
        const extendedProps =
          d.extendedProps as CreatePollCalendarExtendedProps;
        return {
          ...d,
          id: getUUID(),
          classNames: [
            invySlotClassName,
            extendedProps?.childType === CreatePollCalendarEventType.available
              ? availableSlotClassName
              : occupiedSlotClassName,
          ],
          extendedProps: {
            type: CreatePollCalendarEventType.available,
            index,
          },
        } as EventInput;
      }),
      selected: [], // 현재는 기존 이벤트 제거하고 리셋
      selectedLabel: [],
    } as PollCalendarEventInputs;
  } else {
    return initPollCalendarEvents;
  }
}

function getStartAndEndAtByHourOption(
  payload: PollHourOption
): [startAt: string, endAt: string] {
  switch (payload) {
    case "workhour":
      return ["08:00", "19:00"];
    case "noon":
      return ["12:00", "14:00"];
    case "evening":
      return ["18:00", "20:00"];
    case "all":
      return ["00:00", "24:00"];
    default:
      return ["08:00", "19:00"];
  }
}

export const createPollSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
      state.calendarEventInputs = getInitCalendarEventInputs(state);
    },
    setDateRange: (
      state,
      action: PayloadAction<CreatePollState["dateRange"]>
    ) => {
      state.dateRange = action.payload;
      state.calendarEventInputs = getInitCalendarEventInputs(state);
    },
    setStartAt: (state, action: PayloadAction<string>) => {
      state.startAt = action.payload;
      state.calendarEventInputs = getInitCalendarEventInputs(state);
    },
    setEndAt: (state, action: PayloadAction<string>) => {
      state.endAt = action.payload;
      state.calendarEventInputs = getInitCalendarEventInputs(state);
    },
    setHourOption: (state, action: PayloadAction<PollHourOption>) => {
      state.hourOption = action.payload;
      // startAt, endAt 를 변경한다.
      const [startAt, endAt] = getStartAndEndAtByHourOption(action.payload);
      state.startAt = startAt;
      state.endAt = endAt;
      state.calendarEventInputs = getInitCalendarEventInputs(state);
    },
    addSelectedEvents: (state, action: PayloadAction<SelectedEvent[]>) => {
      selectedEventsAdapter.addMany(state.selectedEvents, action);
      const selectedEvents = state.selectedEvents.ids;
      const selected: EventInput[] = [];
      const selectedLabel: EventInput[] = [];
      let background = state.calendarEventInputs.background;
      selectedEvents.forEach((id, selectedIndex) => {
        const d = state.selectedEvents.entities[id];
        if (d) {
          // FIXME: 매번 재갱신 하지 않고 normalizr 써서 update 하기
          const backgroundIndex = background.findIndex((b) =>
            moment(b.start).isSame(moment(d.start))
          );
          const newSelected = {
            id: d.id,
            start: d.start,
            end: d.end,
            classNames: [invySlotClassName, selectedSlotClassName],
            extendedProps: {
              type: CreatePollCalendarEventType.selected,
              index: selectedIndex,
              selectedIndex,
              backgroundIndex,
            } as CreatePollCalendarExtendedProps,
          } as EventInput;

          selected.push(newSelected);

          selectedLabel.push({
            id: getUUID(),
            start: newSelected.start,
            end: newSelected.end,
            classNames: [invySlotClassName, selectedLabelSlotClassName],
            extendedProps: {
              type: CreatePollCalendarEventType.selectedLabel,
              index: selectedIndex,
              selectedIndex,
              backgroundIndex,
            } as CreatePollCalendarExtendedProps,
          });
          if (backgroundIndex >= 0) {
            const backgroundExtendedProps = background[backgroundIndex]
              ?.extendedProps as CreatePollCalendarExtendedProps;
            backgroundExtendedProps.isSelected = true;
            backgroundExtendedProps.selectedEventId = id as string;
            background = [
              ...background.slice(0, backgroundIndex),
              background[backgroundIndex],
              ...background.slice(backgroundIndex + 1),
            ];
          }
        }
      });

      state.calendarEventInputs = {
        ...state.calendarEventInputs,
        selected,
        selectedLabel,
        background,
      };
    },
    /*
    setAllSelectedEvents: (state, action: PayloadAction<SelectedEvent[]>) => {
      selectedEventsAdapter.setAll(state.selectedEvents, action);
    },
     */
    removeSelectedEvents: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;
      let { background, selected, selectedLabel } = state.calendarEventInputs;
      ids.forEach((id) => {
        const selectedEvent = selectedEventsAdapter
          .getSelectors()
          .selectById(state.selectedEvents, id);
        if (selectedEvent) {
          // FIXME: normalizr 사용해서 최적화하기
          const backgroundIndex = background.findIndex((b) =>
            moment(b.start).isSame(moment(selectedEvent.start))
          );
          if (backgroundIndex >= 0) {
            const backgroundExtendedProps = background[backgroundIndex]
              ?.extendedProps as CreatePollCalendarExtendedProps;
            backgroundExtendedProps.isSelected = false;
            backgroundExtendedProps.selectedEventId = undefined;
            background = [
              ...background.slice(0, backgroundIndex),
              background[backgroundIndex],
              ...background.slice(backgroundIndex + 1),
            ];
          }
          const selectedIndex = selected.findIndex(
            (s) => s?.extendedProps?.backgroundIndex === backgroundIndex
          );
          if (selectedIndex >= 0) {
            selected = [
              ...selected.slice(0, selectedIndex),
              ...selected.slice(selectedIndex + 1),
            ];
            selectedLabel = [
              ...selectedLabel.slice(0, selectedIndex),
              ...selectedLabel.slice(selectedIndex + 1),
            ];
          }
        }
        // selected, selectedLabel 지우기
      });
      state.calendarEventInputs = {
        ...state.calendarEventInputs,
        background,
        selectedLabel,
        selected,
      };
      selectedEventsAdapter.removeMany(state.selectedEvents, action);
    },
    removeSelectedEventsAll: (state) => {
      selectedEventsAdapter.removeAll(state.selectedEvents);
      state.calendarEventInputs = getInitCalendarEventInputs(state);
    },
    setCalendarEventInputs: (
      state,
      action: PayloadAction<PollCalendarEventInputs>
    ) => {
      state.calendarEventInputs = {
        ...state.calendarEventInputs,
        ...action.payload,
      };
    },
  },
});

export const createPollSelectors = {
  selectState: (state: RootState) => state[name] as CreatePollState,
  selectedEventsSelectors: selectedEventsAdapter.getSelectors(
    (state: RootState) => state[name].selectedEvents
  ),
};
