import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";

interface ScrollToTopProps {
  history: RouteComponentProps["history"];
}

function ScrollToTop({ history }: ScrollToTopProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <React.Fragment />;
}

export default withRouter(ScrollToTop);
