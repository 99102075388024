import React from "react";
import { UrlView } from "./UrlView";
import { EventInfo } from "./EventInfo";
import { EventFormItem, EventFormItemProps } from "./EventFormItem";
import styled from "@mui/styled-engine";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EventWithGuests } from "../../app/services/events/types";

export const StyledUrlViewWrapper = styled(Box)`
  border-top: solid 1px #dadada;
  padding: 20px 0;
`;

export interface EventInfoWithUrlViewProps
  extends Omit<EventFormItemProps, "children"> {
  event?: EventWithGuests;
  isUrlHidden?: boolean;
}

export function EventInfoWithUrlView({
  event,
  isUrlHidden,
  ...props
}: EventInfoWithUrlViewProps) {
  const { t: tEventInfo } = useTranslation("eventInfo");

  return (
    <EventFormItem title={tEventInfo("Label")} {...props}>
      {isUrlHidden ? null : (
        <StyledUrlViewWrapper component="div">
          <UrlView title={event?.title} url={event?.url} showWarning={false} />
        </StyledUrlViewWrapper>
      )}
      <EventInfo event={event} />
    </EventFormItem>
  );
}
