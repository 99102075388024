import { Box, Button, TextField, Typography } from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExtendedEvent } from "./BaseCalendar";
import { CommonModal } from "../modal/CommonModal";
import styled from "@mui/styled-engine";
import { getUUID } from "../../utils/getUUID";

const StyledCalendarInputItem = styled(Box)`
  margin-bottom: 30px;
  & > .calendar-input {
    height: 48px;
    &-label {
      font-size: 14px;
      font-weight: 600;
      color: #232323;
      margin-bottom: 10px;
    }
  }
`;

export function generateDummyEvent() {}

export interface ChangeScheduleCalendarInputModalProps {
  open: boolean;
  event?: ExtendedEvent;
  onClose: () => void;
  onSave: (event?: ExtendedEvent) => void;
  onDelete?: (event?: ExtendedEvent) => void;
}

export function ScheduleCalendarInputModal({
  open,
  event,
  onClose,
  onDelete,
  onSave,
}: ChangeScheduleCalendarInputModalProps) {
  const { t } = useTranslation("scheduleCalendarInput");
  const [modifiedEvent, setModifiedEvent] = useState<ExtendedEvent | undefined>(
    undefined
  );

  const handleDelete = useCallback(() => {
    if (onDelete) onDelete(modifiedEvent);
  }, [onDelete, modifiedEvent]);

  const handleSave = useCallback(() => {
    onSave(modifiedEvent);
  }, [onSave, modifiedEvent]);

  const handleInputChange = useCallback(
    (newDate, targetProp: "start" | "end") => {
      const newDateMoment = moment(newDate);
      if (newDateMoment.isValid()) {
        setModifiedEvent(
          modifiedEvent && {
            ...modifiedEvent,
            [targetProp]: newDateMoment.toDate() || null,
          }
        );
      }
    },
    [modifiedEvent]
  );

  useEffect(() => {
    if (open) {
      if (event) {
        setModifiedEvent({ ...event });
      } else {
        // TODO: dummy event 추가
        setModifiedEvent({
          id: getUUID(),
          start: new Date(),
          end: new Date(), // FIXME: 기본 단위 offset 두도록 수정
          isTemp: true,
        });
      }
    } else {
      setModifiedEvent(undefined);
    }
  }, [open, event]);

  return (
    <CommonModal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={
        modifiedEvent?.isTemp
          ? t("InputSchedulesDirectly")
          : t("UpdateSchedulesDirectly")
      }
      width={300}
      onConfirm={handleSave}
      confirmButtonText={t("Save")}
      confirmButtonColor="secondary"
      confirmButtonDisabled={modifiedEvent === undefined}
      cancelButtonText={t("Cancel")}
      cancelButtonColor="normal"
    >
      <StyledCalendarInputItem>
        <Typography variant={"subtitle1"} className="calendar-input-label">
          {t("StartDate")}
        </Typography>
        <DateTimePicker
          className="calendar-input"
          renderInput={(params) => <TextField fullWidth {...params} />}
          value={modifiedEvent?.start}
          onChange={(date) => handleInputChange(date, "start")}
        />
      </StyledCalendarInputItem>
      <StyledCalendarInputItem>
        <Typography variant={"subtitle1"} className="calendar-input-label">
          {t("EndDate")}
        </Typography>
        <DateTimePicker
          className="calendar-input"
          renderInput={(params) => <TextField fullWidth {...params} />}
          value={modifiedEvent?.end}
          onChange={(date) => handleInputChange(date, "end")}
        />
      </StyledCalendarInputItem>
      {modifiedEvent && !modifiedEvent.isTemp ? (
        <Button
          variant="cta"
          color={"normal"}
          fullWidth
          onClick={handleDelete}
          sx={{ height: "48px" }}
        >
          {t("Delete")}
        </Button>
      ) : null}
    </CommonModal>
  );
}
