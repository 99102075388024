export const tempSlotTitleClassName = "fc-invy-temp-slot-title";
export const invySlotClassName = "fc-invy-slot";
export const invyTempSlotClassName = "fc-invy-temp-slot";
export const availableSlotClassName = "fc-invy-available-slot";
export const backgroundSlotClassName = "fc-invy-background-slot";
export const occupiedSlotClassName = "fc-invy-occupied-slot";
export const selectedSlotClassName = "fc-invy-selected-slot";
export const selectedLabelSlotClassName = "fc-invy-selected-label-slot";
export const tempAvailableSlotClassName = "fc-invy-temp-available-slot";
export const tempOccupiedSlotClassName = "fc-invy-temp-occupied-slot";
