import React, { useCallback, useEffect, useState } from "react";
import { ContainerWithTitle } from "../../components/commons/ContainerWithTitle";
import { useTranslation } from "react-i18next";
import {
  LeaveTerm,
  LeaveTermsForm,
  LeaveTermsValue,
} from "../../components/forms/LeaveTermsForm";
import { AvatarCard } from "../../components/cards/AvatarCard";
import { useUserMe } from "../../hooks/useUserMe";
import { useDeleteUnregisterMutation } from "../../app/services/accounts/authApi";
import { Redirect } from "react-router-dom";
import { useLogOut } from "../../hooks/useLogOut";
import { User } from "../../app/services/accounts/types";
import { AvatarCardWrapper } from "../../components/cards/AvatarCardWrapper";

export const terms = [
  { id: "restore" },
  { id: "delete" },
  { id: "remain" },
  { id: "sync" },
];

const defaultTermsValue = terms.reduce(
  (acc: LeaveTermsValue, cur: LeaveTerm) => {
    acc[cur.id] = false;
    return acc;
  },
  {}
);

export function LeavePage() {
  const { data: me, isError: isUserMeError } = useUserMe();
  const { t } = useTranslation("leave");
  const [deleteUnregister, { isSuccess: isDeleteUnregisterSuccess }] =
    useDeleteUnregisterMutation();
  useLogOut(isDeleteUnregisterSuccess);

  const [tempUser, setTempUser] = useState<User | undefined>(undefined);
  const [termsValue, setTermsValue] =
    useState<LeaveTermsValue>(defaultTermsValue);
  const handleSubmit = useCallback(() => {
    deleteUnregister();
  }, [deleteUnregister]);

  useEffect(() => {
    if (me?.user) {
      setTempUser(me.user);
    }
    return () => {
      setTempUser(undefined);
    };
  }, [me]);

  return isUserMeError && !isDeleteUnregisterSuccess ? (
    <Redirect to={"/login"} />
  ) : isDeleteUnregisterSuccess && tempUser ? (
    <ContainerWithTitle
      title={t("TitleAfterLeave", { name: tempUser.name })}
      description={t("DescriptionAfterLeave")}
      hasQuotation={true}
    >
      <AvatarCardWrapper>
        <AvatarCard
          email={tempUser.email}
          name={tempUser.name}
          bgColor={tempUser.bg_color}
        />
      </AvatarCardWrapper>
    </ContainerWithTitle>
  ) : me?.user ? (
    <ContainerWithTitle
      title={t("Title")}
      description={t("Description")}
      descriptionMarginTop={10}
    >
      <AvatarCardWrapper>
        <AvatarCard
          email={me.user.email}
          name={me.user.name}
          bgColor={me.user.bg_color}
        />
      </AvatarCardWrapper>
      <LeaveTermsForm
        terms={terms}
        value={termsValue}
        onChange={setTermsValue}
        onSubmit={handleSubmit}
      />
    </ContainerWithTitle>
  ) : null;
}
