import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostVerifyEmailMutation } from "../../app/services/accounts/verifyApi";
import { useLogOut } from "../../hooks/useLogOut";
import { ContainerWithTitle } from "../../components/commons/ContainerWithTitle";
import { AvatarCard } from "../../components/cards/AvatarCard";
import { useAppDispatch } from "../../app/hooks";
import { authSlice } from "./authSlice";
import { Box, Button } from "@mui/material";
import { User } from "../../app/services/accounts/types";

export function VerifiedUserContainer({ user }: { user: User }) {
  const { t } = useTranslation("verify");

  return (
    <ContainerWithTitle
      title={`${user.name}${t("Welcome")}`}
      description={t("Description")}
      hasQuotation={true}
    >
      <AvatarCard email={user.email} name={user.name} bgColor={user.bg_color} />
      <Box className="footer">
        <Button href={"/"} fullWidth variant="cta" color="primary">
          {t("Start")}
        </Button>
      </Box>
    </ContainerWithTitle>
  );
}

export function VerifyPage() {
  const dispatch = useAppDispatch();
  const { user } = useLogOut();
  const { token } = useParams<{ token: string }>();
  const [postVerifyEmail, { data, isError, isSuccess }] =
    usePostVerifyEmailMutation();

  useEffect(() => {
    postVerifyEmail({ token });
  }, [token, postVerifyEmail]);

  useEffect(() => {
    if (isSuccess) {
      if (data && data.user !== null && data.token !== null) {
        // FIXME: UserResponse 정상 아닐 때 error Response 하기

        dispatch(
          authSlice.actions.setCredentials({
            user: data.user,
            token: data.token,
          })
        );
      }
    }
  }, [dispatch, isSuccess, data]);

  return (
    <div>
      {isError ? (
        <Redirect to={"/empty"} />
      ) : isSuccess && user ? (
        <VerifiedUserContainer user={user} />
      ) : null}
    </div>
  );
}
