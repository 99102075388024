import { getImageUrl } from "../commons/imageUrl";

const hostname = process.env?.REACT_EMAIL_URL_PREFIX || "";
export const theme = {
  header: {
    image: {
      LOGO: getImageUrl("ic_logo", hostname),
    },
  },
  common: {
    color: {
      primary: "#4555ff",
      button: "#4555ff",
    },
    image: {
      QUOTATION_START: getImageUrl("ic_image_quotation_start", hostname),
      QUOTATION_END: getImageUrl("ic_image_quotation_end", hostname),
    },
  },
  guest: {
    color: {
      primary: "#d76010",
      button: "#ac2707",
    },
    image: {
      QUOTATION_START: getImageUrl("ic_image_quotation_start_guest", hostname),
      QUOTATION_END: getImageUrl("ic_image_quotation_end_guest", hostname),
    },
  },
};
