import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EventPageWithMeParams } from "./EventPage";
import {
  EventInfoRequest,
  GuestEmailAndKey,
  GuestWithStatus,
  RecommendSchedule,
} from "../../app/services/events/types";
import {
  ExtendedRecommendedSchedule,
  HostEventForm,
} from "../../components/forms/HostEventForm";
import {
  useGetAllRecommendSchedulesQuery,
  useGetInviteResendMutation,
  useGetPriorRecommendSchedulesQuery,
  usePostEventsInfoQuery,
  usePostInsertScheduleMutation,
  usePostInviteGuestMutation,
  usePostInviteResendMutation,
  usePostSchedulesQuery,
  usePutSelectScheduleMutation,
} from "../../app/services/events/eventApi";
import { skipToken, SkipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { CalendarRange } from "../../components/calendars/ConfirmScheduleCalendar";
import { CommonEventPage } from "./CommonEventPage";
import { EditEventButtonWithModals } from "./EditEventButtonWithModals";
import { useIsDeletedOrFinishedEvent } from "../../hooks/useIsDeletedOrFinishedEvent";
import { CreateEventPageCalendarDrawer } from "./CreateEventPageCalendarDrawer";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const pageSize = 5;

export function convertRecommendedSchedules(
  schedules: RecommendSchedule[],
  guests: GuestWithStatus[],
  isDailySchedules?: boolean
): ExtendedRecommendedSchedule[] {
  const newSchedules = schedules.map(
    (s, i, arr): ExtendedRecommendedSchedule => {
      const availableParticipants = s.guests.filter((g) => g.status === 1);
      const isAllAvailable = guests.length === availableParticipants.length;
      return {
        ...s,
        availableParticipants,
        isPrior: !isDailySchedules,
        isAllAvailable,
        isHeadOfDate:
          isDailySchedules &&
          (i === 0 ||
            !moment(arr[i - 1].from_time)
              .startOf("day")
              .isSame(moment(s.from_time).startOf("day"))),
      };
    }
  );
  if (isDailySchedules)
    newSchedules.sort((a, b) => moment(a.from_time).diff(b.from_time));
  return newSchedules;
}

export interface HostEventPageProps extends EventPageWithMeParams {}
/*
일정 확정 페이지
 */
export function HostEventPage({ eventId, me }: HostEventPageProps) {
  const { t } = useTranslation("confirmSchedule");
  const [page, setPage] = useState(1);
  const [
    extendedPriorRecommendedSchedules,
    setExtendedPriorRecommendedSchedules,
  ] = useState<ExtendedRecommendedSchedule[]>([]);
  const [
    extendedDailyRecommendedSchedules,
    setExtendedDailyRecommendedSchedules,
  ] = useState<ExtendedRecommendedSchedule[]>([]);
  const [
    extendedCalendarRecommendedSchedules,
    setExtendedCalendarRecommendedSchedules,
  ] = useState<ExtendedRecommendedSchedule[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<
    undefined | RecommendSchedule
  >(undefined);
  const [requiredParticipant, setRequiredParticipant] = useState<
    undefined | GuestWithStatus
  >(undefined);
  const [isMoreButtonHidden, setIsMoreButtonHidden] = useState(false);
  const { data: eventInfo /*,refetch: refetchEventInfo */ } =
    usePostEventsInfoQuery({
      id: eventId,
      emailAndKey: me.key !== undefined ? (me as GuestEmailAndKey) : undefined,
    });
  const [queryArg, setQueryArg] = useState<SkipToken | EventInfoRequest>(
    skipToken
  );
  const [calendarRange, setCalendarRange] = useState<CalendarRange | undefined>(
    undefined
  );
  const [changeCalendarOpen, setChangeCalendarOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { data: guestSchedules } = usePostSchedulesQuery(queryArg);
  const {
    data: priorRecommendedSchedules,
    isLoading: isPriorRecommendedSchedules,
  } = useGetPriorRecommendSchedulesQuery(
    eventInfo
      ? { id: eventInfo.id, email: requiredParticipant?.email }
      : skipToken
  );
  const {
    data: dailyRecommendSchedules,
    isError: isDailyRecommendSchedulesError,
    isLoading: isDailyRecommendSchedulesLoading,
  } = useGetAllRecommendSchedulesQuery(
    eventInfo
      ? {
          id: eventInfo.id,
          page,
          limit: pageSize,
          email: requiredParticipant?.email,
        }
      : skipToken
  );

  const { data: calendarRecommendSchedules } = useGetAllRecommendSchedulesQuery(
    eventInfo && calendarRange
      ? {
          id: eventInfo.id,
          limit: 999,
          email: requiredParticipant?.email,
          //from_at: moment(calendarRange.start).format("YYYY-MM-DD"),
          //to_at: moment(calendarRange.end).format("YYYY-MM-DD"),
        }
      : skipToken
  );
  const [
    putSelectSchedule,
    {
      isLoading: isPutSelectScheduleLoading,
      isSuccess: isPutSelectScheduleSuccess,
    },
  ] = usePutSelectScheduleMutation();
  const [
    postInsertSchedule,
    {
      data: userDefinedRecommendSchedule,
      isLoading: isPostInsertScheduleLoading,
    },
  ] = usePostInsertScheduleMutation();
  const [postInviteResend] = usePostInviteResendMutation();
  const [postInviteGuest] = usePostInviteGuestMutation();
  const [getInviteResend, { isSuccess: isInviteResendSuccess }] =
    useGetInviteResendMutation();
  const isDeletedOrFinished = useIsDeletedOrFinishedEvent(eventInfo);

  const handleMoreButtonClick = useCallback(
    () => setPage((page) => page + 1),
    []
  );

  const handleSubmit = useCallback(
    (schedule: RecommendSchedule) => {
      if (eventInfo) {
        if (schedule.id >= 0) {
          putSelectSchedule({ id: eventInfo.id, scheduleId: schedule.id });
        } else {
          postInsertSchedule({
            id: eventInfo.id,
            from_time: schedule.from_time,
            to_time: schedule.to_time,
          });
        }
      }
    },
    [putSelectSchedule, postInsertSchedule, eventInfo]
  );

  const handleChangeSelectedSchedule = useCallback(
    (schedule) => setSelectedSchedule(schedule),
    []
  );

  const handleRequiredParticipantChange = useCallback((participant) => {
    setRequiredParticipant(participant);
  }, []);

  const handleCalendarRangeChange = useCallback((range) => {
    setCalendarRange(range);
  }, []);

  const handleGuestDelete = useCallback(
    (guest) => {
      if (eventInfo) {
        const others = eventInfo.guests.filter(
          (d) => d.email !== guest.email && !d.is_host
        );
        postInviteGuest({ id: eventInfo.id, guests: others });
      }
    },
    [eventInfo, postInviteGuest]
  );

  const handleMailSend = useCallback(
    (guest) => {
      if (eventInfo) {
        postInviteResend({ id: eventInfo.id, email: guest.email });
      }
    },
    [eventInfo, postInviteResend]
  );

  const handleAllMailSend = useCallback(() => {
    if (eventInfo && getInviteResend) {
      getInviteResend({ id: eventInfo.id });
      setIsSnackbarOpen(true);
    }
  }, [getInviteResend, eventInfo]);

  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);

  const handleChangeCalendarOpen = useCallback(() => {
    setChangeCalendarOpen(true);
  }, []);

  const handleChangeCalendarClose = useCallback(() => {
    setChangeCalendarOpen(false);
  }, []);

  const hostEventConfirmListLoading = useMemo(
    () => isPriorRecommendedSchedules || isDailyRecommendSchedulesLoading,
    [isDailyRecommendSchedulesLoading, isPriorRecommendedSchedules]
  );

  useEffect(() => {
    if (isInviteResendSuccess) {
      setIsSnackbarOpen(true);
    }
  }, [isInviteResendSuccess]);

  useEffect(() => {
    if (eventInfo && eventInfo.selected) {
      setSelectedSchedule(eventInfo.selected);
    }
  }, [eventInfo]);

  useEffect(() => {
    // FIXME: GuestEventPage와 동일하므로 refactor 필요
    setQueryArg(
      me.key === undefined
        ? eventInfo !== undefined
          ? { id: eventInfo.id }
          : skipToken
        : { id: eventId, emailAndKey: me as GuestEmailAndKey }
    );
  }, [me, eventId, eventInfo]);

  useEffect(() => {
    if (priorRecommendedSchedules && eventInfo) {
      setExtendedPriorRecommendedSchedules(
        convertRecommendedSchedules(priorRecommendedSchedules, eventInfo.guests)
      );
    } else {
      setExtendedPriorRecommendedSchedules([]);
    }
  }, [priorRecommendedSchedules, eventInfo]);

  useEffect(() => {
    if (dailyRecommendSchedules && eventInfo) {
      setExtendedDailyRecommendedSchedules((schedules) => {
        const newSchedules = convertRecommendedSchedules(
          dailyRecommendSchedules,
          eventInfo.guests,
          true
        );
        if (
          schedules.length > 0 &&
          newSchedules.length > 0 &&
          moment(schedules[schedules.length - 1].from_time)
            .startOf("day")
            .isSame(moment(newSchedules[0].from_time).startOf("day"))
        ) {
          newSchedules[0].isHeadOfDate = false;
        }
        return schedules.concat(newSchedules);
      });
      setIsMoreButtonHidden(dailyRecommendSchedules.length < pageSize);
    } // daily reset 하는 경우 수정 필요
  }, [dailyRecommendSchedules, eventInfo]);

  useEffect(() => {
    // 에러 발생 했을 때는 빈 리스트 전달
    if (calendarRecommendSchedules && eventInfo) {
      setExtendedCalendarRecommendedSchedules(
        convertRecommendedSchedules(
          calendarRecommendSchedules,
          eventInfo.guests,
          true
        )
      );
    } else {
      setExtendedCalendarRecommendedSchedules([]);
    }
  }, [calendarRecommendSchedules, eventInfo]);

  // FIXME: optimistic update: https://redux-toolkit.js.org/rtk-query/api/created-api/api-slice-utils#updatequerydata 적용하고 404 일 경우 undo 하지 않기
  useEffect(() => {
    // 일단 초기화
    setExtendedPriorRecommendedSchedules([]);
    setExtendedDailyRecommendedSchedules([]);
    setExtendedCalendarRecommendedSchedules([]);
    setIsMoreButtonHidden(true);
    setPage(1);
  }, [requiredParticipant]);

  useEffect(() => {
    if (isDailyRecommendSchedulesError) {
      setIsMoreButtonHidden(true);
    }
  }, [isDailyRecommendSchedulesError]);

  // 직접 입력 완료 시에는 해당 일정 id를 재전송해야한다.
  useEffect(() => {
    if (eventInfo && userDefinedRecommendSchedule) {
      setSelectedSchedule(userDefinedRecommendSchedule);
      putSelectSchedule({
        id: eventInfo.id,
        scheduleId: userDefinedRecommendSchedule.id,
      });
    }
  }, [eventInfo, putSelectSchedule, userDefinedRecommendSchedule]);

  return (isPutSelectScheduleSuccess && eventInfo?.selected) ||
    isDeletedOrFinished ? (
    <CommonEventPage eventId={eventId} me={me} />
  ) : (
    <>
      <HostEventForm
        me={me.email}
        eventInfo={eventInfo}
        editEventButton={<EditEventButtonWithModals eventInfo={eventInfo} />}
        guestSchedules={guestSchedules || []}
        priorRecommendSchedules={extendedPriorRecommendedSchedules}
        dailyRecommendSchedules={extendedDailyRecommendedSchedules}
        calendarRecommendSchedules={extendedCalendarRecommendedSchedules}
        moreButtonHidden={isMoreButtonHidden}
        submitButtonLoading={
          isPutSelectScheduleLoading || isPostInsertScheduleLoading
        }
        selectedSchedule={selectedSchedule}
        requiredParticipant={requiredParticipant}
        onCalendarRangeChange={handleCalendarRangeChange}
        onRequiredParticipantChange={handleRequiredParticipantChange}
        onMoreButtonClick={handleMoreButtonClick}
        onMailSend={handleMailSend}
        onAllMailSend={handleAllMailSend}
        onGuestDelete={handleGuestDelete}
        onChangeScheduleCalendarOpen={handleChangeCalendarOpen}
        onChange={handleChangeSelectedSchedule}
        onSubmit={handleSubmit}
        hostEventConfirmListLoading={hostEventConfirmListLoading}
      />
      {eventInfo && (
        <CreateEventPageCalendarDrawer
          me={me?.email}
          event={eventInfo}
          open={changeCalendarOpen}
          onClose={handleChangeCalendarClose}
        />
      )}
      <Snackbar
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={t("listEmpty.ResentMessage")}
      />
    </>
  );
}
