import React, { useCallback, useMemo, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Button,
  Link,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import styled from "@mui/styled-engine";
import {
  StyledFormCard,
  StyledFormCardContent,
} from "../styled/StyledSignUpForm";
import { TermsModal } from "../modal/TermsModal";
import { FormWithValidator } from "../commons/FormWithValidator";

const StyledTermsCardContent = styled(StyledFormCardContent)`
  .check-box {
    &-all {
      &-description {
        font-size: 12px;
        font-weight: 600;
        color: #b0b0b0;
        margin: 0 0 0 33px;
        white-space: pre-line;
      }
    }
    &-label {
      font-size: 16px;
      font-weight: bold;
      color: #232323;
      white-space: nowrap;
      &-wrapper {
        display: inline-flex;
        align-items: center;
      }
    }
    &-link {
      width: 16px;
      height: 16px;
      margin-left: 6px;
      position: relative;
      transform: rotate(45deg);
      transform-origin: center center;

      & > span {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          background-color: #bbbbbb;
          border-radius: 1px;
          width: 12px;
          height: 2px;
        }
        &:before {
          top: 0;
          left: 0;
          width: 12px;
          height: 2px;
        }
        &:after {
          right: 0;
          top: 0;
          width: 2px;
          height: 12px;
        }
      }
    }
  }
`;

export interface SignUpTerm {
  id: string;
  isRequired: boolean;
  text?: string;
}

export type SignUpTermsValue = Record<SignUpTerm["id"], boolean>;

export interface SignUpTermsFormProps {
  value?: SignUpTermsValue;
  terms: SignUpTerm[];
  onChange?: (value: SignUpTermsValue) => void;
  onSubmit?: () => void;
}

export function SignUpTermsForm({
  value,
  terms = [],
  onChange,
  onSubmit,
}: SignUpTermsFormProps) {
  const { t: tSignUp } = useTranslation("signUp");
  const { t: tTerms } = useTranslation("signupTerms");
  const [isValidated, setValidated] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [currentShowingTermId, setCurrentShowingTermId] = useState<
    SignUpTerm["id"]
  >(terms[0].id);
  const currentShowingTerm = useMemo(() => {
    return terms.find((d) => d.id === currentShowingTermId);
  }, [currentShowingTermId, terms]);
  const isAllChecked = useMemo(
    () =>
      value ? Object.values(value).every((agreed: boolean) => agreed) : false,
    [value]
  );
  const isRequiredAllChecked = useMemo(
    () =>
      terms.every((term: SignUpTerm) => {
        return !term.isRequired || (term.isRequired && value?.[term.id]);
      }),
    [terms, value]
  );

  const checkTerm = useCallback(
    (checked: boolean, termId: string) => {
      const changedTermsAgreed = {
        ...value,
        [termId]: checked,
      };
      if (onChange) onChange(changedTermsAgreed);
    },
    [value, onChange]
  );

  const toggleAllTerms = useCallback(
    (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      if (onChange && value) {
        onChange(
          Object.keys(value).reduce(
            (acc: SignUpTermsValue, cur: SignUpTerm["id"]) => {
              acc[cur] = checked;
              return acc;
            },
            {}
          )
        );
      }
    },
    [onChange, value]
  );

  const handleOpenTermsModal = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, id: SignUpTerm["id"]) => {
      event.stopPropagation();
      event.preventDefault();
      setCurrentShowingTermId(id);
      setOpen(true);
    },
    []
  );

  const handleCloseTermsModal = useCallback(() => setOpen(false), []);

  const handleSubmit = useCallback(() => {
    setValidated(true);
    onSubmit?.();
  }, [onSubmit]);

  return (
    <FormWithValidator
      title={tSignUp("TermsWelcome")}
      errorMessage={
        isValidated && !isRequiredAllChecked ? tTerms("ErrorMessage") : ""
      }
    >
      <StyledFormCard sx={{ marginTop: "32px" }}>
        <StyledTermsCardContent>
          <FormControlLabel
            control={<Checkbox checked={isAllChecked} />}
            label={
              <Typography component="span" className="check-box-label">
                {tSignUp("termsSelectAll.Title")}
              </Typography>
            }
            onChange={toggleAllTerms}
          />
          <Typography
            variant="body1"
            component="p"
            gutterBottom={true}
            className="check-box-all-description"
          >
            {tSignUp("termsSelectAll.Description")}
          </Typography>
          <Divider light sx={{ margin: "30px 0" }} />
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            <FormGroup>
              {terms.map(({ id, isRequired }) => {
                return (
                  <FormControlLabel
                    key={id}
                    control={<Checkbox />}
                    label={
                      <Box className="check-box-label-wrapper">
                        <Typography
                          component="span"
                          className="check-box-label"
                        >
                          {`[${
                            isRequired
                              ? tSignUp("TermsRequired")
                              : tSignUp("TermsOptional")
                          }] ${tTerms(`${id}.Title`)}`}
                        </Typography>
                        <Link
                          className="check-box-link"
                          onClick={(
                            event: React.MouseEvent<HTMLAnchorElement>
                          ) => handleOpenTermsModal(event, id)}
                        >
                          <span />
                        </Link>
                      </Box>
                    }
                    checked={value ? value[id] : false}
                    onChange={(_, checked) => checkTerm(checked, id)}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </StyledTermsCardContent>
      </StyledFormCard>
      <Box component="div" className="footer">
        <Button
          className="footer-button"
          fullWidth
          onClick={handleSubmit}
          type={"submit"}
          variant="cta"
          color="primary"
        >
          {tSignUp("TermsAgree")}
        </Button>
      </Box>
      <TermsModal
        title={tTerms(`${currentShowingTermId}.Title`)}
        description={currentShowingTerm?.text}
        open={open}
        onClose={handleCloseTermsModal}
        onCloseButtonClick={handleCloseTermsModal}
      />
    </FormWithValidator>
  );
}
