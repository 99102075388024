import React from "react";
import styled from "styled-components";
import { Box, IconButton } from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { hhHourFormatStr } from "../create-poll/CreatePollSetting";

const SelectedEventListBox = styled(Box)``;
const SelectedEventListItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const SelectedEventListItemBox = styled(Box)`
  padding: 20px 20px 10px;
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color: #fff;
  position: relative;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SelectedEventListItemIndex = styled(Box)`
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  background-color: #4555ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 50%;
`;

const SelectedEventListItemContainer = styled(Box)`
  margin: 0 0 0 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const SelectedEventListItemDate = styled(Box)`
  font-size: 24px;
  font-weight: bold;
  color: #232323;
  margin-right: 8px;
`;
const SelectedEventListItemHourRange = styled(Box)`
  font-size: 18px;
  color: #333;
`;
const SelectedEventListItemOverlap = styled(Box)`
  font-size: 12px;
  color: #adadad;
`;

export interface SelectedEvent {
  id: string;
  start: Date;
  end: Date;
  overlap?: string;
}

interface SelectedEventListItemProps {
  event: SelectedEvent;
  index: number;
  onDelete?: (event: SelectedEvent) => void;
}

const SelectedEventListItem = ({
  event,
  index,
  onDelete,
}: SelectedEventListItemProps) => {
  return (
    <SelectedEventListItemBox>
      <SelectedEventListItemWrapper>
        <SelectedEventListItemIndex>{index + 1}</SelectedEventListItemIndex>
        <SelectedEventListItemContainer>
          <SelectedEventListItemDate>
            {moment(event.start).format("MM.DD dddd")}
          </SelectedEventListItemDate>
          <SelectedEventListItemHourRange>
            {moment(event.start).format(hhHourFormatStr) +
              " ~ " +
              moment(event.end).format(hhHourFormatStr)}
          </SelectedEventListItemHourRange>
        </SelectedEventListItemContainer>
      </SelectedEventListItemWrapper>
      {event?.overlap ? (
        <SelectedEventListItemOverlap>
          {"겹치는 일정"} : {event?.overlap}
        </SelectedEventListItemOverlap>
      ) : undefined}
      {onDelete ? (
        <IconButton
          aria-label="delete"
          color={"default"}
          onClick={() => onDelete(event)}
          size={"small"}
          sx={{ top: 0, right: 0, position: "absolute" }}
        >
          <CloseIcon />
        </IconButton>
      ) : undefined}
    </SelectedEventListItemBox>
  );
};

interface SelectedEventListProps {
  events: SelectedEvent[];
  onDelete?: (event: SelectedEvent) => void;
}

export const SelectedEventList = ({
  events,
  onDelete,
}: SelectedEventListProps) => {
  return (
    <SelectedEventListBox component="ul">
      {events.map((event, index) => (
        <SelectedEventListItem
          key={event.id}
          event={event}
          index={index}
          onDelete={onDelete}
        />
      ))}
    </SelectedEventListBox>
  );
};
