import validator from "validator";

export function isPassword(str: string) {
  return validator.isStrongPassword(str, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 0,
    minNumbers: 1,
    minSymbols: 1,
    returnScore: false,
  });
}
