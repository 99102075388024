import React from "react";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { EventWithGuests } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { EventInfoWithUrlView } from "../event/EventInfoWithUrlView";
import { EventGuestItemList } from "../event/EventGuestItemList";
import { useSortedGuestsFromEvent } from "../../hooks/useSortedGuestsFromEvent";
import { useIsDeletedOrFinishedEvent } from "../../hooks/useIsDeletedOrFinishedEvent";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";
import { StyledEventDetailContainer } from "../styled/StyledEventDetailContainer";

export interface CommonEventFormProps {
  me: string;
  eventInfo?: EventWithGuests;
  editEventButton?: React.ReactNode;
}

export function CommonEventForm({
  me,
  editEventButton,
  eventInfo,
}: CommonEventFormProps) {
  const isDesktop = useDesktopMatches();
  const { t } = useTranslation("commonEvent");
  const guests = useSortedGuestsFromEvent(eventInfo);
  const isDeletedOrFinished = useIsDeletedOrFinishedEvent(eventInfo);

  return (
    <ContainerWithTitle
      title={
        isDeletedOrFinished
          ? t("TitleWhenFinishedOrDeleted")
          : t("Title", {
              dateTime: eventInfo?.selected?.from_time
                ? moment(eventInfo.selected.from_time).format("llll")
                : "",
            })
      }
      titleDecorator={eventInfo?.title}
      titleComponent={"span"}
      rightButton={
        eventInfo?.is_host && !isDeletedOrFinished ? editEventButton : null
      }
    >
      <StyledEventDetailContainer isDesktop={isDesktop} component={"div"}>
        <EventInfoWithUrlView event={eventInfo} collapsible={false} />
        <EventGuestItemList
          me={me}
          guests={guests}
          collapsible={false}
          isAfterConfirm={true}
        />
      </StyledEventDetailContainer>
    </ContainerWithTitle>
  );
}
