import React, { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useLogOut } from "../../hooks/useLogOut";
import { SignUpForm, SignUpFormValue } from "../../components/forms/SignUpForm";
import {
  SignUpTerm,
  SignUpTermsForm,
  SignUpTermsValue,
} from "../../components/forms/SignUpTermsForm";
import {
  useGetServiceTermsQuery,
  usePostOauthRegisterMutation,
  usePostRegisterMutation,
} from "../../app/services/accounts/authApi";
import { usePostSendEmailMutation } from "../../app/services/accounts/verifyApi";
import { useErrorStatusCode } from "../../hooks/useErrorStatusCode";
import { UserResponse } from "../../app/services/accounts/types";
import { RequestVerificationContainer } from "../../components/forms/RequestVerificationContainer";

export const defaultTerms: SignUpTerm[] = [
  { id: "service", isRequired: true },
  { id: "privacy", isRequired: true },
  { id: "marketing", isRequired: false },
];

const defaultTermsValue: SignUpTermsValue = defaultTerms.reduce(
  (acc: SignUpTermsValue, cur: SignUpTerm) => {
    acc[cur.id] = false;
    return acc;
  },
  {}
);
export function SignUpPage() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { state } = useLocation<{ userFromLoginGoogle?: UserResponse }>();
  const { token } = useLogOut();
  const [userFromLoginGoogle, setUserFromLoginGoogle] = useState<
    UserResponse | undefined
  >(undefined);
  const [value, setValue] = useState<SignUpFormValue>({
    email: undefined,
    name: undefined,
    password: undefined,
    confirmPassword: undefined,
  });
  const [terms, setTerms] = useState<SignUpTerm[]>(defaultTerms);
  const [termsValue, setTermsValue] =
    useState<SignUpTermsValue>(defaultTermsValue);
  const [isTermsRequiredChecked, setIsTermsRequiredChecked] = useState(false);
  const { data: serviceTermsText } = useGetServiceTermsQuery();
  const [
    postRegister,
    {
      data: registerData,
      isSuccess: isSuccessRegister,
      isLoading: isLoadingRegister,
      error: errorRegister,
    },
  ] = usePostRegisterMutation();
  const [
    postOauthRegister,
    {
      data: registerOauthData,
      isSuccess: isSuccessOauthRegister,
      error: errorOauthRegister,
    },
  ] = usePostOauthRegisterMutation();
  const [postSendEmail, { isSuccess: isSuccessSendEmail }] =
    usePostSendEmailMutation();
  const errorStatusCode = useErrorStatusCode(
    errorRegister || errorOauthRegister
  );

  useEffect(() => {
    if (serviceTermsText) {
      setTerms((terms) => {
        return terms.map((term) => {
          // @ts-ignore
          return { ...term, text: serviceTermsText[term.id] };
        });
      });
    }
  }, [serviceTermsText]);

  useEffect(() => {
    setIsTermsRequiredChecked(
      defaultTerms.reduce((acc: boolean, cur: SignUpTerm) => {
        if (cur.isRequired) {
          return acc && termsValue[cur.id];
        }
        return acc;
      }, true)
    );
  }, [termsValue]);

  useEffect(() => {
    if (isSuccessRegister) {
      history.push(`${path}/verify`);
    }
    // ignore 'path'
    // eslint-disable-next-line
  }, [isSuccessRegister]);

  // oauth 기반 가입
  useEffect(() => {
    if (isSuccessOauthRegister && registerOauthData) {
      history.push(`/verify/google`, {
        userFromLoginGoogle: registerOauthData,
      });
    }
    // ignore history
    // eslint-disable-next-line
  }, [isSuccessOauthRegister, registerOauthData]);

  useEffect(() => {
    if (state?.userFromLoginGoogle) {
      // oauth 통해 들어온 경우 해당 값 저장
      const { email, name } = state.userFromLoginGoogle.user;
      setValue((prevState) => {
        return { ...prevState, email, name };
      });
      setUserFromLoginGoogle(state.userFromLoginGoogle);
    }
  }, [state?.userFromLoginGoogle]);

  const handleTermsValueChange = useCallback(
    (value: SignUpTermsValue) => setTermsValue(value),
    []
  );
  const handleSignUpTermsSubmit = useCallback(() => {
    if (isTermsRequiredChecked) history.push(`${path}/account`);
  }, [isTermsRequiredChecked, history, path]);
  const handleSignUpSubmit = useCallback(
    (value) => {
      if (value) {
        const { email, password, name } = value;
        if (email && password && name && isTermsRequiredChecked) {
          const payload = {
            email,
            password,
            name,
            agree_marketing: !!termsValue?.marketing,
          };
          // oauth 통과한 경우 postOauthRegister 사용
          if (userFromLoginGoogle && userFromLoginGoogle.token) {
            postOauthRegister({
              ...payload,
              token: userFromLoginGoogle.token,
            });
          } else {
            postRegister(payload);
          }
        }
      }
    },
    [
      postRegister,
      postOauthRegister,
      isTermsRequiredChecked,
      termsValue.marketing,
      userFromLoginGoogle,
    ]
  );
  const handleResendClick = useCallback(() => {
    if (isSuccessRegister && registerData?.token) {
      postSendEmail({ token: registerData.token });
    }
  }, [isSuccessRegister, registerData, postSendEmail]);

  return (
    <>
      {!token ? (
        <Switch>
          <Route exact={true} path={path}>
            <SignUpTermsForm
              value={termsValue}
              terms={terms}
              onChange={handleTermsValueChange}
              onSubmit={handleSignUpTermsSubmit}
            />
          </Route>
          <Route path={`${path}/account`}>
            <SignUpForm
              disabledKeys={userFromLoginGoogle ? ["email"] : undefined}
              value={value}
              errorStatusCode={errorStatusCode}
              onChange={setValue}
              onSubmit={handleSignUpSubmit}
            />
          </Route>
          <Route path={`${path}/verify`}>
            {isSuccessRegister && registerData ? (
              <RequestVerificationContainer
                email={registerData.user.email}
                name={registerData.user.name}
                bgColor={registerData.user.bg_color}
                isLoading={isLoadingRegister}
                isSuccess={isSuccessSendEmail}
                onSend={handleResendClick}
              />
            ) : (
              <Redirect to={path} />
            )}
          </Route>
        </Switch>
      ) : null}
    </>
  );
}
