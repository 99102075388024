import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Redirect } from "react-router-dom";

import {
  ResetPasswordForm,
  ResetPasswordFormValue,
} from "../../components/forms/ResetPasswordForm";
import { useUserMe } from "../../hooks/useUserMe";
import { useErrorStatusCode } from "../../hooks/useErrorStatusCode";
import { usePutResetPasswordWithUserMutation } from "../../app/services/accounts/authApi";

export function ResetPasswordWithUser() {
  const { data } = useUserMe({ failure: "/login" });
  const [value, setValue] = useState<ResetPasswordFormValue | undefined>(
    undefined
  );
  const [postResetPassword, { isSuccess, error: errorResetPassword }] =
    usePutResetPasswordWithUserMutation();
  const errorStatusCode = useErrorStatusCode(errorResetPassword);
  const handleSubmit = useCallback(
    (value) => {
      if (value?.password && value?.confirmPassword) {
        postResetPassword({
          password: value.password,
          password_check: value.confirmPassword,
        });
      }
    },
    [postResetPassword]
  );

  return data ? (
    isSuccess ? (
      <Redirect to={"/personal-info"} />
    ) : (
      <Box>
        <ResetPasswordForm
          value={value}
          errorStatusCode={errorStatusCode}
          onChange={(value) => setValue(value)}
          onSubmit={handleSubmit}
        />
      </Box>
    )
  ) : null;
}
