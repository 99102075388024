import { createApi } from "@reduxjs/toolkit/query/react";

import { baseUrl } from "./config";
import { UserResponseFromGoogle } from "./types";
import { fetchApiBaseQuery } from "../fetchApiBaseQuery";
import { useEffect } from "react";

export const googleApi = createApi({
  baseQuery: fetchApiBaseQuery({
    baseUrl: `${baseUrl}/google/`,
  }),
  reducerPath: "accounts/googleApi",
  endpoints: (build) => ({
    postGoogleCallback: build.mutation<
      UserResponseFromGoogle,
      { code: string; authuser: string }
    >({
      query(body) {
        return {
          url: "callback/",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { usePostGoogleCallbackMutation } = googleApi;

export function useGoogleLoginRedirect() {
  useEffect(() => {
    window.location.href = `${baseUrl}/google/login/`;
  }, []);
}
