import React, { useCallback, useEffect } from "react";
import styled from "@mui/styled-engine";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DateRangePicker, StaticDateRangePicker } from "@mui/lab";
import moment from "moment";

import { SettingBorder } from "../../../styled/layout/setting";
import { createPollSelectors, createPollSlice } from "./createPollSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLazyGetLatestSettingsQuery } from "../../../app/services/poll/pollApi";
import { PollHourOption } from "../../../app/services/poll/types";
import { PageTitle, SettingTitle } from "../../../styled/typography";
import { useHandleDateRangeChange } from "../common/hooks/useHandleDateRangeChange";

const CreatePollSettingBox = styled(Box)``;

const DurationSelect = styled(Select)`
  height: 48px;
  font-size: 16px;
  color: #333;
`;

export const hourRangePreset: {
  key: number;
  value: PollHourOption;
  label: string;
}[] = [
  {
    key: 0,
    value: "all",
    label: "없음",
  },
  {
    key: 1,
    value: "workhour",
    label: "근무시간",
  },
  {
    key: 2,
    value: "noon",
    label: "점심",
  },
  {
    key: 3,
    value: "evening",
    label: "저녁",
  },
];
export const hhHourFormatStr = "HH:mm";
export const kkHourFormatStr = "kk:mm";
export const formatHourToString = (hour: number, format: string) => {
  return moment()
    .set({ hour, minute: 0, second: 0, millisecond: 0 })
    .format(format);
};
export const parseHourToMoment = (
  hour: string,
  format: string = hhHourFormatStr
) => {
  return moment(hour, [format]).set({ second: 0, millisecond: 0 });
};

export const CreatePollSetting = () => {
  const dispatch = useAppDispatch();
  const { duration, startAt, endAt, hourOption, dateRange } = useAppSelector(
    createPollSelectors.selectState
  );
  const [
    getLatestSettings,
    { data: latestSettings, isLoading: isLatestSettingsLoading },
  ] = useLazyGetLatestSettingsQuery();

  const handleDurationSelect = useCallback(
    ({ target: { value } }: SelectChangeEvent) => {
      dispatch(createPollSlice.actions.setDuration(Number(value)));
      dispatch(createPollSlice.actions.removeSelectedEventsAll());
    },
    [dispatch]
  );

  const handleDateRangeChange = useHandleDateRangeChange();

  const handleStartHourSelect = useCallback(
    ({ target: { value } }: SelectChangeEvent) => {
      if (value.localeCompare(endAt) >= 0) {
        const maxEndAt = formatHourToString(24, kkHourFormatStr);
        let newEndAt = parseHourToMoment(endAt, kkHourFormatStr)
          .add(duration, "minute")
          .format(kkHourFormatStr);
        if (newEndAt.localeCompare(maxEndAt) >= 0) {
          newEndAt = maxEndAt;
        }
        dispatch(createPollSlice.actions.setEndAt(newEndAt));
      }
      dispatch(createPollSlice.actions.setStartAt(value));
      dispatch(createPollSlice.actions.removeSelectedEventsAll());
    },
    [dispatch, endAt, duration]
  );

  const handleEndHourSelect = useCallback(
    ({ target: { value } }: SelectChangeEvent) => {
      if (value.localeCompare(startAt) < 0) {
        const minStartAt = formatHourToString(0, hhHourFormatStr);
        let newStartAt = parseHourToMoment(startAt, hhHourFormatStr)
          .subtract(duration, "minute")
          .format(hhHourFormatStr);

        if (newStartAt.localeCompare(minStartAt) < 0) {
          newStartAt = minStartAt;
        }
        dispatch(createPollSlice.actions.setStartAt(newStartAt));
      }
      dispatch(createPollSlice.actions.setEndAt(value));
      dispatch(createPollSlice.actions.removeSelectedEventsAll());
    },
    [dispatch, startAt, duration]
  );

  useEffect(() => {
    getLatestSettings(); // 최초에 기존 세팅 불러오기
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (latestSettings) {
      const { duration, start_at, end_at, hour_option } = latestSettings;
      if (duration) dispatch(createPollSlice.actions.setDuration(duration));
      if (start_at) dispatch(createPollSlice.actions.setStartAt(start_at));
      if (end_at) dispatch(createPollSlice.actions.setEndAt(end_at));
      if (hour_option)
        dispatch(createPollSlice.actions.setHourOption(hour_option));
    }
  }, [dispatch, latestSettings]);

  return (
    <CreatePollSettingBox>
      <PageTitle sx={{ marginBottom: "50px" }}>새로운 이벤트 생성</PageTitle>
      <SettingTitle>
        이벤트 소요 시간 <span className="required">*</span>
      </SettingTitle>
      <DurationSelect
        sx={{ width: "100%" }}
        labelId="duration-select-label"
        id="duration-select"
        value={duration}
        // @ts-ignore
        onChange={handleDurationSelect}
      >
        {Array.from({ length: 24 }, (_, i) => (i + 1) * 60).map((value) => (
          <MenuItem value={value} key={value}>
            {moment.duration(value, "minutes").humanize({ h: 25 })}
          </MenuItem>
        ))}
      </DurationSelect>
      <SettingBorder />
      <SettingTitle>이벤트 희망 기간</SettingTitle>
      <DateRangePicker
        value={dateRange}
        loading={isLatestSettingsLoading}
        onChange={handleDateRangeChange}
        renderInput={(
          { label: startLabel, ...startProps },
          { label: endLabel, ...endProps }
        ) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/*@ts-ignore*/}
            <OutlinedInput {...startProps} sx={{ height: "48px" }} />
            <Box sx={{ mx: 2 }}> ~ </Box>
            {/*@ts-ignore*/}
            <OutlinedInput {...endProps} sx={{ height: "48px" }} />
          </Box>
        )}
      />
      <Box sx={{ height: "40px" }} />
      {/*@ts-ignore*/}
      <StaticDateRangePicker
        calendars={1}
        displayStaticWrapperAs="desktop"
        value={dateRange}
        loading={isLatestSettingsLoading}
        onChange={handleDateRangeChange}
      />
      <SettingBorder />
      <SettingTitle>이벤트 희망 시간대</SettingTitle>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DurationSelect
          sx={{ width: "100%" }}
          labelId="duration-select-label"
          id="duration-select"
          value={startAt}
          /*@ts-ignore*/
          onChange={handleStartHourSelect}
        >
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => {
            const value = formatHourToString(hour, hhHourFormatStr);
            return (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            );
          })}
        </DurationSelect>
        <Box sx={{ mx: 2 }}> ~ </Box>
        <DurationSelect
          sx={{ width: "100%" }}
          labelId="duration-select-label"
          id="duration-select"
          value={endAt}
          /*@ts-ignore*/
          onChange={handleEndHourSelect}
        >
          {Array.from({ length: 24 }, (_, i) => i + 1).map((hour) => {
            const value = formatHourToString(hour, kkHourFormatStr);
            return (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            );
          })}
        </DurationSelect>
      </Box>
      <Box>
        <FormGroup sx={{ flexDirection: "row", marginTop: "12px" }}>
          {hourRangePreset.map(({ key, label, value }) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  value
                  checked={value === hourOption}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(createPollSlice.actions.setHourOption(value));
                    }
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={label}
            />
          ))}
        </FormGroup>
      </Box>
      <SettingBorder />
      <SettingTitle>구글 캘린더</SettingTitle>
    </CreatePollSettingBox>
  );
};
