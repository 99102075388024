import React from "react";
import { Page } from "../../../layout/Page";
import { SelectEventMain } from "./SelectEventMain";
import { SelectEventSetting } from "./SelectEventSetting";
import { useParams } from "react-router-dom";
import { useGetPollInfoByKeyQuery } from "../../../app/services/poll/pollApi";
import { skipToken } from "@reduxjs/toolkit/query";

export const SelectEventPage = () => {
  const { eventKey } = useParams<{ eventKey: string }>();

  const { data: pollInfo } = useGetPollInfoByKeyQuery(
    eventKey ? { key: eventKey } : skipToken
  );

  return (
    <Page
      main={<SelectEventMain pollInfo={pollInfo} />}
      setting={<SelectEventSetting pollInfo={pollInfo} />}
      settingStyle={{ backgroundColor: "#f8fafb" }}
    />
  );
};
