import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Prompt, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useUserMe } from "../../hooks/useUserMe";
import { CreateEventForm } from "../../components/forms/CreateEventForm";
import {
  useGetRecentEmailsMutation,
  usePostCreateEventMutation,
  usePostEventsInfoQuery,
  usePostInviteGuestMutation,
  usePostInviteResendMutation,
} from "../../app/services/events/eventApi";
import { CreateEventRequest } from "../../app/services/events/types";
import {
  InviteGuestsToEventForm,
  InviteGuestsToEventFormValue,
} from "../../components/forms/InviteGuestsToEventForm";
import { CreateEventCompleteForm } from "../../components/forms/CreateEventCompleteForm";
import { CreateEventPageCalendarDrawer } from "./CreateEventPageCalendarDrawer";
import { useAppSelector } from "../../app/hooks";
import { authSelectors } from "../auth/authSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  EventInputListValue,
  eventInputListValueKeys,
  initEventInputListValue,
} from "../../components/event/EventInputList";
import { EditEventButtonWithModals } from "./EditEventButtonWithModals";
import { isDeletedOrFinishedEvent } from "../../utils/isFinishedEvent";

const initInvitationValue: InviteGuestsToEventFormValue = {
  input: undefined,
  guests: [],
};

const translationNamespaces = {
  createEvent: { ns: "createEvent" },
  inviteGuestsToEvent: { ns: "inviteGuestsToEvent" },
};

export function CreateEventPage() {
  const { t } = useTranslation([
    translationNamespaces.createEvent.ns,
    translationNamespaces.inviteGuestsToEvent.ns,
  ]);
  const { user } = useAppSelector(authSelectors.selectState);
  const [postInviteResend] = usePostInviteResendMutation();
  const [postInviteGuest] = usePostInviteGuestMutation();
  const { isLoginSuccess: isUserMeSuccess, data: userData } = useUserMe({
    failure: "/login",
  });
  const [postEventNew, { data: eventData, isSuccess: isCreateSuccess }] =
    usePostCreateEventMutation();
  const [getRecentEmails, { data: recentEmails }] =
    useGetRecentEmailsMutation();
  const { data: eventInfo /*,refetch: refetchEventInfo */ } =
    usePostEventsInfoQuery(
      eventData
        ? {
            id: eventData.id,
          }
        : skipToken
    );
  const [value, setValue] = useState<EventInputListValue>(
    initEventInputListValue
  );
  const [invitationValue, setInvitationValue] =
    useState<InviteGuestsToEventFormValue>(initInvitationValue);
  const [formIsHalfFilledOut, setFormIsHalfFilledOut] = useState(false);
  const [openCalendarDrawer, setOpenCalendarDrawer] = useState(false);
  const [showBeforeUnloadPrompt, setShowBeforeUnloadPrompt] = useState(false);
  const isCompleteFormOpen = useMemo(() => {
    return !!(isCreateSuccess && eventData && userData);
  }, [isCreateSuccess, eventData, userData]);
  const handleEventChange = useCallback((value: EventInputListValue) => {
    setValue(value);
  }, []);
  const handleEventSubmit = useCallback(
    (value: EventInputListValue) => {
      if (value) {
        const { title, duration_minutes } = value;
        if (title !== undefined && duration_minutes !== undefined) {
          postEventNew({
            ...value,
            title,
            duration_minutes,
            guests: invitationValue?.guests || [],
          } as CreateEventRequest);
        }
      }
    },
    [invitationValue?.guests, postEventNew]
  );
  const handleInvitationChange = useCallback(
    (value: InviteGuestsToEventFormValue) => {
      setInvitationValue(value);
    },
    []
  );
  const handleCalendarDrawerOpen = useCallback(() => {
    setOpenCalendarDrawer(true);
  }, []);
  const handleCalendarDrawerClose = useCallback(() => {
    setOpenCalendarDrawer(false);
  }, []);

  // FIXME: HostEventPage와 중복 코드
  const handleGuestDelete = useCallback(
    (guest) => {
      if (eventInfo) {
        const others = eventInfo.guests.filter(
          (d) => d.email !== guest.email && !d.is_host
        );
        postInviteGuest({ id: eventInfo.id, guests: others });
      }
    },
    [eventInfo, postInviteGuest]
  );

  const handleMailSend = useCallback(
    (guest) => {
      if (eventInfo) {
        postInviteResend({ id: eventInfo.id, email: guest.email });
      }
    },
    [eventInfo, postInviteResend]
  );
  useEffect(() => {
    return () => {
      window.onbeforeunload = null; // reset
    };
  }, []);
  useEffect(() => {
    if (isUserMeSuccess) {
      getRecentEmails();
    }
  }, [getRecentEmails, isUserMeSuccess]);

  useEffect(() => {
    for (const valueKey of eventInputListValueKeys) {
      if (value[valueKey] !== undefined && value[valueKey] !== "") {
        return setFormIsHalfFilledOut(true);
      }
    }
    return setFormIsHalfFilledOut(false);
  }, [value]);

  useEffect(() => {
    setShowBeforeUnloadPrompt(formIsHalfFilledOut && !isCreateSuccess);
  }, [formIsHalfFilledOut, isCreateSuccess]); // FIXME : hook 으로 빼서 교체

  useEffect(() => {
    if (showBeforeUnloadPrompt) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [showBeforeUnloadPrompt]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isCompleteFormOpen]);

  return (
    <>
      {eventInfo && isDeletedOrFinishedEvent(eventInfo) ? (
        // 삭제된 경우
        <Redirect to={`/event/${eventInfo.id}`} />
      ) : isCompleteFormOpen && eventInfo ? (
        // 생성된 경우
        <>
          <CreateEventCompleteForm
            user={user}
            event={eventInfo}
            editEventButton={
              <EditEventButtonWithModals eventInfo={eventInfo} />
            }
            onGuestDelete={handleGuestDelete}
            onMailSend={handleMailSend}
            onSelectSchedulesButtonClick={handleCalendarDrawerOpen}
          />
          <CreateEventPageCalendarDrawer
            /*
            // @ts-ignore */
            me={userData.user.email}
            /*
            // @ts-ignore */
            event={eventData}
            open={openCalendarDrawer}
            onClose={handleCalendarDrawerClose}
          />
        </>
      ) : isUserMeSuccess && userData ? (
        // 인증 후 생성 화면
        <>
          <CreateEventForm
            hostName={userData.user.name}
            value={value}
            onChange={handleEventChange}
            onSubmit={handleEventSubmit}
          >
            <InviteGuestsToEventForm
              recentEmails={recentEmails}
              value={invitationValue}
              onChange={handleInvitationChange}
            />
          </CreateEventForm>
        </>
      ) : null}
      <Prompt
        when={showBeforeUnloadPrompt}
        message={t("BeforeUnload", translationNamespaces.createEvent)}
      />
    </>
  );
}
