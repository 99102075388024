import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { UnAuthGuestEventPage } from "./UnAuthGuestEventPage";
import { HostEventPage } from "./HostEventPage";
import { GuestEventPage } from "./GuestEventPage";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../../app/hooks";
import { authSelectors } from "../auth/authSlice";
import { usePostEventsInfoQuery } from "../../app/services/events/eventApi";
import { CommonEventPage } from "./CommonEventPage";
import { useErrorStatusCode } from "../../hooks/useErrorStatusCode";

export interface EventPageParams {
  eventId: string;
}

export interface EventPageWithMeParams extends EventPageParams {
  me: { email: string; key?: string };
}

/*
이벤트 페이지 진입 시 분기를 처리 
 */
export function EventPage() {
  const { eventId } = useParams<EventPageParams>();
  const { user } = useAppSelector(authSelectors.selectState);
  const { error: eventInfoError, data: eventInfoData } = usePostEventsInfoQuery(
    user
      ? {
          id: eventId,
        }
      : skipToken
  );
  const eventInfoErrorStatusCode = useErrorStatusCode(eventInfoError);
  return (
    <>
      {!user || eventInfoError ? (
        eventInfoErrorStatusCode === 404 ? (
          <Redirect to={"/empty"} />
        ) : (
          <UnAuthGuestEventPage eventId={eventId} />
        )
      ) : eventInfoData ? (
        eventInfoData.selected ? (
          <CommonEventPage eventId={eventId} me={{ email: user.email }} />
        ) : eventInfoData.is_host ? (
          <HostEventPage eventId={eventId} me={{ email: user.email }} />
        ) : (
          <GuestEventPage eventId={eventId} me={{ email: user.email }} />
        )
      ) : null}
    </>
  );
}
