import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventBase, UpdateEventRequest } from "../../app/services/events/types";
import { EventInputList, EventInputListValue } from "../event/EventInputList";
import { FormBox } from "../commons/FormBox";
import { StyledCommonModalForEdit } from "../styled/StyledCommonModalForEdit";

const disabledKeys = ["title" as const, "duration_minutes" as const];
export interface EditEventModalProps {
  open: boolean;
  event?: EventBase;
  onClose: () => void;
  onSave: (event?: UpdateEventRequest) => void;
}

export function EditEventModal({
  open,
  event,
  onClose,
  onSave,
}: EditEventModalProps) {
  const { t } = useTranslation("editEvent");
  const [eventInputListValue, setEventInputListValue] = useState<
    EventInputListValue | undefined
  >(undefined);
  const handleChange = useCallback((value) => {
    setEventInputListValue(value);
  }, []);
  const handleConfirm = useCallback(() => {
    if (onSave && event && eventInputListValue !== undefined) {
      onSave({
        ...event,
        title: eventInputListValue.title as string,
        description: eventInputListValue.description,
        event_type: eventInputListValue.event_type,
        place_detail: eventInputListValue.place_detail,
        duration_minutes: eventInputListValue.duration_minutes as number,
      });
    }
  }, [onSave, event, eventInputListValue]);

  useEffect(() => {
    if (event) {
      setEventInputListValue({
        title: event.title,
        description: event.description || undefined,
        event_type: event.event_type || undefined,
        place_detail: event.place_detail || undefined,
        duration_minutes: event.duration_minutes || undefined,
      });
    } else {
      setEventInputListValue(undefined);
    }
  }, [event]);

  return (
    <StyledCommonModalForEdit
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("Title")}
      width={565}
      onConfirm={handleConfirm}
      confirmButtonText={t("Save")}
      confirmButtonColor="secondary"
      confirmButtonDisabled={false}
      cancelButtonText={t("Cancel")}
      cancelButtonColor="normal"
    >
      <FormBox noMinusMargin={true}>
        {eventInputListValue && (
          <EventInputList
            value={eventInputListValue}
            onChange={handleChange}
            disabledKeys={disabledKeys}
          />
        )}
      </FormBox>
    </StyledCommonModalForEdit>
  );
}
