import React, { useCallback } from "react";
import styled, { css } from "@mui/styled-engine";
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  TextField,
} from "@mui/material";
import { StyledAvatar } from "../../../components/styled/StyledAvatar";
import EditIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useDesktopMatches } from "../../../hooks/useDesktopMatches";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";

const StyledGuestItemContent = styled(Box)<{
  isDesktop: boolean;
}>`
  display: flex;
  flex: 1 1 auto;
  ${(props) =>
    props.isDesktop
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : css`
          flex-direction: column;
        `}
  > .guest-item-content {
    &-email {
      width: 172px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-divider {
      width: 1px;
      height: 21px;
      margin: 0 30px;
      background-color: #d1d1d1;
    }

    &-name {
      display: inline-flex;
      align-items: center;
      & input {
        width: auto;
        padding: 0;
      }
    }
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
`;

interface GuestInformationInputProps {
  value?: string;
  placeholder: string;
  onChange?: (event: any) => void;
}

const GuestInformationInput = ({
  value,
  placeholder,
  onChange,
}: GuestInformationInputProps) => {
  return (
    <Box className="guest-item-content-name" component="label">
      <StyledTextField
        sx={{
          width: "130px",
        }}
        defaultValue={value}
        variant="standard"
        placeholder={placeholder}
        onChange={onChange}
      />
      {onChange ? (
        <EditIcon
          sx={{
            width: "16px",
            color: "#767676",
            cursor: "pointer",
            marginLeft: "8px",
          }}
        />
      ) : undefined}
    </Box>
  );
};

interface GuestInputItemProps {
  index: number;
  guest: PollEventGuestCandidate;
  onInputChange?: (guest: PollEventGuestCandidate, index: number) => void;
  onDelete: (guest: PollEventGuestCandidate, index: number) => void;
}

export const GuestInputListItem = ({
  index,
  guest,
  onInputChange,
  onDelete,
}: GuestInputItemProps) => {
  const isDesktop = useDesktopMatches();

  const handleItemChange = useCallback(
    (field: string) => (event: any) => {
      const value = event.target.value;
      onInputChange?.(
        {
          ...guest,
          [field]: value,
        },
        index
      );
    },
    [onInputChange, index, guest]
  );

  const handleItemDelete = useCallback(() => {
    onDelete(guest, index);
  }, [guest, index, onDelete]);

  return (
    <ListItem
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: "16px",
        paddingBottom: "16px",
        borderBottom: "solid 1px #e1e1e1",
        "&:last-of-type": {
          borderBottom: 0,
          paddingBottom: 0,
        },
      }}
    >
      <ListItemAvatar>
        <StyledAvatar bgColor={"gray"}>
          {guest.name.substr(0, 1).toUpperCase()}
        </StyledAvatar>
      </ListItemAvatar>
      <StyledGuestItemContent isDesktop={isDesktop}>
        <GuestInformationInput value={guest.name} placeholder={"이름 입력"} />
        <Box className="guest-item-content-divider" component="span" />
        <GuestInformationInput
          value={guest.email}
          placeholder={"이메일 입력"}
          onChange={handleItemChange("email")}
        />
        <Box className="guest-item-content-divider" component="span" />
        <GuestInformationInput
          value={guest.phone_number}
          placeholder={"전화번호 입력"}
          onChange={handleItemChange("phone_number")}
        />
      </StyledGuestItemContent>
      <IconButton
        aria-label="delete"
        color={"error"}
        onClick={handleItemDelete}
        sx={{ marginRight: "-8px" }}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  );
};
