import React, { useCallback, useState } from "react";
import { EventPageParams } from "./EventPage";
import { useLogOut } from "../../hooks/useLogOut";
import { skipToken } from "@reduxjs/toolkit/query";
import { Redirect } from "react-router-dom";
import { VerifyGuestForm } from "../../components/forms/VerifyGuestForm";
import { Box } from "@mui/material";
import { GuestEmailAndKey } from "../../app/services/events/types";
import { GuestEventPage } from "./GuestEventPage";
import { useErrorStatusCode } from "../../hooks/useErrorStatusCode";
import {
  useGetGuestsEmailsQuery,
  usePostGuestsVerifyMutation,
  usePostEventsInfoQuery,
} from "../../app/services/events/eventApi";
import { CommonEventPage } from "./CommonEventPage";

export interface UnAuthGuestEventPageProps extends EventPageParams {}

/*
게스트 일정 선택 페이지
 */
export function UnAuthGuestEventPage({ eventId }: UnAuthGuestEventPageProps) {
  const { user } = useLogOut();
  const {
    data: guests,
    isError: isGetGuestsError,
    isSuccess: isGetGuestsSuccess,
  } = useGetGuestsEmailsQuery(user ? skipToken : { id: eventId });
  const [
    postGuestVerify,
    { isSuccess: isVerifySuccess, isError: isVerifyError, error: verifyError },
  ] = usePostGuestsVerifyMutation();

  const [submittedGuest, setSubmittedGuest] = useState<
    GuestEmailAndKey | undefined
  >(undefined);

  const {
    data: eventInfo,
    error: eventInfoError /*,refetch: refetchEventInfo */,
  } = usePostEventsInfoQuery(
    isVerifySuccess && submittedGuest
      ? {
          id: eventId,
          emailAndKey:
            submittedGuest.key !== undefined ? submittedGuest : undefined,
        }
      : skipToken
  );

  const verifyErrorStatusCode = useErrorStatusCode(
    isVerifyError && verifyError
  );
  const eventInfoErrorStatusCode = useErrorStatusCode(eventInfoError);
  const handleSubmit = useCallback(
    (guest: GuestEmailAndKey) => {
      setSubmittedGuest(guest);
      postGuestVerify({ id: eventId, ...guest });
    },
    [postGuestVerify, eventId]
  );

  return user ? null : isGetGuestsError ||
    (eventInfoError && eventInfoErrorStatusCode === 404) ||
    (isGetGuestsSuccess && guests === null) ? (
    <Redirect to={"/empty"} />
  ) : isVerifySuccess && submittedGuest && eventInfo ? (
    eventInfo.selected ? (
      <CommonEventPage me={submittedGuest} eventId={eventId} />
    ) : (
      <GuestEventPage me={submittedGuest} eventId={eventId} />
    )
  ) : (
    <Box>
      {isGetGuestsSuccess && guests && guests.length > 0 ? (
        <VerifyGuestForm
          guests={guests}
          errorStatusCode={verifyErrorStatusCode}
          onSubmit={handleSubmit}
        />
      ) : null}
    </Box>
  );
}
