import { createContext, useContext } from "react";

interface LayoutContextValueTypes {
  isDesktop?: boolean;
  headerHeight?: number;
  platform?: "desktop" | "mobile";
  isSettingOpenOnMobile?: boolean;
  setSettingOpenOnMobile?: (value: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextValueTypes>({
  isDesktop: false,
  headerHeight: 0,
  platform: "desktop",
  isSettingOpenOnMobile: false,
  setSettingOpenOnMobile: (value) => {},
});

export const useLayoutContext = () => useContext(LayoutContext);
