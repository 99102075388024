import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { FormBox } from "../commons/FormBox";
import { InvitationToEventMailModal } from "../modal/InvitationToEventMailModal";
import { EventInputList, EventInputListValue } from "../event/EventInputList";

export interface CreateEventFormProps {
  hostName: string;
  value: EventInputListValue;
  errorStatusCode?: number;
  onChange?: (value: EventInputListValue) => void;
  onSubmit?: (value: EventInputListValue) => void;
  children?: React.ReactNode;
}

export function CreateEventForm({
  hostName,
  value,
  children,
  onChange,
  onSubmit,
}: CreateEventFormProps) {
  const { t } = useTranslation("createEvent");
  const [isAfterSubmit, setIsAfterSubmit] = useState<boolean>(false);

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState<boolean>(false);
  const [isTotalValid, setIsTotalValid] = useState<boolean>(false);
  const handleSubmit = useCallback(() => {
    setIsAfterSubmit(true);
    if (onSubmit && isTotalValid) {
      onSubmit(value);
    }
  }, [onSubmit, value, isTotalValid]);

  const handlePreviewModalOpen = useCallback(() => {
    setIsOpenPreviewModal(true);
  }, []);

  const handlePreviewModalClose = useCallback(() => {
    setIsOpenPreviewModal(false);
  }, []);

  const handleValidationChange = useCallback((validation) => {
    setIsTotalValid(validation.isTotalValid);
  }, []);

  useEffect(() => {
    setIsAfterSubmit(false);
  }, [value]);

  return (
    <>
      <ContainerWithTitle>
        <ContainerWithTitle
          title={t("Title")}
          description={t("Description")}
          descriptionMarginTop={3}
          noTopPadding={true}
          isWrapper={true}
        >
          <FormBox>
            <EventInputList
              value={value}
              isAfterSubmit={isAfterSubmit}
              onChange={onChange}
              onValidationChange={handleValidationChange}
            />
            <Box component="div" sx={{ textAlign: "right" }}>
              <Button
                variant={"cta"}
                color={"secondary"}
                type={"submit"}
                disabled={!isTotalValid}
                sx={{
                  width: 144,
                  height: 48,
                }}
                onClick={handlePreviewModalOpen}
              >
                {t("Preview")}
              </Button>
            </Box>
          </FormBox>
        </ContainerWithTitle>
        {children}
        <Box component="div" className="footer" sx={{ paddingBottom: "80px" }}>
          <Button
            fullWidth
            onClick={handleSubmit}
            type={"submit"}
            variant="cta"
            color="primary"
          >
            {t("Create")}
          </Button>
        </Box>
      </ContainerWithTitle>
      <InvitationToEventMailModal
        open={isOpenPreviewModal}
        onClose={handlePreviewModalClose}
        onCloseButtonClick={handlePreviewModalClose}
        hostName={hostName}
        event={value}
        guestMail={"guest@sample.com"}
      />
    </>
  );
}
