import React from "react";
import { Item, Span } from "react-html-email";
import { EmailCommonProps } from "./types";

const emailFooterTextStyle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#333",
};

export interface EmailFooterTexProps extends EmailCommonProps {}
export function EmailFooterText({ children, style }: EmailFooterTexProps) {
  return (
    <Item style={style}>
      <Span {...emailFooterTextStyle}>{children}</Span>
    </Item>
  );
}
