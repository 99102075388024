import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import * as locales from "@mui/material/locale";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import InvyCustomTheme, { HeaderHeight } from "./mui/theme";
import styled from "@mui/styled-engine";
import { useSnackbar } from "notistack";

import { GlobalAppBar } from "./features/common/GlobalAppBar";
import { useMuiLocale } from "./hooks/useMuiLocale";
import { SignUpPage } from "./features/auth/SignUpPage";
import { LogInPage } from "./features/auth/LogInPage";
import { LogOutPage } from "./features/auth/LogOutPage";
import { VerifyPage } from "./features/auth/VerifyPage";
import { FindPasswordPage } from "./features/auth/FindPasswordPage";
import { ResetPasswordWithTokenPage } from "./features/auth/ResetPasswordWithTokenPage";
import { LoginGooglePage } from "./features/auth/LoginGooglePage";
import { CreateEventPage } from "./features/event/CreateEventPage";
import { WorkspacePage } from "./features/workspace/WorkspacePage";
import { CalendarPage } from "./features/calendar/CalendarPage";
import { EventPage } from "./features/event/EventPage";
import { EventDetailPage } from "./features/event/EventDetailPage";
import { EmptyPage } from "./features/common/EmptyPage";
import ScrollToTop from "./components/commons/ScrollToTop";
import { PersonalInfoPage } from "./features/personalInfo/PersonalInfoPage";
import { useDesktopMatches } from "./hooks/useDesktopMatches";
import { ResetPasswordWithUser } from "./features/auth/ResetPasswordWithUserPage";
import { LeavePage } from "./features/auth/LeavePage";
import { ResendVerificationPage } from "./features/auth/ResendVerificationPage";
import { VerifyPageGoogle } from "./features/auth/VerifyPageGoogle";
import { useAppSelector } from "./app/hooks";
import { globalSliceSelectors } from "./features/global/globalSlice";
import { useTranslation } from "react-i18next";
import { WelcomePage } from "./features/welcome/WelcomPage";
import { CreatePollPage } from "./features/poll/create-poll/CreatePollPage";
import { CreatePollEventPage } from "./features/poll/create-event/CreatePollEventPage";
import { CompletePollEventPage } from "./features/poll/complete-poll/CompletePollEventPage";
import { SelectEventPage } from "./features/poll/select-event/SelectEventPage";
import { SelectCompletePage } from "./features/poll/select-complete/SelectCompletePage";

export const AppContainer = styled(Box)<{
  isDesktop: boolean;
}>`
  padding: ${(props) =>
      props.isDesktop ? HeaderHeight.DESKTOP : HeaderHeight.MOBILE}px
    0 100px;
`;

function App() {
  const { t: tError } = useTranslation("errorMessages");
  const { errorMessage } = useAppSelector(globalSliceSelectors.selectState);
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useDesktopMatches();
  const locale = useMuiLocale();
  const theme = createTheme(InvyCustomTheme, locales[locale]);

  useEffect(() => {
    if (errorMessage) {
      const messagePath = `${errorMessage.reducerPath}.${errorMessage.endpointName}`;
      const tErrorMessage = tError(messagePath);
      if (tErrorMessage !== messagePath) {
        // FIXME: i18n.exists 가 작동 하지 않음
        enqueueSnackbar(tError(messagePath));
      }
    }
  }, [tError, enqueueSnackbar, errorMessage]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <GlobalAppBar />
          <AppContainer isDesktop={isDesktop}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/">
                <WorkspacePage />
              </Route>
              <Route path={"/welcome"}>
                <WelcomePage />
              </Route>
              <Route path={"/poll/create"}>
                <CreatePollPage />
              </Route>
              <Route path={"/poll/event"}>
                <CreatePollEventPage />
              </Route>
              <Route path={"/poll/:eventId/complete"}>
                <CompletePollEventPage />
              </Route>
              <Route path={"/poll/complete"}>
                <SelectCompletePage />
              </Route>
              <Route path={"/poll/:eventKey"}>
                <SelectEventPage />
              </Route>
              <Route path={"/sign-up"}>
                <SignUpPage />
              </Route>
              <Route path={"/login/google"}>
                <LoginGooglePage />
              </Route>
              <Route path={"/login"}>
                <LogInPage />
              </Route>
              <Route path={"/logout"}>
                <LogOutPage />
              </Route>
              <Route path={"/verify/resend"}>
                <ResendVerificationPage />
              </Route>
              <Route path={"/verify/google"}>
                <VerifyPageGoogle />
              </Route>
              <Route path={"/verify/:token"}>
                <VerifyPage />
              </Route>
              <Route path={"/find/password"}>
                <FindPasswordPage />
              </Route>
              <Route path={"/reset/password"}>
                <ResetPasswordWithUser />
              </Route>
              <Route path={"/reset/:token"}>
                <ResetPasswordWithTokenPage />
              </Route>
              <Route path={"/event/new"}>
                <CreateEventPage />
              </Route>
              <Route exact path={"/event/:eventId"}>
                <EventPage />
              </Route>
              <Route exact path={"/event/detail/:eventId"}>
                <EventDetailPage />
              </Route>
              <Route path={"/personal-info"}>
                <PersonalInfoPage />
              </Route>
              <Route path={"/leave"}>
                <LeavePage />
              </Route>
              <Route path={"/calendar"}>
                <CalendarPage />
              </Route>
              <Route path={"/empty"}>
                <EmptyPage />
              </Route>
              <Route path={"*"}>
                <EmptyPage />
              </Route>
            </Switch>
          </AppContainer>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
