import React, { ComponentProps, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Autocomplete, Box, Button, Card, List, Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { GuestWithBgColor } from "../../../app/services/events/types";
import { TextFieldWithValidation } from "../../../components/inputs/TextFieldWithValidation";
import styled from "@mui/styled-engine";
import { useDesktopMatches } from "../../../hooks/useDesktopMatches";
import { GuestInputListItem } from "./GuestInputListItem";
import { RecentlyInvitedGuestList } from "./RecentlyInvitedGuestList";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";

export const GuestAddModalContainer = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

export const StyledCommonCard = styled(Card)<{ width: number }>`
  max-width: ${(props) => props.width}px;
  width: 100%;
  padding: 30px 25px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .guest-add-modal {
    &-header {
      position: relative;
      font-size: 24px;
      font-weight: 600;
      color: #232323;
      margin-bottom: 20px;
    }
    &-description {
      font-size: 14px;
      font-weight: 500;
      color: #232323;
      margin-bottom: 30px;
    }
    &-content {
      margin-bottom: 10px;
      max-height: calc(100vh - 220px);
      overflow-y: auto;
    }
    &-footer {
      margin-top: 20px;
      align-self: flex-end;
      width: 300px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      column-gap: 10px;
      & > button {
        white-space: nowrap;
        height: 48px;
      }
    }
  }
`;

export interface InviteGuestsToEventFormValue {
  input?: GuestWithBgColor;
  guests?: GuestWithBgColor[];
}

export interface InviteGuestsToEventFormProps {
  recentlyInvitedGuests: PollEventGuestCandidate[];
  noMinusMargin?: boolean;
  isAddLoading?: boolean;
  onClose: ComponentProps<typeof Modal>["onClose"];
  onSubmit: (guests: PollEventGuestCandidate[]) => void;
  open: boolean;
}

//const maxGuestsSize = 20;

export function GuestAddModal({
  open,
  isAddLoading,
  recentlyInvitedGuests,
  onClose,
  onSubmit,
}: InviteGuestsToEventFormProps) {
  const { t } = useTranslation("inviteGuestsToEvent");
  const [inputValue, setInputValue] = useState("");
  const [guests, setGuests] = useState<PollEventGuestCandidate[]>([]);

  useEffect(() => {
    if (!open) {
      setInputValue("");
      setGuests([]);
    }
  }, [open]); //{ email: "landkid12@gmail.com", name: "Douglas", bg_color: "#ff0000" },

  const isDesktop = useDesktopMatches();

  const handleRecentlyInvitedGuestClick = useCallback(
    (guest: PollEventGuestCandidate) => {
      setGuests((guests) => [...guests, guest]);
      setInputValue("");
    },
    []
  );
  const handleGuestNameInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleGuestAdd = useCallback(() => {
    if (inputValue && inputValue.length > 0) {
      setGuests((guests) => [...guests, { name: inputValue }]);
      setInputValue("");
    }
  }, [inputValue]);

  const handleGuestNameInputKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleGuestAdd();
      }
    },
    [handleGuestAdd]
  );

  const handleGuestItemChange = useCallback(
    (_guest: PollEventGuestCandidate, index: number) => {
      setGuests((_guests) => {
        _guests[index] = _guest;
        return [..._guests];
      });
    },
    []
  );

  const handleGuestItemDelete = useCallback(
    (_guest: PollEventGuestCandidate, index: number) => {
      setGuests((_guests) => {
        return [..._guests.slice(0, index), ..._guests.slice(index + 1)];
      });
    },
    []
  );

  const handleGuestItemsSubmit = useCallback(() => {
    onSubmit(guests);
  }, [guests, onSubmit]);

  return (
    <GuestAddModalContainer open={open} onClose={onClose}>
      <StyledCommonCard width={872}>
        <Box className="guest-add-modal-header">{"게스트 등록하기"}</Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            marginBottom: "30px",
          }}
        >
          <Autocomplete
            sx={{
              flex: 1,
              "& input": {
                height: 16,
              },
            }}
            freeSolo={true}
            disableClearable={true}
            value={inputValue}
            onChange={handleGuestNameInputChange}
            inputValue={inputValue}
            onInputChange={handleGuestNameInputChange}
            options={[]}
            getOptionLabel={(option: string) => {
              return option;
            }}
            renderInput={(params) => (
              <TextFieldWithValidation
                {...params}
                placeholder={t("labels.NameInput")}
              />
            )}
            onKeyDown={handleGuestNameInputKeyDown}
          />
          <Button
            disabled={!inputValue || inputValue.length === 0}
            variant={"cta"}
            color={"secondary"}
            onClick={handleGuestAdd}
            type={"submit"}
            sx={{
              width: 144,
              height: 48,
              marginLeft: "20px",
            }}
          >
            {t("Add")}
          </Button>
        </Box>
        {recentlyInvitedGuests?.length > 0 ? (
          <RecentlyInvitedGuestList
            guests={recentlyInvitedGuests}
            onItemClick={handleRecentlyInvitedGuestClick}
          />
        ) : undefined}
        {guests?.length ? (
          <List
            sx={{
              paddingTop: "8px",
              paddingBottom: 0,
              marginBottom: "0 !important",
            }}
          >
            {guests?.map((guest, i) => {
              return (
                <GuestInputListItem
                  index={i}
                  guest={guest}
                  key={`${guest.name}-${i}`}
                  onInputChange={handleGuestItemChange}
                  onDelete={handleGuestItemDelete}
                />
              );
            })}
          </List>
        ) : undefined}
        <Box className="guest-add-modal-footer">
          <Button variant="inner" color="secondary">
            {"초대장 미리보기"}
          </Button>
          <LoadingButton
            disabled={guests.length === 0}
            loading={isAddLoading}
            variant="inner"
            color="primary"
            onClick={handleGuestItemsSubmit}
          >
            {"게스트 등록하기"}
          </LoadingButton>
        </Box>
      </StyledCommonCard>
    </GuestAddModalContainer>
  );
}
