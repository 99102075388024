import React, { useMemo } from "react";
import { Chip, ChipProps } from "@mui/material";
import { EventStatus, EventStatusMap } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";

export interface EventStatusChipProps
  extends Omit<ChipProps, "color" | "variant" | "label"> {
  eventStatus: EventStatus;
}

export function EventStatusChip({
  eventStatus,
  ...props
}: EventStatusChipProps) {
  const { t } = useTranslation("workspace");
  const themeByEventStatus = useMemo((): Pick<
    ChipProps,
    "color" | "variant"
  > => {
    switch (`${eventStatus}`) {
      case `${EventStatusMap.CONFIRMED}`:
        return {
          color: "primary",
          variant: "filled",
        };
      default:
        return {
          color: "default",
          variant: "outlined",
        };
    }
  }, [eventStatus]);

  return (
    <Chip
      label={t(`status.${eventStatus}`)}
      color={themeByEventStatus.color}
      variant={themeByEventStatus.variant}
      {...props}
    />
  );
}
