import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  EventWithGuests,
  GuestWithBgColor,
} from "../../app/services/events/types";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { EventFormItem } from "../event/EventFormItem";
import {
  ChangeScheduleCalendar,
  ChangeScheduleCalendarProps,
} from "../calendars/ChangeScheduleCalendar";
import { CommonModal } from "../modal/CommonModal";
import { ExtendedEvent } from "../calendars/BaseCalendar";
import { useSortedGuestsFromEvent } from "../../hooks/useSortedGuestsFromEvent";
import { EventGuestItemList } from "../event/EventGuestItemList";
import { EventInfoWithUrlView } from "../event/EventInfoWithUrlView";
import { StyledEventDetailContainer } from "../styled/StyledEventDetailContainer";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

export interface GuestEventFormProps
  extends Omit<ChangeScheduleCalendarProps, "others"> {
  event?: EventWithGuests;
}

export function GuestEventForm({
  me,
  event,
  schedules,
  attend,
  submitDisabled,
  onAttendOrNotChange,
  onSchedulesChange,
  onSubmit,
}: GuestEventFormProps) {
  const { t } = useTranslation("guestEvent");
  const guests = useSortedGuestsFromEvent(event);
  const [others, setOthers] = useState<GuestWithBgColor[]>([]);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [activeEvents, setActiveEvents] = useState<ExtendedEvent[]>([]);
  const isDesktop = useDesktopMatches();
  const handleChange = useCallback(
    (events) => {
      setActiveEvents(events);
      if (onSchedulesChange) onSchedulesChange(events);
    },
    [onSchedulesChange]
  );

  const handleSubmit = useCallback((events) => {
    setActiveEvents(events);
    setIsSubmitModalOpen(true);
  }, []);
  const handleModalConfirm = useCallback(() => {
    if (onSubmit && attend) {
      onSubmit(activeEvents);
    }
    setIsSubmitModalOpen(false);
  }, [onSubmit, attend, activeEvents]);

  const handleModalCancel = useCallback(() => {
    setIsSubmitModalOpen(false);
  }, []);

  useEffect(() => {
    if (event) {
      setOthers(event.guests.filter((d) => d.email !== me));
    } else {
      setOthers([]);
    }
  }, [event, me]);

  return event ? (
    <ContainerWithTitle
      title={t("Title")}
      titleDecorator={event.title}
      titleComponent="span"
    >
      <StyledEventDetailContainer isDesktop={isDesktop}>
        <EventInfoWithUrlView
          isUrlHidden={true}
          event={event}
          collapsible={true}
          defaultCollapsed={false}
        />
        <EventGuestItemList
          me={me}
          guests={guests}
          collapsible={true}
          defaultCollapsed={false}
        />
      </StyledEventDetailContainer>
      <EventFormItem>
        <ChangeScheduleCalendar
          me={me}
          others={others}
          attend={attend}
          schedules={schedules}
          submitDisabled={submitDisabled}
          onAttendOrNotChange={onAttendOrNotChange}
          onSchedulesChange={handleChange}
          onSubmit={handleSubmit}
        />
      </EventFormItem>
      <CommonModal
        open={isSubmitModalOpen}
        title={t("confirmModal.Title")}
        cancelButtonText={t("confirmModal.Cancel")}
        confirmButtonText={t("confirmModal.Confirm")}
        description={t("confirmModal.Description", {
          count: activeEvents?.length || 0,
        })}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
      />
    </ContainerWithTitle>
  ) : null;
}
