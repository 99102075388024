import React from "react";
import styled from "@mui/styled-engine";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { CommonModal } from "./CommonModal";
import { RecommendSchedule } from "../../app/services/events/types";

const ConfirmScheduleModalBox = styled(Box)`
  padding: 20px 0 60px;
  .confirm-schedule-modal {
    &-description {
      font-size: 14px;
      color: #232323;
      margin-bottom: 31px;
      font-weight: bold;
    }
    &-item {
      margin-bottom: 28px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &-label {
      font-size: 14px;
      font-weight: bold;
      color: #232323;
      margin-bottom: 5px;
    }
    &-value {
      font-size: 14px;
      color: #232323;
      * {
        font-size: 14px;
      }
    }
  }
`;

export interface ConfirmScheduleModalProps {
  open: boolean;
  schedule?: RecommendSchedule;
  onClose: () => void;
  onConfirm: () => void;
}

export function ConfirmScheduleModal({
  open,
  schedule,
  onClose,
  onConfirm,
}: ConfirmScheduleModalProps) {
  const { t: tConfirmSchedule } = useTranslation("confirmSchedule");

  return (
    <CommonModal
      open={open}
      width={566}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      confirmButtonText={tConfirmSchedule("confirmModal.Yes")}
      cancelButtonText={tConfirmSchedule("confirmModal.No")}
      title={tConfirmSchedule("confirmModal.Title")}
    >
      <ConfirmScheduleModalBox>
        <Typography className="confirm-schedule-modal-description">
          {tConfirmSchedule("confirmModal.Description")}
        </Typography>
        {schedule ? (
          <>
            <Box className="confirm-schedule-modal-item">
              <Typography className="confirm-schedule-modal-label">
                {tConfirmSchedule("confirmModal.Time")}
              </Typography>
              <Typography className="confirm-schedule-modal-value">
                {`${moment(schedule?.from_time).format("L LT")} - ${moment(
                  schedule?.to_time
                ).format("L LT")}`}
              </Typography>
            </Box>
            <Box className="confirm-schedule-modal-item">
              <Typography className="confirm-schedule-modal-label">
                {tConfirmSchedule("confirmModal.GuestStatus")}
              </Typography>
              <Box className="confirm-schedule-modal-value">
                {schedule.guests.map((guest, i) => (
                  <Typography key={`${guest.email}-${i}`}>
                    {`${guest.email} (${
                      [
                        tConfirmSchedule("confirmModal.Unsettled"),
                        tConfirmSchedule("confirmModal.Matched"),
                        tConfirmSchedule("confirmModal.Unmatched"),
                      ][guest.status]
                    })`}
                  </Typography>
                ))}
              </Box>
            </Box>
          </>
        ) : null}
      </ConfirmScheduleModalBox>
    </CommonModal>
  );
}
