import React, { useCallback, useEffect, useState } from "react";
import styled from "@mui/styled-engine";
import {
  initValue,
  InviteGuestsToEventForm,
  InviteGuestsToEventFormProps,
  InviteGuestsToEventFormValue,
} from "../forms/InviteGuestsToEventForm";
import { GuestWithBgColor } from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
import { StyledCommonModalForEdit } from "../styled/StyledCommonModalForEdit";

export interface InviteGuestsToEventModalProps
  extends Omit<InviteGuestsToEventFormProps, "value" | "onChange"> {
  open: boolean;
  guests?: GuestWithBgColor[];
  onSave: (guests?: GuestWithBgColor[]) => void;
  onClose: () => void;
}

const StyledInviteGuestsToEventForm = styled(InviteGuestsToEventForm)`
  padding-top: 0;
`;

export function InviteGuestsToEventModal({
  open,
  guests,
  recentEmails,
  onSave,
  onClose,
}: InviteGuestsToEventModalProps) {
  const { t } = useTranslation("editEvent");
  const [value, setValue] = useState<InviteGuestsToEventFormValue>(initValue);
  const handleChange = useCallback((value) => {
    setValue(value);
  }, []);
  const handleConfirm = useCallback(() => {
    onSave(value.guests);
    onClose();
  }, [value.guests, onSave, onClose]);

  useEffect(() => {
    setValue((value) => ({
      ...value,
      guests,
    }));
  }, [guests]);

  return (
    <StyledCommonModalForEdit
      open={open}
      width={565}
      onConfirm={handleConfirm}
      onCancel={onClose}
      onClose={onClose}
      confirmButtonText={t("Save")}
      confirmButtonColor="secondary"
      confirmButtonDisabled={false}
      cancelButtonText={t("Cancel")}
      cancelButtonColor="normal"
      noHeader={true}
    >
      <StyledInviteGuestsToEventForm
        value={value}
        recentEmails={recentEmails}
        onChange={handleChange}
        noMinusMargin={true}
      />
    </StyledCommonModalForEdit>
  );
}
