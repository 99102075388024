import React, { useCallback } from "react";
import { Box, BoxProps, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useHistory } from "react-router-dom";

export interface MoreButtonOption {
  title: string;
  action?: () => void;
  to?: string;
}

export interface MoreButtonProps extends BoxProps {
  button?: React.ReactNode;
  options: MoreButtonOption[];
}

export const MoreButton = ({ button, options, ...props }: MoreButtonProps) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOptionsOpen, setOptionOpen] = React.useState(false);

  const handleOptionOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOptionOpen(true);
    },
    []
  );

  const onClose = useCallback(() => setOptionOpen(false), []);
  const handleOptionClickFactory = useCallback(
    (option: MoreButtonOption) => () => {
      if (option.action) option.action();
      else if (option.to) history.push(option.to);
      onClose();
    },
    [onClose, history]
  );

  return (
    <Box component="span" {...props}>
      <>
        {button && React.isValidElement(button) ? (
          React.cloneElement(button, { onClick: handleOptionOpen })
        ) : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleOptionOpen}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOptionsOpen}
        onClose={onClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`option-${index}`}
            onClick={handleOptionClickFactory(option)}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
