import React, { useCallback, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import styled, { css } from "@mui/styled-engine";
import { UserResponse } from "../../app/services/accounts/types";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";
import { MoreButton } from "../../components/buttons/MoreButton";
import { useAppSelector } from "../../app/hooks";
import { authSelectors } from "../auth/authSlice";

const menuRouterLink = [
  {
    key: "Workspace",
    to: "/",
  },
  // {
  //   key: "Calendar",
  //   to: "/calendar",
  // },
];

const loginRouterLink = {
  key: "Login",
  to: "/login",
};

const StyledAppBar = styled(AppBar)<{ isDesktop: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  ${(props) =>
    props.isDesktop
      ? css`
          background-color: #ffffff;
          color: #232323;
        `
      : css`
          background-color: #000;
          color: rgba(255, 255, 255, 0.87);
        `}
`;

const StyledLogo = styled(Link)`
  text-decoration: none;
  margin-right: 78px;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  &.desktop {
    display: inline-flex;
    color: #232323;
  }
  &.mobile {
    display: flex;
    color: rgba(255, 255, 255, 0.87);
  }
  & > img {
    margin-left: 8px;
    height: 28px;
  }
`;

const StyledDesktopToolbar = styled(Toolbar)`
  padding: 0 65px 0 57px;
`;

const StyledMobileToolbar = styled(Toolbar)`
  padding: 0 8px 0 20px;
`;

const StyledDesktopMenu = styled(Box)`
  & > a {
    text-decoration: none;
    margin-right: 58px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

interface LogoProps {
  isDesktop: boolean;
}

const Logo = ({ isDesktop }: LogoProps) => {
  const platform = isDesktop ? "desktop" : "mobile";

  return (
    <Box
      component={RouterLink}
      to="/"
      sx={{
        textDecoration: "none",
        flexGrow: isDesktop ? 0 : 1,
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <StyledLogo className={platform} variant="h1">
        <img src={`/images/ic_logo_big_${platform}.svg`} alt="" />
      </StyledLogo>
    </Box>
  );
};

interface MobileMenuProps {
  user?: UserResponse["user"];
}

const DesktopMenu = ({ user }: MobileMenuProps) => {
  const { t } = useTranslation("global");

  return (
    <>
      <StyledDesktopMenu sx={{ flexGrow: 1 }} className="menu">
        {user &&
          menuRouterLink.map((menu, index) => (
            <Link
              component={RouterLink}
              color="inherit"
              to={menu.to}
              key={`link-${index}`}
            >
              {t(menu.key)}
            </Link>
          ))}
      </StyledDesktopMenu>
      {user === undefined ? (
        <Link component={RouterLink} color="inherit" to={loginRouterLink.to}>
          {t(loginRouterLink.key)}
        </Link>
      ) : (
        <MoreButton
          options={[
            { title: t("PersonalInfo"), to: "/personal-info" },
            { title: t("Logout"), to: "/logout" },
          ]}
          button={
            <Link color="inherit" sx={{ cursor: "pointer" }}>
              {user?.name}
            </Link>
          }
        />
      )}
    </>
  );
};

const MobileMenu = ({ user }: MobileMenuProps) => {
  const { t } = useTranslation("global");
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const handleMobileMenuOpen = useCallback(
    () => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onClose = useCallback(() => setAnchorEl(null), []);

  const handleMobileMenuClose = useCallback(
    (to: string) => {
      setAnchorEl(null);
      history.push(to);
    },
    [history]
  );

  useEffect(() => {
    setMobileMenuOpen(Boolean(anchorEl));
  }, [anchorEl]);

  return (
    <>
      <IconButton
        size={"large"}
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleMobileMenuOpen()}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={onClose}
      >
        {(user
          ? [
              ...menuRouterLink,
              {
                key: "User",
                to: "/personal-info",
              },
              {
                key: "Logout",
                to: "/logout",
              },
            ]
          : [loginRouterLink]
        ).map((menu, index) => (
          <MenuItem
            onClick={() => handleMobileMenuClose(menu.to)}
            key={`menu-${index}`}
          >
            {menu.key === "User" ? user?.name : t(menu.key)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export interface GlobalAppBarProps {}

export function GlobalAppBar(_: GlobalAppBarProps) {
  const isDesktop = useDesktopMatches();
  const { user } = useAppSelector(authSelectors.selectState);

  return (
    <StyledAppBar position={"static"} isDesktop={isDesktop}>
      {isDesktop ? (
        <StyledDesktopToolbar variant="desktop">
            <Logo isDesktop={isDesktop} />
          <DesktopMenu user={user} />
        </StyledDesktopToolbar>
      ) : (
        <StyledMobileToolbar variant="mobile">
            <Logo isDesktop={isDesktop} />
          <MobileMenu user={user} />
        </StyledMobileToolbar>
      )}
    </StyledAppBar>
  );
}
