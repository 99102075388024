import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "@mui/material";
import { css } from "@mui/styled-engine";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";

const InvitedGuestChip = styled(Box)<{
  clickable?: boolean;
  selected?: boolean;
}>`
  display: inline-flex;
  min-width: 108px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  padding: 0 30px;
  margin-bottom: 12px;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 14px;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  transition: background-color 0.2s;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        transition: background-color 0.2s;
      }
      &:hover {
        &:before {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    `}
  ${({ selected }) =>
    selected &&
    css`
      background-color: #4555ff;
      border: 1px solid #4555ff;
      color: #ffffff;
    `}
`;

interface GuestChipProps extends BoxProps {
  guest: PollEventGuestCandidate;
  children?: React.ReactNode;
  onChipClick?: (guest: PollEventGuestCandidate, selected?: boolean) => void;
  selected?: boolean;
}

export const GuestChip = ({
  children,
  guest,
  onChipClick,
  selected,
  ...props
}: GuestChipProps) => {
  return (
    <InvitedGuestChip
      selected={selected}
      clickable={!!onChipClick}
      onClick={() => onChipClick?.(guest, selected)}
      {...props}
    >
      {children || `${guest.name}${guest.email ? ` | ${guest.email}` : ""}`}
    </InvitedGuestChip>
  );
};
