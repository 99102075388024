import React from "react";
import { Box, Image, Item, Span } from "react-html-email";
import { EmailThemeType } from "./types";
import { theme } from "../theme";

const titleStyle = {
  fontSize: 30,
  fontWeight: "bold",
  color: "#333",
  lineHeight: 36,
};

const titleDecoratorStyle = {
  fontSize: 30,
  fontWeight: "bold",
  lineHeight: 36,
};

const imageContainerStyle = { verticalAlign: "top", width: "60px" };

const imageStyle = {
  display: "inline",
};

export interface EmailTitleProps extends EmailThemeType {
  title?: string;
  titleDecorator?: string;
  titlePostfix?: string;
  description?: string;
  children?: React.ReactNode;
}

export function EmailTitle({
  title,
  titleDecorator,
  titlePostfix,
  children,
  type = "common",
}: EmailTitleProps) {
  return (
    <Item style={{ padding: "30px 0" }}>
      <Box style={{ width: "100%" }}>
        <tr>
          <td style={imageContainerStyle}>
            <Image
              alt={"quotation start"}
              height={44}
              src={theme[type].image.QUOTATION_START}
              width={35}
              style={{ ...imageStyle }}
            />
          </td>
          <td>
            <Span {...titleStyle}>
              {title && (
                <>
                  {title}
                  <br />
                </>
              )}
              {titleDecorator && (
                <>
                  <Span
                    {...titleDecoratorStyle}
                    color={theme[type].color.primary}
                  >
                    {titleDecorator}
                  </Span>
                  <br />
                </>
              )}
              {titlePostfix && titlePostfix}
              {children}
            </Span>
          </td>
          <td
            style={{
              ...imageContainerStyle,
              textAlign: "right",
            }}
          >
            <Image
              alt={"quotation end"}
              height={44}
              src={theme[type].image.QUOTATION_END}
              width={35}
              style={{ ...imageStyle }}
            />
          </td>
        </tr>
      </Box>
    </Item>
  );
}
