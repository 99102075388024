import React, { useEffect, useState } from "react";
import { useLogOut } from "../../hooks/useLogOut";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  useGoogleLoginRedirect,
  usePostGoogleCallbackMutation,
} from "../../app/services/accounts/googleApi";
import { useLogIn } from "../../hooks/useLogIn";
import { useQuery } from "../../hooks/useQuery";

function LoginGoogleRedirect() {
  useGoogleLoginRedirect();
  return <></>;
}

function LoginGoogleCallback() {
  const query = useQuery();
  const [postGoogleCallback, { data, isError: isCallbackError }] =
    usePostGoogleCallbackMutation();
  const [isEmptyCodeError, setIsEmptyCodeError] = useState(false);
  const { isError: isLoginDataError } = useLogIn(
    data?.is_register ? undefined : data,
    { success: "/" }
  );

  useEffect(() => {
    const code = query.get("code");
    const authuser = query.get("authuser");
    if (code && authuser) {
      postGoogleCallback({ code, authuser });
    } else {
      setIsEmptyCodeError(true);
    }
  }, [query, postGoogleCallback]);

  return isEmptyCodeError || isLoginDataError || isCallbackError ? (
    <Redirect to={"/empty"} />
  ) : data && data.is_register ? (
    <Redirect
      to={{ pathname: "/sign-up", state: { userFromLoginGoogle: data } }}
    />
  ) : null;
}

export function LoginGooglePage() {
  const { path } = useRouteMatch();
  const { token } = useLogOut();

  return !token ? (
    <>
      <Switch>
        <Route exact={true} path={path}>
          <LoginGoogleRedirect />
        </Route>
        <Route path={`${path}/callback`}>
          <LoginGoogleCallback />
        </Route>
      </Switch>
    </>
  ) : null;
}
