import React from "react";
import ReactMarkdown from "react-markdown";
import styled, { css } from "@mui/styled-engine";
import {
  Box,
  Card,
  Fade,
  Link,
  ModalUnstyled,
  ModalUnstyledProps,
} from "@mui/material";
import { HeaderHeight } from "../../mui/theme";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

const StyledTermsModal = styled(ModalUnstyled)<{ isDesktop: boolean }>`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  ${(props) =>
    props.isDesktop
      ? css`
          align-items: center;
        `
      : ""}
  justify-content: center;
  overflow-y: auto;
`;

const StyledTermsCard = styled(Card)<{ isDesktop: boolean }>`
  margin-top: ${(props) => (props.isDesktop ? 0 : `${HeaderHeight.MOBILE}px`)};
  width: 674px;
  height: ${(props) =>
    props.isDesktop ? "600px" : `calc(100% - ${HeaderHeight.MOBILE}px)`};
  padding: 27px 30px 27px 30px;
  border-radius: 10px;
  border: solid 1px #d2d2d2;
  background-color: #fff;
  .terms-modal {
    &-header {
      position: relative;
      height: 40px;
      display: flex;
      font-size: 20px;
      font-weight: bold;
      color: #232323;
      align-items: center;
    }
    &-content {
      margin-top: 30px;
      height: calc(100% - 70px);
      overflow-y: auto;
      white-space: pre-line;
      & > p {
        margin: 0;
      }
    }
    &-close {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      position: absolute;
      display: block;
      width: 34px;
      height: 34px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background: #f5f6fa;
      }
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        background-color: #111111;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 2px;
        border-radius: 1px;
        transform-origin: center center;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
`;

export interface TermsModalProps extends Omit<ModalUnstyledProps, "children"> {
  title?: string;
  description?: string;
  onCloseButtonClick?: () => void;
}

export function TermsModal({
  title,
  description,
  onCloseButtonClick,
  open,
  ...props
}: TermsModalProps) {
  const isDesktop = useDesktopMatches();

  return (
    <StyledTermsModal isDesktop={isDesktop} open={open} {...props}>
      <Fade in={open}>
        <StyledTermsCard isDesktop={isDesktop}>
          <Box className="terms-modal-header">
            {title}
            <Link
              component="span"
              className="terms-modal-close"
              onClick={() => {
                if (onCloseButtonClick) {
                  onCloseButtonClick();
                }
              }}
            />
          </Box>
          <Box className="terms-modal-content">
            {description && <ReactMarkdown>{description}</ReactMarkdown>}
          </Box>
        </StyledTermsCard>
      </Fade>
    </StyledTermsModal>
  );
}
