import styled from "@mui/styled-engine";
import { Link } from "@mui/material";

export const GoogleSignUpLink = styled(Link)`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-decoration: underline !important;
`;
