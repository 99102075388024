import { AttendStatus } from "../types";
import { User } from "../accounts/types";

export type PollEventType = "video" | "offline" | "phone";
export const pollEventTypeKeys: PollEventType[] = ["video", "offline", "phone"];

export type PollEventStatus = "wait" | "confirmed" | "deleted" | "finished";
export type PollHourOption = "all" | "workhour" | "noon" | "evening";

export interface PollEventKey {
  key: string;
}
export interface PollEventId {
  id: number;
}
export interface PollEventGuestCandidate {
  key?: string;
  name: string;
  email?: string;
  phone_number?: string;
}

export interface PollEventRecentGuestResult extends PollEventGuestCandidate {
  bg_color: string;
}

export interface PollEventGuestResult extends PollEventGuestCandidate {
  id: number;
  poll_id: string;
  status: AttendStatus;
  bg_color: string;
  key: string;
  guest_key: string;
}

export interface PollCardCandidate {
  start_at: string; //iso-8601;
  end_at: string;
}

export interface PollCardResult extends PollCardCandidate {
  id: number;
  key: string;
  select_count: number;
  card_key: string;
  start_at: string;
  end_at: string;
  phone_number?: string;
  guests: PollEventGuestResult[];
  stats: 0 | 1 | 2; //0: 미정, 1: 참석 예정, 2: 참석 불가
}

export interface PollCardsResponse {
  cards: PollCardResult[];
}

export interface PollEventRequest {
  title: string;
  description?: string;
  event_type?: PollEventType;
  place_detail?: string;
  cards?: PollCardCandidate[];
  duration_minutes: number;
}

export interface PollEventResponse extends PollEventId {
  host_info: User;
  title: string;
  description: string;
  event_type: PollEventType;
  status: PollEventStatus;
  place_detail?: string;
  url: string;
  key: string;
  guests: PollEventGuestResult[];
  start_at?: string;
  end_at?: string;
  duration_minutes: number;
  cards: PollCardResult[];
}

export interface PollCardAddRequest extends PollEventId {
  cards: PollCardCandidate[];
}

export interface PollCardAddResponse {
  cards: PollCardResult[];
}

export interface PollEventInviteRequest {
  id: number;
  guests: PollEventGuestCandidate[];
}

export interface PollSettingRequest {
  duration: number;
  can_weekend: boolean;
  hour_option?: PollHourOption;
  start_at: string; //hh:mm
  end_at: string; //hh:mm
}

export interface PollSettingResponse extends PollSettingRequest {
  user_id: number;
}

export interface PollGuestSelfAddRequest extends PollEventKey {
  guest: PollEventGuestCandidate;
}
