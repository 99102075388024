import React from "react";
import { Typography } from "@mui/material";
import { StyledAvatar } from "../styled/StyledAvatar";
import {
  StyledFormCard,
  StyledFormCardContent,
} from "../styled/StyledSignUpForm";
import styled from "@mui/styled-engine";
import { color } from "../../mui/token/color";

export interface AvatarCardProps {
  email: string;
  name?: string;
  bgColor?: string;
}

const StyledAvatarCardContent = styled(StyledFormCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .avatar {
    margin-bottom: 17px;
    &-email {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin-bottom: 6px;
    }

    &-name {
      font-size: 16px;
      color: #333;
    }
  }
`;

export function AvatarCard({ email, name, bgColor }: AvatarCardProps) {
  return (
    <StyledFormCard>
      <StyledAvatarCardContent>
        <StyledAvatar className="avatar" bgColor={bgColor || color.primary}>
          {(name || email).substr(0, 1)}
        </StyledAvatar>
        <Typography
          className="avatar-email"
          variant={"h5"}
          component="div"
          gutterBottom={true}
        >
          {email}
        </Typography>
        {name ? (
          <Typography
            className="avatar-name"
            variant={"subtitle1"}
            component="div"
            gutterBottom={true}
          >
            {name}
          </Typography>
        ) : null}
      </StyledAvatarCardContent>
    </StyledFormCard>
  );
}
