import React from "react";
import { Box } from "@mui/material";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

interface AvatarCardWrapperProps {
  children?: React.ReactNode;
}

export function AvatarCardWrapper({ children }: AvatarCardWrapperProps) {
  const isDesktop = useDesktopMatches();

  return (
    <Box
      sx={{
        margin: isDesktop ? "16px 0 0" : "16px -20px 0",
      }}
    >
      {children}
    </Box>
  );
}
