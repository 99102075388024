import React from "react";
import {
  IconButton,
  IconButtonProps,
  Snackbar,
  SnackbarProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface SnackbarWithCloseButtonProps extends SnackbarProps {
  onCloseButtonClick?: IconButtonProps["onClick"];
}
export function SnackbarWithCloseButton({
  autoHideDuration,
  open,
  message,
  onClose,
  onCloseButtonClick,
  ...props
}: SnackbarWithCloseButtonProps) {
  return (
    <Snackbar
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onCloseButtonClick}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
      open={open}
      autoHideDuration={autoHideDuration || 4000}
      onClose={onClose}
      message={message}
      {...props}
    />
  );
}
