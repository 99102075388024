import { Schedule } from "../app/services/events/types";
import moment from "moment";
import { getMomentRange } from "./getMomentRange";

export function splitScheduleRanges(schedule: Schedule): Schedule[] {
  let toTime = moment(schedule.to_time);
  if (moment(toTime).startOf("days").valueOf() === toTime.valueOf()) {
    toTime = moment(toTime).subtract(1, "days").endOf("days");
  }
  const ranges = [
    moment(schedule.from_time),
    ...getMomentRange(
      moment(schedule.from_time).endOf("days"), //endOf, startOf는 mutate 시킨다.
      moment(toTime).endOf("days"),
      "days"
    ),
    moment(toTime),
  ];
  const dailyRanges = [];
  for (let i = 0; i < ranges.length - 1; i++) {
    // range-1 까지만 루프
    let currentDate = ranges[i];
    if (moment(currentDate).endOf("days").valueOf() === currentDate.valueOf()) {
      currentDate = moment(currentDate).add(1, "days").startOf("days");
    }
    dailyRanges.push({
      from_time: currentDate.toISOString(),
      to_time: ranges[i + 1].toISOString(),
    });
  }
  return dailyRanges;
}
