import React, { useCallback } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AvatarCard } from "../cards/AvatarCard";
import { ExtendedEvent } from "./BaseCalendar";
import { timeRangeFormat } from "./ChangeScheduleCalendar";
import { CommonModal } from "../modal/CommonModal";

export interface ChangeScheduleCalendarGuestModalProps {
  selectedEvent: ExtendedEvent | null;
  onButtonClick: (event: ExtendedEvent | null) => void;
  onClose: () => void;
}

export function ChangeScheduleCalendarGuestModal({
  selectedEvent,
  onClose,
  onButtonClick,
}: ChangeScheduleCalendarGuestModalProps) {
  const { t } = useTranslation("changeScheduleCalendar");

  const handleButtonClick = useCallback(() => {
    if (onButtonClick) onButtonClick(selectedEvent);
  }, [selectedEvent, onButtonClick]);

  return (
    <CommonModal
      open={selectedEvent !== null}
      onClose={onClose}
      width={300}
      aria-labelledby="invy-change-schedule-calendar-modal-title"
      disableEnforceFocus
      footer={
        <Button variant="cta" fullWidth onClick={handleButtonClick}>
          {t("ChooseThisSchedule")}
        </Button>
      }
    >
      <>
        <Typography
          variant={"h6"}
          sx={{ lineHeight: 1 }}
          id="invy-change-schedule-calendar-modal-title"
        >
          {selectedEvent
            ? timeRangeFormat(selectedEvent.start, selectedEvent.end)
            : ""}
        </Typography>
        <Typography variant={"h6"}>{t("CandidateInfo")}</Typography>
        {selectedEvent ? (
          <AvatarCard
            email={selectedEvent.email || ""}
            name={selectedEvent.name || ""}
            bgColor={selectedEvent.bgColor || "gray"}
          />
        ) : null}
      </>
    </CommonModal>
  );
}
