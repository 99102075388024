import React from "react";
import { ContainerWithTitle } from "../../components/commons/ContainerWithTitle";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@mui/styled-engine";

const StyledEmptyPage = styled(Box)`
  text-align: center;
  padding: 60px 20px 0;
  & > .empty-page-emoji {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 16px;
  }
  & > .empty-page-title {
    font-size: 24px;
    font-weight: bold;
    color: #232323;
    margin-bottom: 16px;
  }
  & > .empty-page-description {
    font-size: 14px;
    color: #767676;
    white-space: pre-line;
  }
`;

export function EmptyPage() {
  const { t } = useTranslation("emptyPage");
  const { t: tEmoji } = useTranslation("emoji");
  return (
    <ContainerWithTitle>
      <StyledEmptyPage>
        <Typography component="p" className="empty-page-emoji">
          {tEmoji("Unavailable")}
        </Typography>
        <Typography component="h6" className="empty-page-title">
          {t("Title")}
        </Typography>
        <Box component="p" className="empty-page-description">
          {t("Description")}
        </Box>
      </StyledEmptyPage>
    </ContainerWithTitle>
  );
}
