import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CssBaselineProps from "@mui/material/CssBaseline";
import { I18nextProvider } from "react-i18next";
import { SnackbarProvider } from "notistack";

import "./index.css";
import App from "./App";
import { Persistor, store } from "./app/store";
import * as serviceWorker from "./serviceWorker";
import i18n from "./configs/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={Persistor}>
        <BrowserRouter>
          <CssBaselineProps></CssBaselineProps>
          <I18nextProvider i18n={i18n}>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
