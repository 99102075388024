import React from "react";
import { useTranslation } from "react-i18next";
import { convertMinutesToText } from "../../utils/format";
import { InvitationToEventMailTemplate } from "./InvitationToEventMailTemplate";
import { EventInputListValue } from "../event/EventInputList";

export interface InvitationToEventMailProps {
  hostName: string;
  guestMail: string;
  event: EventInputListValue;
}

export function InvitationToEventMail({
  hostName,
  guestMail,
  event,
}: InvitationToEventMailProps) {
  const { t } = useTranslation("eventInputList");
  return (
    <InvitationToEventMailTemplate
      hostName={hostName}
      title={event.title || ""}
      description={event.description || ""}
      duration={
        event.duration_minutes
          ? convertMinutesToText(event.duration_minutes, t("Hour"), t("Minute"))
          : ""
      }
      eventType={
        event.event_type ? t(`placeDetailLabels.${event.event_type}`) : ""
      }
      placeDetail={event.place_detail ? `(${event.place_detail})` : ""}
      expirationTime={"0000-00-00 00:00:00"}
      guestMail={guestMail}
    />
  );
}
