import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/material";

import {
  Calendar,
  Views,
  Event,
  SlotInfo,
  CalendarProps,
  DateLocalizer,
  View,
  Culture,
  DateRange,
  DateFormatFunction,
  DateRangeFormatFunction,
} from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop, {
  withDragAndDropProps,
} from "react-big-calendar/lib/addons/dragAndDrop";
import styled from "@mui/styled-engine";
import moment from "moment";
import { color } from "../../mui/token/color";
import { Schedule } from "../../app/services/events/types";
import { css } from "@mui/styled-engine";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

export interface ExtendedEvent extends Event {
  id: string;
  isActive?: boolean;
  isDraggable?: boolean;
  bgColor?: string;
  strokeColor?: string;
  email?: string;
  name?: string;
  isTemp?: boolean; // 캘린더에 일정이 추가되기 전(일정 직접입력 저장 전)
}

const DragAndDropCalendar = withDragAndDrop<any, any>(Calendar);

const rbcAddonsDndRowHeight = 40;
const StyledBaseCalendar = styled(DragAndDropCalendar)<{
  isSmall?: boolean;
  isMobile?: boolean;
  anchorTop?: number;
  disabled?: boolean;
  isScrollBarVisible?: boolean;
}>`
  ${(props) =>
    props.isSmall
      ? css`
          margin: 0 -20px;
        `
      : ""}

  .rbc-toolbar {
    margin-bottom: 30px;
    ${(props) =>
      props.isSmall
        ? css`
            padding: 0 12px;
          `
        : ""}
    &-label {
      ${(props) =>
        !props.isSmall
          ? css`
              font-size: 24px;
            `
          : css`
              font-size: 20px;
            `}
      font-weight: bold;
      color: #232323;
    }
  }
  .rbc-btn {
    &-group {
      & > button {
        cursor: pointer;
        background-color: #ffffff;
        ${(props) =>
          !props.isSmall
            ? css`
                padding: 8px 12px;
              `
            : css`
                padding: 8px 6px;
              `}
        border-color: #dcdcdc;
        font-weight: 500;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:hover {
          border-color: #dcdcdc;
          background-color: #e0e0e0;
        }
        &:active {
          box-shadow: none;
        }

        &.rbc-active {
          color: #ffffff;
          box-shadow: none;
          background-color: #4555ff;
          border-color: #4555ff;
        }
      }
    }
  }
  ${(props) =>
    props.disabled
      ? css`
          .rbc-month-view,
          .rbc-time-view {
            position: relative;
            &:after {
              content: " ";
              position: absolute;
              z-index: 101;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(17, 17, 17, 0.16);
              cursor: not-allowed;
            }
          }
        `
      : ""}

  .rbc-month-view {
    background-color: #ffffff;
  }
  .rbc-time-view {
    border-bottom: none;
    .rbc-current-time-indicator {
      height: 2px;
    }
    .rbc-header {
      background-color: #fbfbfc;
      border-bottom: none;
      height: 37px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 14px;
      font-weight: normal;
      color: #333;
      border-bottom: 1px solid #dddddd;
    }
    .rbc-allday-cell {
      height: auto;
    }
    .rbc-time {
      &-header {
        border-bottom: 1px solid #dddddd;

        ${(props) =>
          props.isSmall &&
          css`
            position: sticky;
            background: white;
            top: ${props?.anchorTop}px;
            z-index: 100;
          `}

        &-content {
          .rbc-event-allday {
            border: 1px solid;
          }
        }
      }
      &-content {
        border-top: 0;
        ${(props) =>
          !props.isSmall
            ? css`
                .rbc-time-gutter {
                  .rbc-timeslot-group:nth-child(1),
                  .rbc-timeslot-group:nth-child(13) {
                    .rbc-label {
                      position: relative;
                      &:before {
                        position: absolute;
                        right: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        color: #c0c0c0;
                      }
                    }
                  }
                  //.rbc-timeslot-group:nth-child(1) {
                  //  .rbc-label {
                  //    &:before {
                  //      content: "오전";
                  //    }
                  //  }
                  //}

                  //.rbc-timeslot-group:nth-child(13) {
                  //  .rbc-label {
                  //    position: relative;
                  //    &:before {
                  //      content: "오후";
                  //    }
                  //  }
                  //}
                }
              `
            : ""}
      }
      &-gutter {
        width: ${(props) => (props.isSmall ? 50 : 80)}px !important;
      }
      &-header-gutter {
        width: ${(props) => (props.isSmall ? 50 : 80)}px !important;
        background-color: #fbfbfc;
        position: relative;
        &:after {
          content: "종일";
          position: absolute;
          bottom: ${rbcAddonsDndRowHeight / 2}px;
          left: 50%;
          transform: translate(-50%, 50%);
          white-space: nowrap;
          font-size: 14px;
          color: #333;
        }
      }
      &-header {
        border-right: 0 !important;
      }
      ${(props) =>
        props.isMobile || props.isSmall
          ? css`
              &-header {
                margin-right: 0 !important;
              }
              &-content {
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            `
          : props.isScrollBarVisible
          ? css`
              &-header {
                margin-right: 15px;
              }
            `
          : css`
              &-header {
                margin-right: 0 !important;
              }
              &-content {
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            `}
    }
    .rbc-timeslot-group {
      background-color: #ffffff;
      .rbc-time-slot {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .rbc-label {
          font-size: 14px;
          color: #333;
        }
      }
    }
    .rbc-event {
      border-radius: 4px;
      //padding: 6px;
      &s-container {
      }
      &-label {
        display: none;
      }
      &-content {
        font-size: 11px;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .rbc-row-content {
      .rbc-addons-dnd-row-body {
        .rbc-row:first-of-type {
          min-height: ${rbcAddonsDndRowHeight / 2}px;
        }
      }
    }
  }

  .rbc-today {
    &.rbc-header {
      background-color: rgba(19, 150, 156, 0.87);
      color: #ffffff;
      position: relative;
      overflow: inherit;
      &:before {
        content: "Today";
        display: flex;
        position: absolute;
        bottom: 100%;
        height: 20px;
        background-color: rgba(19, 150, 156, 0.4);
        color: #1a1a1a;
        width: 100%;
        font-size: 11px;
        align-items: center;
        justify-content: center;
        border-radius: 3px 3px 0 0;
      }
    }
    &.rbc-day-bg {
      background-color: rgba(19, 150, 156, 0.19);
    }
    &.rbc-time-column {
      .rbc-events-container {
        margin-right: 10px;
        &:before {
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          right: -10px;
          bottom: 0;
          background-color: rgba(19, 150, 156, 0.19);
        }
      }
      .rbc-current-time-indicator {
        background-color: rgba(19, 150, 156, 0.87);
      }
    }
  }
`;

const BaseCalendarBox = styled(Box)<{
  isDesktop: boolean;
  currentView: string;
}>`
  position: relative;
  ${(props) =>
    props.isDesktop || props.currentView !== Views.WEEK
      ? css`
          height: 530px;
        `
      : ""}
`;

export interface ExtendedEventWithSchedule<T extends Schedule>
  extends ExtendedEvent {
  originSchedule?: T;
}

export interface BaseCalendarProps
  extends CalendarProps<ExtendedEvent, any>,
    withDragAndDropProps<ExtendedEvent, any> {
  activeEvents: ExtendedEvent[];
  passiveEvents: ExtendedEvent[];
  onEventCreate?: (event: Event, view?: View) => void;
  onEventSelect?: (event: ExtendedEvent) => void;
  onEventChange?: (event: ExtendedEvent) => void; //이벤트 정보 이동 했을 때, 조절 했을 때
  onEventDoubleClick?: (event: ExtendedEvent) => void;
  anchorTop?: number;
  disabled?: boolean;
}

export interface ResizedEvent<TEvent> {
  event: TEvent;
  start: Date | string;
  end: Date | string;
  isAllDay: boolean;
}

function eventPropGetter(event: ExtendedEvent) {
  return {
    style: {
      backgroundColor: event.bgColor || color.primary,
      borderColor: event.strokeColor || "inherit",
    },
  };
}

export function getTitleFromTimeRange(
  start: string | Date,
  end: string | Date,
  localizer: DateLocalizer
): { dayDiff: number; allDay: boolean; title: string } {
  const [startMoment, endMoment] = [moment(start), moment(end)];
  const dayDiff = endMoment.diff(startMoment, "days", true);
  if (dayDiff >= 1 && startMoment.hour() === 0) {
    // @ts-ignore
    return {
      dayDiff,
      allDay: true,
      // @ts-ignore
      title: localizer.format(start, localizer.formats.dayFormat),
    };
  } else {
    // @ts-ignore
    return {
      dayDiff,
      allDay: false,
      // @ts-ignore
      title: localizer.format(start, localizer.formats.timeGutterFormat),
    };
  }
}

export function BaseCalendar(props: BaseCalendarProps) {
  const {
    activeEvents,
    passiveEvents,
    localizer,
    onRangeChange,
    onEventChange,
    onEventSelect,
    onEventCreate,
    onEventDoubleClick,
    anchorTop = 56,
    draggableAccessor = "isActive",
    disabled = false,
  } = props;
  const [totalEvents, setTotalEvents] = useState<ExtendedEvent[]>([]);
  const [currentView, setCurrentView] = useState<View>(Views.WEEK);
  const calendarRef = useRef(null);
  const defaultDateRef = useRef(new Date());
  const scrollToTimeRef = useRef(new Date(new Date().setHours(9, 0, 0)));

  const handleEventMove = useCallback(
    (movedEvent: ResizedEvent<ExtendedEvent>) => {
      const { event, start, end, isAllDay: droppedOnAllDaySlot } = movedEvent;

      if (!event.isActive) return;
      if (droppedOnAllDaySlot && !event.allDay) return; // allDay 슬롯과 time slot 사이에 이동 불가

      const { title, allDay } = getTitleFromTimeRange(start, end, localizer);
      const updatedEvent = {
        ...event,
        title,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        allDay,
      };

      if (onEventChange) onEventChange(updatedEvent);
    },
    [onEventChange, localizer]
  );

  const handleEventResize = useCallback(
    (resizedEvent: ResizedEvent<ExtendedEvent>) => {
      const { event, start, end } = resizedEvent;
      if (!event.isActive) return;

      const { title, allDay } = getTitleFromTimeRange(start, end, localizer);
      const newEvent = {
        ...event,
        title,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        allDay,
      };
      if (onEventChange) onEventChange(newEvent);
    },
    [onEventChange, localizer]
  );

  const handleActiveEventAdd = useCallback(
    ({ start, end, slots, action }: SlotInfo) => {
      const { title, allDay } = getTitleFromTimeRange(start, end, localizer);
      const newEvent: Event = {
        title,
        allDay,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
      };

      if (onEventCreate) onEventCreate(newEvent, currentView);
    },
    [localizer, onEventCreate, currentView]
  );

  const handleEventSelect = useCallback(
    (event) => {
      if (onEventSelect) onEventSelect(event);
    },
    [onEventSelect]
  );

  const handleEventDoubleClick = useCallback(
    (event) => {
      if (onEventDoubleClick) onEventDoubleClick(event);
    },
    [onEventDoubleClick]
  );

  useEffect(() => {
    setTotalEvents([...(passiveEvents || []), ...activeEvents]);
  }, [activeEvents, passiveEvents]);

  useEffect(() => {
    if (onRangeChange) {
      const today = moment();
      onRangeChange(
        {
          start: today.startOf("week").toDate(),
          end: today.endOf("week").toDate(),
        },
        Views.WEEK
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToTimeRef.current = new Date(new Date().setHours(9, 0, 0));
  }, []);

  const isDesktop = useDesktopMatches();
  const isScrollBarVisible = document.body.clientHeight > window.innerHeight;

  const onViewChange = useCallback((view: View) => {
    setCurrentView(view);
  }, []);

  const customFormats = useMemo(
    () => ({
      timeGutterFormat: !isDesktop ? "HH:mm" : "a hh시",
      monthHeaderFormat: "YYYY년 MMM",
      dayFormat: ((
        date: Date,
        culture?: Culture,
        localizer?: DateLocalizer
      ) => {
        return localizer?.format(
          date,
          !isDesktop ? "dd DD" : "dd MM/DD",
          culture || ""
        );
      }) as DateFormatFunction,
      dayRangeHeaderFormat: ((
        range: DateRange,
        culture?: Culture,
        localizer?: DateLocalizer
      ) => {
        const format = !isDesktop ? "MM/DD" : "MMM Do";
        return `${localizer?.format(
          range.start,
          format,
          culture || ""
        )} - ${localizer?.format(range.end, format, culture || "")}`;
      }) as DateRangeFormatFunction,
    }),
    [isDesktop]
  );

  return (
    // @ts-ignore
    <BaseCalendarBox isDesktop={isDesktop} currentView={currentView}>
      <StyledBaseCalendar
        {...props}
        draggableAccessor={draggableAccessor}
        anchorTop={anchorTop}
        isSmall={!isDesktop}
        isMobile={isMobile}
        isScrollBarVisible={isScrollBarVisible}
        defaultDate={defaultDateRef.current}
        scrollToTime={scrollToTimeRef.current}
        ref={calendarRef}
        defaultView={currentView}
        step={30}
        views={[Views.MONTH, Views.WEEK]}
        events={totalEvents}
        eventPropGetter={eventPropGetter}
        onEventDrop={handleEventMove}
        onView={onViewChange}
        // onDragOver={customOnDragOver}
        onEventResize={handleEventResize}
        onSelectSlot={handleActiveEventAdd}
        onSelectEvent={handleEventSelect}
        onDoubleClickEvent={handleEventDoubleClick}
        popup
        resizable={!isMobile}
        selectable
        messages={{
          // FIXME: locale에 따라 변화하게 수정
          date: "일자",
          time: "시간",
          event: "이벤트",
          allDay: "종일",
          week: "주간",
          work_week: "주중",
          day: "일간",
          month: "월간",
          previous: "이전",
          next: "다음",
          yesterday: "어제",
          tomorrow: "내일",
          today: "오늘",
          agenda: "예정",
          noEventsInRange: "해당 범위 내 이벤트가 없습니다.",
        }}
        formats={customFormats}
        longPressThreshold={80}
        disabled={disabled}
      />
    </BaseCalendarBox>
  );
}
