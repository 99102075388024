import { useCallback } from "react";
import { EventClickArg } from "@fullcalendar/core";
import { EventInput } from "@fullcalendar/react";
import { CreatePollCalendarEventType } from "../types/CreatePollCalendarEventType";
import { createPollSlice } from "../../createPollSlice";
import { SelectedEvent } from "../../../common/SelectedEventList";
import { useAppDispatch } from "../../../../../app/hooks";

export function useHandleEventMouseClick({
  tempEventInput,
  onTempEventInputChange,
  isAvailableAppend,
}: {
  tempEventInput?: EventInput;
  onTempEventInputChange: (e: EventInput | undefined) => void;
  isAvailableAppend: boolean;
}) {
  const dispatch = useAppDispatch();
  const onSelectedEventAdd = useCallback(
    (event) => {
      dispatch(createPollSlice.actions.addSelectedEvents([event]));
    },
    [dispatch]
  );
  const onSelectedEventRemove = useCallback(
    (eventId) => {
      dispatch(createPollSlice.actions.removeSelectedEvents([eventId]));
    },
    [dispatch]
  );

  return useCallback(
    ({ event }: EventClickArg) => {
      if (
        tempEventInput &&
        // @ts-ignore
        (tempEventInput?.extendedProps?.childType ===
          CreatePollCalendarEventType.available ||
          // @ts-ignore
          tempEventInput?.extendedProps.childType ===
            CreatePollCalendarEventType.occupied) && // FIXME: occupied는 제거 필요
        !event?.extendedProps?.isSelected &&
        isAvailableAppend
      ) {
        const selectedEvent = {
          id: tempEventInput.id as string, // new id
          start: tempEventInput.start,
          end: tempEventInput.end,
          overlap: "",
        } as SelectedEvent;

        onSelectedEventAdd(selectedEvent);
        onTempEventInputChange(undefined);
      } else if (
        event.extendedProps.isSelected &&
        event.extendedProps.selectedEventId
      ) {
        onSelectedEventRemove(event.extendedProps.selectedEventId);
      }
    },
    [
      tempEventInput,
      onSelectedEventAdd,
      onSelectedEventRemove,
      onTempEventInputChange,
      isAvailableAppend,
    ]
  );
}
