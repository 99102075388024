import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { LeaveTermsCheck } from "../inputs/LeaveTermsCheck";

export interface LeaveTerm {
  id: string;
  //isRequired: boolean;
}

export type LeaveTermsValue = Record<LeaveTerm["id"], boolean>;

export interface LeaveTermsFormProps {
  value?: LeaveTermsValue;
  terms: LeaveTerm[];
  onChange?: (value: LeaveTermsValue) => void;
  onSubmit?: () => void;
}

export function LeaveTermsForm({
  value,
  terms,
  onChange,
  onSubmit,
}: LeaveTermsFormProps) {
  const { t: tLeave } = useTranslation("leave");
  const { t: tTerms } = useTranslation("leaveTerms");
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const checkTerm = useCallback(
    (checked: boolean, termId: string) => {
      const changedTermsAgreed = {
        ...value,
        [termId]: checked,
      };
      if (onChange) onChange(changedTermsAgreed);
    },
    [value, onChange]
  );

  useEffect(() => {
    setIsAllChecked(
      value ? Object.values(value).every((agreed: boolean) => agreed) : false
    );
  }, [value]);

  return (
    <>
      <Box sx={{ marginBottom: "30px" }}>
        {terms.map(({ id }) => (
          <LeaveTermsCheck
            label={tTerms(`${id}.Title`)}
            subLabel={tTerms(`${id}.Description`)}
            checked={value ? value[id] : false}
            onChange={(checked) => checkTerm(checked, id)}
          />
        ))}
      </Box>
      <Box component="div" className="footer">
        <Button
          className="footer-button"
          fullWidth
          onClick={onSubmit}
          type={"submit"}
          variant="cta"
          color="primary"
          disabled={!isAllChecked}
        >
          {tLeave("TermsAgree")}
        </Button>
      </Box>
    </>
  );
}
