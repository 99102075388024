import React, { useCallback } from "react";
import styled, { css } from "@mui/styled-engine";
import { Box } from "@mui/material";
import { SubDescription } from "../../../styled/typography";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";
import { GuestChip } from "./GuestChip";

interface RecentlyInvitedGuestListProps {
  guests: PollEventGuestCandidate[];
  onItemClick?: (guest: PollEventGuestCandidate) => void;
}

export const InvitedGuestChip = styled(Box)<{ clickable: boolean }>`
  display: inline-flex;
  min-width: 108px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  margin-bottom: 12px;
  margin-right: 15px;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    `}
`;

export const RecentlyInvitedGuestList = ({
  guests,
  onItemClick,
}: RecentlyInvitedGuestListProps) => {
  const handleClick = useCallback(
    (guest: PollEventGuestCandidate) => () => onItemClick?.(guest),
    [onItemClick]
  );
  return (
    <Box>
      <SubDescription sx={{ marginBottom: "13px" }}>
        {"최근 초대한 게스트"}
      </SubDescription>
      <Box>
        {guests.map((guest) => (
          <GuestChip onChipClick={handleClick(guest)} guest={guest} />
        ))}
      </Box>
    </Box>
  );
};
