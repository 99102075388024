import React, { useMemo } from "react";
import { Box, Button } from "@mui/material";
import { UrlView } from "../event/UrlView";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { EventFormItem } from "../event/EventFormItem";
import { EventInfo } from "../event/EventInfo";
import {
  EventWithGuests,
  GuestWithStatus,
} from "../../app/services/events/types";
import { useTranslation } from "react-i18next";
import { User } from "../../app/services/accounts/types";
import { StyledEventDetailContainer } from "../styled/StyledEventDetailContainer";
import { EventGuestItemList } from "../event/EventGuestItemList";

interface CreateEventCompleteFormProps {
  event: EventWithGuests | undefined;
  user?: User;
  editEventButton?: React.ReactNode;
  onSelectSchedulesButtonClick: () => void;
  onGuestDelete?: (guest: GuestWithStatus) => void;
  onMailSend?: (guest: GuestWithStatus) => void;
}

export function CreateEventCompleteForm({
  event,
  editEventButton,
  user,
  onGuestDelete,
  onMailSend,
  onSelectSchedulesButtonClick,
}: CreateEventCompleteFormProps) {
  const { t } = useTranslation("createEvent");
  const { t: tEventInfo } = useTranslation("eventInfo");
  // const { t: tGuestInfo } = useTranslation("guestInfo");
  const guests = useMemo(() => {
    if (event) {
      return [...event.guests].sort((a, b) => {
        return a.is_host ? -1 : b.is_host ? 1 : 0;
      });
    } else {
      return [];
    }
  }, [event]);

  return (
    <ContainerWithTitle
      title={t("complete.Title")}
      titleDecorator={event?.title}
      rightButton={editEventButton}
    >
      <StyledEventDetailContainer component="div">
        <EventFormItem>
          <UrlView url={event?.url} title={event?.title} />
        </EventFormItem>
        <EventFormItem title={tEventInfo("Label")}>
          <EventInfo event={event} />
        </EventFormItem>
        {user?.email && (
          <EventGuestItemList
            me={user?.email}
            guests={guests}
            isFromHostEvent={true}
            collapsible={false}
            onGuestDelete={onGuestDelete}
            onMailSend={onMailSend}
            onChangeScheduleCalendarOpen={onSelectSchedulesButtonClick}
          />
        )}
        <Box>
          <Button
            fullWidth
            onClick={onSelectSchedulesButtonClick}
            variant="cta"
            color="primary"
          >
            {t("SelectSchedules")}
          </Button>
        </Box>
      </StyledEventDetailContainer>
    </ContainerWithTitle>
  );
}
