import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { TextField, Button, Link, Divider } from "@mui/material";
import styled from "@mui/styled-engine";
import validator from "validator";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import {
  InputFormValueBase,
  InputValidationBase,
  useInputFormatValidation,
} from "../../hooks/useInputFormatValidation";
import { StyledEventDetailContainer } from "../styled/StyledEventDetailContainer";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

const StyledLoginFormContent = styled(StyledEventDetailContainer)`
  .textfield {
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .link {
    display: inline-block;
    font-size: 14px;
    color: #333;
    text-decoration: none;
    cursor: pointer;
    &s {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 26px;
    }
  }
`;

export interface InputValidations extends InputValidationBase {
  email: boolean;
  password: boolean;
}

export type ValueKeys = keyof InputValidations;
export const valueKeys: ValueKeys[] = ["email", "password"];

export interface LoginFormValue
  extends Partial<Record<ValueKeys, string>>,
    InputFormValueBase {
  email?: string;
  password?: string;
}

export const initInputFormatValidations: InputValidations = {
  email: true,
  password: true,
};

export const initInputFulfillValidations: InputValidations = {
  email: false,
  password: false,
};

const inputValidators: Record<ValueKeys, (value: string) => boolean> = {
  email: validator.isEmail,
  password: (_) => true,
};

export interface LoginFormProps {
  value?: LoginFormValue;
  errorStatusCode?: number;
  onChange?: (value?: LoginFormValue) => void;
  onSubmit?: (value?: LoginFormValue) => void;
}

const StyledButtonDivider = styled(Box)`
  position: relative;
  & > label {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 12px;
    color: #8c8c8c;
    padding: 0 9px;
    background-color: #f5f6fa;
  }
`;

function ButtonDivider() {
  const { t } = useTranslation("login");

  return (
    <StyledButtonDivider>
      <Divider light sx={{ margin: "24px 0" }} />
      <Typography component="label">{t("Or")}</Typography>
    </StyledButtonDivider>
  );
}

export interface LogInTextFieldProps {
  name: ValueKeys;
  type: React.ComponentProps<typeof TextField>["type"];
  value?: LoginFormValue;
  inputFormatValidations: InputValidations;
  inputFulfillValidations: InputValidations;
  errorMessage?: string;
  isAfterSubmit: boolean;
  onChange?: (value?: LoginFormValue) => void;
  onSubmit?: () => void;
  className?: string;
}

export function LogInTextField({
  inputFormatValidations,
  inputFulfillValidations,
  isAfterSubmit,
  name,
  value,
  type,
  errorMessage,
  onChange,
  className,
  onSubmit = () => {},
}: LogInTextFieldProps) {
  const { t } = useTranslation("login");
  const { isError, handleChange } = useInputFormatValidation<
    InputValidations,
    LoginFormValue
  >({
    name,
    onChange,
    value,
    errorMessage,
    isAfterSubmit,
    inputFulfillValidations,
    inputFormatValidations,
  });

  return (
    <TextField
      error={isError}
      helperText={
        !inputFormatValidations[name]
          ? t(`invalidFormats.${name}`)
          : errorMessage
          ? errorMessage
          : isError
          ? t(`needInput.${name}`)
          : undefined
      }
      id={`invy-log-in-form-${name}`}
      placeholder={t(`labels.${name}`)}
      variant="outlined"
      type={type}
      value={value?.[name]}
      autoFocus={name === "email"}
      autoComplete={`section-login ${
        type === "password" ? "current-password" : "email"
      }`}
      className={className}
      onChange={handleChange}
      onKeyPress={(ev) => {
        if (ev.key === "Enter") {
          ev.preventDefault();
          onSubmit?.();
        }
      }}
      fullWidth
    />
  );
}

export function LogInForm({
  value,
  errorStatusCode,
  onChange,
  onSubmit,
}: LoginFormProps) {
  const history = useHistory();
  const isDesktop = useDesktopMatches();
  const { t } = useTranslation("login");
  const [isAfterSubmit, setIsAfterSubmit] = useState<boolean>(false);
  const [isFulfilled, setIsFulfilled] = useState<boolean>(false);
  const [inputFormatValidations, setInputFormatValidations] =
    useState<InputValidations>(initInputFormatValidations);
  const [inputFulfillValidations, setInputFulfillValidations] =
    useState<InputValidations>(initInputFulfillValidations);

  const handleSubmit = useCallback(() => {
    setIsAfterSubmit(true);
    if (onSubmit && isFulfilled) {
      onSubmit(value);
    }
  }, [onSubmit, value, isFulfilled]);

  useEffect(() => {
    setIsAfterSubmit(false); // 초기화
  }, [value]);

  useEffect(() => {
    // validate fulfill
    if (!value) return setInputFulfillValidations(initInputFulfillValidations);
    const validations = { ...initInputFulfillValidations };
    for (const key of valueKeys) {
      const targetValue = value[key];
      validations[key] = targetValue !== undefined && targetValue.trim() !== "";
    }
    setInputFulfillValidations(validations);
  }, [value]);

  useEffect(() => {
    // validate formats
    if (value) {
      const newErrors = Object.entries(inputValidators).reduce(
        (result, [key, validator]) => {
          const typedKey = key as ValueKeys;
          const targetValue = value[typedKey];

          if (targetValue && targetValue !== "") {
            result[typedKey] = validator(targetValue);
          } else {
            result[typedKey] = true;
          }
          return result;
        },
        { ...initInputFormatValidations }
      );
      setInputFormatValidations(newErrors);
    }
  }, [value]);

  useEffect(() => {
    // 모두 validate 한지 확인
    for (const prop in inputFormatValidations) {
      const isValid = inputFormatValidations[prop as ValueKeys];
      if (!isValid) {
        return setIsFulfilled(false);
      }
    }

    for (const prop in inputFulfillValidations) {
      const isValid = inputFormatValidations[prop as ValueKeys];
      if (!isValid) {
        return setIsFulfilled(false);
      }
    }

    setIsFulfilled(true);
  }, [inputFormatValidations, inputFulfillValidations]);

  return (
    <ContainerWithTitle title={t("Welcome")} hasQuotation={true}>
      <StyledLoginFormContent isDesktop={isDesktop}>
        <Box
          component="form"
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
        >
          {valueKeys.map((name) => {
            return (
              <LogInTextField
                className="textfield"
                key={name}
                name={name}
                type={
                  name === "email"
                    ? name
                    : name === "password"
                    ? "password"
                    : "text"
                }
                value={value}
                isAfterSubmit={isAfterSubmit}
                inputFormatValidations={inputFormatValidations}
                inputFulfillValidations={inputFulfillValidations}
                onChange={onChange}
                onSubmit={() => handleSubmit()}
              />
            );
          })}

          {errorStatusCode !== undefined && errorStatusCode >= 400 ? (
            <Typography variant="caption" sx={{ color: "#d32f2f" }}>
              {t("Invalid")}
            </Typography>
          ) : null}
        </Box>
        <Button
          fullWidth
          onClick={handleSubmit}
          type={"submit"}
          variant="cta"
          color="primary"
          sx={{
            m: "38px 0 0 0",
          }}
        >
          {t("Login")}
        </Button>

        <ButtonDivider />
        <Button
          fullWidth
          type="submit"
          variant="cta"
          color="normal"
          href={history.createHref({ pathname: "/login/google" })}
        >
          {t("GoogleLogin")}
        </Button>

        <Box component="div" className="links">
          <Link className="link" component={RouterLink} to={"/find/password"}>
            {t("FindPasswordLink")}
          </Link>
          <Link className="link" component={RouterLink} to={"/sign-up"}>
            {t("SignUpLink")}
          </Link>
        </Box>
      </StyledLoginFormContent>
    </ContainerWithTitle>
  );
}
