import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./config";
import { TokenRequest, TokenResponse } from "./types";
import { fetchApiBaseQuery } from "../fetchApiBaseQuery";

export interface PasswordResetRequest extends TokenRequest {
  password: string;
}
export const resetApi = createApi({
  baseQuery: fetchApiBaseQuery({ baseUrl: `${baseUrl}/reset/` }),
  reducerPath: "accounts/resetApi",
  endpoints: (build) => ({
    // 패스워드 재설정 요청
    postResetPasswordSendEmail: build.mutation<void, { email: string }>({
      query: (body) => ({
        url: "password/send/",
        method: "POST",
        body,
      }),
    }),
    // 이메일 링크 인증
    postResetPasswordVerifyEmail: build.mutation<TokenResponse, TokenRequest>({
      query: (body) => ({
        url: "password/verify/",
        method: "POST",
        body,
      }),
    }),
    // 패스워드 재설정
    putResetPasswordWithToken: build.mutation<void, PasswordResetRequest>({
      query: (body) => ({
        url: "password/reset/",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  usePostResetPasswordSendEmailMutation,
  usePostResetPasswordVerifyEmailMutation,
  usePutResetPasswordWithTokenMutation,
} = resetApi;
