import React from "react";
import styled from "@mui/styled-engine";
import { Box, Button } from "@mui/material";
import { useLayoutContext } from "./context/LayoutContext";
// @ts-ignore
import { SxProps, Theme } from "@mui/material/styles";

const MainContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  &.desktop {
    overflow-y: auto;
  }
`;

const MainWrapper = styled(Box)``;

interface MainProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  hasSetting?: boolean;
}

export const Main = ({ children, sx, hasSetting }: MainProps) => {
  const { platform, setSettingOpenOnMobile, isDesktop } = useLayoutContext();
  return (
    <MainContainer id="main-container" className={platform} sx={sx}>
      <MainWrapper>
        {!isDesktop && hasSetting && (
          <Button onClick={() => setSettingOpenOnMobile?.(true)}>
            세팅열기
          </Button>
        )}
        {children}
      </MainWrapper>
    </MainContainer>
  );
};
