import React, { useCallback } from "react";
import { DayHeaderContentArg } from "@fullcalendar/common";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styled from "@mui/styled-engine";
import { IconButton } from "@mui/material";

const StyledArrowIconButton = styled(IconButton)`
  position: absolute;
  top: 46px;
  z-index: 3;
  &.left {
    left: 12px;
  }
  &.right {
    right: 12px;
  }
`;

export function useHandleDayHeaderContentChange({
  calendarRef,
  isMobile,
}: {
  calendarRef: React.MutableRefObject<FullCalendar | null>;
  isMobile?: boolean;
}) {
  const handleCalendarPrevClick = useCallback(() => {
    calendarRef?.current?.getApi().prev();
  }, [calendarRef]);
  const handleCalendarNextClick = useCallback(() => {
    calendarRef?.current?.getApi().next();
  }, [calendarRef]);
  return useCallback(
    (args: DayHeaderContentArg) => {
      const { date, text, dow } = args;
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
      return (
        <div style={{ display: "flex", flexDirection: "column" }} key={text}>
          <span>{formatDate(date, { month: "long", locale: "ko" })}</span>
          <span>{formatDate(date, { day: "numeric", locale: "en" })}</span>
          <span>{formatDate(date, { weekday: "long", locale: "ko" })}</span>
          {dow === 0 ? (
            <StyledArrowIconButton
              aria-label="go to prev"
              className={"left"}
              onClick={handleCalendarPrevClick}
            >
              <ArrowLeftIcon />
            </StyledArrowIconButton>
          ) : undefined}
          {(!isMobile && dow === 6) || (isMobile && dow === 2) ? (
            <StyledArrowIconButton
              aria-label="go to next"
              className={"right"}
              onClick={handleCalendarNextClick}
            >
              <ArrowRightIcon />
            </StyledArrowIconButton>
          ) : undefined}
        </div>
      );
    },
    [handleCalendarPrevClick, handleCalendarNextClick, isMobile]
  );
}
