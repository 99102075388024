import React from "react";
import styled from "@mui/styled-engine";
import { Box, Button, Card, Fade, Modal, ModalProps } from "@mui/material";

const StyledCommonModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const StyledCommonCard = styled(Card)<{ width: number }>`
  width: ${(props) => props.width}px;
  padding: 30px 25px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  .common-modal {
    &-header {
      position: relative;
      font-size: 24px;
      font-weight: 600;
      color: #232323;
      margin-bottom: 20px;
    }
    &-description {
      font-size: 14px;
      font-weight: 500;
      color: #232323;
      margin-bottom: 30px;
    }
    &-content {
      margin-bottom: 10px;
      max-height: calc(100vh - 220px);
      overflow-y: auto;
    }
    &-footer {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      column-gap: 10px;
      & > button {
        white-space: nowrap;
        height: 48px;
      }
    }
  }
`;

type ButtonColorProp = React.ComponentProps<typeof Button>["color"];
interface CommonModalFooterButtonProps {
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: ButtonColorProp;
  cancelButtonColor?: ButtonColorProp;
  confirmButtonDisabled?: boolean;
  cancelButtonDisabled?: boolean;
}
export interface CommonModalProps
  extends Omit<ModalProps, "children">,
    CommonModalFooterButtonProps {
  width?: number;
  title?: string;
  description?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  noHeader?: boolean;
}

export function CommonModal({
  title,
  description,
  width = 300,
  onCancel,
  onConfirm,
  open,
  noHeader = false,
  footer,
  children,
  confirmButtonText = "OK",
  cancelButtonText = "CANCEL",
  confirmButtonColor = "secondary",
  cancelButtonColor = "normal",
  confirmButtonDisabled = false,
  cancelButtonDisabled = false,
  ...props
}: CommonModalProps) {
  return (
    <StyledCommonModal open={open} {...props}>
      <Fade in={open}>
        <StyledCommonCard width={width}>
          {!noHeader && (
            <>
              <Box className="common-modal-header">{title}</Box>
              {description && (
                <Box component="p" className="common-modal-description">
                  {description}
                </Box>
              )}
            </>
          )}
          <Box className="common-modal-content">{children}</Box>
          <Box className="common-modal-footer">
            {footer ? (
              footer
            ) : (
              <>
                <Button
                  variant="inner"
                  color={cancelButtonColor}
                  onClick={onCancel}
                  disabled={cancelButtonDisabled}
                >
                  {cancelButtonText}
                </Button>
                <Button
                  variant="inner"
                  color={confirmButtonColor}
                  onClick={onConfirm}
                  disabled={confirmButtonDisabled}
                >
                  {confirmButtonText}
                </Button>
              </>
            )}
          </Box>
        </StyledCommonCard>
      </Fade>
    </StyledCommonModal>
  );
}
