import React, { useState } from "react";
import { Box } from "@mui/material";
import "moment/locale/ko";
import { ChangeScheduleCalendar } from "../../components/calendars/ChangeScheduleCalendar"; // FIXME: localization setting 에 따라 locale dynamic 설정

export function CalendarPage() {
  const [attend, setAttend] = useState(true);
  return (
    <Box>
      <ChangeScheduleCalendar
        attend={attend}
        me={"test@example.com"}
        others={[]}
        schedules={[]}
        onAttendOrNotChange={(attend) => setAttend(attend)}
      />
    </Box>
  );
}
