import styled from "@mui/styled-engine";
import { Card, CardContent } from "@mui/material";

export const StyledFormCard = styled(Card)`
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: none;
  border: none;
`;

export const StyledFormCardContent = styled(CardContent)`
  padding: 30px 20px;
  & > .card-title {
    font-size: 24px;
    font-weight: bold;
    color: #232323;
    margin-bottom: 3px;
  }

  & > .card-description {
    font-size: 14px;
    color: #767676;
    margin-bottom: 30px;
  }

  & > .card-form {
    margin-bottom: 24px;
  }

  .textfield {
    margin-bottom: 12px;
    input {
      background: #ffffff;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
