export interface TokenRequest {
  token: string;
}

export interface TokenResponse {
  token: string | null;
}

export interface User {
  id: number;
  name: string;
  email: string;
  is_active: boolean;
  bg_color: string;
}
export interface UserRequest {
  email: string;
  password: string;
}

export interface UserSignUpRequest extends UserRequest {
  name: string;
  agree_marketing: boolean;
}

export interface UserSignUpRequestWithToken extends UserSignUpRequest {
  token: string;
}

export interface UserResponse extends TokenResponse {
  user: User;
}

export interface UserResponseFromGoogle extends UserResponse {
  is_register?: boolean; // 일반 가입 필요할 경우 true
}

export interface StatusError {
  data: unknown;
  status: number;
}

export function isStatusError(a: any): a is StatusError {
  return typeof a?.status === "number";
}
