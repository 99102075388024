import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserResponse } from "../app/services/accounts/types";
import { authSlice } from "../features/auth/authSlice";
import { useAppDispatch } from "../app/hooks";

/*
login 후 data를 설정하고 redirect 한다.
 */

export interface RedirectTo {
  success?: string;
  failure?: string;
}
export function useLogIn(data?: UserResponse, redirectTo?: RedirectTo) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      if (data.token && data.user.is_active) {
        dispatch(
          authSlice.actions.setCredentials({
            user: data.user,
            token: data.token,
          })
        );
        setIsSuccess(true);
        setIsError(false);
        if (redirectTo?.success) history.push(redirectTo.success);
      } else {
        setIsSuccess(false);
        setIsError(true);
        if (redirectTo?.failure) history.push(redirectTo.failure);
      }
    }
  }, [dispatch, history, data, redirectTo]);

  return { isError, isSuccess };
}
