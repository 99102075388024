import { useCallback } from "react";
import { EventInput } from "@fullcalendar/react";

export function useHandleEventMouseLeave({
  onTempEventInputChange,
}: {
  onTempEventInputChange: React.Dispatch<
    React.SetStateAction<EventInput | undefined>
  >;
}) {
  return useCallback(() => {
    onTempEventInputChange(undefined);
  }, [onTempEventInputChange]);
}
