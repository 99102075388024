import styled from "@mui/styled-engine";
import { CommonModal } from "../modal/CommonModal";

export const StyledCommonModalForEdit = styled(CommonModal)`
  .container-with-title {
    padding-top: 0;
  }
  .MuiPaper-root {
    border-radius: 0;
  }
  .MuiCardContent-root {
    padding: 0;
  }
`;
