import React from "react";
import { useTranslation } from "react-i18next";
import { ContainerWithTitle } from "../../components/commons/ContainerWithTitle";
import { AvatarCard } from "../../components/cards/AvatarCard";
import { useUserMe } from "../../hooks/useUserMe";
import { MoreButton } from "../../components/buttons/MoreButton";
import { AvatarCardWrapper } from "../../components/cards/AvatarCardWrapper";

export function PersonalInfoPage() {
  const { data } = useUserMe({
    failure: "/login",
  });
  const { t: tPersonalInfo } = useTranslation("managePersonalInfo");

  return data ? (
    <>
      <ContainerWithTitle
        title={tPersonalInfo("Title")}
        rightButton={
          <MoreButton
            options={[
              { title: tPersonalInfo("ChangePassword"), to: "/reset/password" },
              { title: tPersonalInfo("Leave"), to: "/leave" },
            ]}
          />
        }
        isRightButtonWithTitle={true}
      >
        <AvatarCardWrapper>
          <AvatarCard
            email={data.user.email}
            name={data.user.name}
            bgColor={data.user.bg_color}
          />
        </AvatarCardWrapper>
      </ContainerWithTitle>
    </>
  ) : null;
}
