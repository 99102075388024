import React, { ComponentProps } from "react";
import styled from "styled-components";
import { Box, Button, Typography } from "@mui/material";
import {
  PollEventGuestCandidate,
  PollEventGuestResult,
} from "../../../app/services/poll/types";

export const StyledGuestAddWidgetBox = styled(Box)`
  padding: 30px 20px;
  border-radius: 10px;
  background-color: #f5f5f5;
  position: relative;
`;

export const StyledGuestAddWidgetTitle = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  color: #232323;
  margin-bottom: 16px;
`;

export const StyledGuestAddWidgetDescription = styled(Typography)`
  font-size: 14px;
  color: #7e7e7e;
  margin-bottom: 32px;
`;

export const StyledGuestAddButton = styled(Button)`
  position: absolute;
  top: 30px;
  right: 20px;
`;

export interface GuestAddWidgetProps {
  guests: PollEventGuestResult[];
  children: React.ReactNode;
  isAddLoading?: boolean;
  onSubmit: (guests: PollEventGuestCandidate[]) => void;
}

export const GuestAddWidgetTitle = () => (
  <StyledGuestAddWidgetTitle>
    이벤트에 참여하는 게스트를 등록하시겠어요 ?
  </StyledGuestAddWidgetTitle>
);

export const GuestAddWidgetDescription = () => (
  <StyledGuestAddWidgetDescription>
    게스트 등록 시 등록한 이메일로 초대장이 발송됩니다. <br />
    이메일 외 연락이 필요한 경우 아래 URL을 공유하여 이벤트에 초대가 가능합니다.
  </StyledGuestAddWidgetDescription>
);

export const GuestAddButton = (
  props: ComponentProps<typeof StyledGuestAddButton>
) => (
  <StyledGuestAddButton
    {...props}
    variant="contained"
    color="primary"
    size="large"
  >
    {"게스트 등록하기"}
  </StyledGuestAddButton>
);
