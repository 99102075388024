import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { User } from "../../app/services/accounts/types";

const name = "auth";

export interface AuthState {
  user?: User;
  token?: string;
}

const initialState: AuthState = {
  user: undefined,
  token: undefined,
};

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthState["user"]>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<AuthState["token"]>) => {
      state.token = action.payload;
    },
    setCredentials: (
      state,
      {
        payload: { user, token },
      }: PayloadAction<{
        user: AuthState["user"];
        token: AuthState["token"];
      }>
    ) => {
      state.user = user;
      state.token = token;
    },
    clearCredentials: (state) => {
      state.user = undefined;
      state.token = undefined;
    },
  },
});

export const authSelectors = {
  selectState: (state: RootState) => state[authSlice.name],
};
