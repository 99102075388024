import React from "react";
import { EventPageWithMeParams } from "./EventPage";
import { usePostEventsInfoQuery } from "../../app/services/events/eventApi";
import { GuestEmailAndKey } from "../../app/services/events/types";
import { Redirect } from "react-router-dom";
import { CommonEventForm } from "../../components/forms/CommonEventForm";
import { DeleteEventButtonWithModals } from "./DeleteEventButtonWithModals";
import { useIsDeletedOrFinishedEvent } from "../../hooks/useIsDeletedOrFinishedEvent";

export interface CommonEventPageProps extends EventPageWithMeParams {}

export function CommonEventPage({ eventId, me }: CommonEventPageProps) {
  const { data: eventInfo /*,refetch: refetchEventInfo */ } =
    usePostEventsInfoQuery({
      id: eventId,
      emailAndKey: me.key !== undefined ? (me as GuestEmailAndKey) : undefined,
    }); // FIXME: event page 마다 반복 되므로 hook 이나 redux 쪽으로 빼기
  const isDeletedOrFinished = useIsDeletedOrFinishedEvent(eventInfo);

  return eventInfo && isDeletedOrFinished && !eventInfo.is_host ? (
    <Redirect to={"/"} />
  ) : (
    <CommonEventForm
      me={me.email}
      eventInfo={eventInfo}
      editEventButton={<DeleteEventButtonWithModals eventInfo={eventInfo} />}
    />
  );
}
