import React from "react";
import styled from "@mui/styled-engine";
import { Typography } from "@mui/material";
import { ContainerWithTitle } from "./ContainerWithTitle";

export const StyledErrorMessage = styled(Typography)`
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #f00;
`;

export interface FormWithValidatorProps {
  title?: string;
  description?: string;
  errorMessage?: string;
  children?: React.ReactNode;
}
export function FormWithValidator({
  title,
  description,
  errorMessage,
  children,
}: FormWithValidatorProps) {
  return (
    <ContainerWithTitle
      title={title}
      description={description}
      hasQuotation={true}
    >
      {children}
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </ContainerWithTitle>
  );
}
