import React from "react";
import { EmailTitle } from "./commons/EmailTitle";
import { EmailInfoBox } from "./commons/EmailInfoBox";
import { EmailContainer } from "./commons/EmailContainer";
import { EmailButton } from "./commons/EmailButton";
import { EmailInfoTitle } from "./commons/EmailInfoTitle";
import { EmailInfoDescription } from "./commons/EmailInfoDescription";
import { useEmailTranslation } from "./useEmailTranslation";
import { EmailDescriptionText } from "./commons/EmailDescriptionText";
import { EmailTemplateCommonProps } from "./commons/types";
import { EmailHeader } from "./commons/EmailHeader";

export interface InvitationToEventMailTemplateEvent {
  hostName: string;
  title: string;
  description: string | null;
  duration: string;
  eventType: string | null;
  placeDetail: string | null;
  url: string;
}

export interface InvitationToEventMailTemplateProps
  extends EmailTemplateCommonProps {
  hostName: string;
  title: string;
  description: string | null;
  duration: string;
  eventType: string | null;
  placeDetail: string | null;
  url?: string;
  guestMail: string;
}

export function InvitationToEventMailTemplate({
  hostName,
  title,
  description,
  duration,
  eventType,
  placeDetail,
  expirationTime,
  url,
  guestMail,
}: InvitationToEventMailTemplateProps) {
  const { t: tFunc } = useEmailTranslation("invitationToEventMail");
  return (
    <EmailContainer
      title={tFunc("Title", { hostName: hostName, event: title })}
      expirationTime={expirationTime}
    >
      <EmailHeader />
      <EmailTitle
        title={tFunc("Host", {
          hostName: hostName,
        })}
        titleDecorator={tFunc("EventDecorated", { event: title })}
        titlePostfix={tFunc("InviteToEvent")}
      />
      <EmailDescriptionText style={{ padding: "0 0 30px" }}>
        {tFunc("Description", { hostName: hostName, guestMail })}
      </EmailDescriptionText>
      <EmailInfoBox>
        <>
          <EmailInfoTitle style={{ padding: "0 0 10px" }}>
            {tFunc("EventDecorated", { event: title })}
          </EmailInfoTitle>
          <EmailInfoDescription style={{ padding: "0 0 15px" }}>
            {description || ""}
          </EmailInfoDescription>
          <EmailInfoDescription style={{ padding: "0 0 10px" }}>
            - {tFunc("Duration", { duration })}
          </EmailInfoDescription>
          <EmailInfoDescription>
            - {tFunc("Place", { eventType, placeDetail })}
          </EmailInfoDescription>
        </>
      </EmailInfoBox>
      {url ? (
        <EmailButton to={url} style={{ padding: "0 0 30px" }}>
          {tFunc("Button")}
        </EmailButton>
      ) : null}
    </EmailContainer>
  );
}
