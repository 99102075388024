import React from "react";
import styled, { css } from "@mui/styled-engine";
import { Box, Checkbox } from "@mui/material";
import { SelectedEvent } from "./SelectedEventList";
import moment from "moment/moment";
import { PollEventGuestResult } from "../../../app/services/poll/types";

const SelectScheduleCheckboxContainer = styled(Box)<{
  checked: boolean;
}>`
  position: relative;
  height: 111px;
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ checked }) =>
    checked &&
    css`
      border: solid 1px #4555ff;
      outline: solid 1px #4555ff;
      background-color: #eef0ff;
    `};
`;

const SelectScheduleCheckboxIndex = styled(Box)`
  width: 24px;
  height: 24px;
  background-color: #555;
  flex: 0 0 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  margin-left: 30px;
`;

const SelectScheduleCheckboxDate = styled(Box)`
  font-size: 26px;
  font-weight: bold;
  color: #232323;
  margin-left: 24px;
`;

const SelectScheduleCheckboxHourRange = styled(Box)`
  display: inline-block;
  padding-top: 4px;
  margin-left: 16px;
  font-size: 20px;
  color: #333;
`;

const SelectScheduleCheckboxRateContainer = styled(Box)`
  width: 100%;
  position: absolute;
  left: 102px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  bottom: 7px;
`;

const SelectScheduleCheckboxRate = styled(Box)`
  position: relative;
  display: block;
  overflow: hidden;
  width: calc(100% - 200px);
  background-color: #d8d8d8;
  height: 6px;
  > span {
    position: absolute;
    width: 100%;
    right: 100%;
    display: block;
    height: 6px;
    background-color: #4555ff;
    transition: transform 0.3s ease-in-out;
  }
`;

const SelectScheduleCheckboxRateNumber = styled(Box)`
  font-size: 20px;
  color: #333;
  margin-left: 12px;
`;

export interface SelectedScheduleWithGuests extends SelectedEvent {
  select_count: number;
  guests: PollEventGuestResult[];
}

export interface SelectSchedule {
  schedule: SelectedScheduleWithGuests;
  checked: boolean;
  guestTotal: number;
}

interface SelectScheduleCheckboxProps extends SelectSchedule {
  index: number;
  disabled?: boolean;
  onChange: (checked: boolean, index: number) => void;
}

const hhHourFormatStr = "a hh:mm";
export const SelectScheduleCheckbox = ({
  schedule,
  index,
  checked,
  onChange,
  guestTotal,
  disabled,
}: SelectScheduleCheckboxProps) => {
  const handleClick = () => {
    if (!disabled) onChange(!checked, index);
  };

  const currentLength = schedule.guests.length + (checked ? 1 : 0);

  return (
    <SelectScheduleCheckboxContainer onClick={handleClick} checked={checked}>
      <Checkbox checked={checked} disabled={disabled} />
      <SelectScheduleCheckboxIndex>{index + 1}</SelectScheduleCheckboxIndex>
      <SelectScheduleCheckboxDate>
        {moment(schedule.start).format("MM.DD dddd")}
      </SelectScheduleCheckboxDate>
      <SelectScheduleCheckboxHourRange>
        {moment(schedule.start).format(hhHourFormatStr) +
          " ~ " +
          moment(schedule.end).format(hhHourFormatStr)}
      </SelectScheduleCheckboxHourRange>
      <SelectScheduleCheckboxRateContainer>
        <SelectScheduleCheckboxRate>
          <Box
            component="span"
            sx={{
              transform: `translateX(${(currentLength / guestTotal) * 100}%)`,
            }}
          />
        </SelectScheduleCheckboxRate>
        <SelectScheduleCheckboxRateNumber>
          {currentLength} / {guestTotal}
        </SelectScheduleCheckboxRateNumber>
      </SelectScheduleCheckboxRateContainer>
    </SelectScheduleCheckboxContainer>
  );
};
