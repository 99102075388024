import React, { useCallback, useEffect, useState } from "react";
import { EventPageWithMeParams } from "./EventPage";
import {
  EventInfoRequest,
  GuestEmailAndKey,
} from "../../app/services/events/types";
import { GuestEventForm } from "../../components/forms/GuestEventForm";
import {
  usePostEventsInfoQuery,
  usePostSchedulesQuery,
  usePostAttendeeQuery,
  usePostSelectSchedulesMutation,
  usePutUpdateStatusMutation,
} from "../../app/services/events/eventApi";
import { useEventAttendStatus } from "../../hooks/useEventAttendStatus";
import { SkipToken, skipToken } from "@reduxjs/toolkit/query";
import { ExtendedEvent } from "../../components/calendars/BaseCalendar";

export interface GuestEventPageProps extends EventPageWithMeParams {}

/*
게스트 일정 선택 페이지
 */
export function GuestEventPage({ eventId, me }: GuestEventPageProps) {
  const { data: event /*,refetch: refetchEventInfo */ } =
    usePostEventsInfoQuery({
      id: eventId,
      emailAndKey: me.key !== undefined ? (me as GuestEmailAndKey) : undefined,
    });
  const [queryArg, setQueryArg] = useState<SkipToken | EventInfoRequest>(
    skipToken
  );

  const { data: schedules } = usePostSchedulesQuery(queryArg);
  const { data: attendData } = usePostAttendeeQuery(queryArg);
  const [putUpdateStatus, { isError: isPutUpdateStatusError }] =
    usePutUpdateStatusMutation();
  const [
    postSelectSchedules,
    {
      isSuccess: isPostSuccess,
      isError: isPostError,
      isLoading: isPostLoading,
    },
  ] = usePostSelectSchedulesMutation();
  const attend = useEventAttendStatus(attendData);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const handleAttendOrNotChange = useCallback(
    (attend) => {
      if (queryArg !== skipToken) {
        putUpdateStatus({ ...queryArg, status: attend ? 1 : 2 });
      }
    },
    [putUpdateStatus, queryArg]
  );
  const handleSubmit = useCallback(
    (schedules: ExtendedEvent[]) => {
      if (queryArg !== skipToken) {
        postSelectSchedules({
          ...queryArg,
          schedules: schedules.map((d) => ({
            from_time: d.start?.toISOString() as string,
            to_time: d.end?.toISOString() as string,
          })),
        });
      }
    },
    [queryArg, postSelectSchedules]
  );
  const handleSchedulesChange = useCallback(() => {
    setSubmitDisabled(false);
  }, []);

  useEffect(() => {
    setSubmitDisabled(isPostLoading || isPostSuccess || isPostError);
  }, [isPostError, isPostSuccess, isPostLoading]);

  useEffect(() => {
    setQueryArg(
      me.key === undefined
        ? event !== undefined
          ? { id: event.id }
          : skipToken
        : { id: eventId, emailAndKey: me as GuestEmailAndKey }
    );
  }, [me, eventId, event]);

  useEffect(() => {
    // TODO: attend 변경 실패 시 보고
  }, [isPutUpdateStatusError]);

  useEffect(() => {
    // TODO: 제출 실패 보고
  }, [isPostError]);

  return (
    <GuestEventForm
      attend={attend}
      me={me.email}
      event={event}
      schedules={schedules || []}
      submitDisabled={submitDisabled}
      onAttendOrNotChange={handleAttendOrNotChange}
      onSchedulesChange={handleSchedulesChange}
      onSubmit={handleSubmit}
    />
  );
}
