import styled from "@mui/styled-engine";
import { Avatar, AvatarGroup } from "@mui/material";

const Size = {
  MEDIUM: 44,
  SMALL: 34,
};

export const StyledAvatarGroup = styled(AvatarGroup)<{ size?: string }>`
  & > .MuiAvatarGroup-avatar {
    width: ${(props) => (props.size === "small" ? Size.SMALL : Size.MEDIUM)}px;
    height: ${(props) => (props.size === "small" ? Size.SMALL : Size.MEDIUM)}px;
    font-size: 12px;
  }
`;

export const StyledAvatar = styled(Avatar)<{ bgColor: string; size?: string }>`
  width: ${(props) => (props.size === "small" ? Size.SMALL : Size.MEDIUM)}px;
  height: ${(props) => (props.size === "small" ? Size.SMALL : Size.MEDIUM)}px;
  background-color: ${(props) => props.bgColor};
`;
