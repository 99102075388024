import styled from "@mui/styled-engine";
import { Typography } from "@mui/material";

export const PageTitle = styled(Typography)`
  font-size: 28px;
  font-weight: bold;
  color: #232323;
`;

export const SubTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  color: #232323;
`;

export const SettingTitle = styled(Typography)`
  font-size: 16px;
  color: #767676;
  margin-bottom: 14px;
  & > .required {
    color: #ff1919;
  }
`;

export const BigTitle = styled(Typography)`
  font-size: 28px;
  font-weight: bold;
  color: #232323;
  > strong {
    width: 100%;
    color: #4555ff;
  }
`;

export const MainTitle = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  color: #232323;
  > strong {
    color: #4555ff;
  }
`;

export const SubDescription = styled(Typography)`
  font-size: 14px;
  color: #767676;
  white-space: pre-line;
`;

export const ContentTitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  > strong {
    color: #4555ff;
  }
`;

export const ContentDescription = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #333;
`;
