import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { GuestEmailAndKey } from "../../app/services/events/types";
import { GuestItem } from "../event/GuestItem";
import { VerifyGuestModal } from "../modal/VerifyGuestModal";

export interface VerifyGuestFormProps {
  guests: GuestEmailAndKey[];
  errorStatusCode?: number;
  onSubmit: (guest: GuestEmailAndKey) => void;
}

export function VerifyGuestForm({
  guests,
  errorStatusCode,
  onSubmit,
}: VerifyGuestFormProps) {
  const { t } = useTranslation("verifyGuest");
  const [selectedGuest, setSelectedGuest] = useState<
    GuestEmailAndKey | undefined
  >(undefined);

  const handleGuestItemClick = useCallback((guest: GuestEmailAndKey) => {
    setSelectedGuest(guest);
  }, []);

  const handleVerifyGuestModalClose = useCallback(() => {
    setSelectedGuest(undefined);
  }, []);

  const handleVerifyGuestModalSubmit = useCallback(
    (value) => {
      onSubmit(value);
    },
    [onSubmit]
  );

  useEffect(() => {
    return () => {
      setSelectedGuest(undefined); // reset on unmount
    };
  }, []);

  return (
    <>
      <ContainerWithTitle title={t("Title")}>
        <Box sx={{ padding: `20px 0` }}>
          {guests.map((d, i) => (
            <GuestItem
              onClick={() => handleGuestItemClick(d)}
              key={`${d.email}-${i}`}
              isHost={false}
              guest={{
                email: d.email,
              }}
              isMe={false}
              type="card"
            />
          ))}
        </Box>
      </ContainerWithTitle>
      <VerifyGuestModal
        open={selectedGuest !== undefined}
        guest={selectedGuest}
        errorStatusCode={errorStatusCode}
        onSubmit={handleVerifyGuestModalSubmit}
        onClose={handleVerifyGuestModalClose}
      />
    </>
  );
}
