import React from "react";
import { Item, Span } from "react-html-email";

const emailInfoDescriptionStyle = {
  fontSize: 12,
  fontWeight: "bold",
  color: "#7e7e7e",
};

export interface EmailInfoDescriptionProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}
export function EmailInfoDescription({
  children,
  style,
}: EmailInfoDescriptionProps) {
  return (
    <Item style={style}>
      <Span {...emailInfoDescriptionStyle}>{children}</Span>
    </Item>
  );
}
