import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";

export interface InputValidationBase {}
export interface InputFormValueBase {}

interface InputFormatValidationProps<IV, FV> {
  name: keyof IV;
  onChange?: (value?: FV) => void;
  value?: FV;
  errorMessage?: string;
  isAfterSubmit: boolean;
  inputFulfillValidations: IV;
  inputFormatValidations: IV;
}

export const useInputFormatValidation = <
  IV extends InputValidationBase,
  FV extends InputFormValueBase
>({
  name,
  onChange,
  value,
  errorMessage,
  isAfterSubmit,
  inputFulfillValidations,
  inputFormatValidations,
}: InputFormatValidationProps<IV, FV>) => {
  const [isError, setIsError] = useState(false);
  const handleChange = useCallback<
    NonNullable<React.ComponentProps<typeof TextField>["onChange"]>
  >(
    (e) => {
      if (onChange) onChange({ ...value, [name]: e.target.value } as FV);
    },
    [name, onChange, value]
  );
  useEffect(() => {
    setIsError(
      errorMessage !== undefined ||
        !inputFormatValidations[name] ||
        (isAfterSubmit && !inputFulfillValidations[name])
    );
  }, [
    errorMessage,
    name,
    inputFormatValidations,
    isAfterSubmit,
    inputFulfillValidations,
  ]);

  return {
    isError,
    handleChange,
  };
};
