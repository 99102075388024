import React, { useCallback } from "react";
import { CommonModal } from "./CommonModal";
import { useTranslation } from "react-i18next";
import styled from "@mui/styled-engine";
import { Box, Typography } from "@mui/material";

const StyledDeleteEventModal = styled(Box)`
  margin-bottom: 30px;
`;
export interface DeleteEventModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export function DeleteEventModal({
  open,
  onSave,
  onClose,
}: DeleteEventModalProps) {
  const { t } = useTranslation("deleteEvent");
  const { t: tEditEvent } = useTranslation("editEvent");

  const handleConfirm = useCallback(() => {
    onSave();
    onClose();
  }, [onSave, onClose]);

  return (
    <CommonModal
      open={open}
      title={t("Title")}
      onConfirm={handleConfirm}
      onClose={onClose}
      onCancel={onClose}
      confirmButtonText={tEditEvent("Yes")}
      confirmButtonColor="secondary"
      confirmButtonDisabled={false}
      cancelButtonText={tEditEvent("No")}
      cancelButtonColor="normal"
    >
      <StyledDeleteEventModal>
        <Typography>{t("Description")}</Typography>
      </StyledDeleteEventModal>
    </CommonModal>
  );
}
