import React, { useEffect, useState } from "react";
import styled, { css } from "@mui/styled-engine";
import { Box, Button, Fab } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useLayoutContext } from "./context/LayoutContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const FloatingContainer = styled(Box)<{ open: boolean }>`
  left: 100%;
  height: 100vh;
  transform: translateX(${({ open }) => (open ? "-100%" : 0)});
  position: fixed;
  top: 0;
  z-index: 1001;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow-y: auto;
  padding: 52px 34px;
  background-color: #f8fafb;
  ${({ open }) =>
    open
      ? css`
          box-shadow: -18px 0 24px 0 rgba(0, 0, 0, 0.1);
        `
      : css`
          box-shadow: none;
        `}
  &.desktop {
    width: 337px;
  }
  &.mobile {
    width: 100vw;
  }
`;

const FloatingWrapper = styled(Box)``;

const CloseButton = styled(Button)`
  position: absolute;
  top: 4px;
  right: 4px;
  color: #000000;
  min-width: 36px;
`;

const FloatingButton = styled(Fab)<{ isDesktop: boolean }>`
  position: fixed;
  z-index: 1000;
  padding: 0 22px;
  border-radius: 28px;
  width: unset;
  ${({ isDesktop }) =>
    !isDesktop
      ? css`
          width: 100vw;
          border-radius: 0;
          bottom: 0;
        `
      : css`
          right: 40px;
          bottom: 40px;
        `}
`;

interface FloatingProps {
  children: React.ReactNode;
  defaultOpen?: boolean;
  buttonText: string;
}

export const Floating = ({
  children,
  defaultOpen = false,
  buttonText = "",
}: FloatingProps) => {
  const { platform, isDesktop } = useLayoutContext();
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    if (!isDesktop) {
      document.body.style.overflow = open ? "hidden" : "";
    }
  }, [isDesktop, open]);

  return (
    <>
      <FloatingContainer open={open} className={platform}>
        <CloseButton variant="text" onClick={() => setOpen(false)}>
          <CloseRoundedIcon />
        </CloseButton>
        <FloatingWrapper>{children}</FloatingWrapper>
      </FloatingContainer>
      <FloatingButton
        isDesktop={!!isDesktop}
        color="primary"
        aria-label="edit"
        onClick={() => setOpen(true)}
      >
        {buttonText} <ArrowForwardRoundedIcon />
      </FloatingButton>
    </>
  );
};
