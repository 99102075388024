import React from "react";
import { Item, Span } from "react-html-email";
import { EmailCommonProps } from "./types";

const descriptionTextStyle = {
  fontSize: 18,
  fontWeight: "bold",
  color: "#333",
  lineHeight: 22,
};

export interface EmailDescriptionTextProps extends EmailCommonProps {}
export function EmailDescriptionText({
  children,
  style,
}: EmailDescriptionTextProps) {
  return (
    <Item style={style}>
      <Span {...descriptionTextStyle}>{children}</Span>
    </Item>
  );
}
