import React, { useEffect, useState } from "react";
import validator from "validator";
import { FormControl, MenuItem } from "@mui/material";
import { TextFieldWithValidation } from "../inputs/TextFieldWithValidation";
import { EventTypeKey, eventTypeKeys } from "../../app/services/events/types";
import { TFunction, useTranslation } from "react-i18next";
import {
  useFormValidation,
  ValidationOptions,
} from "../../hooks/useFormValidation";
import { convertMinutesToText } from "../../utils/format";

export interface EventInputListValue {
  title?: string;
  description?: string;
  event_type?: EventTypeKey;
  place_detail?: string;
  duration_minutes?: number;
}

export const initEventInputListValue: EventInputListValue = {
  title: undefined,
  description: undefined,
  event_type: undefined,
  place_detail: undefined,
  duration_minutes: undefined,
};

export const eventInputListValueKeys: (keyof EventInputListValue)[] = [
  "title",
  "description",
  "event_type",
  "place_detail",
  "duration_minutes",
];

export const eventInputListValidators: ValidationOptions<EventInputListValue> =
  {
    title: {
      required: true,
      specialCharacterNotAllowed: true,
      min: 0,
      max: 25,
    },
    description: {
      required: false,
      specialCharacterNotAllowed: true,
      min: 0,
      max: 400,
    },
    event_type: { required: false },
    place_detail: {
      required: false,
      specialCharacterNotAllowed: true,
      min: 0,
      max: 80,
    },
    duration_minutes: { required: true },
  };

export interface EventInputListProps {
  value: EventInputListValue;
  errorStatusCode?: number;
  isAfterSubmit?: boolean;
  disabledKeys?: (keyof EventInputListValue)[];
  onChange?: (value: EventInputListValue) => void;
  onValidationChange?: (
    validation: ReturnType<typeof useFormValidation>
  ) => void;
}

export interface DurationMinuteItem {
  minutes: number;
  label: string;
}

export const getDurationMinuteItems = (
  translator: TFunction<"eventInputList">
) => {
  const minuteExamples: DurationMinuteItem[] = [
    { minutes: 24 * 60, label: translator("AllDay") },
  ];
  for (let i = 0; i < 12; i++) {
    const totalMinutes = (i + 1) * 30;
    minuteExamples.push({
      minutes: totalMinutes,
      label: convertMinutesToText(
        totalMinutes,
        translator("Hour"),
        translator("Minute")
      ),
    });
  }
  return minuteExamples;
};

const notAllowedSpecialCharacters = "<>'\"";
const notAllowedSpecialCharacterRegex = RegExp(
  `[${notAllowedSpecialCharacters}]`,
  "gi"
);
export function EventInputList({
  value,
  isAfterSubmit,
  disabledKeys,
  onChange,
  onValidationChange,
}: EventInputListProps) {
  const { t } = useTranslation("eventInputList"); // eventInputList 옮기기
  const [durationMinuteItems, setDurationMinuteItems] = useState<
    DurationMinuteItem[]
  >([]);
  const validation = useFormValidation(
    eventInputListValueKeys,
    value,
    eventInputListValidators
  );

  useEffect(() => {
    if (t) {
      setDurationMinuteItems(getDurationMinuteItems(t));
    }
  }, [t]);
  useEffect(() => {
    if (onValidationChange) onValidationChange(validation);
  }, [validation, onValidationChange]);

  return (
    <>
      {eventInputListValueKeys.map((key) => {
        const label = t(`labels.${key}`);
        const isValueFulfill = validation.isFulfillValid[key];

        const { min, max, specialCharacterNotAllowed } =
          eventInputListValidators[key];

        const isSpecialCharacterNotValid =
          specialCharacterNotAllowed && typeof value[key] === "string"
            ? validator.matches(
                value[key] as string,
                notAllowedSpecialCharacterRegex
              )
            : false;

        const errorMessage =
          isAfterSubmit && !isValueFulfill
            ? t(`needInput.${key}`)
            : isSpecialCharacterNotValid && key !== "place_detail"
            ? t(`invalidFormats.isNotAllowedSpecialCharacter`, {
                notAllowedSpecialCharacters,
              })
            : undefined;

        const onValueChangeWithMinMax = (
          changedValue: string | undefined,
          field: "title" | "description" | "place_detail"
        ) => {
          if (
            onChange &&
            (changedValue === undefined ||
              validator.isLength(changedValue, {
                min,
                max,
              }))
          ) {
            onChange({ ...value, [field]: changedValue });
          }
        };

        if (key === "title") {
          return (
            <FormControl key={key} fullWidth={true}>
              <TextFieldWithValidation
                disabled={disabledKeys?.includes(key)}
                type={"text"}
                placeholder={label}
                value={value[key]}
                required={true}
                errorMessage={errorMessage}
                onValueChange={(value) => onValueChangeWithMinMax(value, key)}
              />
            </FormControl>
          );
        } else if (key === "description") {
          return (
            <TextFieldWithValidation
              disabled={disabledKeys?.includes(key)}
              key={key}
              type="text"
              placeholder={label}
              value={value[key]}
              errorMessage={errorMessage}
              rows={6}
              multiline={true}
              onValueChange={(value) => onValueChangeWithMinMax(value, key)}
            />
          );
        } else if (key === "event_type") {
          return (
            <TextFieldWithValidation
              disabled={disabledKeys?.includes(key)}
              key={key}
              placeholder={label}
              select={true}
              value={value["event_type"]}
              onValueChange={(eventType) => {
                if (onChange)
                  onChange({
                    ...value,
                    event_type: eventType as EventTypeKey,
                    place_detail: undefined,
                  });
              }}
            >
              {eventTypeKeys.map((eventTypeKey) => {
                return (
                  <MenuItem key={eventTypeKey} value={eventTypeKey}>
                    {t(`eventTypeLabels.${eventTypeKey}`)}
                  </MenuItem>
                );
              })}
            </TextFieldWithValidation>
          );
        } else if (
          key === "place_detail" &&
          value["event_type"] !== undefined
        ) {
          const label = t(`placeDetailLabels.${value["event_type"]}`);
          return (
            <TextFieldWithValidation
              disabled={disabledKeys?.includes(key)}
              key={key}
              type={"text"}
              placeholder={label}
              value={value[key]}
              errorMessage={errorMessage}
              helperText={`${t("placeDetailHelperText.Prefix")}${label}${t(
                "placeDetailHelperText.Suffix"
              )}`}
              onValueChange={(value) => onValueChangeWithMinMax(value, key)}
            />
          );
        } else if (key === "duration_minutes") {
          return (
            <TextFieldWithValidation
              disabled={disabledKeys?.includes(key)}
              key={key}
              placeholder={label}
              select={true}
              value={value["duration_minutes"]}
              required={true}
              onValueChange={(durationMinutes) => {
                if (onChange)
                  onChange({
                    ...value,
                    duration_minutes: durationMinutes
                      ? +durationMinutes
                      : undefined,
                  });
              }}
            >
              {durationMinuteItems.map(({ minutes, label }) => {
                return (
                  <MenuItem key={`${minutes}`} value={minutes}>
                    {label}
                  </MenuItem>
                );
              })}
            </TextFieldWithValidation>
          );
        } else return null;
      })}
    </>
  );
}
