import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { authSelectors, authSlice } from "../features/auth/authSlice";
import { useDeleteLogoutMutation } from "../app/services/accounts/authApi";

export function useLogOut(on = true) {
  const dispatch = useAppDispatch();
  const { user, token } = useAppSelector(authSelectors.selectState);
  const [
    deleteLogout,
    { isError, isLoading, isSuccess, isUninitialized, error },
  ] = useDeleteLogoutMutation();

  useEffect(() => {
    if (on && (user || token)) {
      deleteLogout();
      dispatch(authSlice.actions.clearCredentials());
      localStorage.clear();
    }
    // eslint-disable-next-line
  }, [on]);

  return { user, token, isError, isLoading, isUninitialized, isSuccess, error };
}
