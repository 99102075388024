export const CreatePollCalendarEventType = {
  background: "background",
  occupied: "occupied",
  available: "available",
  selected: "selected",
  selectedLabel: "selectedLabel",
  tempAvailable: "tempAvailable",
  tempOccupied: "tempOccupied",
};
// eslint-disable-next-line
export type CreatePollCalendarEventType =
  | "background"
  | "occupied"
  | "available"
  | "selected"
  | "selectedLabel"
  | "tempAvailable"
  | "tempOccupied";
