import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchCsrfBaseQuery } from "../fetchCsrfBaseQuery";
import { baseUrl } from "./config";
import {
  PollCardAddRequest,
  PollCardAddResponse,
  PollCardResult,
  PollCardsResponse,
  PollEventGuestResult,
  PollEventId,
  PollEventInviteRequest,
  PollEventKey,
  PollEventRecentGuestResult,
  PollEventRequest,
  PollEventResponse,
  PollGuestSelfAddRequest,
  PollSettingRequest,
  PollSettingResponse,
} from "./types";

export const pollApi = createApi({
  baseQuery: fetchCsrfBaseQuery(`${baseUrl}/`),
  reducerPath: "poll/pollApi",
  tagTypes: ["PollEventInfo", "PollRecentGuests"],
  endpoints: (build) => ({
    postCreatePoll: build.mutation<PollEventResponse, PollEventRequest>({
      query(body) {
        return {
          url: `poll/`,
          method: "POST",
          body,
        };
      },
    }),
    postAddCards: build.mutation<PollCardAddResponse, PollCardAddRequest>({
      query({ id, ...body }) {
        return {
          url: `poll/${id}/card/add/`,
          method: "POST",
          body,
        };
      },
    }),
    postInviteGuests: build.mutation<void, PollEventInviteRequest>({
      query({ id, ...body }) {
        return {
          url: `poll/${id}/invite/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: "PollEventInfo", id: args.id }];
      },
    }),
    postUpdateSettings: build.mutation<PollSettingResponse, PollSettingRequest>(
      {
        query(body) {
          return {
            url: `settings/`,
            method: "POST",
            body,
          };
        },
      }
    ),
    getLatestSettings: build.query<PollSettingResponse, void>({
      query() {
        return {
          url: `settings/latest/`,
          method: "GET",
        };
      },
    }),
    getPollCards: build.query<PollCardsResponse, PollEventId>({
      query({ id }) {
        return {
          url: `poll/${id}/cards/`,
          method: "GET",
        };
      },
    }),
    getPollCardInfo: build.query<PollCardResult, PollEventId>({
      query({ id }) {
        return {
          url: `poll/card/${id}/info/`,
          method: "GET",
        };
      },
    }),
    getPollGuests: build.query<PollEventGuestResult[], PollEventId>({
      query({ id }) {
        return {
          url: `poll/${id}/guests/`,
          method: "GET",
        };
      },
    }),
    getPollInfo: build.query<PollEventResponse, PollEventId>({
      query({ id }) {
        return {
          url: `poll/${id}/info/`,
          method: "GET",
        };
      },
      providesTags: (result) => {
        return result
          ? [{ type: "PollEventInfo" as const, id: result.id }, "PollEventInfo"]
          : ["PollEventInfo" as const];
      },
    }),
    getPollInfoByKey: build.query<PollEventResponse, PollEventKey>({
      query({ key }) {
        return {
          url: `poll/${key}/info/`,
          method: "GET",
        };
      },
      providesTags: (result) => {
        return result
          ? [{ type: "PollEventInfo" as const, id: result.id }, "PollEventInfo"]
          : ["PollEventInfo" as const];
      },
    }),
    getRecentGuests: build.query<
      { guests: PollEventRecentGuestResult[] },
      void
    >({
      query() {
        return {
          url: `host/recent-guests/`,
          method: "GET",
        };
      },
    }),
    postPollGuestSelfAdd: build.mutation<
      { guests: PollEventGuestResult[] },
      PollGuestSelfAddRequest
    >({
      query({ key, guest }) {
        return {
          url: `poll/${key}/guest/self_add/`,
          method: "POST",
          body: guest,
        };
      },
      invalidatesTags: ["PollEventInfo"],
    }),
  }),
});

export const {
  usePostCreatePollMutation,
  usePostAddCardsMutation,
  usePostInviteGuestsMutation,
  usePostUpdateSettingsMutation,
  useGetLatestSettingsQuery,
  useLazyGetLatestSettingsQuery,
  useGetRecentGuestsQuery,
  useGetPollInfoQuery,
  useGetPollInfoByKeyQuery,
  useGetPollCardsQuery,
  usePostPollGuestSelfAddMutation,
} = pollApi;
