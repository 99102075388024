import React from "react";
import { Box } from "@mui/material";
import {
  StyledFormCard,
  StyledFormCardContent,
} from "../styled/StyledSignUpForm";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

interface FormBoxProps {
  children?: React.ReactNode;
  noMinusMargin?: boolean;
}

export function FormBox({ children, noMinusMargin = false }: FormBoxProps) {
  const isDesktop = useDesktopMatches();

  return (
    <StyledFormCard
      sx={{
        margin: `16px ${isDesktop || noMinusMargin ? 0 : -20}px 30px`,
      }}
    >
      <StyledFormCardContent>
        <Box
          component={"form"}
          sx={{
            "& > :not(style)": { mb: 1 },
          }}
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
        >
          {children}
        </Box>
      </StyledFormCardContent>
    </StyledFormCard>
  );
}
