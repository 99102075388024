import React from "react";
import { Box, Card, Link, Modal, ModalProps } from "@mui/material";
import {
  InvitationToEventMail,
  InvitationToEventMailProps,
} from "../mails/InvitationToEventMail";
import styled, { css } from "@mui/styled-engine";
import { useTranslation } from "react-i18next";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

export interface InvitationToEventMailModalProps
  extends Omit<ModalProps, "children">,
    InvitationToEventMailProps {
  onCloseButtonClick?: () => void;
}
const StyledInvitationPreviewModal = styled(Modal)<{ isDesktop: boolean }>`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  ${({ isDesktop }) =>
    !isDesktop
      ? css`
          padding: 56px 0 0 0;
          .MuiPaper-root {
            max-height: 100%;
          }
          .terms-modal-content {
            max-height: 500px;
          }
        `
      : css`
          padding: 80px 0;
        `}
`;

const StyledInvitationPreviewCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 500px;
  min-width: 320px;
  .terms-modal {
    &-header {
      position: relative;
      height: 64px;
      padding: 0 25px;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      color: #232323;
      align-items: center;
    }
    &-content {
      overflow-y: auto;
      white-space: pre-line;
      & > p {
        margin: 0;
      }
    }
    &-close {
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background: #f5f6fa;
      }
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        background-color: #111111;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 2px;
        border-radius: 1px;
        transform-origin: center center;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
`;

export function InvitationToEventMailModal({
  hostName,
  event,
  onClose,
  onCloseButtonClick,
  ...modalProps
}: InvitationToEventMailModalProps) {
  const { t } = useTranslation("createEvent");
  const isDesktop = useDesktopMatches();
  return (
    <StyledInvitationPreviewModal
      onClose={onClose}
      isDesktop={isDesktop}
      {...modalProps}
    >
      <StyledInvitationPreviewCard>
        <Box className="terms-modal-header">
          {t("PreviewText")}
          <Link
            component="span"
            className="terms-modal-close"
            onClick={() => {
              if (onCloseButtonClick) {
                onCloseButtonClick();
              }
            }}
          />
        </Box>
        <Box className="terms-modal-content">
          <InvitationToEventMail
            hostName={hostName}
            event={event}
            guestMail={"guest@sample.com"}
          />
        </Box>
      </StyledInvitationPreviewCard>
    </StyledInvitationPreviewModal>
  );
}
