import React from "react";
import { Box, Typography } from "@mui/material";
import styled, { css } from "@mui/styled-engine";
import { StyledAvatar } from "../styled/StyledAvatar";
import { useTranslation } from "react-i18next";

const StyledGuestItem = styled(Box)<{
  type: "default" | "card";
}>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.type === "card"
      ? css`
          padding: 14px 20px;
          border-radius: 10px;
          background-color: #fff;
          margin-bottom: 12px;
          &:last-of-type {
            margin-bottom: 0;
          }
        `
      : css`
          padding: 19px 0;
          border-bottom: solid 1px #e1e1e1;
          &:first-of-type {
            border-top: solid 1px #e1e1e1;
          }
        `}

  .guest-item {
    &-avatar {
      position: relative;
      &--is-host {
        &:before {
          content: " ";
          display: block;
          width: 18px;
          height: 18px;
          background: url("/images/ic_host.svg") no-repeat;
          background-size: 18px 18px;
          position: absolute;
          right: -3px;
          bottom: -3px;
          border-radius: 50%;
          z-index: 100;
        }
      }
    }
    &-info {
      margin-left: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-email {
      font-size: 16px;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-host {
      margin-top: 3px;
      font-size: 16px;
      color: #333;
    }
    &-slot {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 12px;
      & > * {
        white-space: nowrap;
      }
    }
  }
`;

interface GuestItemProps {
  guest?: { name?: string; email?: string; bg_color?: string };
  isHost?: Boolean;
  isMe: Boolean;
  children?: React.ReactNode;
  type?: "default" | "card";
  onClick?: () => void;
}

export function GuestItem({
  guest,
  isHost,
  isMe,
  children,
  type = "default",
  onClick,
}: GuestItemProps) {
  const { t } = useTranslation("guestItem");
  return (
    <StyledGuestItem
      component="li"
      type={type}
      onClick={() => onClick?.()}
      sx={{ ...(!!onClick ? { cursor: "pointer" } : {}) }}
    >
      <Box
        component="div"
        className={`guest-item-avatar ${
          isHost ? "guest-item-avatar--is-host" : ""
        }`}
      >
        <StyledAvatar bgColor={guest?.bg_color || "gray"}>
          {(guest?.name || guest?.email)?.substr(0, 1).toUpperCase()}
        </StyledAvatar>
      </Box>
      <Box component="div" className="guest-item-info">
        {guest?.email && (
          <Typography component="p" className="guest-item-email">
            {guest?.email}
          </Typography>
        )}

        {isHost && (
          <Box component="div" className="guest-item-host">
            {isHost && t("Host")} {isMe && t("IsMe")}
          </Box>
        )}
        {!isHost && guest?.name && (
          <Box component="div" className="guest-item-host">
            {guest?.name} {isMe && t("IsMe")}
          </Box>
        )}
      </Box>
      <Box component="div" className="guest-item-slot">
        {children}
      </Box>
    </StyledGuestItem>
  );
}
