import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "@mui/styled-engine";
import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { timeRangeFormat } from "./ChangeScheduleCalendar";
import { useTranslation } from "react-i18next";
import { ExtendedEvent } from "./BaseCalendar";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";
import { EmptyBox } from "../empty/EmptyBox";

const StyledSelectedScheduleOverlay = styled(Box)`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  align-items: center;
  padding: 0 20px;
  background-color: rgba(3, 3, 3, 0.66);
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  justify-content: space-between;
  z-index: 200;
  > .show-selected-schedule {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
  }
`;

const StyledAnchor = styled(Box)`
  position: absolute;
  visibility: hidden;
  top: -80px;
  left: 0;
  right: 0;
`;

const StyledSelectedSchedule = styled(Box)<{ isDesktop: boolean }>`
  position: relative;
  margin: 50px 0 0 0;
  & > h5 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  .selected-schedule-list {
    ${(props) =>
      !props.isDesktop &&
      css`
        margin: 0 -20px;
      `}
    border-radius: 10px;
    background-color: #fff;
    padding: 0 20px;
    &-item {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #d2d2d2;
      height: 48px;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;

const EventSelectedScheduleEmpty = () => {
  const { t } = useTranslation("changeScheduleCalendar");

  return (
    <EmptyBox height={150}>
      <Box className="empty-emoji">{t("empty.Emoji")}</Box>
      <Typography className="empty-description">
        {t("empty.Description")}
      </Typography>
    </EmptyBox>
  );
};

interface EventSelectedScheduleProps {
  activeEvents: ExtendedEvent[];
  handleActiveEventRemoveFactory?: (index: number) => () => void;
}

export const SelectedSchedule = ({
  activeEvents,
  handleActiveEventRemoveFactory,
}: EventSelectedScheduleProps) => {
  const { t } = useTranslation("changeScheduleCalendar");
  const selectedScheduleListRef = useRef<Element | undefined>();
  const observer = useRef<IntersectionObserver | null>(null);
  const isDesktop = useDesktopMatches();
  const [isOverlayShown, setOverlayShown] = useState<boolean>(!isDesktop);
  const disconnectObserver = () => {
    observer.current?.disconnect();
    observer.current = null;
  };

  const connectObserver = useCallback((element: Element) => {
    if (observer.current) {
      disconnectObserver();
    }

    if (!observer.current) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setOverlayShown(false);
            } else {
              setOverlayShown(true);
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0,
        }
      );
      observer.current?.observe(element);
    }
  }, []);

  useEffect(() => {
    if (selectedScheduleListRef.current) {
      connectObserver(selectedScheduleListRef.current);
    }
  }, [connectObserver]);

  return (
    <>
      <StyledSelectedSchedule
        ref={selectedScheduleListRef}
        isDesktop={isDesktop}
      >
        <StyledAnchor id="selected-schedule-list" />
        <Typography variant={"h5"}>
          {t("SelectedSchedules")}({activeEvents.length})
        </Typography>
        {activeEvents.length > 0 ? (
          <Box component="ul" className="selected-schedule-list">
            {activeEvents.map(({ id, start, end }, index) => (
              <Box
                component="li"
                className="selected-schedule-list-item"
                key={id}
              >
                <Typography variant={"body1"} sx={{ fontSize: "14px" }}>
                  {timeRangeFormat(moment(start), moment(end))}
                </Typography>
                <IconButton
                  onClick={handleActiveEventRemoveFactory?.(index)}
                  sx={{ marginRight: "-12px" }}
                >
                  <ClearIcon sx={{ color: "#f00" }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        ) : (
          <EventSelectedScheduleEmpty />
        )}
      </StyledSelectedSchedule>
      {isOverlayShown && !isDesktop && activeEvents.length && (
        <StyledSelectedScheduleOverlay>
          <Box component="span">
            {t("SelectedSchedulesOverlay", {
              activeEventsLength: activeEvents.length,
            })}
          </Box>
          <Box
            component="a"
            className="show-selected-schedule"
            href="#selected-schedule-list"
          >
            {t("ShowSelectedSchedule")}
          </Box>
        </StyledSelectedScheduleOverlay>
      )}
    </>
  );
};
