import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import { Skeleton } from "@mui/material";

import { FormItem, FormList } from "../common/FormItem";
import { DetailSection } from "../../../styled/layout/main";
import { GuestItemList } from "../common/GuestItemList";
import { BigTitle, ContentTitle } from "../../../styled/typography";
import { SelectedEvent, SelectedEventList } from "../common/SelectedEventList";
import {
  useGetPollCardsQuery,
  useGetPollInfoQuery,
} from "../../../app/services/poll/pollApi";
import { CompletePollEventGuestAddWidget } from "./CompletePollEventGuestAddWidget";
import { useAppSelector } from "../../../app/hooks";
import { createPollSelectors } from "../create-poll/createPollSlice";

const CompletePollEventMainBox = styled.div`
  max-width: 826px;
  margin: 0 auto;
  padding: 64px 0;
`;

export interface CompletePollEventMainProps {}

export const CompletePollEventMain = (_: CompletePollEventMainProps) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation("eventInputList"); // eventInputList 옮기기
  const { data: pollInfo } = useGetPollInfoQuery({ id: +eventId });
  const { data: pollCardsInfo } = useGetPollCardsQuery({ id: +eventId });
  const selectedEvents: SelectedEvent[] = useMemo(() => {
    if (pollCardsInfo) {
      return pollCardsInfo.cards.map(
        (d): SelectedEvent => ({
          id: d.key,
          start: moment(d.start_at).toDate(),
          end: moment(d.end_at).toDate(),
        })
      );
    }
    return [];
  }, [pollCardsInfo]);
  const { duration } = useAppSelector(createPollSelectors.selectState);

  return (
    <CompletePollEventMainBox>
      <BigTitle sx={{ marginBottom: "38px", padding: "0 20px" }}>
        {pollInfo ? (
          <>
            <strong>[{pollInfo.title}]</strong>
            이벤트가 생성되었습니다.
          </>
        ) : (
          <Skeleton variant="text" sx={{ bgcolor: "#f5f5f5" }} />
        )}
      </BigTitle>
      {pollInfo ? (
        <>
          <CompletePollEventGuestAddWidget eventId={+eventId} />
          <DetailSection>
            <FormList>
              <FormItem label={t(`labels.title`)} content={pollInfo.title} />
              <FormItem
                label={t(`labels.description`)}
                content={pollInfo.description}
              />
              <FormItem
                label={t(`labels.event_type`)}
                content={
                  pollInfo.event_type
                    ? t(`eventTypeLabels.${pollInfo.event_type}`) +
                      `${
                        pollInfo.place_detail
                          ? `(${pollInfo.place_detail})`
                          : ""
                      }
                      `
                    : ""
                }
              />
              <FormItem
                label={t(`labels.duration_minutes`)}
                content={moment.duration(duration, "minute").humanize()}
              />
            </FormList>
          </DetailSection>
          <DetailSection>
            <ContentTitle sx={{ marginBottom: "20px" }}>
              초대한 게스트 <strong>{pollInfo.guests.length}</strong>명
            </ContentTitle>
            <GuestItemList guests={pollInfo.guests} />
          </DetailSection>
          {pollCardsInfo ? (
            <DetailSection>
              <ContentTitle sx={{ marginBottom: "20px" }}>
                선택일정 <strong>{pollCardsInfo.cards.length}</strong>건
              </ContentTitle>
              <SelectedEventList events={selectedEvents} />
            </DetailSection>
          ) : undefined}
        </>
      ) : (
        <Skeleton
          width="100%"
          variant="rectangular"
          height={245}
          sx={{ bgcolor: "#f5f5f5" }}
        />
      )}
    </CompletePollEventMainBox>
  );
};
