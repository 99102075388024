import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { UserResponse } from "../../app/services/accounts/types";
import { useLogIn } from "../../hooks/useLogIn";
import { VerifiedUserContainer } from "./VerifyPage";

export function VerifyPageGoogle() {
  const { state } = useLocation<{ userFromLoginGoogle?: UserResponse }>();
  const { isSuccess } = useLogIn(state?.userFromLoginGoogle);

  return !state?.userFromLoginGoogle ? (
    <Redirect to={"/login"} />
  ) : state?.userFromLoginGoogle && isSuccess ? (
    <VerifiedUserContainer user={state?.userFromLoginGoogle?.user} />
  ) : null;
}
