import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { layout } from "../token";

export const SettingContainer = styled(Box)<{
  isSettingOpenOnMobile?: boolean;
}>`
  height: 100%;
  position: relative;
  overflow-y: auto;
  &.desktop {
    width: ${layout.setting.width}px;
    border-right: 1px solid #d9d7d2;
  }
  &.mobile {
    width: 100%;
    top: 100%;
    height: 100vh;
    position: fixed;
    transition: transform 0.2s ease-in-out;
    z-index: 2000;
    background: #ffffff;
    ${({ isSettingOpenOnMobile }) =>
      isSettingOpenOnMobile
        ? css`
            transform: translateY(-100%);
          `
        : css`
            transform: translateY(0);
          `}
  }
`;

export const SettingWrapper = styled(Box)`
  padding: ${layout.setting.padding}px;
`;

export const SettingBorder = styled.hr`
  height: 1px;
  margin: 30px -${layout.setting.padding}px;
  background-color: #d3d3d3;
  outline: 0;
  border: 0;
`;

export const MainBorder = styled.hr`
  height: 1px;
  margin: 12px 0;
  background-color: #d3d3d3;
  outline: 0;
  border: 0;
`;
