import React from "react";
import { Page } from "../../../layout/Page";
import { CreatePollMain } from "./CreatePollMain";
import { CreatePollSetting } from "./CreatePollSetting";
import { CreatePollFloating } from "./CreatePollFloating";

export const CreatePollPage = () => {
  return (
    <Page
      main={<CreatePollMain />}
      setting={<CreatePollSetting />}
      floating={<CreatePollFloating />}
    />
  );
};
