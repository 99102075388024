import { useAppSelector } from "../../../../../app/hooks";
import {
  createPollSelectors,
  PollCalendarEventInputs,
} from "../../createPollSlice";
import { EventHoveringArg, EventInput } from "@fullcalendar/react";
import { useCallback } from "react";
import { CreatePollCalendarExtendedProps } from "../types/CreatePollCalendarExtendedProps";
import { CreatePollCalendarEventType } from "../types/CreatePollCalendarEventType";
import moment from "moment";
import {
  invySlotClassName,
  invyTempSlotClassName,
  tempAvailableSlotClassName,
  tempOccupiedSlotClassName,
} from "../createPollCalendarClassNames";
import { getUUID } from "../../../../../utils/getUUID";
import { hhHourFormatStr } from "../../CreatePollSetting";

export function useHandleEventMouseEnter({
  tempEventInput,
  eventInputs,
  onTempEventInputChange,
}: {
  tempEventInput?: EventInput;
  eventInputs: PollCalendarEventInputs;
  onTempEventInputChange: React.Dispatch<
    React.SetStateAction<EventInput | undefined>
  >;
}) {
  const { slotUnitMinutes, duration, endAt } = useAppSelector(
    createPollSelectors.selectState
  );

  return useCallback(
    ({ event }: EventHoveringArg) => {
      const extendedProps =
        event.extendedProps as CreatePollCalendarExtendedProps;
      if (
        extendedProps.type === CreatePollCalendarEventType.background &&
        !extendedProps.isSelected &&
        event.start !== null &&
        (!tempEventInput ||
          !moment(tempEventInput.start).isSame(moment(event.start)))
      ) {
        const slotLength = Math.ceil(duration / slotUnitMinutes);
        let tempType = CreatePollCalendarEventType.available;
        for (let i = 0; i < slotLength; i++) {
          const backgroundEvent =
            eventInputs.background[extendedProps.index + i];
          if (
            backgroundEvent &&
            backgroundEvent?.extendedProps?.childType ===
              CreatePollCalendarEventType.occupied
          ) {
            tempType = CreatePollCalendarEventType.occupied;
          }
        }
        const endMoment = moment(event.start).add(duration, "minutes");
        if (endMoment.format(hhHourFormatStr).localeCompare(endAt) > 0) return; //FIXME: 일자 넘어가는 경우 대응 필요
        const newTempEventInput: EventInput = {
          // @ts-ignore
          id: getUUID(),
          start: event.start,
          end: endMoment.toDate(),
          classNames: [
            invySlotClassName,
            invyTempSlotClassName,
            tempType === CreatePollCalendarEventType.occupied
              ? tempOccupiedSlotClassName
              : tempAvailableSlotClassName,
          ],
          extendedProps: {
            type:
              tempType === CreatePollCalendarEventType.occupied
                ? CreatePollCalendarEventType.tempOccupied
                : CreatePollCalendarEventType.tempAvailable,
            childType: tempType,
            index: 0,
          },
        };
        onTempEventInputChange(newTempEventInput);
      }
    },
    [
      onTempEventInputChange,
      duration,
      slotUnitMinutes,
      eventInputs.background,
      tempEventInput,
      endAt,
    ]
  );
}
