import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "@mui/styled-engine";
import {
  Box,
  Button,
  Link,
  FormControl,
  FormLabel,
  MenuItem,
  Typography,
  BoxProps,
} from "@mui/material";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import { EventFormItem } from "../event/EventFormItem";
import {
  EventWithGuests,
  GuestSchedule,
  GuestWithStatus,
  RecommendSchedule,
} from "../../app/services/events/types";
import {
  EventConfirmList,
  EventConfirmListItem,
  EventConfirmListProps,
} from "../list/EventConfirmList";
import { StyledAvatar, StyledAvatarGroup } from "../styled/StyledAvatar";
import { useSortedGuestsFromEvent } from "../../hooks/useSortedGuestsFromEvent";
import { EventGuestItemList } from "../event/EventGuestItemList";
import { EventInfoWithUrlView } from "../event/EventInfoWithUrlView";
import moment from "moment";
import ListIcon from "@mui/icons-material/List";
import EventIcon from "@mui/icons-material/Event";
import {
  CalendarRange,
  ConfirmScheduleCalendar,
  ExtendedEventWithRecommendedSchedule,
  getEventWithRecommendedSchedule,
} from "../calendars/ConfirmScheduleCalendar";
import { ScheduleCalendarInputModal } from "../calendars/ScheduleCalendarInputModal";
import { LoadingButton } from "@mui/lab";
import { TextFieldWithValidation } from "../inputs/TextFieldWithValidation";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";
import { StyledEventDetailContainer } from "../styled/StyledEventDetailContainer";
import { ExtendedEvent } from "../calendars/BaseCalendar";
import { getUUID } from "../../utils/getUUID";
import { ConfirmScheduleModal } from "../modal/ConfirmScheduleModal";
import { EmptyBox } from "../empty/EmptyBox";

export interface ExtendedRecommendedSchedule extends RecommendSchedule {
  availableParticipants: GuestWithStatus[];
  isPrior?: boolean;
  isAllAvailable?: boolean;
  isHeadOfDate?: boolean;
}

interface HostEventConfirmListProps
  extends Omit<EventConfirmListProps, "children"> {
  schedules: ExtendedRecommendedSchedule[];
  guests: GuestWithStatus[];
  checkedSchedule?: RecommendSchedule;
  onAllMailResend?: () => void;
  onRadioChange: (schedule: RecommendSchedule) => void;
  isLoading?: boolean;
}

const LoadingBox = styled(Box)<{ isDesktop?: boolean }>`
  border-radius: 10px;
  background-color: #fff;
  height: 357px;
  ${(props) =>
    !props.isDesktop &&
    css`
      margin: 0 -20px;
    `}
`;

interface HostEventConfirmListEmptyProps {
  onInviteResend?: () => void;
}

const HostEventConfirmListLoading = () => {
  const isDesktop = useDesktopMatches();

  return <LoadingBox isDesktop={isDesktop} />;
};

const HostEventConfirmListEmpty = ({
  onInviteResend,
}: HostEventConfirmListEmptyProps) => {
  const { t: tConfirmSchedule } = useTranslation("confirmSchedule");

  const resendInvite = useCallback(() => {
    if (onInviteResend) onInviteResend();
  }, [onInviteResend]);

  return (
    <EmptyBox height={357}>
      <Box className="empty-emoji">{tConfirmSchedule("listEmpty.Emoji")}</Box>
      <Typography className="empty-description">
        {tConfirmSchedule("listEmpty.Description")}
      </Typography>
      <Link onClick={resendInvite} className="empty-link">
        {tConfirmSchedule("listEmpty.Resend")}
      </Link>
    </EmptyBox>
  );
};

const ShowGuestTextButtonBox = styled(Box)`
  font-size: 14px;
  color: #4555ff;
  white-space: nowrap;
  text-decoration: underline;
`;

interface ShowGuestTextButtonProps extends BoxProps {
  children: string;
}

const ShowGuestTextButton = ({
  children,
  ...props
}: ShowGuestTextButtonProps) => {
  return (
    <ShowGuestTextButtonBox component="a" {...props}>
      {children}
    </ShowGuestTextButtonBox>
  );
};

function HostEventConfirmList({
  schedules,
  guests,
  checkedSchedule,
  onAllMailResend,
  onMoreButtonClick,
  onRadioChange,
  moreButtonHidden,
  isLoading = false,
}: HostEventConfirmListProps) {
  const { t: tConfirmSchedule } = useTranslation("confirmSchedule");
  const [guestColorMap, setGuestColorMap] = useState(new Map());
  const isDesktop = useDesktopMatches();

  useEffect(() => {
    setGuestColorMap(
      guests.reduce((result, guest) => {
        if (guest.bg_color) result.set(guest.email, guest.bg_color);
        return result;
      }, new Map())
    );
  }, [guests]);

  return isLoading ? (
    <HostEventConfirmListLoading />
  ) : !schedules?.length ? (
    <HostEventConfirmListEmpty onInviteResend={onAllMailResend} />
  ) : (
    <EventConfirmList
      onMoreButtonClick={onMoreButtonClick}
      moreButtonHidden={moreButtonHidden}
    >
      {schedules.map((schedule) => (
        <EventConfirmListItem
          recommended={schedule.isPrior}
          showDaySeparator={schedule.isHeadOfDate}
          key={`${schedule.id}-${schedule.isPrior}`}
          schedule={schedule}
          checked={schedule.id === checkedSchedule?.id}
          onRadioChange={() => onRadioChange(schedule)}
        >
          {schedule.isAllAvailable ? (
            <ShowGuestTextButton>
              {tConfirmSchedule("AllAvailable")}
            </ShowGuestTextButton>
          ) : !isDesktop ? (
            <ShowGuestTextButton>
              {`${schedule.availableParticipants?.length}명`}
            </ShowGuestTextButton>
          ) : (
            <StyledAvatarGroup max={4} size="small">
              {schedule.availableParticipants?.map((guest) => (
                <StyledAvatar
                  key={guest.email}
                  size="small"
                  bgColor={guestColorMap.get(guest.email) || "#000000"}
                >
                  {(guest?.name || guest?.email)?.substr(0, 1).toUpperCase()}
                </StyledAvatar>
              ))}
            </StyledAvatarGroup>
          )}
        </EventConfirmListItem>
      ))}
    </EventConfirmList>
  );
}

const StyledHostSelectedEventDesc = styled(Box)`
  margin-bottom: 10px;
`;

const StyledHostSelectedEventDescItem = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 0 20px;

  & > .label {
    width: 130px;
    flex: 0 0 130px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  & > .content {
    font-size: 16px;
    color: #333;
    strong {
      color: #4555ff;
      font-weight: normal;
    }
    &.empty-state {
      font-size: 16px;
      color: #767676;
    }
  }
`;

function HostSelectedEventDescItem({
  label,
  isEmpty,
  emptyState,
  content,
}: {
  label: string;
  isEmpty: boolean;
  emptyState: string;
  content: string;
}) {
  return (
    <StyledHostSelectedEventDescItem>
      <Box component="label" className="label">
        {label}
      </Box>
      {isEmpty ? (
        <Box
          component="div"
          className="content empty-state"
          dangerouslySetInnerHTML={{ __html: emptyState }}
        />
      ) : (
        <Box
          component="div"
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </StyledHostSelectedEventDescItem>
  );
}

function HostSelectedEventDesc({ schedule }: { schedule?: RecommendSchedule }) {
  const { t: tConfirmSchedule } = useTranslation("confirmSchedule");

  return (
    <StyledHostSelectedEventDesc>
      <HostSelectedEventDescItem
        label={tConfirmSchedule("CheckedSchedule")}
        content={`${moment(schedule?.from_time).format(
          "MM.DD(dd) a hh:mm"
        )} - ${moment(schedule?.to_time).format("MM.DD(dd) a hh:mm")}`}
        isEmpty={!schedule}
        emptyState={tConfirmSchedule("RequireSchedule")}
      />
      <HostSelectedEventDescItem
        label={tConfirmSchedule("ParticipantsDetail")}
        content={tConfirmSchedule("ParticipantsWithCount", {
          matched: schedule?.guests.filter((d) => d.status === 1).length || 0,
          unmatched: schedule?.guests.filter((d) => d.status === 2).length || 0,
          unsettled: schedule?.guests.filter((d) => d.status === 0).length || 0,
        })}
        isEmpty={!schedule}
        emptyState={tConfirmSchedule("RequireGuest")}
      />
    </StyledHostSelectedEventDesc>
  );
}

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 15px;
`;

const StyledFormLabel = styled(FormLabel)`
  min-width: 92px;
  font-size: 16px;
  font-weight: bold;
  color: #717171;
`;

const selectNoParticipantValue = "__ALL__";

function SelectRequiredParticipant({
  selectedParticipant,
  participants,
  onChange,
}: {
  selectedParticipant?: GuestWithStatus;
  participants: GuestWithStatus[];
  onChange?: (value?: GuestWithStatus) => void;
}) {
  const { t } = useTranslation("confirmSchedule");
  const handleChange = useCallback(
    (value?: string) => {
      if (onChange) {
        onChange(
          value ? participants.find((d) => d.email === value) : undefined
        );
      }
    },
    [onChange, participants]
  );
  return (
    <StyledFormControl>
      <StyledFormLabel>{t("RequiredParticipants")}</StyledFormLabel>
      <TextFieldWithValidation
        select={true}
        value={selectedParticipant?.email || selectNoParticipantValue}
        onValueChange={handleChange}
        variant="standard"
        fullWidth={false}
      >
        <MenuItem value={selectNoParticipantValue}>{t("Empty")}</MenuItem>
        {participants.map(({ email, name }, index) => {
          return (
            <MenuItem
              key={`${email}-${index}`}
              value={email}
            >{`${email} / ${name}`}</MenuItem>
          );
        })}
      </TextFieldWithValidation>
    </StyledFormControl>
  );
}

export interface HostEventFormProps {
  me: string;
  eventInfo?: EventWithGuests;
  editEventButton?: React.ReactNode;
  requiredParticipant?: GuestWithStatus;
  guestSchedules: GuestSchedule[];
  selectedSchedule?: RecommendSchedule;
  priorRecommendSchedules: ExtendedRecommendedSchedule[];
  dailyRecommendSchedules: ExtendedRecommendedSchedule[];
  calendarRecommendSchedules: ExtendedRecommendedSchedule[];
  moreButtonHidden?: boolean;
  submitButtonLoading?: boolean;
  onCalendarRangeChange?: (range: CalendarRange) => void;
  onRequiredParticipantChange?: (participant?: GuestWithStatus) => void;
  onMoreButtonClick?: () => void;
  onGuestDelete?: (guest: GuestWithStatus) => void;
  onMailSend?: (guest: GuestWithStatus) => void;
  onAllMailSend?: () => void;
  onChangeScheduleCalendarOpen?: () => void;
  onChange?: (schedule?: RecommendSchedule) => void;
  onSubmit?: (schedule: RecommendSchedule) => void;
  hostEventConfirmListLoading?: boolean;
}

export function HostEventForm({
  me,
  eventInfo,
  editEventButton,
  requiredParticipant,
  guestSchedules,
  priorRecommendSchedules,
  dailyRecommendSchedules,
  calendarRecommendSchedules,
  moreButtonHidden,
  submitButtonLoading,
  selectedSchedule,
  onCalendarRangeChange,
  onRequiredParticipantChange,
  onMoreButtonClick,
  onGuestDelete,
  onMailSend,
  onAllMailSend,
  onChangeScheduleCalendarOpen,
  onChange,
  onSubmit,
  hostEventConfirmListLoading = false,
}: HostEventFormProps) {
  const isDesktop = useDesktopMatches();
  const { t: tEventDetail } = useTranslation("eventDetail");
  const { t: tConfirmSchedule } = useTranslation("confirmSchedule");
  const { t: tInput } = useTranslation("scheduleCalendarInput");
  const guests = useSortedGuestsFromEvent(eventInfo);
  const [displayedSchedules, setDisplayedSchedules] = useState<
    ExtendedRecommendedSchedule[]
  >([]);
  const [checkedSchedule, setCheckedSchedule] = useState<
    RecommendSchedule | undefined
  >(undefined);
  const [isInputModalOpen, setIsInputModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [tempDirectEvent, setTempDirectEvent] = useState<
    ExtendedEvent | undefined
  >(undefined);

  const handleRadioChange = useCallback((newSchedule: RecommendSchedule) => {
    setCheckedSchedule((checkedSchedule) => {
      if (
        checkedSchedule !== undefined &&
        checkedSchedule.id === newSchedule.id
      ) {
        return undefined; // 같은 것 재선택시 해제
      } else {
        return newSchedule;
      }
    });
  }, []);

  const handleCalendarSubmit = useCallback(
    (event?: ExtendedEventWithRecommendedSchedule) => {
      if (event && event.originSchedule) {
        setCheckedSchedule(event.originSchedule); // FIXME: 직접 생성한 id < 0에 대응
      } else {
        setCheckedSchedule(undefined);
      }
    },
    []
  );
  const handleInputScheduleModalOpen = useCallback(() => {
    setIsInputModalOpen(true);
  }, []);
  const handleInputScheduleModalClose = useCallback(() => {
    setIsInputModalOpen(false);
    setTempDirectEvent(undefined);
  }, []);
  const handleInputScheduleModalSave = useCallback(
    (event) => {
      //setCheckedSchedule();
      const extendedEvent = getEventWithRecommendedSchedule(
        event,
        guestSchedules,
        guests
      );
      setCheckedSchedule(extendedEvent.originSchedule);
      setIsInputModalOpen(false);
      setTempDirectEvent(undefined);
    },
    [guestSchedules, guests]
  );
  const handleMonthViewEventCreate = useCallback((event) => {
    if (event) {
      setIsInputModalOpen(true);
      setTempDirectEvent({
        start: event.start,
        end: event.end,
        id: getUUID(),
        isTemp: true,
      });
    }
  }, []);
  const handleSubmit = useCallback(() => {
    if (checkedSchedule) setIsConfirmModalOpen(true);
  }, [checkedSchedule]);
  const handleConfirmModalConfirm = useCallback(() => {
    if (checkedSchedule && onSubmit) onSubmit(checkedSchedule);
    setIsConfirmModalOpen(false);
  }, [checkedSchedule, onSubmit]);
  const handleConfirmModalClose = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);
  const handleMoreButtonClick = useCallback(() => {
    if (onMoreButtonClick) onMoreButtonClick();
  }, [onMoreButtonClick]);
  const CommonSelectRequiredParticipant = () => (
    <SelectRequiredParticipant
      selectedParticipant={requiredParticipant}
      participants={guests || []}
      onChange={onRequiredParticipantChange}
    />
  );

  useEffect(() => {
    const filteredDailyRecommendSchedules = dailyRecommendSchedules.filter(
      (dr) => {
        return !priorRecommendSchedules.some((pr) => pr.id === dr.id);
      }
    );
    if (filteredDailyRecommendSchedules.length > 0) {
      filteredDailyRecommendSchedules[0] = {
        ...filteredDailyRecommendSchedules[0],
        isHeadOfDate: true,
      };
    }

    setDisplayedSchedules([
      ...priorRecommendSchedules,
      ...filteredDailyRecommendSchedules,
    ]);
  }, [priorRecommendSchedules, dailyRecommendSchedules]);

  useEffect(() => {
    if (onChange) onChange(checkedSchedule);
  }, [onChange, checkedSchedule]);

  useEffect(() => {
    setCheckedSchedule(selectedSchedule);
  }, [selectedSchedule]);

  return (
    <ContainerWithTitle
      title={tEventDetail("Title")}
      titleDecorator={eventInfo?.title}
      titleComponent="span"
      rightButton={editEventButton}
    >
      <StyledEventDetailContainer isDesktop={isDesktop} component="div">
        <EventInfoWithUrlView
          event={eventInfo}
          collapsible={true}
          defaultCollapsed={true}
        />
        <EventGuestItemList
          me={me}
          guests={guests}
          defaultCollapsed={true}
          collapsible={true}
          isFromHostEvent={true}
          onChangeScheduleCalendarOpen={onChangeScheduleCalendarOpen}
          onGuestDelete={onGuestDelete}
          onMailSend={onMailSend}
        />
        <EventFormItem
          title={tConfirmSchedule("Label")}
          rightButton={
            <Button
              size="small"
              variant="inner"
              color="normal"
              onClick={handleInputScheduleModalOpen}
            >
              {tInput("InputSchedulesDirectly")}
            </Button>
          }
          selectedSegment={"recommend-list"}
          segmentItems={[
            {
              value: "recommend-list",
              label: <ListIcon />,
              children: (
                <>
                  <CommonSelectRequiredParticipant />
                  <HostEventConfirmList
                    schedules={displayedSchedules}
                    guests={guests}
                    checkedSchedule={checkedSchedule}
                    moreButtonHidden={moreButtonHidden}
                    onAllMailResend={onAllMailSend}
                    onRadioChange={handleRadioChange}
                    onMoreButtonClick={handleMoreButtonClick}
                    isLoading={hostEventConfirmListLoading}
                  />
                </>
              ),
            },
            {
              value: "calendar",
              label: <EventIcon />,
              children: (
                <>
                  <CommonSelectRequiredParticipant />
                  <ConfirmScheduleCalendar
                    me={me}
                    totalParticipants={guests}
                    selectedSchedule={checkedSchedule}
                    recommendedSchedules={calendarRecommendSchedules}
                    guestSchedules={guestSchedules}
                    onMonthViewEventCreate={handleMonthViewEventCreate}
                    onRangeChange={onCalendarRangeChange}
                    onSubmit={handleCalendarSubmit}
                  />
                </>
              ),
            },
          ]}
        />
        <HostSelectedEventDesc schedule={checkedSchedule} />
        <Box>
          <LoadingButton
            loading={submitButtonLoading}
            fullWidth
            onClick={handleSubmit}
            disabled={checkedSchedule === undefined}
            variant="cta"
            color="primary"
          >
            {tConfirmSchedule("Label")}
          </LoadingButton>
          <Box>
            <Typography variant="caption">
              {tConfirmSchedule("AfterConfirmHelpText")}
            </Typography>
          </Box>
        </Box>
      </StyledEventDetailContainer>
      <ScheduleCalendarInputModal
        open={isInputModalOpen}
        event={tempDirectEvent}
        onClose={handleInputScheduleModalClose}
        onSave={handleInputScheduleModalSave}
      />
      <ConfirmScheduleModal
        open={isConfirmModalOpen}
        schedule={checkedSchedule}
        onConfirm={handleConfirmModalConfirm}
        onClose={handleConfirmModalClose}
      />
    </ContainerWithTitle>
  );
}
