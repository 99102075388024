import React from "react";
import styled from "@mui/styled-engine";
import { Box } from "@mui/material";
import { StyledAvatar } from "../../../components/styled/StyledAvatar";

const GuestReadOnlyItemContainer = styled(Box)`
  height: 70px;
  border-bottom: solid 1px #c4c4c4;
  display: flex;
  align-items: center;
  &:last-of-type {
    border-bottom: 0;
  }
`;
const GuestReadOnlyItemTextArea = styled(Box)`
  flex: 1;
  margin-left: 16px;
  > p {
    font-size: 16px;
    color: #333;
    line-height: 1.4;
    margin: 0;
  }
`;

const GuestReadOnlyItemExtra = styled(Box)``;

interface GuestReadOnlyItemProps {
  guest: { name: string; email?: string; bg_color?: string };
  children?: React.ReactNode;
}

export const GuestReadOnlyItemList = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box component="ul" sx={{ width: "100%" }}>
      {children}
    </Box>
  );
};

export const GuestReadOnlyItem = ({
  guest,
  children: extra,
}: GuestReadOnlyItemProps) => {
  return (
    <GuestReadOnlyItemContainer component="li">
      <StyledAvatar
        bgColor={guest.bg_color || "#000000"}
        size="medium"
        sx={{ flex: "0 0 44px" }}
      >
        {guest.name?.substr(0, 1)}
      </StyledAvatar>
      <GuestReadOnlyItemTextArea>
        <Box component="p">{guest.name}</Box>
        {guest.email ? <Box component="p">{guest.email}</Box> : null}
      </GuestReadOnlyItemTextArea>
      <GuestReadOnlyItemExtra>{extra}</GuestReadOnlyItemExtra>
    </GuestReadOnlyItemContainer>
  );
};
