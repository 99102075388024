import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, List, ListItem, ListItemText, Tab } from "@mui/material";
import { CommonModal } from "../modal/CommonModal";
import { ExtendedEventWithRecommendedSchedule } from "./ConfirmScheduleCalendar";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { GuestWithStatus } from "../../app/services/events/types";
import styled from "@mui/styled-engine";
import moment from "moment";

const StyledConfirmScheduleCalendarModal = styled(CommonModal)`
  .common-modal {
    &-header {
      font-size: 16px;
      white-space: pre-line;
    }
`;

const StyledConfirmScheduleCalendarModalContent = styled(Box)`
  .MuiTabPanel-root {
    padding: 0;
  }
  .MuiListItem-root {
    padding: 4px 0;
  }
  .MuiTabs-root {
    min-height: unset;
    margin-left: -8px;
  }
  .MuiTab-root {
    padding: 8px;
    min-height: unset;
    min-width: unset;
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      height: 12px;
      width: 1px;
      background: #979797;
      top: 50%;
      transform: translateY(-50%);
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiListItemText-root {
    display: flex;
    align-items: center;
    & > * {
      font-size: 14px;
      margin-left: 16px !important;
      position: relative;
      &:before {
        content: " ";
        position: absolute;
        left: -8px;
        height: 12px;
        width: 1px;
        background: #e2e2e2;
        top: 50%;
        transform: translateY(-50%);
      }
      &:first-child {
        margin-left: 0 !important;
        &:before {
          display: none;
        }
      }
    }
  }
`;

function ParticipantListItem({
  participant,
}: {
  participant: GuestWithStatus;
}) {
  return (
    <ListItem>
      <ListItemText primary={participant.email} secondary={participant.name} />
    </ListItem>
  );
}

type ParticipantTabValue = "participants" | "unsettled";
export interface ConfirmScheduleCalendarModalProps {
  open: boolean;
  event: ExtendedEventWithRecommendedSchedule | null;
  onSubmit: () => void;
  onClose: () => void;
}

function timeRangeFormat(
  start: string,
  end: string,
  format: string = "MMM Do dd LT"
) {
  return `${moment(start).format(format)}\n- ${moment(end).format(format)}`;
}

export function ConfirmScheduleCalendarModal({
  open,
  event,
  onClose,
  onSubmit,
}: ConfirmScheduleCalendarModalProps) {
  const { t } = useTranslation("confirmScheduleCalendar");
  const title = useMemo(() => {
    if (event?.originSchedule?.from_time && event?.originSchedule?.to_time) {
      return timeRangeFormat(
        event?.originSchedule?.from_time,
        event?.originSchedule?.to_time
      );
    } else {
      return undefined;
    }
  }, [event?.originSchedule?.from_time, event?.originSchedule?.to_time]);
  const [tabValue, setTabValue] = useState<ParticipantTabValue>("participants");
  const [settledParticipants, setSettledParticipants] = useState<
    GuestWithStatus[]
  >([]);
  const [unsettledParticipants, setUnSettledParticipants] = useState<
    GuestWithStatus[]
  >([]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: ParticipantTabValue) => {
      setTabValue(newValue);
    },
    []
  );

  useEffect(() => {
    setTabValue("participants");
  }, [event]);

  useEffect(() => {
    if (event && event?.originSchedule?.guests) {
      setSettledParticipants(
        event.originSchedule.guests.filter((d) => d.status === 1)
      );
      setUnSettledParticipants(
        event.originSchedule.guests.filter((d) => d.status !== 1)
      );
    } else {
      setSettledParticipants([]);
      setUnSettledParticipants([]);
    }
  }, [event]);

  return (
    <StyledConfirmScheduleCalendarModal
      title={title}
      open={open}
      onClose={onClose}
      footer={
        <>
          <Button
            variant="inner"
            color="normal"
            onClick={onSubmit}
            fullWidth={true}
          >
            {t("ChooseThisSchedule")}
          </Button>
        </>
      }
    >
      <StyledConfirmScheduleCalendarModalContent>
        <TabContext value={tabValue}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label={t("ParticipantsWithCount", {
                  count: settledParticipants.length || 0,
                })}
                value="participants"
              />
              <Tab
                label={t("UnsettledWithCount", {
                  count: unsettledParticipants.length,
                })}
                value="unsettled"
              />
            </TabList>
          </Box>
          <TabPanel value="participants">
            <List sx={{ width: "100%" }}>
              {settledParticipants.map((d, i) => (
                <ParticipantListItem key={`${d.email}-${i}`} participant={d} />
              ))}
            </List>
          </TabPanel>
          <TabPanel value="unsettled">
            {unsettledParticipants.map((d, i) => (
              <ParticipantListItem key={`${d.email}-${i}`} participant={d} />
            ))}
          </TabPanel>
        </TabContext>
      </StyledConfirmScheduleCalendarModalContent>
    </StyledConfirmScheduleCalendarModal>
  );
}
