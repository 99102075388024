import React from "react";
import styled from "@mui/styled-engine";
import { Box } from "@mui/material";
import { CreatePollCalendar } from "./create-poll-calendar/CreatePollCalendar";

const CreatePollMainBox = styled(Box)``;

export const CreatePollMain = () => {
  return (
    <CreatePollMainBox>
      <CreatePollCalendar isMobile={false} />
    </CreatePollMainBox>
  );
};
