import React from "react";
import styled, { css } from "@mui/styled-engine";
import { Box, BoxProps } from "@mui/material";

const FormItemBox = styled(Box)<{ direction?: "column" | "row" }>`
  display: flex;
  align-items: ${({ direction }) =>
    direction === "row" ? "center" : "flex-start"}};
  ${({ direction }) => css`
    flex-direction: ${direction ?? "row"};
  `}};
`;

const FormItemLabel = styled(Box)<{ direction: "column" | "row" }>`
  font-size: 14px;
  color: #767676;
  display: inline-block;
  ${({ direction }) =>
    direction === "row"
      ? css`
          padding: 14px 0;
          width: 120px;
        `
      : css`
          margin-bottom: 4px;
        `}};

`;

const FormItemContent = styled(Box)<{ direction: "column" | "row" }>`
  font-size: 14px;
  font-weight: bold;
  color: #333;
  ${({ direction }) =>
    direction === "column" &&
    css`
      margin-bottom: 25px;
      width: 100%;
    `}
`;

interface FormItemProps {
  label: string;
  content: string | React.ReactNode | undefined;
  direction?: "column" | "row";
}

export const FormItem = ({
  label,
  content,
  direction = "row",
}: FormItemProps) => {
  return (
    <FormItemBox component="li" direction={direction}>
      <FormItemLabel direction={direction} component="label">
        {label}
      </FormItemLabel>
      <FormItemContent direction={direction}>{content}</FormItemContent>
    </FormItemBox>
  );
};

const FormListContainer = styled(Box)``;

interface FormListProps extends BoxProps {
  children: React.ReactNode;
}

export const FormList = ({ children, ...props }: FormListProps) => {
  return (
    <FormListContainer component="ul" {...props}>
      {children}
    </FormListContainer>
  );
};
