import React, { useCallback } from "react";
import { EventContentArg } from "@fullcalendar/core";
import { CreatePollCalendarExtendedProps } from "../types/CreatePollCalendarExtendedProps";
import moment from "moment/moment";
import { CreatePollCalendarEventType } from "../types/CreatePollCalendarEventType";
import { tempSlotTitleClassName } from "../createPollCalendarClassNames";

export function useHandleEventContent() {
  return useCallback(({ event }: EventContentArg) => {
    const extendedProps =
      event.extendedProps as CreatePollCalendarExtendedProps;
    const startMoment = moment(event ? event.start : undefined);
    const endMoment = moment(event ? event.end : undefined);
    const startTimeText = startMoment.locale("en").format("A hh:mm");
    if (
      extendedProps.type === CreatePollCalendarEventType.tempOccupied ||
      extendedProps.type === CreatePollCalendarEventType.tempAvailable
    ) {
      const endTimeText = endMoment.locale("en").format("hh:mm");

      return (
        <>
          <div
            className={tempSlotTitleClassName}
          >{`${startTimeText} - ${endTimeText}`}</div>
        </>
      );
    } else if (
      extendedProps.type === CreatePollCalendarEventType.selectedLabel
    ) {
      const endTimeText = endMoment.locale("en").format("A hh:mm");
      return (
        <>
          <div>{(extendedProps?.selectedIndex || 0) + 1}</div>
          <div>{startTimeText}</div>
          <div>~</div>
          <div>{endTimeText}</div>
        </>
      );
    } else if (
      extendedProps.type === CreatePollCalendarEventType.available ||
      extendedProps.type === CreatePollCalendarEventType.occupied
    ) {
      return (
        <>
          <div>{startTimeText}</div>
        </>
      );
    } else {
      return <></>;
    }
  }, []);
}
