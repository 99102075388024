import { ThemeOptions } from "@mui/material";
import { color } from "./token/color";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cta: true;
    inner: true;
  }
  interface ButtonPropsColorOverrides {
    normal: true;
  }
}

declare module "@mui/material/Toolbar" {
  interface ToolbarPropsVariantOverrides {
    mobile: true;
    desktop: true;
  }
}

export const HeaderHeight = {
  DESKTOP: 84,
  MOBILE: 56,
};

export const primaryButtonStyle = {
  color: color.primaryButtonFont,
  backgroundColor: color.primary,
  "&:hover": {
    backgroundColor: color.primaryButtonBackgroundHover,
  },
  "&:disabled": {
    backgroundColor: color.secondaryButtonBackgroundDisabled,
    color: color.secondaryButtonColorDisabled,
  },
};

const secondaryButtonStyle = {
  color: color.secondaryButtonFont,
  backgroundColor: color.secondaryButtonBackground,
  "&:hover": {
    backgroundColor: color.secondaryButtonBackgroundHover,
  },
  "&:disabled": {
    backgroundColor: color.secondaryButtonBackgroundDisabled,
    color: color.secondaryButtonColorDisabled,
  },
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: color.primary,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        style: {
          boxShadow: "none",
        },
      },
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: "desktop" },
          style: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "#232323",
            height: "84px",
            padding: "0 65px 0 57px",
          },
        },
        {
          props: { variant: "mobile" },
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            color: "rgba(255, 255, 255, 0.87)",
            height: "56px",
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        style: {
          textDecoration: "none",
        },
      },
    },
    MuiToggleButton: {
      variants: [
        {
          props: { color: "primary", size: "small" },
          style: {
            width: "36px",
            height: "36px",
          },
        },
      ],
    },
    MuiChip: {
      defaultProps: {
        style: {
          fontWeight: "bold",
        },
      },
      variants: [
        {
          props: { variant: "filled", color: "default" },
          style: {
            color: "#ffffff",
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "inner" },
          style: {
            textTransform: "none",
            height: "36px",
            fontSize: "14px",
            boxShadow: "none",
            border: "solid 1px #dbdbdb",
            backgroundColor: "#ffffff",
            "&:hover": {
              backgroundColor: color.normalButtonBackgroundHover,
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "inner", color: "normal" },
          style: {
            border: "solid 1px #dbdbdb",
            backgroundColor: "#ffffff",
            "&:hover": {
              backgroundColor: color.normalButtonBackgroundHover,
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "inner", color: "primary" },
          style: primaryButtonStyle,
        },
        {
          props: { variant: "inner", color: "secondary" },
          style: {
            fontWeight: "bold",
            ...secondaryButtonStyle,
          },
        },
        {
          props: { variant: "cta" },
          style: {
            textTransform: "none",
            height: "58px",
            fontSize: "16px",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
        {
          props: { variant: "cta", color: "primary" },
          style: primaryButtonStyle,
        },
        {
          props: { variant: "cta", color: "secondary" },
          style: {
            fontWeight: "bold",
            ...secondaryButtonStyle,
          },
        },
        {
          props: { variant: "cta", color: "normal" },
          style: {
            color: color.normalButtonFont,
            fontWeight: "bold",
            backgroundColor: color.normalButtonBackground,
            "&:hover": {
              backgroundColor: color.normalButtonBackgroundHover,
            },
            "&:disabled": {
              backgroundColor: color.secondaryButtonBackgroundDisabled,
              color: color.secondaryButtonColorDisabled,
            },
          },
        },
      ],
    },
  },
};

export default theme;
