import React from "react";
import { Redirect } from "react-router-dom";

import { useLogOut } from "../../hooks/useLogOut";

export function LogOutPage() {
  const { isSuccess, isError } = useLogOut();

  return (
    <>{isSuccess || isError ? <Redirect to={"/login"}></Redirect> : null}</>
  );
}
