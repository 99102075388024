import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { GuestItem } from "../../../components/event/GuestItem";
import { EmptyBox } from "../../../components/empty/EmptyBox";
import { useTranslation } from "react-i18next";
import { PollEventGuestResult } from "../../../app/services/poll/types";

const GuestItemListContainer = styled(Box)`
  width: 100%;
`;

interface GuestItemListProps {
  guests?: PollEventGuestResult[];
}

export const GuestItemList = ({ guests }: GuestItemListProps) => {
  const { t } = useTranslation("pollComplete");
  return (
    <GuestItemListContainer>
      {!guests?.length && (
        <EmptyBox height={200}>
          <Box className="empty-emoji">{t("guestList.EmptyEmoji")}</Box>
          <Typography className="empty-description">
            {t("guestList.EmptyDescription")}
          </Typography>
        </EmptyBox>
      )}
      {!!guests?.length &&
        guests?.map((guest) => <GuestItem guest={guest} isMe={false} />)}
    </GuestItemListContainer>
  );
};
