import React, { useCallback, useState } from "react";
import {
  Box,
  IconButton,
  Snackbar,
  Typography,
  Button,
  Link,
} from "@mui/material";
import styled, { css } from "@mui/styled-engine";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/SendOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDesktopMatches } from "../../../hooks/useDesktopMatches";

const StyledUrlView = styled(Box)<{
  backgroundColor?: string;
  isDesktop: boolean;
}>`
  position: relative;
  padding: 16px 20px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  ${({ isDesktop }) =>
    isDesktop
      ? css`
          align-items: center;
          flex-direction: row;
        `
      : css`
          align-items: flex-start;
          flex-direction: column;
        `};
  > .url-view {
    &-title {
      font-size: 18px;
      font-weight: 700;
      color: #a7a7a7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 16px;
    }
    &-url {
      display: flex;
      align-items: center;
      flex: 1;
      > span {
        font-size: 18px;
        color: #000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1;
      }
    }
  }
`;

const StyledButtonContainer = styled(Box)`
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  top: 16px;
  right: 20px;
`;

const StyledActionButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  color: #333;
  min-width: auto;
  padding: 4px;
  line-height: 1.2;
  white-space: nowrap;
  margin-right: 61px;
  &:before {
    position: absolute;
    content: " ";
    top: 50%;
    transform: translate(0, -50%);
    right: -30px;
    width: 1px;
    height: 25px;
    background-color: #979797;
  }
  &:last-of-type {
    margin-right: 0;
    &:before {
      display: none;
    }
  }
`;

const iconCss = css`
  font-size: 14px;
  margin-right: 6px;
`;

const ShareIcon = styled(SendIcon)`
  ${iconCss}
`;

const CopyIcon = styled(ContentCopyIcon)`
  ${iconCss}
`;

interface UrlViewProps {
  url?: string;
  title?: string;
  backgroundColor?: string;
  layout?: "horizontal" | "vertical";
}

export function UrlView({
  url,
  title,
  backgroundColor = "#ffffff",
  layout,
}: UrlViewProps) {
  const { t } = useTranslation("urlView");
  const isDesktop = useDesktopMatches();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);

  const isShareAvailable = window.navigator.share;

  const webShare = () => {
    if (window.navigator.share) {
      window.navigator.share({
        title,
        text: t("ShareText", {
          title,
        }),
        url,
      });
    }
  };

  return (
    <>
      <StyledUrlView
        component="div"
        backgroundColor={backgroundColor}
        isDesktop={
          typeof layout === "string" ? layout === "horizontal" : isDesktop
        }
      >
        <Typography component="span" className="url-view-title">
          {t("Title")}
        </Typography>
        <Box component="div" className="url-view-url">
          <Typography component="span" title={url} textOverflow={"ellipsis"}>
            <Link
              href={url}
              target={"_blank"}
              color="inherit"
              textOverflow={"ellipsis"}
            >
              {url}
            </Link>
          </Typography>
        </Box>
        <StyledButtonContainer>
          {isShareAvailable && (
            <StyledActionButton variant="text" onClick={webShare}>
              <ShareIcon />
              {t("Share")}
            </StyledActionButton>
          )}
          <CopyToClipboard
            text={url || ""}
            onCopy={() => setIsSnackbarOpen(true)}
          >
            <StyledActionButton variant="text">
              <CopyIcon />
              {t("Copy")}
            </StyledActionButton>
          </CopyToClipboard>
        </StyledButtonContainer>
      </StyledUrlView>
      <Snackbar
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={t("CopiedMessage")}
      />
    </>
  );
}
