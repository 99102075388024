import React, { useCallback, useEffect, useState } from "react";
import {
  GuestAddButton,
  GuestAddWidgetDescription,
  GuestAddWidgetTitle,
  StyledGuestAddWidgetBox,
} from "../common/GuestAddWidget";
import { UrlView } from "../common/UrlView";
import {
  useGetPollInfoQuery,
  useGetRecentGuestsQuery,
  usePostInviteGuestsMutation,
} from "../../../app/services/poll/pollApi";
import { GuestAddModal } from "../common/GuestAddModal";
import { PollEventGuestCandidate } from "../../../app/services/poll/types";

export interface CompletePollEventGuestAddWidgetProps {
  eventId: number;
}
export function CompletePollEventGuestAddWidget({
  eventId,
}: CompletePollEventGuestAddWidgetProps) {
  const { data: pollInfo } = useGetPollInfoQuery({ id: eventId });
  const [isGuestModalOpen, setGuestModalOpen] = useState<boolean>(false);
  const { data: recentlyInvitedGuests } = useGetRecentGuestsQuery();

  const [inviteGuests, { isSuccess, isLoading }] =
    usePostInviteGuestsMutation();

  const handleGuestsAdd = useCallback(
    (guests: PollEventGuestCandidate[]) => {
      inviteGuests({ id: +eventId, guests });
    },
    [eventId, inviteGuests]
  );

  useEffect(() => {
    if (isSuccess) {
      setGuestModalOpen(false);
    }
  }, [isSuccess]);

  return pollInfo ? (
    <StyledGuestAddWidgetBox>
      <GuestAddWidgetTitle />
      <GuestAddWidgetDescription />
      <UrlView url={pollInfo.url} title={pollInfo.title} />
      <GuestAddButton onClick={() => setGuestModalOpen(true)} />
      <GuestAddModal
        isAddLoading={isLoading}
        recentlyInvitedGuests={recentlyInvitedGuests?.guests || []}
        open={isGuestModalOpen}
        onClose={() => setGuestModalOpen(false)}
        onSubmit={handleGuestsAdd}
      />
    </StyledGuestAddWidgetBox>
  ) : (
    <></>
  );
}
