import { useEffect, useState } from "react";

export interface FormValidationValue {
  [k: string]: any;
}

export interface ValidationOption<T> {
  validator?: (value: T) => boolean;
  required: boolean;
  specialCharacterNotAllowed?: boolean;
  min?: number;
  max?: number;
}
export type ValidationOptions<T extends FormValidationValue> = Record<
  keyof T,
  ValidationOption<T>
>;

export interface IsFormInputValid {
  [t: string]: boolean;
}

function initInputValid(valueKeys: string[], initValue: boolean) {
  return valueKeys.reduce((result, key) => {
    result[key] = initValue;
    return result;
  }, {} as IsFormInputValid);
}

export interface FormValidation {
  isFormatValid: IsFormInputValid;
  isFulfillValid: IsFormInputValid;
  isTotalValid: boolean;
}

export const initFormValidation: FormValidation = {
  isFormatValid: {},
  isFulfillValid: {},
  isTotalValid: false,
};
// FIXME: required 인 경우만 fulfill 기본 값 false
export function useFormValidation<T extends FormValidationValue>(
  valueKeys: string[],
  value: T,
  validationOptions: ValidationOptions<T>
): FormValidation {
  const [isFormatValid, setIsFormatValid] = useState<IsFormInputValid>(() =>
    initInputValid(valueKeys, true)
  ); // empty 일 때는 true 이다.
  const [isFulfillValid, setIsFulfillValid] = useState<IsFormInputValid>(() =>
    initInputValid(valueKeys, false)
  );
  const [isTotalValid, setIsTotalValid] = useState(
    initFormValidation.isTotalValid
  );

  useEffect(() => {
    if (value !== undefined) {
      const newFormatValid = valueKeys.reduce((result, key) => {
        const { validator } = validationOptions[key];
        if (value[key] === undefined) result[key] = true;
        else if (
          typeof value[key] === "string" &&
          (value[key] as string).trim() === ""
        )
          result[key] = true;
        else if (validator) result[key] = validator(value);
        else result[key] = true;
        return result;
      }, {} as IsFormInputValid);
      setIsFormatValid(newFormatValid);
    }
  }, [valueKeys, value, validationOptions]);

  useEffect(() => {
    if (value !== undefined) {
      const newFulfillValid = valueKeys.reduce((result, key) => {
        const { required } = validationOptions[key];
        if (required) {
          if (typeof value[key] === "string") {
            result[key] = value[key] !== undefined && value[key].trim() !== "";
          } else {
            result[key] = value[key] !== undefined;
          }
        } else {
          result[key] = true;
        }
        return result;
      }, {} as IsFormInputValid);
      setIsFulfillValid(newFulfillValid);
    }
  }, [valueKeys, value, validationOptions]);

  useEffect(() => {
    // 모두 validate 한지 확인
    for (const key of valueKeys) {
      const isValid = isFormatValid && isFormatValid[key];
      if (!isValid) {
        return setIsTotalValid(false);
      }
    }

    for (const key of valueKeys) {
      const isValid = isFulfillValid && isFulfillValid[key];
      if (!isValid) {
        return setIsTotalValid(false);
      }
    }
    setIsTotalValid(true);
  }, [valueKeys, isFormatValid, isFulfillValid]);

  return { isFormatValid, isFulfillValid, isTotalValid };
}
