import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const name = "globalSlice";

interface ErrorMessageSource {
  reducerPath: string;
  endpointName: string;
}

export interface GlobalSliceState {
  errorMessage?: ErrorMessageSource;
}

const initialState: GlobalSliceState = {
  errorMessage: undefined,
};

export const globalSlice = createSlice({
  name,
  initialState,
  reducers: {
    setErrorMessage: (
      state,
      action: PayloadAction<ErrorMessageSource | undefined>
    ) => {
      state.errorMessage = action.payload;
    },
  },
});

export const globalSliceSelectors = {
  selectState: (state: RootState) => state[globalSlice.name],
};
