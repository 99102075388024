import React, { useState } from "react";
import styled from "@mui/styled-engine";
import { Box, Button, TextField } from "@mui/material";
import {
  BigTitle,
  ContentTitle,
  MainTitle,
  SubDescription,
} from "../../../styled/typography";
import { SelectedEvent, SelectedEventList } from "../common/SelectedEventList";
import moment from "moment";

const SelectCompleteMainBox = styled(Box)`
  padding: 0 24px;
  max-width: 778px;
  margin: 58px auto;
`;

const SelectCompleteMainEmailSendContainer = styled(Box)`
  padding: 34px 22px;
  border-radius: 10px;
  background-color: #f5f5f5;
`;

const SelectCompleteMainEmailInputContainer = styled(Box)`
  display: flex;
`;

const SelectCompleteMainEmailInputBox = styled(Box)`
  width: 100%;
  padding: 0 22px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
`;

const SelectCompleteMainEmailInputLabel = styled(Box)`
  font-size: 18px;
  font-weight: bold;
  color: #a7a7a7;
  flex: 0 0 100px;
  width: 100px;
`;

const SelectCompleteMainEmailInputField = styled(TextField)`
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 19px 0 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
`;

export const SelectCompleteMain = () => {
  const [isRegistered, setRegistered] = useState(false);
  const userName = "장유진";
  const length = 5;

  const selectedEvents: SelectedEvent[] = [
    {
      id: "1",
      start: moment().toDate(),
      end: moment().toDate(),
    },
  ];
  return (
    <SelectCompleteMainBox>
      <BigTitle sx={{ marginBottom: "38px" }}>
        <strong>{userName}</strong>님이 선택한
        <br />
        <strong>{length}건</strong>의 일정이 저장되었습니다.
      </BigTitle>
      <SelectCompleteMainEmailSendContainer sx={{ marginBottom: "32px" }}>
        <MainTitle sx={{ margin: "0 0 22px 22px", whiteSpace: "pre-line" }}>
          {!isRegistered
            ? "일정이 확정되면 이메일로 안내받으시겠어요?"
            : "장조이님의 이메일이 등록되었습니다.\n일정 확정 등 업데이트 소식을 이메일로 안내드릴게요!"}
        </MainTitle>
        {!isRegistered && (
          <SubDescription sx={{ margin: "0 0 32px 22px" }}>
            {
              "이메일 등록 시 이벤트 관련 업데이트 내용이 이메일로 발송됩니다.\n(이벤트 확정일, 이벤트 시작 전 알림 등)"
            }
          </SubDescription>
        )}
        <SelectCompleteMainEmailInputContainer>
          <SelectCompleteMainEmailInputBox>
            <SelectCompleteMainEmailInputLabel>
              이메일
            </SelectCompleteMainEmailInputLabel>
            {
              <SelectCompleteMainEmailInputField
                InputProps={{
                  readOnly: isRegistered,
                }}
              />
            }
          </SelectCompleteMainEmailInputBox>
          {!isRegistered && (
            <Button
              variant="outlined"
              color="inherit"
              sx={{ marginLeft: "14px", width: "192px" }}
              onClick={() => setRegistered(true)}
            >
              등록하기
            </Button>
          )}
        </SelectCompleteMainEmailInputContainer>
      </SelectCompleteMainEmailSendContainer>
      <ContentTitle sx={{ marginBottom: "22px" }}>
        선택일정 <strong>4</strong>건
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          sx={{ marginLeft: "22px" }}
        >
          일정 수정하기
        </Button>
      </ContentTitle>
      <SelectedEventList events={selectedEvents} />
    </SelectCompleteMainBox>
  );
};
