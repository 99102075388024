import { useEffect, useState } from "react";
import { isDeletedOrFinishedEvent } from "../utils/isFinishedEvent";
import { EventWithGuests } from "../app/services/events/types";

export function useIsDeletedOrFinishedEvent(eventInfo?: EventWithGuests) {
  const [isDeletedOrFinished, setIsDeletedOrFinished] = useState(false);

  useEffect(() => {
    setIsDeletedOrFinished(isDeletedOrFinishedEvent(eventInfo));
  }, [eventInfo]);
  return isDeletedOrFinished;
}
