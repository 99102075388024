import React from "react";
import styled from "@mui/styled-engine";
import { Box } from "@mui/material";
import { BigTitle, SubDescription } from "../../../styled/typography";
import { UrlView } from "../common/UrlView";
import { FormItem, FormList } from "../common/FormItem";
import { useTranslation } from "react-i18next";
import { PollEventResponse } from "../../../app/services/poll/types";
import {
  GuestReadOnlyItem,
  GuestReadOnlyItemList,
} from "../common/GuestReadOnlyItem";

const CreateEventSettingBox = styled(Box)``;

export const SelectEventSetting = ({
  pollInfo,
}: {
  pollInfo: PollEventResponse | undefined;
}) => {
  const { t } = useTranslation("eventInputList"); // eventInputList 옮기기
  return (
    <CreateEventSettingBox>
      <BigTitle sx={{ marginBottom: "38px", padding: "0 20px" }}>
        <strong>[{pollInfo?.title}]</strong> 이벤트에 초대되셨습니다.
      </BigTitle>
      <UrlView url={pollInfo?.url} title={pollInfo?.title} layout="vertical" />
      <SubDescription sx={{ margin: "10px 0 56px 10px" }}>
        {"* 초대 URL을 통해 다른 게스트를 초대할 수 있습니다."}
      </SubDescription>
      <FormList sx={{ padding: "0 10px" }}>
        <FormItem
          label={t(`labels.title`)}
          content={pollInfo?.title}
          direction="column"
        />
        <FormItem
          label={t(`labels.description`)}
          content={pollInfo?.description}
          direction="column"
        />
        <FormItem
          label={t(`labels.event_type`)}
          content={
            pollInfo?.event_type
              ? t(`eventTypeLabels.${pollInfo?.event_type}`)
              : ""
          }
          direction="column"
        />
        <FormItem
          label={t(`labels.place_detail`)}
          content={pollInfo?.place_detail}
          direction="column"
        />
        <FormItem
          label={"이벤트 초대자"}
          content={
            <GuestReadOnlyItemList>
              {pollInfo ? (
                <GuestReadOnlyItem guest={pollInfo?.host_info} />
              ) : null}
            </GuestReadOnlyItemList>
          }
          direction="column"
        />
        <FormItem
          label={`초대받은 게스트 (${pollInfo?.guests?.length || 0}명)`}
          content={
            <GuestReadOnlyItemList>
              {pollInfo?.guests?.map((guest) => (
                <GuestReadOnlyItem guest={guest} key={guest.key}>
                  <Box
                    component="span"
                    sx={{ fontSize: "14px", color: "#b0b0b0" }}
                  >
                    {guest.status === 1
                      ? "참석"
                      : guest.status === 2
                      ? "불참"
                      : "미정"}
                  </Box>
                </GuestReadOnlyItem>
              ))}
            </GuestReadOnlyItemList>
          }
          direction="column"
        />
      </FormList>
    </CreateEventSettingBox>
  );
};
