import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/EditOutlined";

import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import validator from "validator";

import { GuestWithBgColor } from "../../app/services/events/types";
import { FormBox } from "../commons/FormBox";
import { TextFieldWithValidation } from "../inputs/TextFieldWithValidation";
import { ContainerWithTitle } from "../commons/ContainerWithTitle";
import {
  useFormValidation,
  ValidationOptions,
} from "../../hooks/useFormValidation";
import { StyledAvatar } from "../styled/StyledAvatar";
import styled, { css } from "@mui/styled-engine";
import { useDesktopMatches } from "../../hooks/useDesktopMatches";

const StyledTextField = styled(TextField)`
  .MuiInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
`;

const StyledGuestItemContent = styled(Box)<{
  isDesktop: boolean;
}>`
  display: flex;
  flex: 1 1 auto;
  ${(props) =>
    props.isDesktop
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : css`
          flex-direction: column;
        `}
  > .guest-item-content {
    &-email {
      width: 172px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-divider {
      width: 1px;
      height: 21px;
      margin: 0 30px;
      background-color: #d1d1d1;
    }

    &-name {
      display: inline-flex;
      align-items: center;
      & input {
        width: auto;
        padding: 0;
      }
    }
  }
`;

export interface InviteGuestsToEventFormValue {
  input?: GuestWithBgColor;
  guests?: GuestWithBgColor[];
}

export const initValue: InviteGuestsToEventFormValue = {
  input: undefined,
  guests: [],
};

export interface InviteGuestsToEventFormProps {
  value: InviteGuestsToEventFormValue;
  recentEmails?: GuestWithBgColor[];
  onChange?: (value: InviteGuestsToEventFormValue) => void;
  noMinusMargin?: boolean;
}

const valueKeys: (keyof InviteGuestsToEventFormValue)[] = ["input"];
export const inputValidators: ValidationOptions<InviteGuestsToEventFormValue> =
  {
    input: {
      validator: (d) => validator.isEmail(d?.input?.email || ""),
      required: false,
    },
    guests: { required: false },
  };

const maxGuestsSize = 20;

export function InviteGuestsToEventForm({
  value,
  recentEmails,
  onChange,
  noMinusMargin = false,
}: InviteGuestsToEventFormProps) {
  const { t } = useTranslation("inviteGuestsToEvent");
  const { isFormatValid, isTotalValid } = useFormValidation(
    valueKeys,
    value,
    inputValidators
  );
  const [isExist, setIsExist] = useState(false);
  const [isOverflow, setIsOverFlow] = useState(false);
  const isDesktop = useDesktopMatches();

  const onAddClick = useCallback(() => {
    if (onChange && isTotalValid && !isOverflow && value.input) {
      onChange({
        input: undefined,
        guests: [...(value?.guests || []), value.input],
      });
    }
  }, [onChange, value, isTotalValid, isOverflow]);

  const handleAutoInputChange = useCallback(
    (e, newInput) => {
      const typedInput = newInput as GuestWithBgColor;
      if (onChange) onChange({ ...value, input: typedInput });
      //setSelectedRecentEmail(value);
    },
    [value, onChange]
  );
  const handleInputChange = useCallback(
    (_, newValue) => {
      if (onChange)
        onChange({
          ...value,
          input: newValue ? { email: newValue.trim() } : undefined,
        });
    },
    [value, onChange]
  );

  const handleGuestNameChange = useCallback(
    (i: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange)
          onChange({
            ...value,
            guests: [
              ...value!.guests!.slice(0, i),
              { ...value!.guests![i], name: e.target.value },
              ...value!.guests!.slice(i + 1),
            ],
          });
      };
    },
    [value, onChange]
  );

  const handleGuestDelete = useCallback(
    (i: number) => () => {
      if (onChange) {
        onChange({
          ...value,
          guests: [
            ...value!.guests!.slice(0, i),
            ...value!.guests!.slice(i + 1),
          ],
        });
      }
    },
    [value, onChange]
  );

  useEffect(() => {
    setIsOverFlow(
      value.guests !== undefined && value.guests.length >= maxGuestsSize
    );
  }, [value.guests]);

  useEffect(() => {
    if (value.guests) {
      const isExist =
        value.guests.find((d) => d.email === value.input?.email) !== undefined;
      setIsExist(isExist);
    } else {
      setIsExist(false);
    }
  }, [value]);

  return (
    <ContainerWithTitle
      title={t("Title")}
      description={t("Description")}
      descriptionMarginTop={3}
      isWrapper={true}
    >
      <FormBox noMinusMargin={noMinusMargin}>
        <Box
          sx={{ display: "flex", flexDirection: isDesktop ? "row" : "column" }}
        >
          <Autocomplete
            sx={{
              flex: 1,
              "& input": {
                height: 16,
              },
            }}
            freeSolo={true}
            disableClearable={false}
            value={value.input || { email: "" }}
            onChange={handleAutoInputChange}
            inputValue={value.input?.email}
            onInputChange={handleInputChange}
            options={recentEmails || []}
            getOptionLabel={(option: any) => {
              return typeof option === "string" ? option : option?.email;
            }}
            renderInput={(params) => (
              <TextFieldWithValidation
                {...params}
                placeholder={t("labels.Input")}
                errorMessage={
                  isFormatValid["input"] ? undefined : t("invalidFormats.Input")
                }
              />
            )}
          />
          <Box
            component="div"
            sx={{
              textAlign: "right",
              marginLeft: "20px",
              marginTop: isDesktop ? 0 : "12px",
            }}
          >
            <Button
              variant={"cta"}
              color={"secondary"}
              onClick={onAddClick}
              type={"submit"}
              disabled={!isTotalValid || isExist || isOverflow}
              sx={{
                width: 144,
                height: 48,
              }}
            >
              {t("Add")}
            </Button>
          </Box>
        </Box>
        {!!value?.guests?.length && (
          <List
            sx={{
              paddingTop: "8px",
              paddingBottom: 0,
              marginBottom: "0 !important",
            }}
          >
            {value?.guests?.map((guest, i) => {
              return (
                <ListItem
                  key={`${guest.email}-${i}`}
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    borderBottom: "solid 1px #e1e1e1",
                    "&:last-of-type": {
                      borderBottom: 0,
                      paddingBottom: 0,
                    },
                  }}
                >
                  <ListItemAvatar>
                    <StyledAvatar bgColor={guest?.bg_color || "gray"}>
                      {(guest?.name || guest.email).substr(0, 1).toUpperCase()}
                    </StyledAvatar>
                  </ListItemAvatar>
                  <StyledGuestItemContent isDesktop={isDesktop}>
                    <Box className="guest-item-content-email">
                      {guest.email}
                    </Box>
                    {isDesktop && (
                      <Box
                        className="guest-item-content-divider"
                        component="span"
                      />
                    )}
                    <Box className="guest-item-content-name" component="label">
                      <StyledTextField
                        sx={{
                          width: "130px",
                        }}
                        value={guest.name}
                        variant="standard"
                        placeholder={t("labels.Name")}
                        onChange={handleGuestNameChange(i)}
                      />
                      <EditIcon
                        sx={{
                          width: "16px",
                          color: "#767676",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </StyledGuestItemContent>
                  <IconButton
                    aria-label="delete"
                    color={"error"}
                    onClick={handleGuestDelete(i)}
                    sx={{ marginRight: "-8px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </FormBox>
    </ContainerWithTitle>
  );
}
