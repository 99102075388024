import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { PollEventType } from "../../../app/services/poll/types";

const name = "createEventSlice";

export interface CreateEventState {
  title: string;
  description: string;
  eventType?: PollEventType;
  placeDetail?: string;
}

const initialState: CreateEventState = {
  title: "",
  description: "",
  eventType: undefined,
  placeDetail: undefined,
};

export const createEventSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setEventType: (state, action: PayloadAction<PollEventType | undefined>) => {
      state.eventType = action.payload;
    },
    setPlaceDetail: (state, action: PayloadAction<string | undefined>) => {
      state.placeDetail = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});

export const createEventSelectors = {
  selectState: (state: RootState) => state[name] as CreateEventState,
};
