import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit";
import { globalSlice } from "../features/global/globalSlice";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && action?.meta?.arg?.type === "mutation") {
      if (action?.type) {
        const reducerPath = action.type.replace(
          /\/executeMutation\/rejected$/i,
          ""
        );
        const endpointName = action.meta.arg?.endpointName;
        if (reducerPath && endpointName) {
          // mutation 에서 에러 발생했을 때 추가
          next({
            type: globalSlice.actions.setErrorMessage.type,
            payload: {
              reducerPath,
              endpointName,
            },
          });
        }
      }
    }

    return next(action);
  };
